import { useCallback, useState } from 'react'
import ApiService from '../../../services/ApiService'
import { decryptCrypto, encryptCrypto } from '../../../utility/Encryption'
import { getItem, setItem } from '../../../utility/LocalStorage'
import { Buffer } from 'buffer'

const InteractorProductItemSummary = () => {
  const [payloadInitial] = useState({
    page: 1,
    limit: 10,
    keywords: {
      province_uid: null,
      category_main_uid: null,
      category_major_uid: null,
      category_minor_uid: null,
      store_uid: null,
      price_range: {
        minimum: null,
        maximum: null,
      },
      rating: null,
      text_search: null,
      sort_by: null,
    },
  })
  const productSummary = useCallback(
    (body, callback, loading) => {
      const payload = {
        ...payloadInitial,
        limit: body.limit,
        keywords: {
          ...payloadInitial.keywords,
          sort_by: body.keywords.sort_by,
        },
      }
      // SESSION
      const session_key = Buffer.from(payload.keywords.sort_by + payload.limit).toString('base64')
      const session_data = getItem(session_key) ? decryptCrypto(getItem(session_key)) : null
      if (session_data && session_data?.expires > new Date().getTime()) {
        callback(session_data)
        loading(false)
      } else {
        ApiService.jsonRequest('/product-item/search', payload, (response) => {
          if (response.status_code === 200) {
            if (response.data) {
              callback(response.data)
              let session_data = { ...response.data }
              session_data.expires = new Date().getTime() + 1 * 60 * 1000 // 1 Minutes
              setItem(session_key, encryptCrypto(JSON.stringify(session_data)))
            }
          }
          loading(false)
        })
      }
    },
    [payloadInitial],
  )
  return { productSummary }
}

export default InteractorProductItemSummary
