import React from 'react'
import ProductSkeleton from '../../assets/image/skeleton/product.png'

const ProductSkeletonTile = () => {
  return (
    <>
      {Array.from(Array(8), (_, idx) => (
        <li key={idx} className="product-item remove-divider mb-2 product-container-tile w-100">
          <div className="product-item__outer w-100">
            <div className="product-item__inner remove-prodcut-hover py-2 row">
              <div className="d-flex w-100">
                <div className="product-item__header col-6 col-md-3">
                  <div className="">
                    <div to="/product" className="d-block text-center">
                      <img className="img-fluid" src={ProductSkeleton} alt="" />
                    </div>
                  </div>
                </div>
                <div className="product-item__body col-6 col-md-6">
                  <div className="pr-lg-10">
                    <div className="mb-2 skeleton-loader width-122 height-10"></div>
                    <div className="mb-3 product-item__title skeleton-loader width-400 height-14"></div>
                    <ul className="font-size-12 p-0 text-gray-110 mb-4 d-none d-md-block list-style-none">
                      <li className="line-clamp-1 mb-1 skeleton-loader width-200 height-20"></li>
                      <li className="line-clamp-1 mb-1 skeleton-loader width-200 height-14"></li>
                    </ul>
                    <div className="mb-3 d-none d-md-flex align-items-center skeleton-loader width-300 height-14"></div>
                  </div>
                </div>
                <div className="product-item__footer col-md-3 d-md-block align-self-center mx-5">
                  <div className="mb-2 flex-center-between">
                    <div className="btn btn-xs col-lg-10 height-35 skeleton-loader"></div>
                  </div>
                  <div>
                    <div className="btn btn-xs col-lg-10 height-35 skeleton-loader"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      ))}
    </>
  )
}

export default ProductSkeletonTile
