import { useMemo, useState } from 'react'

function useHover(ref) {
  const [isOpen, setIsOpen] = useState(false)
  const handleShow = useMemo(
    () => ({
      onMouseOver() {
        setIsOpen(true)
      },
      onMouseLeave(e) {
        const eventBound = e.currentTarget.getBoundingClientRect()
        const refBound = ref.current?.getBoundingClientRect()

        if (refBound) {
          if (e.clientY < eventBound.top) {
            setIsOpen(false)
          }

          if (e.clientY > refBound.bottom) {
            setIsOpen(false)
          }

          if (e.clientX < refBound.left || e.clientX < eventBound.left) {
            setIsOpen(false)
          }
          if (e.clientX > refBound.right || e.clientX > eventBound.right) {
            setIsOpen(false)
          }
        }
      },
    }),
    [ref],
  )

  return [isOpen, handleShow]
}

export default useHover
