import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ProductItemSearch } from '../../redux/product/search/actionCreator'
import { inputNumber, numberFormat, numberFormatDestroy } from '../../utility/Helpers.js'

export const FilterPrice = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { payload } = useSelector((state) => state.productItemSearch)
  const [payloadPrice, setPayloadPrice] = useState({})
  const [inputPrice, setInputPrice] = useState({
    minimumPrice: '',
    maximumPrice: '',
  })
  const handleChangeInputPrice = (e) => {
    const { value, name } = e.currentTarget
    const inputValue = inputNumber(numberFormatDestroy(value))
    inputValue !== ''
      ? setInputPrice({
          ...inputPrice,
          [name]: numberFormat(inputValue),
        })
      : setInputPrice({
          ...inputPrice,
          [name]: '',
        })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let prevPayload = { ...payloadPrice }
    prevPayload.keywords.price_range.minimum = +numberFormatDestroy(inputPrice.minimumPrice)
    prevPayload.keywords.price_range.maximum = +numberFormatDestroy(inputPrice.maximumPrice)
    dispatch(ProductItemSearch(prevPayload))
  }

  useEffect(() => {
    setPayloadPrice(payload)
  }, [payload, inputPrice])

  return (
    <div className="mb-5">
      <div className="border-bottom border-color-1 mb-3">
        <h4 className="section-title section-title__sm mb-0 pb-2 font-size-18">
          {t('title.price')}
        </h4>
      </div>
      <div className="range-slider">
        <input
          className="js-range-slider mb-3 inputTextNumberic border-radius-25"
          type="text"
          placeholder={t('placeholder.minimum_price')}
          name="minimumPrice"
          value={inputPrice.minimumPrice}
          onChange={handleChangeInputPrice}
        />
        <input
          className="js-range-slider mb-3 border-radius-25"
          type="text"
          placeholder={t('placeholder.maximum_price')}
          name="maximumPrice"
          value={inputPrice.maximumPrice}
          onChange={handleChangeInputPrice}
        />
        <div className="mt-1 text-gray-111 d-flex mb-0"></div>
        <button
          type="submit"
          className="btn px-4 btn-primary-dark-w py-2 rounded-lg mb-3"
          onClick={handleSubmit}
        >
          {t('title.filter')}
        </button>
      </div>
    </div>
  )
}
