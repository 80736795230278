import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const PaginationNavigate = (props) => {
  const {
    containerClassName,
    pageNumberClassName,
    buttonPageClassName,
    prevLabel,
    nextLabel,
    totalPage,
    onChangePage,
    activePage,
  } = props
  const [currentPage, setCurrentPage] = useState(1)

  const handleNextPage = () => {
    if (currentPage < totalPage) {
      let nextPage = currentPage + 1
      onChangePage(nextPage)
      setCurrentPage(nextPage)
    }
  }
  const handlePrevPage = () => {
    if (currentPage > 1) {
      let nextPage = currentPage - 1
      onChangePage(nextPage)
      setCurrentPage(nextPage)
    }
  }
  useEffect(() => {
    setCurrentPage(activePage)
  }, [activePage])
  return (
    <>
      <nav className={containerClassName}>
        {totalPage > 0 && (
          <div className={pageNumberClassName}>
            {currentPage}/{totalPage}
          </div>
        )}
        <div className="d-flex bg-white">
          <button className={buttonPageClassName} onClick={handlePrevPage}>
            {prevLabel}
          </button>
          <button className={buttonPageClassName} onClick={handleNextPage}>
            {nextLabel}
          </button>
        </div>
      </nav>
    </>
  )
}

PaginationNavigate.propTypes = {
  totalPage: PropTypes.number,
  containerClassName: PropTypes.string,
  pageNumberClassName: PropTypes.string,
  buttonPageClassName: PropTypes.string,
  prevLabel: PropTypes.node,
  nextLabel: PropTypes.node,
  onChangePage: PropTypes.func,
  activePage: PropTypes.number,
}

export default PaginationNavigate
