import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-responsive-modal'
import { Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  Breadcrumb,
  Pagination,
  PaginationNavigate,
  Rating,
  ProductListTile,
  SidebarFilterProduct,
  ProductSkeletonGrid,
  ProductSkeletonTile,
  Loading,
  ProductGridStore,
} from '../../components/Main'
import { InteractorProductItemSearch, InteractorStoreView } from '../../interactors/Main'
import profileDefault from '../../assets/image/profile/profile.png'
import { formatCash } from '../../utility/Helpers'
const StoreDetail = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { productSearch } = InteractorProductItemSearch()
  const { storeView } = InteractorStoreView()
  const { account } = useSelector((state) => state.account)
  const [currentLayout, setCurrentLayout] = useState('GRID')
  const onChangeLayout = (value) => setCurrentLayout(value)
  const [products, setProducts] = useState({})
  const [productPayload, setProductPayload] = useState({
    page: 1,
    limit: 18,
    keywords: {
      province_uid: null,
      category_main_uid: null,
      category_major_uid: null,
      category_minor_uid: null,
      store_uid: id,
      price_range: {
        minimum: null,
        maximum: null,
      },
      rating: null,
      text_search: '',
      sort_by: 'latest',
      source: 'MARKETPLACE',
      status: '',
    },
  })
  const [productLoading, setProductLoading] = useState(true)
  const handlePageChange = (page) => {
    setProductPayload((prev) => ({ ...prev, page }))
    setProductLoading(true)
  }
  const handleSortByFilter = (e) => {
    e.preventDefault()
    setProductPayload((prev) => ({
      ...prev,
      page: 1,
      keywords: { ...prev.keywords, sort_by: e.target.value },
    }))
    setProductLoading(true)
  }

  const [storeData, setStoreData] = useState({})
  const [storeLoading, setStoreLoading] = useState(true)
  const [isShow, setIsShow] = useState(false)
  const sidebarRef = useRef()

  const handleOpenModal = () => setIsShow(true)
  const handleCloseModal = () => setIsShow(false)

  useEffect(() => {
    if (storeLoading) storeView({ uid: id }, setStoreData, setStoreLoading)
    if (!storeLoading && productLoading) {
      productSearch(productPayload, setProducts, setProductLoading)
    }
  }, [id, productLoading, productPayload, productSearch, storeLoading, storeView])

  return (
    <>
      <Breadcrumb title={t('menu.store_detail')} className="mb-4" />
      <Modal
        open={isShow}
        onClose={() => handleCloseModal()}
        aria-labelledby="profile-store-title"
        aria-describedby="profile-store-description"
        classNames={{
          modal: 'p-5 mx-auto my-4 w-100 w-md-75',
        }}
        center
      >
        <h4 id="profile-store-title" className="mb-4">
          {t('title.company_profile')}
        </h4>
        <p id="profile-store-description">{storeData.company_information}</p>
      </Modal>
      <div className="container">
        <div className="row">
          <div className="col-12">
            {storeLoading ? (
              <div className="d-flex justify-content-center align-items-center height-100vh">
                <Loading />
              </div>
            ) : (
              <div className="d-flex flex-column flex-lg-row w-100 py-4 mb-2 align-items-center justify-content-center card px-2">
                <div className="col-12 col-lg-3 justify-content-center">
                  <div className="p-2 w-50 h-50 d-flex align-items-center justify-content-center my-0 mx-auto">
                    <img
                      className="img-fluid object-fit-cover rounded-circle"
                      style={{ minWidth: '100%', minHeight: '100%' }}
                      src={storeData.store_logo ? storeData.store_logo : profileDefault}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-5 my-3">
                  <h4 className="font-weight-bold font-size-20 font-size-20-lg mb-2 store-title">
                    {storeData.store_name}
                  </h4>
                  <div className="font-size-16 mb-2">
                    {t('title.tax_status')}: {t(`title.tax_type.${storeData.tax_type}`)}
                  </div>
                  <div className="font-size-16 mb-2">{storeData.address_detail}</div>
                  <div className="col-12 pl-0 d-flex flex-column flex-md-row align-items-center justify-content-center mt-4">
                    {account?.uid !== storeData?.account_uid && (
                      <div className="col-12 col-md-6">
                        <Link
                          to={`/chat?seller=${id}`}
                          className="btn btn-xs mb-2 btn-primary mr-lg-2 font-size-14 w-100"
                        >
                          <i className="fa fa-comments mx-1"></i>
                          {t('button.chat_seller')}
                        </Link>
                      </div>
                    )}
                    <div className="col-12 col-md-6">
                      <div
                        className="btn btn-xs mb-2 btn-dark active font-size-14 w-100 pointer"
                        onClick={() => handleOpenModal()}
                      >
                        <i className="fa fa-store-alt mx-1"></i>
                        {t('title.company_profile')}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 py-2 d-flex flex-column justify-content-center align-self-stretch ml-md-4 ml-lg-0 bg-primary-light rounded-lg">
                  <div className="row">
                    <div className="col">
                      <div className="font-weight-bold font-size-16">
                        {t('title.average_rating')}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2 mb-3 justify-content-center font-size-16">
                    <div className="col-auto mr-4">
                      <Rating rating={storeData.store_rating} />
                    </div>
                    <div className="col">
                      {storeData.store_rating} / ({storeData.store_total_review}){' '}
                      {t('title.rating')}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-auto">
                      <div className="font-weight-bold font-size-16">
                        {t('title.product.title')} {t('title.sold')}
                      </div>
                    </div>
                    <div className="col font-size-16">
                      {`${formatCash(
                        parseFloat(storeData.product_sold.toString().replace(',', '')),
                      )} ${t('title.sold')}`}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row mb-8">
          <div className="col-12">
            <h4>{t('title.product.title')}</h4>
            <div className="bg-gray-1 flex-center-between borders-radius-9 py-1">
              <div className="d-xl-none">
                <SidebarFilterProduct sidebarRef={sidebarRef}>
                  <i className="fas fa-sliders-h" />
                  <span className="ml-1">{t('title.filter')}</span>
                </SidebarFilterProduct>
              </div>
              <div className="px-3 d-none d-xl-block">
                <ul className="nav nav-tab-shop" id="pills-tab" role="tablist">
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${currentLayout === 'GRID' ? 'active' : ''}`}
                      id="pills-one-example1-tab"
                      data-toggle="pill"
                      to="#"
                      onClick={() => onChangeLayout('GRID')}
                      role="tab"
                      aria-selected="false"
                    >
                      <div className="d-md-flex justify-content-md-center align-items-md-center">
                        <i className="fa fa-th" />
                      </div>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className={`nav-link ${currentLayout === 'TILE' ? 'active' : ''}`}
                      data-toggle="pill"
                      to="#"
                      onClick={() => onChangeLayout('TILE')}
                      role="tab"
                      aria-selected="true"
                    >
                      <div className="d-md-flex justify-content-md-center align-items-md-center">
                        <i className="fa fa-th-list" />
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <form
                  method="get"
                  className="d-flex justify-content-center align-items-center font-size-14 font-size-14-sm pr-2 pr-lg-0"
                >
                  <span className="mr-2 d-none d-md-inline">{t('title.sort_by')}</span>
                  <select
                    defaultValue={'latest'}
                    className="dropdown-select max-width-200 max-width-200-sm right-dropdown-0 px-2 btn-sm bg-white font-weight-normal py-2 border text-gray-20 bg-lg-down-transparent border-lg-down-0"
                    onChange={handleSortByFilter}
                  >
                    <option value="latest">{t('form.sort_latest')}</option>
                    <option value="related">{t('form.sort_related')}</option>
                    <option value="best_seller">{t('form.sort_best_seller')}</option>
                    <option value="price_low">{t('form.sort_price_low')}</option>
                    <option value="price_high">{t('form.sort_price_high')}</option>
                  </select>
                </form>
              </div>
              <PaginationNavigate
                containerClassName="px-3 flex-horizontal-center text-gray-20 d-none d-xl-flex"
                pageNumberClassName="min-width-50 mr-1"
                buttonPageClassName="btn btn-sm px-2 py-1 text-gray-30 font-size-15 pointer"
                totalPage={products.pages?.total_pages ?? 0}
                activePage={products.pages?.page ?? 1}
                prevLabel={<i className="fa fa-angle-left"></i>}
                nextLabel={<i className="fa fa-angle-right"></i>}
                onChangePage={handlePageChange}
              />
            </div>
            <div className="container-fluid">
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade pt-2 show active"
                  id="pills-one-example1"
                  role="tabpanel"
                  aria-labelledby="pills-one-example1-tab"
                  data-target-group="groups"
                >
                  {productLoading ? (
                    <ul className="row list-unstyled products-group no-gutters">
                      {currentLayout === 'GRID' ? (
                        <ProductSkeletonGrid sizeStyle="col-6 col-md-2 col-wd-2gdot4" maxBox={12} />
                      ) : (
                        <ProductSkeletonTile />
                      )}
                    </ul>
                  ) : (
                    <>
                      {currentLayout === 'GRID' ? (
                        <ul className="row list-unstyled products-group">
                          <ProductGridStore items={products?.items} />
                        </ul>
                      ) : (
                        <ul className="list-unstyled products-group no-gutters">
                          <ProductListTile items={products?.items} />
                        </ul>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="pt-5">
              {products.pages?.total_pages > 1 && (
                <Pagination data={products.pages} onChangePage={handlePageChange} />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StoreDetail
