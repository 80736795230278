import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'
import { showAlert } from '../../../utility/SweetAlert'
import { useTranslation } from 'react-i18next'
const InteractorBillUpdate = () => {
  const { t } = useTranslation()

  const billUpdate = useCallback(
    (payload, redirectLink) => {
      ApiService.jsonRequest('/fund-bill/update', payload, (response) => {
        if (response.status_code === 200) {
          showAlert({
            icon: 'success',
            message: t('response_message.bill_update_success'),
            timer: 2000,
            redirect: response?.data?.payment_invoice_url ?? redirectLink,
          })
        } else {
          if (
            response.messages === 'data_already_exists' &&
            response?.data?.payment_invoice_url !== '' &&
            response?.data?.status === 'PENDING'
          ) {
            showAlert({
              icon: 'success',
              message: t('response_message.bill_update_success'),
              timer: 2000,
              redirect: response?.data?.payment_invoice_url ?? redirectLink,
            })
          } else {
            showAlert({
              icon: 'warning',
              message: t('response_message.bill_update_failed'),
              timer: 2000,
              redirect: redirectLink,
            })
          }
        }
      })
    },
    [t],
  )
  return { billUpdate }
}

export default InteractorBillUpdate
