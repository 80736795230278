import React, { useEffect } from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import AppLayout from './layouts/AppLayout'
import { InteractorAuthorization } from './interactors/Main'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from './components/Main'
import { ShowLoading } from './redux/loading/actionCreator'
import { getCookies } from './utility/Cookies'
import ScrollToTop from './routes/ScrollToTop'
import FirebaseNotification from './firebase/FirebaseNotification'
import ReactGA from 'react-ga4'

export default function App() {
  const { loading } = useSelector((state) => state.loading)
  const { account } = useSelector((state) => state.account)
  const dispatch = useDispatch()
  const { token } = InteractorAuthorization()

  const setGA = () => {
    ReactGA.initialize('G-V8NSDS1EMH')
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname })
  }

  useEffect(() => {
    if (!getCookies('_wide')) {
      dispatch(ShowLoading())
      token()
    }
    if (account && account.account_type !== 'BUYER') {
      window.location.href = process.env.REACT_APP_DOMAIN_SELLER
    }
    setGA()
  }, [account, dispatch, token])

  return (
    <>
      {loading ? (
        <Loading type="page" />
      ) : (
        <BrowserRouter>
          <ScrollToTop>
            <Routes>
              <Route path="*" element={<AppLayout />} />
            </Routes>
          </ScrollToTop>
          {account && <FirebaseNotification />}
        </BrowserRouter>
      )}
    </>
  )
}
