import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Loading, Pagination } from '../Main'
import { Link } from 'react-router-dom'
import { timeAgo } from '../../utility/TimeAgo'
import { useSelector } from 'react-redux'
import { InteractorNotificationUpdate } from '../../interactors/Main'
import { removeCookies } from '../../utility/Cookies'

const WideCardNotification = (data) => {
  const { t } = useTranslation()
  const { account } = useSelector((state) => state.account)
  const { notificationUpdate } = InteractorNotificationUpdate()
  const { loading, datacard, payload } = data
  const [payloadFilter, setPayloadFilter] = useState(payload)

  const handlePageChange = (page) => {
    let objPayload = { ...payloadFilter }
    objPayload.page = page
    data.handleFilter(objPayload)
    setPayloadFilter(objPayload)
  }
  const handleUpdate = (row) => {
    if (row.notification_read === 'N') {
      removeCookies('summary-header')
      document.getElementById(`read-${row.uid}`).remove()
      let newPayload = { ...row }
      newPayload.notification_read = 'Y'
      newPayload.updated_by = account.full_name
      notificationUpdate(newPayload)
    }
    let path = '/notification'
    if (row.notification_category === 'ORDER' && row?.reference_code) {
      path = '/order?ref=' + row?.reference_code
    }
    if (row.notification_category === 'RFQ' && row?.reference_code) {
      path = '/quotation/request?ref=' + row?.reference_code
    }
    if (row.notification_category === 'PAYMENT' && row?.reference_code) {
      path = '/payment?ref=' + row?.reference_code
    }
    window.location = path
  }
  const handleIcon = (row) => {
    if (row.notification_category === 'RFQ') {
      return <i className="fa fa-file" />
    } else if (row.notification_category === 'ORDER') {
      return <i className="fa fa-shopping-cart" />
    } else if (row.notification_category === 'PAYMENT') {
      return <i className="fa fa-credit-card" />
    } else {
      return <i className="fa fa-info" />
    }
  }
  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center">
          <Loading />
        </div>
      ) : (
        <>
          {datacard.items?.length > 0 ? (
            <>
              {datacard.items.map((row, key) => {
                return (
                  <div key={key}>
                    <Link to={'#'} className="text-dark" onClick={() => handleUpdate(row)}>
                      <div className="border border-width-2 border-color-3 borders-radius-6 container wide-card rounded mb-3">
                        <div className="container pt-3 pb-3 p-2">
                          <div className="row">
                            <div className="col-8">
                              <span className="badge bg-dark text-white p-2 mr-1">
                                {handleIcon(row)}
                              </span>
                              <span className="font-weight-bold">{row.notification_title}</span>
                              <br />
                              {row.notification_message} <br />
                            </div>
                            <div className="col-4 mt-3 d-block text-right justify-content-between">
                              {timeAgo(row.created_date)}
                              {row.notification_read === 'N' && (
                                <span id={`read-${row.uid}`}>
                                  <i className="ml-2 fa fa-circle font-size-12 text-danger"></i>
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                )
              })}
              <Pagination data={datacard.pages} onChangePage={handlePageChange} />
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">{t('message.data_not_found')}</div>
            </>
          )}
        </>
      )}
    </>
  )
}

export default WideCardNotification
