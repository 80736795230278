import { getItem } from './LocalStorage'
const lng = getItem('language') ? getItem('language') : 'id'

const getLanguage = () => {
  if (lng === 'id') {
    return {
      id: 'ID',
      title: 'Bahasa Indonesia',
    }
  } else if (lng === 'en') {
    return {
      en: 'EN',
      title: 'English',
    }
  }
}

export { getLanguage }
