import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ProductItemSearch } from '../../redux/product/search/actionCreator'
import FilterRatingStar from './FilterRatingStar'

export const FilterRating = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { payload } = useSelector((state) => state.productItemSearch)

  const handlerOnClick = (value) => {
    let prevPayload = { ...payload }
    if (prevPayload.keywords.rating !== value) {
      prevPayload.keywords.rating = value
    } else {
      prevPayload.keywords.rating = 0
    }
    dispatch(ProductItemSearch(prevPayload))
  }

  return (
    <div className="mb-5">
      <div className="border-bottom border-color-1 mb-3">
        <h4 className="section-title section-title__sm mb-0 pb-2 font-size-18">
          {t('title.rating')}
        </h4>
      </div>
      <div className="d-flex w-auto flex-column">
        {[...Array(5)].reverse().map((_, index) => {
          const star = index + 1
          return (
            <div
              className={`pointer ${
                payload.keywords.rating === star ? 'bg-gray-4' : ''
              } d-flex mb-2 border-radius-10 px-3`}
              key={index}
              onClick={() => handlerOnClick(star)}
            >
              <div className="mr-2">
                <FilterRatingStar ratingStar={star} />
              </div>

              <div style={{ gridColumnStart: '2' }}>
                {star === 5 ? null : <h6 className="font-size-14 mt-1">{t('title.and_up')}</h6>}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
