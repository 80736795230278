import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ProductItemSearch } from '../../redux/product/search/actionCreator'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import { useNavigate } from 'react-router-dom'

const FilterHeaderKeyword = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { payload } = useSelector((state) => state.productItemSearch)
  const [payloadFilter, setPayloadFilter] = useState({})

  const handleChangeKeyword = (e) => {
    let value = e.currentTarget.value
    let objPayload = { ...payloadFilter }
    objPayload.keywords.text_search = value
    setPayloadFilter(objPayload)
  }
  const handleSubmitKeyword = (e) => {
    e.preventDefault()
    let objPayload = { ...payloadFilter }
    dispatch(ProductItemSearch(objPayload))
    navigate('/product')
  }

  const handleClearKeyword = (e) => {
    e.preventDefault()
    let objPayload = { ...payloadFilter }
    objPayload.keywords.text_search = ''
    setPayloadFilter(objPayload)
    dispatch(ProductItemSearch(objPayload))
  }

  const { finalTranscript, resetTranscript, listening } = useSpeechRecognition()
  const voiceStart = () => {
    SpeechRecognition.startListening({
      language: 'id-ID',
    })
  }
  const voiceStop = () => {
    SpeechRecognition.stopListening()
  }

  useEffect(() => {
    // SEARCH BY VOICE
    if (finalTranscript !== '') {
      const speech_text = finalTranscript.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, '')
      let objPayload = { ...payloadFilter }
      objPayload.keywords.text_search = speech_text
      setPayloadFilter(objPayload)
      dispatch(ProductItemSearch(objPayload))
      resetTranscript()
      navigate('/product')
    }
    // END SEARCH BY VOICE
    setPayloadFilter(payload)
  }, [dispatch, finalTranscript, navigate, payload, payloadFilter, resetTranscript])
  return (
    <>
      <div className="col align-self-center">
        <form className="js-focus-state">
          <label className="sr-only" htmlFor="searchProduct">
            {t('title.search')}
          </label>
          <div className="input-group">
            <input
              type="text"
              className="form-control py-2 pl-5 font-size-15 border-0 height-40 rounded-left-pill"
              name="text"
              id="searchProduct"
              placeholder={t('placeholder.search_keyword')}
              aria-label={t('placeholder.search_keyword')}
              aria-describedby="searchProduct1"
              required=""
              value={payload?.keywords?.text_search}
              onChange={handleChangeKeyword}
            />
            <div className="input-group-append">
              <div
                className={`btn text-gray-60 font-weight-bolder font-size-22 border-0 rounded-0 px-1 py-1 bg-white text-center ${
                  payload?.keywords?.text_search === '' ? 'd-none' : 'd-block'
                }`}
                type="button"
                onClick={handleClearKeyword}
              >
                <span aria-hidden="true">&times;</span>
              </div>
              {listening === false ? (
                <div
                  className={`btn text-gray-60 font-weight-bolder border-0 rounded-0 px-3 py-1 bg-white text-center`}
                  type="button"
                  onClick={voiceStart}
                >
                  <span className="font-size-16">
                    <i className="fas fa-microphone mt-2" />
                  </span>
                  <span className="tooltip-text">{t('title.voice_search')}</span>
                </div>
              ) : (
                <div
                  className={`btn text-gray-60 font-weight-bolder border-0 rounded-0 px-3 py-1 bg-white text-center`}
                  type="button"
                  onClick={voiceStop}
                >
                  <span className="font-size-16">
                    <i className="fas fa-microphone text-red mt-2" />
                  </span>
                  <span className="tooltip-text">{t('title.voice_search')}</span>
                </div>
              )}
              <button
                className="btn btn-dark height-40 py-2 px-3 rounded-right-pill"
                type="submit"
                onClick={handleSubmitKeyword}
              >
                <span className="ec ec-search font-size-24" />
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default FilterHeaderKeyword
