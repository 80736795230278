import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ApiService from '../../../services/ApiService'
import { showAlert } from '../../../utility/SweetAlert'

const InteractorAccountAddressUpdate = () => {
  const { t } = useTranslation()
  const { account } = useSelector((state) => state.account)
  const addressUpdate = useCallback(
    (payload) => {
      let body = {
        ...payload,
        account_uid: account.uid,
        updated_by: account.full_name,
      }
      ApiService.jsonRequest('/account-user/address-update', body, (response) => {
        if (response.status_code === 200) {
          showAlert({
            icon: 'success',
            message: t(`response_message.address_primary_has_been_update`),
            timer: 2000,
            reload: true,
          })
        }
      })
    },
    [account.full_name, account.uid, t],
  )

  return { addressUpdate }
}

export default InteractorAccountAddressUpdate
