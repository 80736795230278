import React, { useEffect, useState } from 'react'
import { Breadcrumb, InputText, MenuAccount } from '../../../components/Main'
import { useTranslation } from 'react-i18next'
import { Buffer } from 'buffer'
import { useNavigate } from 'react-router-dom'
import {
  InteractorAccountUserPasswordVerify,
  InteractorAccountUserPasswordChange,
} from '../../../interactors/Main'
import { showAlert } from './../../../utility/SweetAlert'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import Validation, {
  passwordMaxLength,
  passwordMinLength,
} from '../../../constant/global/validation'

const PasswordChange = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { validationInputNumber } = Validation()
  const [searchParams] = useSearchParams()
  const { account } = useSelector((state) => state.account)
  const { verifyPassword } = InteractorAccountUserPasswordVerify()
  const { changePassword } = InteractorAccountUserPasswordChange()
  const [passwordPayload, setPasswordPayload] = useState({
    password_old: '',
    password_new: '',
  })

  const [showPassword, setShowPassword] = useState({
    password_old: false,
    password_new: false,
  })

  const [error, setError] = useState({
    password_old: null,
    password_new: null,
  })

  useEffect(() => {
    if (!searchParams.get('next')) {
      navigate('/account/change-password?next=/account/profile')
    }
  }, [navigate, searchParams])

  const inputOnhandler = (e) => {
    let { name, value } = e.target
    let msg = null

    switch (name) {
      case 'password_new':
        msg = validationInputNumber(
          value,
          passwordMinLength,
          passwordMaxLength,
          t('title.new_password'),
        )
        break
      case 'password_old':
        msg = value === '' ? `${t('title.old_password')} ${t('message.not_empty')}` : null
        break
      default:
        msg = null
    }
    setError((prev) => ({ ...prev, [name]: msg }))

    setPasswordPayload({
      ...passwordPayload,
      [name]: value,
    })
  }

  const onCheckSubmit = () => {
    setError({
      ...passwordPayload,
      password_old:
        passwordPayload.password_old === ''
          ? `${t('title.old_password')} ${t('message.not_empty')}`
          : null,
      password_new:
        passwordPayload.password_new === ''
          ? `${t('title.new_password')} ${t('message.not_empty')}`
          : null,
    })
  }

  const handleShowPassword = (name, value) => {
    setShowPassword((prev) => ({ ...prev, [name]: value }))
  }

  const handleVerify = (val) => {
    if (val) {
      let payload = {
        email_address: account.email_address,
        password: Buffer.from(passwordPayload.password_new).toString('base64'),
      }
      if (account?.must_change_password === 'Y') {
        payload.must_change_password = 'N'
      }
      changePassword(payload)
    } else {
      showAlert({
        icon: 'warning',
        message: t('message.invalid_match_password'),
        timer: false,
      })
      return
    }
  }
  const onSubmit = (e) => {
    e.preventDefault()
    if (error.password_new || error.password_new) {
      return
    }
    onCheckSubmit()
    if (!passwordPayload.password_new || !passwordPayload.password_old) {
      showAlert({
        icon: 'error',
        message: t('message.invalid_change_password'),
        reload: false,
      })
      return
    }

    passwordPayload.password_new === passwordPayload.password_old
      ? showAlert({
          icon: 'warning',
          message: t('message.invalid_new_password'),
        })
      : verifyPassword(
          {
            uid: account.uid,
            password: Buffer.from(passwordPayload.password_old).toString('base64'),
          },
          handleVerify,
        )
  }
  return (
    <>
      <Breadcrumb title={t('menu.change_password')} className="mb-4" />
      <div className="container">
        <div className="row mb-8">
          <MenuAccount />
          <div className="col-xl-9 col-wd-9gdot5">
            <div className="flex-center-between">
              <h3 className="font-size-25 mb-0">{t('menu.change_password')}</h3>
            </div>
            <hr />
            {account?.must_change_password === 'Y' && (
              <span className="alert alert-danger d-block" role="alert">
                {t('message.must_change_password')}
              </span>
            )}
            <div className="col-6">
              <form onSubmit={onSubmit}>
                <div className="js-form-message form-group">
                  <InputText
                    label={t('title.old_password')}
                    type={showPassword.password_old ? 'text' : 'password'}
                    placeholder={t('title.old_password')}
                    onChange={inputOnhandler}
                    error={error.password_old}
                    errorMsg={error.password_old}
                    name="password_old"
                    required
                    visibleText={showPassword.password_old}
                    onShow={() => handleShowPassword('password_old', !showPassword.password_old)}
                  />
                </div>
                <div className="js-form-message form-group">
                  <InputText
                    label={t('title.new_password')}
                    type={showPassword.password_new ? 'text' : 'password'}
                    placeholder={t('title.new_password')}
                    onChange={inputOnhandler}
                    error={error.password_new}
                    errorMsg={error.password_new}
                    name="password_new"
                    required
                    visibleText={showPassword.password_new}
                    onShow={() => handleShowPassword('password_new', !showPassword.password_new)}
                  />
                </div>
                <div className="mb-6">
                  <div className="mb-3">
                    <button type="submit" className="btn btn-primary-dark-w px-5">
                      {t('button.send')}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PasswordChange
