import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'

const InteractorProductReviewSummary = () => {
  const reviewSummary = useCallback((payload, callback) => {
    ApiService.jsonRequest('/product-review/summary', payload, (response) => {
      if (response.status_code === 200) {
        if (response.data) {
          callback(response.data)
        }
      }
    })
  }, [])
  return { reviewSummary }
}

export default InteractorProductReviewSummary
