import React, { useState } from 'react'
import { inputNumber, numberFormat, numberFormatDestroy } from '../../utility/Helpers'

const InputQuantity = (data) => {
  const [value, setValue] = useState(numberFormat(data.value) ?? 1)
  const maximum = 10000000
  const onChange = (e) => {
    e.preventDefault()
    const inputValue = inputNumber(numberFormatDestroy(e.target.value))

    let val = ''
    if (+inputValue >= 1 && +inputValue <= maximum) {
      val = inputValue
    } else if (inputValue > maximum) {
      val = maximum
    }
    data.onChange({
      id: data.id,
      value: val,
    })
    setValue(numberFormat(val))
  }
  const onChangeBlur = (e) => {
    e.preventDefault()
    const inputValue = numberFormatDestroy(e.target.value)
    if (inputValue === '') {
      let val = 1
      data.onChange({
        id: data.id,
        value: val,
      })
      setValue(numberFormat(val))
    }
  }
  const clickIncrease = () => {
    let val = maximum
    const input_value = Number(value.replace(/[^0-9]/g, ''))
    if (Number(input_value) < val) {
      val = Number(input_value) + 1
    }
    data.onChange({
      id: data.id,
      value: val,
    })
    setValue(numberFormat(val))
  }

  const clickDecrease = () => {
    let val = 1
    const input_value = Number(value.replace(/[^0-9]/g, ''))
    if (Number(input_value) > 1) {
      val = Number(input_value) - 1
    }
    data.onChange({
      id: data.id,
      value: val,
    })
    setValue(numberFormat(val))
  }

  return (
    <>
      <div className="input-group">
        <input
          type="text"
          value={value}
          onChange={onChange}
          onBlur={onChangeBlur}
          className="py-3 height-20 form-control"
        />
        <div className="input-group-append">
          <button
            className="btn p-2 btn-xs btn-secondary"
            onClick={() => clickDecrease()}
            type="button"
          >
            <i className="fas fa-minus" />
          </button>
          <button
            className="btn p-2 pr-3 btn-xs btn-secondary"
            onClick={() => clickIncrease()}
            type="button"
          >
            <i className="fas fa-plus" />
          </button>
        </div>
      </div>
    </>
  )
}

export default InputQuantity
