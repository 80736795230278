import React, { useEffect, useState } from 'react'
import FilterCategorySubMenu from './FilterCategorySubMenu'
import { InteractorCategoryMain, InteractorFilterMain } from './../../interactors/Main'
import { categoryUrl } from '../../utility/Helpers'
import { Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

const FilterCategory = () => {
  const { categoryMainSummary } = InteractorCategoryMain()
  const { payload } = useSelector((state) => state.productItemSearch)
  const { uid } = useParams()
  const { compareMainUid } = InteractorFilterMain()
  const [masterCategory, setMasterCategory] = useState([])
  const [openCategory, setOpenCategory] = useState(false)

  const handleOpenDropdown = (uid) => {
    if (openCategory === uid) return setOpenCategory(null)
    setOpenCategory(uid)
  }
  useEffect(() => {
    categoryMainSummary(setMasterCategory)
    compareMainUid(uid, setOpenCategory)
  }, [categoryMainSummary, compareMainUid, uid])
  return (
    <>
      {masterCategory?.map((main, indexMain) => (
        <li key={indexMain}>
          <div
            className="dropdown-toggle dropdown-toggle-collapse collapsed pointer"
            onClick={() => handleOpenDropdown(main.uid)}
            aria-expanded={openCategory === main.uid && true}
          >
            <Link
              to={categoryUrl(main.name, main, null, null)}
              onClick={() => handleOpenDropdown(main.uid)}
              className={`${
                payload.keywords.category_main_uid === main.uid && 'font-weight-bold'
              } text-wrap`}
            >
              {main.name}
            </Link>
          </div>
          <FilterCategorySubMenu main={main} show={openCategory} showCallback={setOpenCategory} />
        </li>
      ))}
    </>
  )
}

export default FilterCategory
