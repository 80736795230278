import React from 'react'
import PropTypes from 'prop-types'
import { Link, useNavigate } from 'react-router-dom'
import { removeCookies } from '../../utility/Cookies'

const Menu = (props) => {
  const { to, children, className } = props
  const navigate = useNavigate()
  const handleNavigate = (e) => {
    e.preventDefault()
    props.callbackSidebar()
    if (to === '/account/signout') {
      removeCookies('_wsso')
      removeCookies('_wchat')
      removeCookies('_wssc')
      removeCookies('summary-header')
      window.location = '/'
    } else {
      navigate(to)
    }
  }
  return (
    <>
      <Link to="#" className={className} onClick={handleNavigate}>
        {children}
      </Link>
    </>
  )
}

Menu.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  callbackSidebar: PropTypes.any,
}

export default Menu
