import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReCAPTCHA from 'react-google-recaptcha'
import { Link, useNavigate } from 'react-router-dom'
import { Buffer } from 'buffer'
import { InteractorAccountUserRegister } from '../../../interactors/Main'
import { getCookies } from '../../../utility/Cookies'
import { Loading, InputText } from '../../../components/Main'
import Validation, {
  emailRegex,
  fullNameMaxLength,
  fullNameMinLength,
  passwordMaxLength,
  passwordMinLength,
} from '../../../constant/global/validation'

const Register = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { validationInputText, validationInputNumber } = Validation()
  const { register } = InteractorAccountUserRegister()
  const [loading, setLoading] = useState(false)
  const [formIsValid, setFormIsValid] = useState(false)
  const recaptchaRef = React.createRef()
  const [showPassword, setShowPassword] = useState(false)
  const [registerPayload, setRegisterPayload] = useState({
    full_name: '',
    email_address: '',
    password: '',
  })

  const [error, setError] = useState({
    full_name: null,
    email_address: null,
    password: null,
  })

  useEffect(() => {
    if (getCookies('_wsso')) {
      return navigate('/')
    }
  }, [navigate])

  const onValidateInput = (value, name) => {
    if (name === 'password') {
      let msg = validationInputNumber(value, passwordMinLength, passwordMaxLength, 'Password')
      setError((prev) => ({ ...prev, [name]: msg }))
    }

    if (name === 'email_address') {
      let validateEmail = emailRegex.test(value)
      setError((prev) => ({ ...prev, email_address: !validateEmail ? 'Email Tidak Valid' : null }))
    }

    if (name === 'full_name') {
      let msg = validationInputText(value, fullNameMinLength, fullNameMaxLength, 'Nama Lengkap')
      setError((prev) => ({ ...prev, [name]: msg }))
    }
  }

  const inputOnhandler = (e) => {
    let { name, value } = e.target

    onValidateInput(value, name)

    if (name === 'password') {
      let base64 = Buffer.from(value).toString('base64')
      value = base64
    }

    setRegisterPayload({
      ...registerPayload,
      [name]: value,
    })

    if (
      registerPayload.email_address !== '' &&
      registerPayload.full_name !== '' &&
      registerPayload.password
    ) {
      setFormIsValid(true)
    }
  }

  const recaptchaChange = (value) => {
    if (value) {
      setFormIsValid(true)
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const recaptchaValue = recaptchaRef.current.getValue()
    if (!recaptchaValue) {
      setFormIsValid(false)
      return
    }

    if (
      registerPayload.email_address === '' ||
      registerPayload.full_name === '' ||
      registerPayload.password === ''
    ) {
      setFormIsValid(false)
      return
    }
    if (error.email_address || error.full_name || error.password) {
      setFormIsValid(false)
      return
    }

    setLoading(true)
    register(registerPayload, setLoading)
  }

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center">
          <div className="px-10 py-10">
            <Loading />
          </div>
        </div>
      ) : (
        <>
          <div className="container">
            <div className="d-flex justify-content-center my-4 my-xl-8">
              <div className="row">
                <div className="col-md-12 ml-xl-0 mr-xl-auto">
                  <div className="border-bottom border-color-1 mb-6">
                    <h3 className="d-md-flex justify-content-md-center align-items-md-center font-size-26 font-weight-bold">
                      {t('menu.register_account')}
                    </h3>
                  </div>
                  <p className="mb-4 text-center text-danger font-weight-bold">
                    {t('content.adaloka_usage')} <br />
                    {t('content.adaloka_contact')} <br /> <br />
                    <Link className="text-blue" to="/page/help">
                      <i className="fas fa-question-circle mr-1"></i> {t('title.need_help')}
                    </Link>
                  </p>
                  {/* <p className="mb-4">{t('content.joined')}</p>
                  <form onSubmit={onSubmit}>
                    <div className="js-form-message form-group">
                      <InputText
                        label={t('form.full_name')}
                        value={registerPayload.full_name}
                        placeholder={t('form.full_name')}
                        onChange={inputOnhandler}
                        error={error.full_name}
                        errorMsg={error.full_name}
                        name="full_name"
                        required
                      />
                    </div>
                    <div className="js-form-message form-group">
                      <InputText
                        label={t('form.email_address')}
                        value={registerPayload.email_address}
                        placeholder={t('form.email_address')}
                        onChange={inputOnhandler}
                        error={error.email_address}
                        errorMsg={error.email_address}
                        name="email_address"
                        required
                      />
                    </div>
                    <div className="js-form-message form-group">
                      <InputText
                        label={t('form.password')}
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        onChange={inputOnhandler}
                        error={error.password}
                        errorMsg={error.password}
                        name="password"
                        required
                        visibleText={showPassword}
                        onShow={() => setShowPassword(!showPassword)}
                      />
                    </div>
                    <div className="js-form-message form-group">
                      <ReCAPTCHA
                        style={{ display: 'inline-block' }}
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        onChange={recaptchaChange}
                      />
                    </div>
                    <p className="mb-4">
                      {t('content.register_agreement')}{' '}
                      <Link className="text-red" to="/page/terms-and-conditions">
                        {t('menu.toc')}
                      </Link>
                      ,<br />
                      <Link className="text-red" to="/page/privacy-policy">
                        {t('menu.privacy')}{' '}
                      </Link>
                      Adaloka.com
                    </p>
                    <div className="mb-6">
                      <div className="mb-3">
                        <button
                          // disabled={!formIsValid}
                          disabled
                          type="submit"
                          className="btn btn-primary-dark-w px-5"
                        >
                          {t('button.register')}
                        </button>
                      </div>
                      <div className="mb-2">
                        {t('title.have_account')}{' '}
                        <Link className="text-blue" to="/account/signin">
                          {t('menu.signin')}
                        </Link>
                      </div>
                    </div>
                  </form> */}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default Register
