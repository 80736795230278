import { useMemo, useState } from 'react'

export default function useDropdown() {
  const [openDropdown, setOpenDropdown] = useState(false)

  const dropdownHandler = useMemo(
    () => ({
      onClick() {
        setOpenDropdown(!openDropdown)
      },
    }),
    [openDropdown],
  )

  return [openDropdown, dropdownHandler]
}
