import { useCallback } from 'react'
import ApiService from '../../services/ApiService'
import { Buffer } from 'buffer'
import { useDispatch } from 'react-redux'
import { HideLoading } from './../../redux/loading/actionCreator'
import { setCookies } from '../../utility/Cookies'

const InteractorAuthorization = () => {
  const dispatch = useDispatch()
  const token = useCallback(() => {
    const client = {
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_key: process.env.REACT_APP_CLIENT_KEY,
      client_role: process.env.REACT_APP_CLIENT_ROLE,
    }
    const payload = {
      payload:
        process.env.REACT_APP_CLIENT_KEY + Buffer.from(JSON.stringify(client)).toString('base64'),
    }
    ApiService.jsonRequest('/authorization/request', payload, (response) => {
      const expires = new Date().getTime() + response.data.expires
      setCookies({
        key: '_wide',
        value: response.data.uid,
        expires: expires,
      })
      dispatch(HideLoading())
      window.location.reload(false)
    })
  }, [dispatch])

  return { token }
}

export default InteractorAuthorization
