import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReCAPTCHA from 'react-google-recaptcha'
import { Link, useNavigate } from 'react-router-dom'
import { InteractorAccountUserLogin } from '../../../interactors/Main'
import { Buffer } from 'buffer'
import { getCookies } from '../../../utility/Cookies.js'
import { InputText, Loading } from '../../../components/Main'

const SignIn = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { login } = InteractorAccountUserLogin()
  const recaptchaRef = React.createRef()
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [formIsValid, setFormIsValid] = useState(false)
  const [loginPayload, setLoginPayload] = useState({
    email_address: '',
    password: '',
  })

  useEffect(() => {
    if (getCookies('_wsso')) {
      return navigate('/')
    }
  }, [navigate])

  const inputOnhandler = (e) => {
    let { name, value } = e.target
    if (name === 'password') {
      let base64 = Buffer.from(value).toString('base64')
      value = base64
    }

    let objPayload = { ...loginPayload }
    objPayload[name] = value
    setLoginPayload(objPayload)

    if (objPayload.email_address !== '' && objPayload.password !== '') {
      if (queryParams.get('source') !== '60a59367254fdf39823f02746fdce59c') {
        if (recaptchaRef.current.getValue()) {
          setFormIsValid(true)
        }
      } else {
        setFormIsValid(true)
      }
    } else {
      setFormIsValid(false)
    }
  }

  const recaptchaChange = (value) => {
    if (value && loginPayload.email_address !== '' && loginPayload.password !== '') {
      setFormIsValid(true)
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (queryParams.get('source') !== '60a59367254fdf39823f02746fdce59c') {
      const recaptchaValue = recaptchaRef.current.getValue()
      if (!recaptchaValue) {
        setFormIsValid(false)
        return
      }
    }
    setLoading(true)
    login(loginPayload, setLoading)
  }
  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center">
          <div className="px-10 py-10">
            <Loading />
          </div>
        </div>
      ) : (
        <>
          <div className="container">
            <div className="d-flex justify-content-center my-4 my-xl-8">
              <div className="row">
                <div className="col-md-12 ml-xl-0 mr-xl-auto">
                  <div className="border-bottom border-color-1 mb-6">
                    <h3 className="d-md-flexjustify-content-md-center align-items-md-center font-size-26 font-weight-bold">
                      {t('menu.signin')}
                    </h3>
                  </div>
                  <p className="mb-4">{t('content.joined')}</p>
                  <form onSubmit={onSubmit}>
                    <div className="js-form-message form-group">
                      <label className="form-label" htmlFor="signin">
                        {t('form.email_address')}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        name="email_address"
                        pattern="^[_a-z0-9-+]+(\.[_a-z0-9-+]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$"
                        id="signin"
                        autoComplete="username"
                        placeholder={t('form.email_address')}
                        aria-label={t('form.email_address')}
                        required=""
                        data-msg={t('message.invalid_email_address')}
                        data-error-class="u-has-error"
                        data-success-class="u-has-success"
                        onChange={inputOnhandler}
                      />
                    </div>
                    <div className="js-form-message form-group">
                      <InputText
                        label={t('form.password')}
                        placeholder={t('form.password')}
                        type={showPassword ? 'text' : 'password'}
                        onChange={inputOnhandler}
                        visibleText={showPassword}
                        onShow={() => setShowPassword(!showPassword)}
                        name="password"
                      />
                    </div>
                    {queryParams.get('source') !== '60a59367254fdf39823f02746fdce59c' && (
                      <div className="js-form-message form-group">
                        <ReCAPTCHA
                          style={{ display: 'inline-block' }}
                          ref={recaptchaRef}
                          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                          onChange={recaptchaChange}
                        />
                      </div>
                    )}
                    <div className="mb-6">
                      <div className="mb-3">
                        <button
                          type="submit"
                          disabled={!formIsValid}
                          className="btn btn-primary-dark-w px-5"
                        >
                          {t('button.signin')}
                        </button>
                      </div>
                      <div className="mb-2">
                        {t('title.have_account_yet') + ' '}
                        <Link className="text-blue" to="/account/register">
                          {t('menu.register_account')}
                        </Link>
                      </div>
                      <div className="mb-2">
                        {t('title.forgot_password') + ' '}
                        <Link className="text-blue" to="/account/forgot-password">
                          {t('menu.forgot_password')}
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default SignIn
