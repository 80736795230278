import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ApiService from '../../../services/ApiService'
import { showAlert } from '../../../utility/SweetAlert'

const InteractorContentBlogView = () => {
  const { t } = useTranslation()
  const blogView = useCallback(
    (payload, callback, loading) => {
      ApiService.jsonRequest('/content-blog/view', payload, (response) => {
        if (response.status_code === 200) {
          callback(response.data)
        } else {
          showAlert({
            icon: 'info',
            message: t(`response_message.data_not_exist`),
            timer: 2000,
            redirect: '/blog',
          })
        }
        loading(false)
      })
    },
    [t],
  )
  return { blogView }
}

export default InteractorContentBlogView
