import { getCookies } from '../../../utility/Cookies'
import { decryptCrypto } from '../../../utility/Encryption'
import { SUMMARY_HEADER } from './actions'

const initialState = {
  header_info: getCookies('summary-header')
    ? decryptCrypto(getCookies('summary-header'))
    : {
        cart: {
          total: 0,
          items: null,
        },
        chat_unread: 0,
        notification_unread: 0,
      },
}

export default function SummaryHeaderReducer(state = initialState, action) {
  switch (action.type) {
    case SUMMARY_HEADER:
      return {
        ...state,
        header_info: action.header_info,
      }
    default:
      return {
        ...state,
      }
  }
}
