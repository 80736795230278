import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Breadcrumb,
  SliderCompany,
  BannerFull,
  ProductBestSeller,
  ProductRecentlyViewed,
} from '../../components/Main'
const PageNotFound = () => {
  const { t } = useTranslation()
  return (
    <>
      <Breadcrumb title={t('title.page_not_found')} />
      <div className="text-center pb-3 border-bottom border-color-1">
        <h1 className="font-size-sl-72 font-weight-light mb-3">404!</h1>
        <p className="text-white font-size-20 mb-0 font-weight-light">
          {t('message.page_not_found')}
        </p>
      </div>
      <div className="space-1 bg-gray-7 mb-5">
        <div className="container">
          <ProductBestSeller />
        </div>
      </div>
      <div className="container space-top-1">
        <BannerFull />
        <ProductRecentlyViewed />
        <SliderCompany />
      </div>
    </>
  )
}

export default PageNotFound
