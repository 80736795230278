import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { categoryUrl } from '../../utility/Helpers'
import { Link } from 'react-router-dom'

const MenuCategorySubMain = ({ categories, setMenuCallback }) => {
  const dropdownRef = useRef(null)
  const handleClick = () => {
    dropdownRef.current.classList.toggle('show')
  }
  return (
    <li className="u-has-submenu u-header-collapse__submenu">
      <div onClick={handleClick} className="d-flex align-items-center pointer">
        <Link
          to={categoryUrl(categories.name, categories, null, null)}
          className="u-header-collapse__nav-link"
          onClick={() => setMenuCallback(false)}
        >
          {categories.name}
        </Link>
        <small className="u-header-collapse__nav-pointer ml-auto"></small>
      </div>
      <div
        id={`${categories.uid}`}
        className="collapse"
        data-parent="#headerSidebarContent"
        ref={dropdownRef}
      >
        {categories.major.map((major, indexMajor) => {
          return (
            <ul key={indexMajor} className="u-header-collapse__nav-list">
              <li onClick={handleClick}>
                <Link
                  className="u-header-sidebar__sub-menu-title"
                  to={categoryUrl(major.name, categories, major, null)}
                  onClick={() => setMenuCallback(false)}
                >
                  {major.name}
                </Link>
              </li>
              {major.minor.map((minor, indexMinor) => {
                return (
                  <li key={indexMinor} onClick={handleClick}>
                    <Link
                      className="u-header-collapse__submenu-nav-link"
                      to={categoryUrl(minor.name, categories, major, minor)}
                      onClick={() => setMenuCallback(false)}
                    >
                      {minor.name}
                    </Link>
                  </li>
                )
              })}
            </ul>
          )
        })}
      </div>
    </li>
  )
}

MenuCategorySubMain.propTypes = {
  categories: PropTypes.any,
  setMenuCallback: PropTypes.any,
}

export default MenuCategorySubMain
