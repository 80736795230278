import { useEffect, useRef, useState } from 'react'

const useCountDown = (targetDate) => {
  const countDownDate = new Date(targetDate).getTime()

  const [timer, setTimer] = useState(countDownDate - new Date().getTime())
  const [pause, setPause] = useState(false)
  const intervalTimer = useRef(null)

  const setRepeatTimer = () => {
    setTimer(countDownDate - new Date().getTime())
    setPause(false)
  }
  useEffect(() => {
    if (!pause && timer > 0) {
      intervalTimer.current = setInterval(() => setTimer(timer - 1000), 1000)
    }

    if (timer === 0) {
      clearInterval(intervalTimer.current)
      setPause(true)
    }

    return () => clearInterval(intervalTimer.current)
  }, [pause, targetDate, timer])
  return { timer, setRepeatTimer }
}

export const getReturnValues = (countDown) => {
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24))
  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000)
  return [days, hours, minutes, seconds]
}

export default useCountDown
