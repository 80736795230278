import { useCallback } from 'react'
import { useSelector } from 'react-redux'

const InteractorFilterMain = () => {
  const { payload } = useSelector((state) => state.productItemSearch)
  const filterCategoryProduct = useCallback(
    (uid) => {
      let categories = uid ? uid.split('.') : []
      const main_uid = categories[0] ? categories[0] : ''
      const major_uid = categories[1] ? categories[1] : ''
      const minor_uid = categories[2] ? categories[2] : ''
      if (main_uid) {
        payload.keywords.category_main_uid = main_uid
      }
      payload.keywords.category_main_uid = main_uid ? main_uid : null
      payload.keywords.category_major_uid = major_uid ? major_uid : null
      payload.keywords.category_minor_uid = minor_uid ? minor_uid : null
      return payload
    },
    [payload],
  )

  const compareMainUid = useCallback(
    (uid, callback) => {
      const createUid = filterCategoryProduct(uid)
      if (payload.keywords.category_main_uid === createUid.category_main_uid) {
        callback(payload.keywords.category_main_uid)
      }
    },
    [filterCategoryProduct, payload.keywords.category_main_uid],
  )

  const compareMinorUid = useCallback(
    (uid, callback) => {
      const createUid = filterCategoryProduct(uid)
      if (payload.keywords.category_minor_uid === createUid.category_minor_uid) {
        callback(payload.keywords.category_major_uid)
      }
    },
    [
      filterCategoryProduct,
      payload.keywords.category_major_uid,
      payload.keywords.category_minor_uid,
    ],
  )
  return { filterCategoryProduct, compareMainUid, compareMinorUid }
}

export default InteractorFilterMain
