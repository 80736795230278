import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, useParams } from 'react-router-dom'
import { categoryUrl } from '../../utility/Helpers.js'
import { useSelector } from 'react-redux'
import { InteractorFilterMain } from '../../interactors/Main.js'

const FilterCategorySubMenu = ({ main, show }) => {
  const [activeCategory, setActiveCategory] = useState(null)
  const { payload } = useSelector((state) => state.productItemSearch)
  const { compareMinorUid } = InteractorFilterMain()
  const { uid } = useParams()
  const handleShowMajor = (uid) => {
    if (activeCategory === uid) return setActiveCategory(null)
    setActiveCategory(uid)
  }

  useEffect(() => {
    compareMinorUid(uid, setActiveCategory)
  }, [compareMinorUid, uid])

  return (
    <>
      <div
        id="sidebarNavCollapse"
        className={`collapse ${show === main.uid && 'show'}`}
        data-parent="#sidebarNav"
      >
        <ul
          id="sidebarNav1"
          className="list-unstyled custom-sidebar-padding"
          style={{ paddingBottom: '0' }}
        >
          {main.major?.map((major, indexMajor) => {
            return (
              <li key={indexMajor}>
                <div
                  className="dropdown-toggle dropdown-toggle-collapse collapsed pointer"
                  aria-expanded={activeCategory === major.uid && true}
                  onClick={() => handleShowMajor(major.uid)}
                >
                  <Link
                    to={categoryUrl(major.name, main, major, null)}
                    onClick={() => handleShowMajor(major.uid)}
                    className={`${
                      payload.keywords.category_major_uid === major.uid && 'font-weight-bold'
                    } text-wrap`}
                  >
                    {major.name}
                  </Link>
                </div>
                <div
                  id="sidebarNavCollapse"
                  className={`collapse ${activeCategory === major.uid && 'show'}`}
                  data-parent="#sidebarNav"
                >
                  <ul
                    id="sidebarNav1"
                    className="list-unstyled custom-sidebar-padding-minor"
                    style={{ paddingBottom: '0' }}
                  >
                    {major.minor.map((minor, indexMinor) => {
                      return (
                        <li key={indexMinor}>
                          <div
                            className="dropdown-toggle-custom dropdown-toggle-collapse collapsed pointer list-bullet-custom d-flex align-items-center"
                            aria-expanded={false}
                          >
                            <Link
                              to={categoryUrl(minor.name, main, major, minor)}
                              className={`${
                                payload.keywords.category_minor_uid === minor.uid &&
                                'font-weight-bold'
                              } text-wrap`}
                              onClick={() => setActiveCategory(minor.major_uid)}
                            >
                              {minor.name}
                            </Link>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}

FilterCategorySubMenu.propTypes = {
  main: PropTypes.any,
  show: PropTypes.any,
  showCallback: PropTypes.any,
}

export default FilterCategorySubMenu
