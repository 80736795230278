import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { InputSelect } from '../../components/Main'
import {
  InteractorAccountAddressList,
  InteractorQuotationRequestUpdate,
} from '../../interactors/Main'
import { useSelector } from 'react-redux'
import { findByArray, formatDate } from '../../utility/Helpers'
import { showAlert } from '../../utility/SweetAlert'

const QuotationChangeAddress = (data) => {
  const { t } = useTranslation()
  const { account } = useSelector((state) => state.account)
  const { addressList } = InteractorAccountAddressList()
  const { requestUpdate } = InteractorQuotationRequestUpdate()
  const [masterAddress, setMasterAddress] = useState([])
  const [newAddress, setNewAddress] = useState({})
  const [listAddress, setListAddress] = useState([{ value: 'NEW', label: t('button.address_add') }])
  const [payloadQuotation, setPayloadQuotation] = useState({})

  const handleResponseAddress = useCallback(
    (data) => {
      let address = [{ value: 'NEW', label: t('button.address_add') }]
      if (data.length > 0) {
        setMasterAddress(data)
        data.forEach((res) => {
          const obj = { value: res.uid, label: res.title }
          address.push(obj)
        })
      }
      setListAddress(address)
    },
    [t],
  )

  useEffect(() => {
    const initial_data = data.data ?? null
    if (initial_data) {
      setPayloadQuotation(initial_data)
      addressList(
        {
          account_uid: account.uid,
        },
        handleResponseAddress,
      )
    }
  }, [account.uid, addressList, data.data, handleResponseAddress])

  const onValidateInput = (value, name) => {
    setError((state) => ({
      ...state,
      [name]: { ...state[name], errorMsg: value },
    }))
  }
  const [error, setError] = useState({
    address: {
      isRequired: true,
      errorMsg: '',
      validateFunc: onValidateInput,
    },
  })

  const handleFormValue = useCallback(
    (value, name) => {
      let objNewAddress = { ...newAddress }
      if (name === 'address') {
        if (value !== 'NEW' || !value) {
          const rowAddress = findByArray(masterAddress, 'uid', value)
          if (rowAddress) {
            objNewAddress.destination_country_uid = rowAddress.country_uid
            objNewAddress.destination_province_uid = rowAddress.province_uid
            objNewAddress.destination_city_uid = rowAddress.city_uid
            objNewAddress.destination_district_uid = rowAddress.district_uid
            objNewAddress.destination_subdistrict_uid = rowAddress.subdistrict_uid
            objNewAddress.destination_postal_code_uid = rowAddress.postal_code_uid
            objNewAddress.destination_address = rowAddress.address_detail
            objNewAddress.consignee_full_name = rowAddress.consignee_full_name
            objNewAddress.consignee_phone_number = rowAddress.consignee_phone_number
          } else {
            objNewAddress.destination_address = null
          }
        } else {
          objNewAddress.destination_address = null
          data.handleModal(data.data, 'ADDRESS')
        }
      }
      setNewAddress(objNewAddress)
    },
    [data, masterAddress, newAddress],
  )

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!newAddress.destination_address) {
      showAlert({
        icon: 'warning',
        message: t('alert.required') + ': ' + t('title.new_delivery_address'),
      })
    } else {
      if (payloadQuotation.destination_address === newAddress.destination_address) {
        showAlert({
          icon: 'info',
          message: t(`alert.rfq_change_address`),
        })
      } else {
        let payloadUpdate = { ...payloadQuotation }
        if (payloadUpdate?.request_payment_term_date) {
          payloadUpdate.request_payment_term_date = formatDate(
            new Date(payloadUpdate.request_payment_term_date),
            'YYYY-MM-DD HH:mm:ss',
          )
        }
        payloadUpdate.destination_country_uid = newAddress.destination_country_uid
        payloadUpdate.destination_province_uid = newAddress.destination_province_uid
        payloadUpdate.destination_city_uid = newAddress.destination_city_uid
        payloadUpdate.destination_district_uid = newAddress.destination_district_uid
        payloadUpdate.destination_subdistrict_uid = newAddress.destination_subdistrict_uid
        payloadUpdate.destination_postal_code_uid = newAddress.destination_postal_code_uid
        payloadUpdate.destination_address = newAddress.destination_address
        payloadUpdate.consignee_full_name = newAddress.consignee_full_name
        payloadUpdate.consignee_phone_number = newAddress.consignee_phone_number
        payloadUpdate.updated_by = account.full_name
        requestUpdate(payloadUpdate, 'ADDRESS')
      }
    }
  }

  return (
    <div className="container-fluid mx-0">
      <div
        className="col-12 px-1 font-size-18 text-center mb-1 font-weight-bold"
        id="popup-cart-title"
      >
        <h4>{t('title.address_edit')}</h4>
      </div>
      <div className="col-12">
        <hr className="mt-0" />
        <div className="row justify-content-between mb-3">
          <div className="font-weight-bold">
            {t('title.new_delivery_address')}
            <span className="text-danger ml-1">*</span>
          </div>
          <div>
            <InputSelect
              items={listAddress}
              onChange={handleFormValue}
              name="address"
              title={t('invoice.order_destination')}
              {...error.address}
            />
          </div>
        </div>
        {payloadQuotation.destination_address && (
          <>
            <span className="font-weight-bold">{t('title.data_before')}:</span>
            <div className="row d-flex justify-content-between mb-3 border border-radius-25 bg-light-4 p-3">
              <div className="font-size-14">
                <span className="font-weight-bold">{t(`title.ship_consignee`)}: </span>
                {payloadQuotation.consignee_full_name +
                  ' / ' +
                  payloadQuotation.consignee_phone_number}
                <br />
                <span className="font-weight-bold"> {t(`title.address_detail`)}:</span> <br />
                {payloadQuotation.destination_address}
              </div>
            </div>
          </>
        )}
        {newAddress.destination_address && (
          <>
            <span className="font-weight-bold">{t('title.data_new')}:</span>
            <div className="row d-flex justify-content-between mb-3 border border-radius-25 bg-gray-4 p-3">
              <div className="font-size-14">
                <span className="font-weight-bold">{t(`title.ship_consignee`)}: </span>
                {newAddress.consignee_full_name + ' / ' + newAddress.consignee_phone_number} <br />
                <span className="font-weight-bold"> {t(`title.address_detail`)}:</span> <br />
                {newAddress.destination_address}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="col-12">
        <hr className="mt-3" />
        <div className="d-flex justify-content-center align-items-center mb-3">
          <button
            type="submit"
            className="btn btn-md btn-primary-dark font-weight-700"
            to="#"
            onClick={handleSubmit}
          >
            {t('button.save')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default QuotationChangeAddress
