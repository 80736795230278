import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import FilterMainMobile from '../filter/FilterMainMobile'
import SidebarLeft from './SidebarLeft'

const SidebarFilterProduct = (props) => {
  const [openToggle, setOpenToggle] = useState(true)
  const [openSidebar, setOpenSidebar] = useState(false)
  const handleStatusSidebar = () => {
    openToggle ? setOpenToggle(false) : setOpenToggle(true)
    handleToggle(openToggle)
    props.handleFilter && props.handleFilter(openToggle)
  }
  const handleToggle = (value) => setOpenSidebar(value)

  useEffect(() => {
    const handleCloseOutside = (e) => {
      if (props.sidebarRef && !props.sidebarRef.current.contains(e.target)) {
        setOpenSidebar(false)
        setOpenToggle(!openSidebar)
      }
    }
    document.addEventListener('mousedown', handleCloseOutside)

    return () => document.removeEventListener('mousedown', handleCloseOutside)
  }, [openSidebar, props.sidebarRef])
  return (
    <>
      <div>
        <div
          id="sidebarFilterMenu"
          type="button"
          className="navbar-toggler d-block btn u-hamburger mr-3 mr-xl-0 z-index-2"
          data-clicked={openToggle}
          onClick={handleStatusSidebar}
        >
          {props.children}
        </div>
      </div>
      <SidebarLeft menu={openSidebar} setMenuCallback={handleToggle} sidebarRef={props.sidebarRef}>
        <FilterMainMobile />
      </SidebarLeft>
    </>
  )
}

SidebarFilterProduct.propTypes = {
  children: PropTypes.any,
  handleFilter: PropTypes.any,
  sidebarRef: PropTypes.any,
}

export default SidebarFilterProduct
