import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Breadcrumb, Loading, ProductLatest } from '../../components/Main'
import { InteractorContentPageSearch } from '../../interactors/Main'
import { findByArray } from '../../utility/Helpers'
import { getItem } from '../../utility/LocalStorage'
import parser from 'react-html-parser'

const PageSellerGuide = () => {
  const { t } = useTranslation()
  const lng = getItem('language') ? getItem('language') : 'id'
  const [payload] = useState({
    page: 1,
    limit: 10,
    keywords: {
      text_search: '',
      page_category: 'HOW_TO_BE_SELLER',
    },
  })
  const [loading, setLoading] = useState(true)
  const [content, setContent] = useState({})
  const { pageSearch } = InteractorContentPageSearch()
  const handleResponse = useCallback(
    (data) => {
      if (data?.items?.length > 0) {
        const row = findByArray(data.items, 'language', lng.toUpperCase())
        if (row) {
          setContent(row)
        }
      }
    },
    [lng],
  )
  useEffect(() => {
    if (loading) {
      pageSearch(payload, handleResponse, setLoading)
    }
    //
  }, [handleResponse, loading, pageSearch, payload])
  return (
    <>
      <Breadcrumb title={t('menu.how_to_be_seller')} />
      {loading ? (
        <div className="d-flex justify-content-center align-items-center height-100vh">
          <Loading />
        </div>
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-wd">
              <div className="min-width-1100-wd">
                <article className="card mb-8 border-0">
                  <div className="card-body pt-0 pb-0 px-0">
                    <div className="d-block d-md-flex flex-center-between mb-4 mb-md-4 border-bottom">
                      <h4 className="mb-md-3 mb-1">{t('menu.how_to_be_seller')}</h4>
                    </div>
                    {content.page_content ? parser(`${content.page_content}`) : ''}
                  </div>
                </article>
              </div>
            </div>
            <div className="col-xl-3 col-wd">
              <div className="mt-md-3 mt-1">
                <ProductLatest limit={3} mode="list" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default PageSellerGuide
