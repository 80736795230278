import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ApiService from '../../../services/ApiService'
import { showAlert } from '../../../utility/SweetAlert'

const InteractorNewsletterCreate = () => {
  const { t } = useTranslation()
  const newsletterCreate = useCallback(
    (payload) => {
      ApiService.jsonRequest('/communication-newsletter/create', payload, (response) => {
        showAlert({
          icon: 'success',
          message: t('response_message.send_success'),
          timer: 2000,
          reload: true,
        })
      })
    },
    [t],
  )
  return { newsletterCreate }
}

export default InteractorNewsletterCreate
