import { useTranslation } from 'react-i18next'

export const phoneNumberMinLength = 6
export const phoneNumberMaxLength = 16
export const fullNameMinLength = 3
export const fullNameMaxLength = 70
export const titleMinLength = 5
export const titleMaxLength = 70
export const addressMinLength = 20
export const addressMaxLength = 200
export const passwordMinLength = 6
export const passwordMaxLength = 100
export const reasonRejectedLength = 200
export const reviewCommentLength = 200

// REGEX
export const emailRegex =
  // eslint-disable-next-line no-useless-escape
  /^[a-zA-Z]+([a-zA-Z\d\.\_\W]+)\@([a-zA-Z]+)\.([a-zA-Z\.]+)$/
export const numberRegex = /^[0-9]*$/
export const alphaNumeric = /([\w\d\s/,.!()]+)/g

// Format
export const imgFormat = ['image/jpeg', 'image/jpg', 'image/png']
const Validation = () => {
  const { t } = useTranslation()
  const validationInputText = (input, min, max, title) => {
    let msg_error =
      input.length === 0
        ? title + ` ${t('message.not_empty')}`
        : input.length < min
        ? title + ` ${t('message.min_input_form')} ` + min + ` ${t('unit.text')}`
        : input.length > max
        ? title + ` ${t('message.max_input_form')} ` + max + ` ${t('unit.text')}`
        : null

    return msg_error
  }

  const validationInputNumber = (input, min, max, title) => {
    let msg_error =
      input.length === 0
        ? title + ` ${t('message.not_empty')}`
        : input.length < min
        ? title + ` ${t('message.min_input_form')} ` + min + ` ${t('unit.number')}`
        : input.length > max
        ? title + ` ${t('message.max_input_form')} ` + max + ` ${t('unit.number')}`
        : null

    return msg_error
  }

  return { validationInputText, validationInputNumber }
}

export default Validation
