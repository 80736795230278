import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ApiService from '../../../services/ApiService'
import { removeCookies } from '../../../utility/Cookies'
import { showAlert } from '../../../utility/SweetAlert'

const InteractorQuotationCartDelete = () => {
  const { t } = useTranslation()
  const cartDelete = useCallback(
    (payload) => {
      ApiService.jsonRequest('/rfq-cart/delete', payload, (response) => {
        if (response.status_code === 200) {
          removeCookies('summary-header')
          showAlert({
            icon: 'success',
            message: t('response_message.cart_update_success'),
            timer: 2000,
            reload: true,
          })
        } else {
          showAlert({
            icon: 'warning',
            message: t('response_message.cart_update_failed'),
            timer: 2000,
            reload: true,
          })
        }
      })
    },
    [t],
  )
  return { cartDelete }
}

export default InteractorQuotationCartDelete
