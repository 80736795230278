import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Buffer } from 'buffer'
import { useLocation, useNavigate } from 'react-router-dom'
import { InteractorAccountUserPasswordChange } from '../../../interactors/Main'
import { showAlert } from './../../../utility/SweetAlert'
import { InputText } from '../../../components/Main'
import Validation, {
  passwordMaxLength,
  passwordMinLength,
} from '../../../constant/global/validation'

const ConfirmationPassword = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const { validationInputNumber } = Validation()
  const { changePassword } = InteractorAccountUserPasswordChange()
  const [passwordPayload, setPasswordPayload] = useState({
    password: '',
    password_new: '',
  })
  const [showPassword, setShowPassword] = useState({
    password: '',
    password_new: '',
  })

  const [error, setError] = useState({
    password: null,
    password_new: null,
  })

  useEffect(() => {
    if (location?.state === null) {
      navigate('/account/signin')
    }
  }, [location, navigate])

  const handleShowPassword = (name, value) =>
    setShowPassword((prev) => ({ ...prev, [name]: value }))

  const inputOnhandler = (e) => {
    let { name, value } = e.target
    let msg = null
    switch (name) {
      case 'password':
        msg = validationInputNumber(
          value,
          passwordMinLength,
          passwordMaxLength,
          t('title.new_password'),
        )
        break
      case 'password_new':
        msg = !value
          ? `${t('title.confirmation_new_password')} ${t('message.not_empty')}`
          : value !== passwordPayload.password
          ? `${t('title.confirmation_new_password')} ${t(
              'response_message.password_does_not_match',
            )}`
          : null
        break
      default:
        msg = null
    }

    setError((prev) => ({ ...prev, [name]: msg }))

    setPasswordPayload({
      ...passwordPayload,
      [name]: value,
    })
  }

  const onCheckSubmit = () => {
    setError({
      password:
        passwordPayload.password === ''
          ? `${t('title.new_password')} ${t('message.not_empty')}`
          : null,
      password_new:
        passwordPayload.password_new === ''
          ? `${t('title.confirmation_new_password')} ${t('message.not_empty')}`
          : null,
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    onCheckSubmit()

    if (!passwordPayload.password || !passwordPayload.password_new) return

    if (passwordPayload.password !== passwordPayload.password_new) {
      showAlert({
        icon: 'warning',
        message: t('message.invalid_match_password'),
        timer: false,
        reload: true,
      })
      return
    }
    const payload = {
      email_address: location.state?.email,
      password: Buffer.from(passwordPayload.password).toString('base64'),
    }
    changePassword(payload)
  }
  return (
    <>
      <div className="container">
        <div className="d-flex justify-content-center my-4 my-xl-8">
          <div className="row">
            <div className="col-md-12 ml-xl-0 mr-xl-auto">
              <div className="border-bottom border-color-1 mb-6">
                <h3 className="d-md-flexjustify-content-md-center align-items-md-center font-size-26 font-weight-bold">
                  {t('menu.confirmation_password')}
                </h3>
              </div>
              <p className="mb-4">{t('message.confirmation_password')}</p>
              <form onSubmit={onSubmit}>
                <div className="js-form-message form-group">
                  <label className="form-label" htmlFor="password">
                    {t('form.email_address')} <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email_address"
                    id="email_address"
                    value={location?.state?.email ? location?.state?.email : ''}
                    required
                    readOnly
                  />
                </div>
                <div className="js-form-message form-group">
                  <InputText
                    label={t('title.new_password')}
                    type={showPassword.password ? 'text' : 'password'}
                    placeholder={t('title.new_password')}
                    onChange={inputOnhandler}
                    name="password"
                    required
                    error={error.password}
                    errorMsg={error.password}
                    visibleText={showPassword.password}
                    onShow={() => handleShowPassword('password', !showPassword.password)}
                  />
                </div>
                <div className="js-form-message form-group">
                  <InputText
                    label={t('title.confirmation_new_password')}
                    type={showPassword.password_new ? 'text' : 'password'}
                    placeholder={t('title.confirmation_new_password')}
                    onChange={inputOnhandler}
                    name="password_new"
                    required
                    error={error.password_new}
                    errorMsg={error.password_new}
                    visibleText={showPassword.password_new}
                    onShow={() => handleShowPassword('password_new', !showPassword.password_new)}
                  />
                </div>
                <div className="mb-6">
                  <div className="mb-3">
                    <button type="submit" className="btn btn-primary-dark-w px-5">
                      {t('button.send')}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ConfirmationPassword
