import React from 'react'
import ProductSkeleton from '../../assets/image/skeleton/product.png'

const ProductSkeletonGrid = (data) => {
  return (
    <>
      {Array.from(Array(data.maxBox ?? 8), (_, idx) => (
        <li
          key={idx}
          className={`${
            data.sizeStyle ? data.sizeStyle : 'col-6 col-md-3 col-wd-2gdot4'
          } product-item`}
        >
          <div className="product-item__outer h-100">
            <div className=" px-xl-4 p-3">
              <div className="product-item__body pb-xl-2">
                <div className="mb-2 height-10 skeleton-loader width-75 w-100"></div>
                <div className="mb-1 product-item__title w-75 skeleton-loader height-20"></div>
                <div className="d-block text-center">
                  <img className="img-fluid" src={ProductSkeleton} alt="" />
                </div>
                <div className="my-3 max-width-15 skeleton-loader height-14"></div>
                <div className="pt-2 mt-2 max-width-20 skeleton-loader height-12"></div>
                <div className="pt-2 mt-2 max-width-20 skeleton-loader height-12"></div>
              </div>
            </div>
          </div>
        </li>
      ))}
    </>
  )
}

export default ProductSkeletonGrid
