import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'
import { decryptCrypto, encryptCrypto } from '../../../utility/Encryption'
import { setItem, getItem } from '../../../utility/LocalStorage'
import { Buffer } from 'buffer'

const InteractorCategoryMain = () => {
  const categoryMainSummary = useCallback((callback) => {
    const session_key = Buffer.from('category-summary').toString('base64')
    const category_summary = getItem(session_key) ? decryptCrypto(getItem(session_key)) : null
    if (category_summary) {
      callback(category_summary)
    } else {
      ApiService.jsonRequest('/master-category/main-summary', {}, (response) => {
        if (response.status_code === 200) {
          setItem(session_key, encryptCrypto(JSON.stringify(response.data.main)))
          callback(response.data.main)
        }
      })
    }
  }, [])

  return { categoryMainSummary }
}

export default InteractorCategoryMain
