import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'

const InteractorAccountUserPasswordVerify = () => {
  const verifyPassword = useCallback((payload, callback) => {
    payload = {
      ...payload,
    }
    ApiService.jsonRequest('/account-user/verify-password', payload, (response) => {
      if (response.status_code === 200) {
        callback(true)
      } else {
        callback(false)
      }
    })
  }, [])

  return { verifyPassword }
}

export default InteractorAccountUserPasswordVerify
