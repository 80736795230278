import React from 'react'
import Modal from 'react-responsive-modal'
import PropTypes from 'prop-types'
import { useState } from 'react'

const ImageModal = (props) => {
  const [open, setOpen] = useState(false)
  const { title, src, border } = props
  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)

  return (
    <>
      <div className={`form-picture w60 ${border ? 'border border-danger' : ''}`}>
        <img
          className="form-picture-image"
          src={src}
          width="60"
          height="60"
          alt=""
          onClick={onOpenModal}
        />
      </div>
      <Modal open={open} onClose={onCloseModal}>
        <div className="row col-12">
          <div className="col-12 p-2 font-size-18 text-center mb-1 font-weight-bold">
            <h3>{title}</h3>
            <hr />
          </div>
          <div className="col-12 d-flex justify-content-center">
            <img className="form-picture-image" src={src} width={250} height={'auto'} alt="" />
          </div>
        </div>
      </Modal>
    </>
  )
}

ImageModal.propTypes = {
  title: PropTypes.string,
  src: PropTypes.string,
  border: PropTypes.bool,
}

export default ImageModal
