import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { showAlert } from './../../../utility/SweetAlert'
import { useSearchParams } from 'react-router-dom'

const InteractorAuthenticationOtpRequest = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const OTPRequest = useCallback(
    (payload) => {
      payload = {
        ...payload,
      }
      const params = {
        next: searchParams.get('next') ? searchParams.get('next') : '/',
      }
      const queryString = new URLSearchParams(params).toString()
      ApiService.jsonRequest('/auth-otp/request', payload, (response) => {
        if (response.status_code === 200 || response.messages === 'please_try_again_in_5_minutes') {
          const path = `/account/security-verification/${response.data.verify_token}?${queryString}`
          let otp_category = payload?.otp_category
          let target_identity = payload?.target_identity
          if (response.messages === 'please_try_again_in_5_minutes') {
            otp_category = response.data.otp_category
            target_identity = response.data.target_identity
          }

          if (response.messages === 'please_try_again_in_5_minutes') {
            showAlert({
              icon: 'error',
              message: t(`response_message.${response.messages}`),
              timer: 2000,
            })
          } else {
            showAlert({
              icon: 'success',
              message: t(`response_message.please_check_your_email`),
              timer: 2000,
            })
          }
          navigate(path, {
            state: {
              type: otp_category,
              email: target_identity,
              full_name: payload?.target_full_name,
              uid: payload?.account_uid,
              expires: response.data.expires,
            },
          })
        } else {
          showAlert({
            icon: 'error',
            message: t(`response_message.${response.messages}`),
            timer: 2000,
          })
        }
      })
    },
    [t, navigate, searchParams],
  )

  return { OTPRequest }
}

export default InteractorAuthenticationOtpRequest
