import { useCallback, useState, useEffect } from 'react'

export default function useShowMenuButton(ref) {
  const [isOpen, setIsOpen] = useState(false)
  const handleOpen = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  useEffect(() => {
    function handleCloseOutside(e) {
      if (ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false)
      }
    }
    document.addEventListener('mousedown', handleCloseOutside)

    return () => document.removeEventListener('mousedown', handleCloseOutside)
  }, [ref])

  return [isOpen, handleOpen]
}
