import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'
import { showAlert } from '../../../utility/SweetAlert'
import { useTranslation } from 'react-i18next'

const InteractorOrderUpdate = () => {
  const { t } = useTranslation()
  const orderUpdate = useCallback(
    (payload, status = 'APPROVED', uid) => {
      ApiService.jsonRequest('/transaction-order/update', payload, (response) => {
        if (response.status_code === 200) {
          status === 'REJECTED'
            ? showAlert({
                icon: 'success',
                message: t('response_message.order_rejected'),
                timer: 2000,
                redirect: uid ? `/order/view/${uid}` : '/order',
              })
            : showAlert({
                icon: 'success',
                message: t('response_message.send_success'),
                timer: 2000,
                redirect: uid ? `/order/view/${uid}` : '/order',
              })
        } else {
          showAlert({
            icon: 'warning',
            message: t('response_message.send_failed'),
            timer: 2000,
            reload: true,
          })
        }
      })
    },
    [t],
  )
  return { orderUpdate }
}

export default InteractorOrderUpdate
