import React from 'react'
import ProductSkeleton from '../../assets/image/skeleton/product.png'

const VerticalGridSkeleton = (data) => {
  return (
    <>
      {Array.from(Array(data.box ?? 12), (_, idx) => (
        <li
          key={idx}
          className="col-wd-3 col-md-4 product-item product-item__card pb-2 mb-2 pb-md-0 mb-md-0 border-bottom border-md-bottom-0"
        >
          <div className="product-item__outer h-100">
            <div className="product-item__inner p-md-3 row no-gutters">
              <div className="col col-lg-auto product-media-left mr-3">
                <div className="img-product d-block">
                  <img className="img-fluid" src={ProductSkeleton} alt="" />
                </div>
              </div>
              <div className="col product-item__body">
                <div className="mb-2">
                  <div className="mb-2 skeleton-loader height-10 width-50"></div>
                  <div className="product-item__title skeleton-loader height-22 width-134 max-width-148"></div>
                </div>
                <div className="flex-center-between mb-1 mt-3">
                  <div className="product-price skeleton-loader height-12 width-75"></div>
                </div>
                <div className="d-block mb-1">
                  <div className="flex-center-between mt-1">
                    <div>
                      <div className="skeleton-loader height-10 width-122 mb-1"></div>
                      <div className="skeleton-loader height-10 width-122"></div>
                    </div>
                  </div>
                  <div className="flex-center-between mt-1">
                    <div className="skeleton-loader height-10 width-122"></div>
                  </div>
                </div>
                <div className="d-block mb-1">
                  <div className="skeleton-loader height-10 width-122"></div>
                </div>
                <div className="product-item__footer">
                  <div className="border-top flex-center-between flex-wrap">
                    <div className="btn btn-xs btn-block skeleton-loader height-33 font-weight-bold text-center font-size-12"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      ))}
    </>
  )
}

export default VerticalGridSkeleton
