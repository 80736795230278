import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const Breadcrumb = ({ title }) => {
  const { t } = useTranslation()
  return (
    <div className="bg-gray-13 bg-md-transparent">
      <div className="container">
        <div className="my-md-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-3 flex-nowrap flex-xl-wrap overflow-auto overflow-xl-visble">
              <li className="breadcrumb-item flex-shrink-0 flex-xl-shrink-1">
                <Link to="/">{t('menu.home')}</Link>
              </li>
              <li
                className={
                  title !== undefined
                    ? 'breadcrumb-item flex-shrink-0 flex-xl-shrink-1 active'
                    : 'd-none'
                }
                aria-current="page"
              >
                {title === undefined ? '' : title}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  )
}

Breadcrumb.propTypes = {
  title: PropTypes.string,
}
export default Breadcrumb
