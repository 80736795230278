import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { InteractorProductItemSummary } from '../../interactors/Main'
import { currencyFormat, subStringContent, prettyUrl, formatCash } from '../../utility/Helpers'
import { Rating } from '../Main'
import { useSelector } from 'react-redux'

const ProductRelated = () => {
  const { t } = useTranslation()
  const { account } = useSelector((state) => state.account)
  const [loading, setLoading] = useState(true)
  const { productSummary } = InteractorProductItemSummary()
  const [dataProduct, setDataProduct] = useState({})
  const [payload] = useState({
    limit: 5,
    keywords: {
      sort_by: 'related',
    },
  })
  useEffect(() => {
    if (loading) {
      productSummary(payload, setDataProduct, setLoading)
    }
  }, [loading, payload, productSummary])
  return (
    <>
      <div className="mb-10">
        <div className="d-flex justify-content-between align-items-center border-bottom border-color-1 flex-lg-nowrap flex-wrap mb-4">
          <h3 className="section-title mb-0 pb-2 font-size-22">{t('menu.related_product')}</h3>
        </div>
        <ul className="row list-unstyled products-group no-gutters">
          {dataProduct.items?.map((item, index) => (
            <li key={index} className="col-6 col-md-3 col-xl-2gdot4-only col-wd-2 product-item">
              <div className="product-item__outer h-100">
                <div className="product-item__inner px-xl-4 p-3">
                  <div className="product-item__body pb-xl-2">
                    <div className="mb-2 font-size-12">
                      <div className="pointer font-size-12 d-none d-lg-block store-title text-gray-5">
                        {subStringContent(item.store_name, 25)}
                      </div>
                      <div className="pointer font-size-10-sm d-block d-lg-none store-title text-gray-5">
                        {subStringContent(item.store_name, 20)}
                      </div>
                    </div>
                    <h5 className="mb-1 product-item__title">
                      <Link
                        to={`/product/detail/${prettyUrl(item.product_title) + '/' + item.uid}`}
                        className="text-blue font-weight-bold"
                        title={item.product_title}
                      >
                        {subStringContent(item.product_title, 30)}
                      </Link>
                    </h5>
                    <div className="mb-2">
                      <Link
                        to={`/product/detail/${prettyUrl(item.product_title) + '/' + item.uid}`}
                        className="d-block text-center"
                        title={item.product_title}
                      >
                        <img
                          className="img-fluid img-product"
                          src={item.product_thumbnail}
                          alt={item.product_title}
                        />
                      </Link>
                    </div>
                    <div className="flex-center-between mb-1">
                      <div className="product-price">
                        <div className="text-gray-100 font-weight-bold">
                          {currencyFormat(item.product_price, item.product_price_currency)}
                        </div>
                      </div>
                    </div>
                    <div className="flex-center-between mt-1">
                      <div className="text-gray-6 d-flex align-items-center font-size-12 pointer">
                        <Rating rating={item.product_rating} />
                        <span className="ml-1">
                          {formatCash(item.product_sold)} {t('title.sold')}
                        </span>
                      </div>
                    </div>
                    <div className="flex-center-between mt-1">
                      <div className="text-gray-6 font-size-12 font-size-12 flex-nowrap">
                        {t('title.tax_status')}: {t(`title.tax_type.${item.store_tax_type}`)}
                      </div>
                    </div>
                    <div className="flex-center-between mt-1">
                      <div className="text-gray-6 font-size-12 font-size-12 flex-nowrap">
                        {subStringContent(item.origin_city_name, 28)}
                      </div>
                    </div>
                  </div>
                  {account?.uid !== item?.store_account_uid && (
                    <div className="product-item__footer">
                      <div className="visible-md-block text-center flex-wrap">
                        <Link
                          to={`/chat?product=${item.uid}&seller=${item.store_uid}`}
                          className="btn btn-block text-nowrap btn-primary btn-xs font-weight-bold"
                        >
                          <i className="fa fa-comments mr-2" />
                          {t('button.chat')}
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

export default ProductRelated
