import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'
import { useTranslation } from 'react-i18next'
import { InteractorAuthenticationOtpRequest } from '../../../interactors/Main'
import { showAlert } from './../../../utility/SweetAlert'

const InteractorAccountUserRegister = () => {
  const { OTPRequest } = InteractorAuthenticationOtpRequest()
  const { t } = useTranslation()
  const register = useCallback(
    (payload, loading) => {
      payload = {
        ...payload,
        source: process.env.REACT_APP_CLIENT_ROLE,
        phone_number: null,
        profile_picture: null,
        gender: null,
        date_birth: '1970-01-01',
        created_by: payload.full_name,
      }
      ApiService.jsonRequest('/account-user/register', payload, (response) => {
        loading(false)
        if (response.status_code === 200) {
          OTPRequest({
            target_type: 'EMAIL',
            target_identity: response.data.email_address,
            target_full_name: response.data.full_name,
            otp_category: 'VERIFY-EMAIL',
            account_uid: response.data.uid,
          })
        } else {
          showAlert({
            icon: 'error',
            message: t(`response_message.${response.messages}`),
            timer: 2000,
          })
        }
      })
    },
    [t, OTPRequest],
  )

  return { register }
}

export default InteractorAccountUserRegister
