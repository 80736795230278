import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import FilterCategory from './FilterCategory.js'
import FilterProvince from './FilterProvince.js'
import { FilterRating } from './FilterRating.js'
import { FilterPrice } from './FilterPrice.js'

const FilterMain = ({ menu }) => {
  const { t } = useTranslation()

  return (
    <>
      {!menu && (
        <div className="col-0 col-md-3 col-wd-2gdot5 d-none d-xl-block">
          <div className="mb-6 border border-width-2 border-color-3 borders-radius-6">
            <ul id="sidebarNav" className="list-unstyled mb-0 sidebar-navbar view-all">
              <li>
                <div className="dropdown-title font-weight-bold">{t('menu.category')}</div>
              </li>
              <FilterCategory />
            </ul>
          </div>
          <div className="mb-6">
            <FilterProvince />
            <FilterRating />
            <FilterPrice />
          </div>
        </div>
      )}
    </>
  )
}

FilterMain.propTypes = {
  menu: PropTypes.any,
}

export default FilterMain
