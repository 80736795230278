import React from 'react'
import PropTypes from 'prop-types'
import ToggleSidebar from '../toggle/ToggleSidebar'
import Menu from '../menu/Menu'

const SidebarLeft = ({ menu, setMenuCallback, children, sidebarRef }) => {
  return (
    <>
      <aside
        id="sidebarHeader1"
        className={`u-sidebar u-sidebar--left u-unfold--css-animation
        ${menu ? 'fadeInLeft' : 'u-unfold--hidden'}
        `}
        ref={sidebarRef}
        aria-labelledby="sidebarHeaderInvoker"
      >
        <div className="u-sidebar__scroller">
          <div className="u-sidebar__container">
            <div className="u-header-sidebar__footer-offset">
              <div className="position-absolute top-0 right-0 z-index-2 pt-4 pr-4 bg-white">
                <ToggleSidebar setMenuCallback={() => setMenuCallback()} show={menu} />
              </div>
              <div className="js-scrollbar u-sidebar__body">
                <div
                  id="headerSidebarContent"
                  className="u-sidebar__content u-header-sidebar__content"
                >
                  <Menu
                    className="navbar-brand u-header__navbar-brand u-header__navbar-brand-center mb-3"
                    to="/"
                  >
                    <img alt="adaloka.com" src="/logo.png" />
                  </Menu>
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </>
  )
}

SidebarLeft.propTypes = {
  menu: PropTypes.any,
  setMenuCallback: PropTypes.any,
  children: PropTypes.any,
  sidebarRef: PropTypes.any,
}

export default SidebarLeft
