import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import useDropdown from '../../hooks/useDropdown'
import useHover from '../../hooks/useHover'
import { ProductItemSearch } from '../../redux/product/search/actionCreator'
import { SearchMobile, SidebarCategory } from './../../components/Main'
import { InteractorCategoryMain } from './../../interactors/Main'
import { categoryUrl } from '../../utility/Helpers.js'
import LogoWhite from '../../assets/image/logo/logo_white.png'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition'
import AlertMessage from '../../components/alert/AlertMessage'

const HeaderHome = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { account } = useSelector((state) => state.account)
  const { header_info } = useSelector((state) => state.summaryHeader)
  const { categoryMainSummary } = InteractorCategoryMain()
  const { t } = useTranslation()
  const [masterCategory, setMasterCategory] = useState([])
  const [keyword, setKeyword] = useState('')
  const [isLogin, setIsLogin] = useState(false)
  const { payload } = useSelector((state) => state.productItemSearch)
  const openMenu = (e) => e.currentTarget.classList.add('hs-mega-menu-opened')
  const closeMenu = (e) => e.currentTarget.classList.remove('hs-mega-menu-opened')
  const cartRef = useRef()
  const [isOpen, handleShow] = useHover(cartRef)
  const { onMouseOver, onMouseLeave } = handleShow
  const [openDropdown, dropdownHandler] = useDropdown()

  const { finalTranscript, resetTranscript, listening } = useSpeechRecognition()
  const voiceStart = () => {
    SpeechRecognition.startListening({
      language: 'id-ID',
    })
  }
  const voiceStop = () => {
    SpeechRecognition.stopListening()
  }

  const onChangeKeyword = (value) => {
    setKeyword(value)
  }

  const handleClearKeyword = (e) => {
    e.preventDefault()
    setKeyword('')
  }
  const handleSubmitSearch = (e) => {
    e.preventDefault()
    const payloadObj = { ...payload }
    payloadObj.keywords.text_search = keyword
    dispatch(ProductItemSearch(payloadObj))
    navigate('/product')
  }
  useEffect(() => {
    categoryMainSummary(setMasterCategory)
    if (account) {
      setIsLogin(true)
    }
    // SEARCH BY VOICE

    if (finalTranscript !== '') {
      const speech_text = finalTranscript.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, '')
      setKeyword(speech_text)
      let objPayload = { ...payload }
      objPayload.keywords.text_search = speech_text
      dispatch(ProductItemSearch(objPayload))
      resetTranscript()
      navigate('/product')
    }

    // END SEARCH BY VOICE
  }, [account, categoryMainSummary, dispatch, finalTranscript, navigate, payload, resetTranscript])
  return (
    <>
      <div className="py-2 py-xl-5 bg-primary-down-lg">
        <div className="container my-0dot5 my-xl-0">
          <div>
            {/* <AlertMessage
              action
              type="danger"
              message={
                <div>
                  <strong>Pemberitahuan!</strong> Pertanggal 9 Oktober 2023 metode pembayaran
                  tagihan hanya dapat dilakukan dengan menggunakan Virtual Akun (VA). Semua
                  transaksi pembayaran akan masuk pada SALDO penjual. Silahkan tambahkan No.
                  Rekening sesuai dengan identitas yang terdaftar pada menu Keuangan. <br />
                  Jika ada pertanyaan silahkan hubungi team kami pada email:{' '}
                  <strong>cs@adaloka.com</strong>
                </div>
              }
            /> */}
          </div>
          <div className="row align-items-center justify-content-between">
            <div className="col col-md-auto px-lg-3 px-2">
              <div className="row align-items-center flex-row-reverse flex-xl-row justify-content-center justify-content-xl-between order-1 order-xl-0 u-header__navbar py-0">
                <div className="col-9">
                  <Link
                    className="navbar-brand u-header__navbar-brand u-header__navbar-brand-center"
                    to="/"
                  >
                    <img className="d-none d-xl-block" alt="adaloka.com" src="/logo.png" />
                    <img className="d-block d-xl-none" alt="adaloka.com" src={LogoWhite} />
                  </Link>
                </div>
                <div className="col-3 d-block d-xl-none">
                  <SidebarCategory>
                    <span id="hamburgerTriggerMenu" className="u-hamburger__box">
                      <span className="u-hamburger__inner" />
                    </span>
                  </SidebarCategory>
                </div>
              </div>
            </div>
            <div className="col d-none d-xl-block">
              <form className="js-focus-state" onSubmit={handleSubmitSearch}>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control py-2 pl-5 font-size-15 border-right-0 height-40 border-width-2 rounded-left-pill border-primary"
                    name="text"
                    id="searchproduct-item"
                    placeholder={t('placeholder.search_keyword')}
                    aria-label={t('placeholder.search_keyword')}
                    aria-describedby="searchProduct1"
                    value={keyword}
                    onChange={(e) => onChangeKeyword(e.target.value)}
                  />
                  <div className="input-group-append">
                    <button
                      className={`btn height-40 text-gray-60 font-size-22 font-weight-bolder border-top border-bottom border-left-0 border-right-0 rounded-0 u-divider border-primary border-width-2 px-2 py-2 ${
                        keyword === '' ? 'd-none' : 'd-block'
                      }`}
                      aria-label="Close"
                      type="button"
                      onClick={handleClearKeyword}
                    >
                      <span aria-hidden="true" className="position-absolute-center">
                        &times;
                      </span>
                    </button>
                    {listening === false ? (
                      <button
                        className="btn height-40 text-gray-60 font-weight-normal border-top border-bottom border-left-0 rounded-0 border-primary border-width-2 pl-3 py-2"
                        type="button"
                        onClick={voiceStart}
                      >
                        <span className="font-size-16">
                          <i className="fas fa-microphone mb-1" />
                        </span>
                        <span className="tooltip-text p-2">{t('title.voice_search')}</span>
                      </button>
                    ) : (
                      <button
                        className="btn height-40 text-gray-60 font-weight-normal border-top border-bottom border-left-0 rounded-0 border-primary border-width-2 pl-3 py-2"
                        type="button"
                        onClick={voiceStop}
                      >
                        <span className="font-size-16">
                          <i className="fas fa-microphone text-red mb-1" />
                        </span>
                        <span className="tooltip-text p-2">{t('title.voice_search')}</span>
                      </button>
                    )}

                    <button
                      className="btn btn-primary height-40 py-2 px-3 rounded-right-pill"
                      id="searchProduct1"
                      type="submit"
                    >
                      <span className="ec ec-search font-size-24" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div className="col col-md-auto text-right text-xl-left pl-0 pl-xl-3 position-static px-sm-0">
              <div className="d-inline-flex">
                <ul className="d-none d-xl-flex list-unstyled mb-0 align-items-center">
                  <li className="col mx-2">
                    <Link
                      id="basicDropdownHoverInvoker"
                      className="text-white position-relative d-flex"
                      data-placement="top"
                      title={t('menu.cart')}
                      aria-controls="basicDropdownHover"
                      aria-haspopup="true"
                      aria-expanded="false"
                      data-unfold-event="click"
                      data-unfold-target="#basicDropdownHover"
                      to="/cart"
                      onMouseOver={onMouseOver}
                      onMouseLeave={onMouseLeave}
                    >
                      <i className="font-size-22 ec ec-shopping-bag text-black" />
                      {header_info.cart.total > 0 && (
                        <span className="bg-lg-down-black width-22 height-22 bg-primary position-absolute d-flex align-items-center justify-content-center rounded-circle left-12 top-8 font-weight-bold font-size-12">
                          {header_info.cart.total}
                        </span>
                      )}
                    </Link>
                    {header_info.cart.total > 0 && (
                      <div
                        id="basicDropdownHover"
                        className={`cart-dropdown dropdown-menu dropdown-unfold border-top border-top-primary mt-3 border-width-2 border-left-0 border-right-0 border-bottom-0 left-auto right-0 ${
                          isOpen && 'show'
                        }`}
                        aria-labelledby="basicDropdownHoverInvoker"
                        onMouseLeave={onMouseLeave}
                        ref={cartRef}
                      >
                        <ul className="list-unstyled px-3 pt-3">
                          {header_info.cart.items.map((row, key) => {
                            return (
                              <React.Fragment key={key}>
                                <li className="border-bottom pb-3 mb-3">
                                  <div className="">
                                    <ul className="list-unstyled row mx-n2">
                                      <li className="px-2 col-auto">
                                        <img className="summary-cart" src={row.store_logo} alt="" />
                                      </li>
                                      <li className="px-2 col">
                                        <h5 className="text-blue font-size-14 font-weight-bold">
                                          {row.store_name}
                                        </h5>
                                        <span className="font-size-14">
                                          {row.total_product} {t('title.product.title')}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                              </React.Fragment>
                            )
                          })}
                        </ul>
                        <div className="flex-center-between px-4 pt-0">
                          <Link
                            to="/cart"
                            className="btn btn-primary mb-3 mb-md-0 font-weight-normal btn-block px-5 px-md-4 px-lg-5"
                          >
                            {t('menu.cart')}
                          </Link>
                        </div>
                      </div>
                    )}
                  </li>
                  <li className="col mx-2">
                    <Link
                      className="text-white position-relative d-flex"
                      data-placement="top"
                      title={t('menu.chat')}
                      to="/chat"
                    >
                      <i className="font-size-22 text-dark fa fa-comments" />
                      {header_info.chat_unread > 0 && (
                        <span className="bg-lg-down-black width-22 height-22 bg-red text-white position-absolute d-flex align-items-center justify-content-center rounded-circle left-12 top-8 font-weight-bold font-size-11">
                          {header_info.chat_unread < 100 ? header_info.chat_unread : '99+'}
                        </span>
                      )}
                    </Link>
                  </li>
                </ul>
                <ul className="d-flex d-xl-none list-unstyled mb-0 align-items-center">
                  <SearchMobile />
                  <li className="col px-2">
                    <Link
                      to={`/account/${isLogin ? 'profile' : 'signin'}`}
                      className="text-white"
                      data-placement="top"
                      title={t('menu.my_account')}
                    >
                      <i className="font-size-22 ec ec-user" />
                    </Link>
                  </li>
                  <li className="col px-2">
                    <Link
                      to="/cart"
                      className="text-white position-relative d-flex"
                      data-placement="top"
                      title={t('menu.cart')}
                    >
                      <i className="font-size-22 ec ec-shopping-bag" />
                      {header_info.cart.total > 0 && (
                        <span className="bg-lg-down-black width-22 height-22 bg-primary position-absolute d-flex align-items-center justify-content-center rounded-circle left-12 top-8 font-weight-bold font-size-12">
                          {header_info.cart.total}
                        </span>
                      )}
                    </Link>
                  </li>
                  <li className="col px-2">
                    <Link
                      to="/chat"
                      className="text-white position-relative d-flex"
                      data-placement="top"
                      title={t('menu.chat')}
                    >
                      <i className="font-size-22 text-dark fa fa-comments text-white" />
                      {header_info.chat_unread > 0 && (
                        <span className="bg-lg-down-red width-22 height-22 bg-red text-white position-absolute d-flex align-items-center justify-content-center rounded-circle left-12 top-8 font-weight-bold font-size-11">
                          {header_info.chat_unread < 100 ? header_info.chat_unread : '99+'}
                        </span>
                      )}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-none d-xl-block container">
        <div className="row">
          <div className="col-md-auto d-none d-xl-block">
            <div className="max-width-270 min-width-270">
              <div id="basicsAccordion">
                <div className="card border-0">
                  <div className="card-header card-collapse border-0" id="basicsHeadingOne">
                    <button
                      type="button"
                      className="btn-link btn-remove-focus btn-block d-flex card-btn py-3 text-lh-1 px-4 shadow-none btn-primary rounded-top-lg border-0 font-weight-bold text-white"
                      data-toggle="collapse"
                      data-target="#basicsCollapseOne"
                      aria-expanded="true"
                      aria-controls="basicsCollapseOne"
                      {...dropdownHandler}
                    >
                      <span className="ml-0 text-white mr-2">
                        <span className="fa fa-list-ul" />
                      </span>
                      <span className="pl-1 text-white">{t('menu.category')}</span>
                    </button>
                  </div>
                  <div
                    id="basicsCollapseOne"
                    className={`collapse vertical-menu ${!openDropdown && 'show'}`}
                    aria-labelledby="basicsHeadingOne"
                    data-parent="#basicsAccordion"
                  >
                    <div className="card-body p-0">
                      <nav className="js-mega-menu navbar navbar-expand-xl u-header__navbar u-header__navbar--no-space hs-menu-initialized">
                        <div
                          id="navBar"
                          className="collapse navbar-collapse u-header__navbar-collapse"
                        >
                          <ul className="navbar-nav u-header__navbar-nav">
                            {masterCategory.map((main, indexMain) => {
                              return (
                                <li
                                  key={indexMain}
                                  className="nav-item hs-has-mega-menu u-header__nav-item"
                                  data-event="hover"
                                  data-animation-in="slideInUp"
                                  data-animation-out="fadeOut"
                                  data-position="left"
                                  onMouseEnter={openMenu}
                                  onMouseLeave={closeMenu}
                                >
                                  <Link
                                    id="basicMegaMenu"
                                    className="nav-link u-header__nav-link u-header__nav-link-toggle"
                                    to={categoryUrl(main.name, main, null, null)}
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    {main.name}
                                  </Link>
                                  <div
                                    className="hs-mega-menu vmm-tfw u-header__sub-menu"
                                    aria-labelledby="basicMegaMenu"
                                  >
                                    <div className="row u-header__mega-menu-wrapper category-scrollbar">
                                      {main.major.map((major, indexMajor) => {
                                        return (
                                          <div key={indexMajor} className="col mb-3 mb-sm-0">
                                            <Link
                                              className="u-header__sub-menu-title"
                                              to={categoryUrl(major.name, main, major, null)}
                                            >
                                              {major.name}
                                            </Link>
                                            <ul className="u-header__sub-menu-nav-group mb-3">
                                              {major.minor.map((minor, indexMinor) => {
                                                let borderBottom = ''
                                                if (indexMinor === major.minor.length - 1) {
                                                  borderBottom = 'border-bottom pb-3'
                                                }
                                                return (
                                                  <li key={indexMinor}>
                                                    <Link
                                                      className={`nav-link u-header__sub-menu-nav-link ${borderBottom}`}
                                                      to={categoryUrl(
                                                        minor.name,
                                                        main,
                                                        major,
                                                        minor,
                                                      )}
                                                    >
                                                      {minor.name}
                                                    </Link>
                                                  </li>
                                                )
                                              })}
                                            </ul>
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </div>
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <nav className="js-mega-menu navbar navbar-expand-md u-header__navbar u-header__navbar--no-space">
              <div id="navBar" className="collapse navbar-collapse u-header__navbar-collapse">
                <ul className="navbar-nav u-header__navbar-nav">
                  <li className="nav-item u-header__nav-item">
                    <Link className="nav-link u-header__nav-link text-sale" to="/product">
                      {t('menu.product')}
                    </Link>
                  </li>
                  <li className="nav-item u-header__nav-item">
                    <Link className="nav-link u-header__nav-link" to="/page/how-to-order">
                      {t('menu.how_to_order')}
                    </Link>
                  </li>
                  <li className="nav-item u-header__nav-item">
                    <Link className="nav-link u-header__nav-link" to="/page/how-to-pay">
                      {t('menu.how_to_pay')}
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  )
}
export default HeaderHome
