import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { FetchAccount } from '../../../redux/account/actionCreator'
import { showAlert } from './../../../utility/SweetAlert'
import { useTranslation } from 'react-i18next'
import { InteractorAuthenticationOtpRequest, InteractorAccountUserStore } from '../../Main'
import { setCookies } from '../../../utility/Cookies'
import { encryptCrypto } from '../../../utility/Encryption'

const InteractorAccountUserLogin = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { OTPRequest } = InteractorAuthenticationOtpRequest()
  const { accountStoreCheck } = InteractorAccountUserStore()
  const login = useCallback(
    async (payload, loading) => {
      payload = {
        ...payload,
        access_user_agent: navigator?.userAgent,
        access_ip_address: null,
        access_location: null,
        access_device: null,
      }
      ApiService.jsonRequest('/account-user/login', payload, (response) => {
        loading(false)
        if (response.status_code === 200) {
          setCookies({
            key: '_wsso',
            value: encryptCrypto(JSON.stringify(response.data)),
            expires: new Date().getTime() + 8 * 3600 * 1000, // 8 Hours
          })
          setCookies({
            key: '_wchat',
            value: encryptCrypto(
              JSON.stringify({
                reference_uid: response.data.uid,
                role: 'CUSTOMER',
                full_name: response.data.full_name,
                profile_picture: response.data.profile_picture ?? '',
              }),
            ),
            expires: new Date().getTime() + 8 * 3600 * 1000, // 8 Hours
          })
          dispatch(FetchAccount(response.data))
          accountStoreCheck({
            uid: '',
            account_uid: response.data.uid,
          })
          return navigate('/')
        } else {
          if (response.messages === 'account_is_not_verified') {
            return OTPRequest({
              target_type: 'EMAIL',
              target_identity: payload.email_address,
              target_full_name: response.data.full_name,
              otp_category: 'VERIFY-EMAIL',
              account_uid: response.data.uid,
            })
          }
          showAlert({
            icon: 'error',
            message: t(`response_message.${response.messages}`),
            timer: 1500,
            reload: true,
          })
        }
      })
    },
    [dispatch, accountStoreCheck, navigate, t, OTPRequest],
  )

  return { login }
}

export default InteractorAccountUserLogin
