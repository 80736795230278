import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import parser from 'react-html-parser'
import { Breadcrumb, Loading, ProductLatest } from '../../components/Main'
import { formatDate } from '../../utility/Helpers'
import { InteractorContentBlogView } from '../../interactors/Main'
const BlogDetail = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { blogView } = InteractorContentBlogView()
  const [loading, setLoading] = useState(true)
  const [blogDetail, setBlogDetail] = useState({})
  const [payload] = useState({
    uid: id,
  })
  useEffect(() => {
    if (loading) {
      blogView(payload, setBlogDetail, setLoading)
    }
  }, [blogView, loading, payload])

  return (
    <>
      <Breadcrumb title={t('title.blog')} />
      {loading ? (
        <div className="d-flex justify-content-center align-items-center height-100vh">
          <Loading />
        </div>
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-wd">
              <div className="min-width-1100-wd">
                <article className="card mb-8 border-0">
                  <div className="card-img-bottom">
                    <img src={blogDetail.media_url} alt={blogDetail.blog_title} className="w-100" />
                  </div>
                  <div className="card-body pt-5 pb-0 px-0">
                    <div className="d-block d-md-flex flex-center-between mb-4 mb-md-0">
                      <h1 className="mb-md-3 mb-1 font-weight-bold">{blogDetail.blog_title}</h1>
                    </div>
                    <div className="mb-3 pb-3 border-bottom">
                      <div className="list-group list-group-horizontal flex-wrap list-group-borderless align-items-center mx-n0dot5">
                        <Link to="#" className="mx-0dot5 text-gray-5">
                          {blogDetail.blog_category}
                        </Link>
                        <span className="mx-2 font-size-n5 mt-1 text-gray-5">
                          <i className="fas fa-circle" />
                        </span>
                        <Link to="#" className="mx-0dot5 text-gray-5">
                          {formatDate(blogDetail.created_date, 'DD/MM/YYYY')}
                        </Link>
                      </div>
                    </div>
                    <div className="font-size-16">{parser(`${blogDetail.blog_content}`)}</div>
                  </div>
                </article>
              </div>
            </div>
            <div className="col-xl-3 col-wd">
              <ProductLatest limit={3} mode="list" />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default BlogDetail
