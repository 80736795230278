import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { currencyFormat, formatDate, numberFormat, numberFormatAPI } from '../../utility/Helpers'

const InteractorCsvOrder = () => {
  const { t } = useTranslation()
  const csvOrder = useCallback(
    (data, callback) => {
      const headers = [
        { label: 'No', key: 'no' },
        { label: t('title.purchase_request_number'), key: 'purchase_request_number' },
        { label: t('title.purchase_order_number'), key: 'purchase_order_number' },
        { label: t('title.seller_name'), key: 'seller_name' },
        { label: t('title.status'), key: 'status' },
        { label: t(`title.order_number`), key: 'order_number' },
        { label: t(`title.order_date`), key: 'order_date' },
        { label: t(`invoice.payment_method`), key: 'payment_method' },
        { label: t(`title.payment_due_date`), key: 'payment_due_date' },
        { label: t(`title.delivery_type`), key: 'delivery_type' },
        { label: t(`title.note`), key: 'note' },
        { label: t(`title.ship_to`), key: 'destination_address' },
        { label: t(`title.ship_consignee`), key: 'ship_consignee' },
        { label: t('title.product.title'), key: 'product_title' },
        { label: t('title.product.product_unit_type'), key: 'product_unit_type' },
        { label: t('title.product.weight_total'), key: 'weight_total' },
        { label: t('title.product.qty'), key: 'product_qty' },
        { label: t('title.product.price'), key: 'price' },
        { label: t('title.subtotal_product'), key: 'subtotal_product' },
        { label: t('title.delivery_cost'), key: 'delivery_cost' },
        { label: t('title.delivery_insurance_cost'), key: 'delivery_insurance_cost' },
        { label: t('title.subtotal_weight'), key: 'subtotal_weight' },
        { label: t('title.payment_total'), key: 'payment_total' },
      ]

      // TOTAL SUM
      let summary_total = []
      data?.forEach((summary) => {
        let total = 0
        summary?.transaction_items?.forEach((item) => {
          total += item.product_quantity * item.product_price
        })
        summary_total.push({
          uid: summary.uid,
          total: total,
        })
      })

      // MAPING ROW
      let results = []
      data?.forEach((row, idx) => {
        data?.forEach((item) => {
          let total_order_product = 0
          let total_order_weight = 0
          item.transaction_items?.map((product) => {
            total_order_weight += product.product_weight * product.product_quantity
            return (total_order_product += product.product_price * product.product_quantity)
          })
          Object.assign(item, {
            subtotal_product: total_order_product,
            subtotal_weight: total_order_weight,
          })
        })
        row?.transaction_items?.forEach((item, key) => {
          const obj = {
            no: key === 0 ? idx + 1 : '',
            purchase_request_number: key === 0 ? row.purchase_request_number : '',
            purchase_order_number: key === 0 ? row.purchase_order_number : '',
            seller_name: key === 0 ? row.store_name : '',
            status: key === 0 ? t(`initial_status.${row.status}`) : '',
            order_number: key === 0 ? row.transaction_code : '',
            order_date: key === 0 ? formatDate(row.created_date, 'DD/MM/YYYY HH:mm') : '',
            payment_method: key === 0 ? t(`initial_status.${row.payment_type}`) : '',
            payment_due_date:
              key === 0
                ? row.payment_type === 'TOP'
                  ? formatDate(row.payment_term_date, 'DD/MM/YYYY HH:mm')
                  : ''
                : '',
            delivery_type: key === 0 ? t(`initial_status.${row.delivery_type}`) : '',
            note: key === 0 ? row.request_quotation_note : '',
            destination_address: key === 0 ? row.destination_address : '',
            ship_consignee:
              key === 0 ? row.consignee_full_name + ' / ' + row.consignee_phone_number : '',
            product_title: item.product_title,
            // product_price:
            //   currencyFormat(item.product_price, item.product_price_currency) +
            //   '/' +
            //   item.product_unit_type,
            product_unit_type: item.product_unit_type,
            product_qty: numberFormat(item.product_quantity),
            weight_total:
              numberFormatAPI(item.product_weight * item.product_quantity, {
                leadingZeroAllowed: true,
              }) +
              ' ' +
              t('title.product.weight_kg'),
            price: currencyFormat(item.product_price, item.product_price_currency),
            subtotal_product: currencyFormat(
              item.product_price * item.product_quantity,
              item?.product_price_currency,
            ),
            subtotal_weight:
              key === 0
                ? `${numberFormatAPI(row?.subtotal_weight, { leadingZeroAllowed: true })} Kg`
                : '',
            delivery_cost:
              key === 0 ? currencyFormat(row.delivery_cost, row.delivery_cost_currency) : 0,
            delivery_insurance_cost:
              key === 0
                ? currencyFormat(row.delivery_insurance_cost, row.delivery_insurance_currency)
                : 0,
            payment_total:
              key === 0 ? currencyFormat(row.payment_amount, row.payment_amount_currency) : 0,
          }
          results.push(obj)
        })
      })
      callback({
        headers: headers,
        data: results,
        filename: 'ORDER-' + new Date().getTime() + '.csv',
      })
    },
    [t],
  )
  return { csvOrder }
}

export default InteractorCsvOrder
