import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'

const InteractorAccountAddressSearch = () => {
  const addressSearch = useCallback((payload, callback, loading) => {
    ApiService.jsonRequest('/account-user/address-search', payload, (response) => {
      if (response.status_code === 200) {
        if (response.data) {
          callback(response.data)
        }
      }
      loading(false)
    })
  }, [])
  return { addressSearch }
}

export default InteractorAccountAddressSearch
