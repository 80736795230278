import React from 'react'
import PropTypes from 'prop-types'

const ToggleSidebar = ({ show, setMenuCallback }) => {
  return (
    <>
      <button
        id="sidebarHeaderInvokerMenu"
        type="button"
        className={`'navbar-toggler d-block btn u-hamburger mr-3 mr-xl-0' 
        ${show && 'active'}`}
        aria-controls="sidebarHeader"
        aria-haspopup="true"
        aria-expanded="true"
        data-unfold-event="click"
        data-unfold-hide-on-scroll="false"
        data-unfold-target="#sidebarHeader1"
        data-unfold-type="css-animation"
        data-unfold-animation-in="fadeInLeft"
        data-unfold-animation-out="fadeOutLeft"
        data-unfold-duration={500}
        onClick={setMenuCallback}
      >
        <span id="hamburgerTriggerMenu" className="u-hamburger__box">
          <span className="u-hamburger__inner" />
        </span>
      </button>
    </>
  )
}

ToggleSidebar.propTypes = {
  show: PropTypes.any,
  setMenuCallback: PropTypes.any,
}

export default ToggleSidebar
