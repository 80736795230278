import React from 'react'
import PropTypes from 'prop-types'
const Loading = (props) => {
  if (props.type === 'page') {
    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 p-0">
              <div className="min-vh-100 align-items-center d-flex flex-column justify-content-center">
                <div style={{ padding: '24px' }}>
                  <div className="loading"></div>
                  <div className="text-center">Loading...</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div style={{ padding: '24px' }}>
          <div className="loading"></div>
          <div className="text-center">Loading...</div>
        </div>
      </>
    )
  }
}

Loading.propTypes = {
  type: PropTypes.string,
}
export default Loading
