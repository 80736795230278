import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Breadcrumb, MenuAccount, WideCardQuotationRequest } from '../../components/Main'
import { InteractorQuotationRequestSearch, InteractorCsvQuotation } from '../../interactors/Main'
import { useSelector } from 'react-redux'

const QuotationRequest = () => {
  const { t } = useTranslation()
  const queryParams = new URLSearchParams(window.location.search)
  const { account } = useSelector((state) => state.account)
  const { requestSearch } = InteractorQuotationRequestSearch()
  const { csvQuotation } = InteractorCsvQuotation()
  const [loading, setLoading] = useState(true)
  const [download, setDownload] = useState({
    headers: null,
    items: null,
    filename: null,
  })
  const [datacard, setDatacard] = useState([])
  const [payload, setPayload] = useState({
    page: 1,
    limit: 5,
    keywords: {
      text_search: queryParams.get('ref') ?? '',
      status: '',
      account_uid: account.uid,
      store_uid: '',
    },
  })

  const handleResponse = useCallback(
    (data) => {
      setDatacard(data)
      csvQuotation(data.items, setDownload)
    },
    [csvQuotation],
  )

  useEffect(() => {
    if (loading) {
      requestSearch(payload, handleResponse, setLoading)
    }
  }, [handleResponse, loading, payload, requestSearch])

  const handleFilter = (row) => {
    setPayload(row)
    setDatacard([])
    setLoading(true)
  }

  return (
    <>
      <Breadcrumb title={t('menu.quotation_request')} className="mb-4" />
      <div className="container">
        <div className="row mb-8">
          <MenuAccount />
          <div className="col-xl-9 col-wd-9gdot5">
            <div className="flex-center-between mt-2 mt-lg-0">
              <h3 className="font-size-20-lg font-size-20 font-weight-bold mb-0">
                {t('menu.quotation_request')}
              </h3>
            </div>
            <hr />
            <WideCardQuotationRequest
              loading={loading}
              datacard={datacard}
              payload={payload}
              handleFilter={handleFilter}
              download={download}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default QuotationRequest
