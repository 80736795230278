import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Breadcrumb, MenuAccount, WideCardAddress } from '../../../components/Main'
import AddressForm from './AddressForm'
import { InteractorAccountAddressSearch } from '../../../interactors/Main'
import { useSelector } from 'react-redux'

const AddressIndex = () => {
  const { t } = useTranslation()
  const { account } = useSelector((state) => state.account)
  const { addressSearch } = InteractorAccountAddressSearch()
  const [loading, setLoading] = useState(true)
  const [payload, setPayload] = useState({
    page: 1,
    limit: 5,
    keywords: {
      text_search: '',
      status: '',
      account_uid: account.uid,
    },
  })
  const [datacard, setDatacard] = useState([])
  const [show, setShow] = useState({ value: false, initial: '' })
  const [address, setAddress] = useState({})

  const handleResponse = useCallback((data) => {
    setDatacard(data)
  }, [])

  useEffect(() => {
    if (!datacard.items) {
      addressSearch(payload, handleResponse, setLoading)
    }
  }, [addressSearch, datacard.items, handleResponse, payload])

  const handleShow = (e) => {
    e.preventDefault()
    setShow({
      value: true,
      initial: 'add',
    })
  }

  const handleBack = (e) => {
    e.preventDefault()
    setShow({
      value: false,
      initial: '',
    })
  }

  const handleEdit = (row) => {
    setAddress(row)
    setShow({
      value: true,
      initial: 'edit',
    })
  }

  const handleFilter = (row) => {
    addressSearch(row, handleResponse, setLoading)
    setPayload(row)
  }

  return (
    <>
      <Breadcrumb title={t('menu.my_address')} className="mb-4" />
      <div className="container">
        <div className="row mb-8">
          <MenuAccount />
          <div className="col-xl-9 col-wd-9gdot5">
            <div className="flex-center-between mt-2 mt-lg-0">
              <h4 className="font-size-20-lg font-size-20 font-weight-bold mb-0">
                {t('menu.my_address')}
              </h4>
              {show.value ? (
                <button type="submit" className="btn btn-primary-dark-w px-5" onClick={handleBack}>
                  {t('title.back')}
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-xs font-weight-bold btn-primary-dark-w px-5"
                  onClick={handleShow}
                >
                  {t('button.address_add')}
                </button>
              )}
            </div>

            <hr />
            {show.value ? (
              <AddressForm initial={show.initial} datas={address} />
            ) : (
              <WideCardAddress
                loading={loading}
                datacard={datacard}
                payload={payload}
                handleFilter={handleFilter}
                download={false}
                handleEdit={handleEdit}
                handleBack={handleBack}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default AddressIndex
