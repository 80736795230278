import React from 'react'
import FooterNewsletter from './FooterNewsletter'
import FooterBottom from './FooterBottom'
import FooterCopyright from './FooterCopyright'

const Footer = () => {
  return (
    <>
      <footer>
        <FooterNewsletter />
        <FooterBottom />
        <FooterCopyright />
      </footer>
    </>
  )
}

export default Footer
