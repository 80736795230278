import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'

const InteractorNotificationUpdate = () => {
  const notificationUpdate = useCallback((payload) => {
    ApiService.jsonRequest('/communication-notification/update', payload, () => {})
  }, [])
  return { notificationUpdate }
}

export default InteractorNotificationUpdate
