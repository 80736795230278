import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'
import {
  ProductListTile,
  ProductListGrid,
  FilterMain,
  Pagination,
  PaginationNavigate,
  SidebarFilterProduct,
  ProductSkeletonGrid,
  ProductSkeletonTile,
} from '../Main'
import { ProductItemSearch } from '../../redux/product/search/actionCreator'
import { InteractorFilterMain, InteractorProductItemSearch } from '../../interactors/Main'

const ProductSearch = () => {
  const { t } = useTranslation()
  const { uid } = useParams()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const { payload } = useSelector((state) => state.productItemSearch)
  const [currentLayout, setCurrentLayout] = useState('GRID')
  const [isOpenSide, setIsOpenSide] = useState(false)
  const sidebarRef = useRef()

  const { productSearch } = InteractorProductItemSearch()
  const { filterCategoryProduct } = InteractorFilterMain()

  const onChangeLayout = (val) => setCurrentLayout(val)
  const handleFilter = (val) => setIsOpenSide(val)

  const handleSortByFilter = (e) => {
    e.preventDefault()
    let objPayload = { ...payload }
    objPayload.page = 1
    objPayload.keywords.sort_by = e.currentTarget.value
    // GET DATA
    setLoading(true)
    productSearch(payload, setData, setLoading)
  }
  const handlePageChange = (page) => {
    let objPayload = { ...payload }
    objPayload.page = page
    // GET DATA
    setLoading(true)
    productSearch(objPayload, setData, setLoading)
  }

  useEffect(() => {
    if (uid) {
      const filterCategory = filterCategoryProduct(uid)
      dispatch(ProductItemSearch(filterCategory))
    }

    // GET DATA
    productSearch(payload, setData, setLoading)

    const handleFilterShow = (e) => {
      if (sidebarRef && !sidebarRef.current.contains(e.target)) {
        setIsOpenSide(false)
      }
    }
    document.addEventListener('mousedown', handleFilterShow)
    return () => document.removeEventListener('mousedown', handleFilterShow)
  }, [dispatch, filterCategoryProduct, payload, productSearch, uid])

  return (
    <>
      <FilterMain menu={isOpenSide} />
      <div className="col-12 col-xl-9 px-2">
        <div className="flex-center-between mb-3">
          <h3 className="font-size-25 mb-0">{t('title.product.title')}</h3>
        </div>
        <div className="bg-gray-1 flex-center-between borders-radius-9 py-1">
          <div className="d-xl-none">
            <SidebarFilterProduct handleFilter={handleFilter} sidebarRef={sidebarRef}>
              <i className="fas fa-sliders-h" />
              <span className="ml-1">{t('title.filter')}</span>
            </SidebarFilterProduct>
          </div>
          <div className="px-3 d-none d-xl-block">
            <ul className="nav nav-tab-shop" id="pills-tab" role="tablist">
              <li className="nav-item">
                <Link
                  className={`nav-link ${currentLayout === 'GRID' ? 'active' : ''}`}
                  id="pills-one-example1-tab"
                  data-toggle="pill"
                  to="#"
                  onClick={() => onChangeLayout('GRID')}
                  role="tab"
                  aria-selected="false"
                >
                  <div className="d-md-flex justify-content-md-center align-items-md-center">
                    <i className="fa fa-th" />
                  </div>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={`nav-link ${currentLayout === 'TILE' ? 'active' : ''}`}
                  data-toggle="pill"
                  to="#"
                  onClick={() => onChangeLayout('TILE')}
                  role="tab"
                  aria-selected="true"
                >
                  <div className="d-md-flex justify-content-md-center align-items-md-center">
                    <i className="fa fa-th-list" />
                  </div>
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <form
              method="get"
              className="d-flex justify-content-center align-items-center font-size-14 font-size-14-sm"
            >
              <span className="mr-2 d-none d-md-inline">{t('title.sort_by')}</span>
              <select
                defaultValue={'latest'}
                className="dropdown-select max-width-200 max-width-200-sm right-dropdown-0 px-2 btn-sm bg-white font-weight-normal py-2 border text-gray-20 bg-lg-down-transparent border-lg-down-0"
                onChange={handleSortByFilter}
              >
                <option value="latest">{t('form.sort_latest')}</option>
                <option value="related">{t('form.sort_related')}</option>
                <option value="best_seller">{t('form.sort_best_seller')}</option>
                <option value="price_low">{t('form.sort_price_low')}</option>
                <option value="price_high">{t('form.sort_price_high')}</option>
              </select>
            </form>
          </div>
          <PaginationNavigate
            containerClassName="px-3 flex-horizontal-center text-gray-20 d-none d-xl-flex"
            pageNumberClassName="min-width-50 mr-1"
            buttonPageClassName="btn btn-sm px-2 py-1 text-gray-30 font-size-15 pointer"
            totalPage={data.pages?.total_pages ?? 0}
            activePage={data.pages?.page ?? 1}
            prevLabel={<i className="fa fa-angle-left"></i>}
            nextLabel={<i className="fa fa-angle-right"></i>}
            onChangePage={handlePageChange}
          />
        </div>
        <div className="container-fluid">
          <div className="tab-content mb-5" id="pills-tabContent">
            <div className="tab-pane fade pt-2 show active">
              {loading ? (
                <ul className="row list-unstyled products-group no-gutters">
                  {currentLayout === 'GRID' ? <ProductSkeletonGrid /> : <ProductSkeletonTile />}
                </ul>
              ) : (
                <>
                  {currentLayout === 'GRID' ? (
                    <ul className="row list-unstyled products-group">
                      <ProductListGrid items={data.items} />
                    </ul>
                  ) : (
                    <ul className="list-unstyled products-group no-gutters">
                      <ProductListTile items={data.items} />
                    </ul>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="pt-3">
            {data.pages?.total_pages > 1 && (
              <Pagination data={data.pages} onChangePage={handlePageChange} />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductSearch
