import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import id from 'date-fns/locale/id'
import 'react-datepicker/dist/react-datepicker.css'
import { t } from 'i18next'

const InputDatePicker = (data) => {
  const [startDate, setStartDate] = useState(data.startDate ?? null)
  let initial = data.initial
  let required = data.required
  let radius = data.radius
  const minDate = data.minDate ?? false
  const maxDate = data.maxDate ?? false
  const changeYear = data.changeYear ?? false
  const [options, setOptions] = useState({})

  const datePick = (value) => {
    setStartDate(value)
    data.onCallback(value)
  }
  useEffect(() => {
    let config = {
      dateFormat: 'dd MMMM yyyy',
      showDateMonthYearPicker: true,
      momentFormat: 'DD MMMM YYYY',
      minDate: minDate,
      maxDate: maxDate,
      dropdownMode: changeYear ? 'select' : false,
      showYearDropdown: changeYear,
    }
    if (initial === 'YY') {
      config = {
        dateFormat: 'yyyy',
        showYearPicker: true,
        momentFormat: 'YYYY',
      }
    }
    if (initial === 'MMYY') {
      config = {
        dateFormat: 'MMMM yyyy',
        showMonthYearPicker: true,
        momentFormat: 'MMMM YYYY',
      }
    }
    setOptions(config)
  }, [changeYear, initial, maxDate, minDate])

  return (
    <>
      <DatePicker
        locale={id}
        selected={startDate}
        onChange={(date) => datePick(date)}
        required={required}
        placeholderText={t('field_input.select_date')}
        className={`form-control ${radius}`}
        {...options}
      />
    </>
  )
}

export default InputDatePicker
