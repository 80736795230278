import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'

const InteractorAccountAddressList = () => {
  const addressList = useCallback((payload, callback) => {
    ApiService.jsonRequest('/account-user/address-list', payload, (response) => {
      if (response.status_code === 200) {
        if (response.data) {
          callback(response.data)
        }
      }
    })
  }, [])
  return { addressList }
}

export default InteractorAccountAddressList
