import React from 'react'

const InputText = (data) => {
  const {
    label,
    name,
    inputClassName,
    value,
    type = 'text',
    onChange,
    validator,
    error,
    errorMsg,
    textarea = false,
    visibleText = null,
    onShow,
    placeholder,
    required,
    rows = 1,
    disable = false,
  } = data
  const handleChange = (e) => {
    let valid = true
    if (validator) {
      valid = validator.test(e.target.value)
    }

    if (valid) {
      onChange(e)
    }
  }
  return (
    <>
      {label && (
        <label className="align-items-md-left font-size-12 font-weight-bold form-label">
          {label} {required && <span className="text-danger">*</span>}
        </label>
      )}
      {textarea ? (
        <textarea
          className={`form-control ${
            error ? 'border border-danger text-danger' : ''
          } ${inputClassName}`}
          rows={rows}
          placeholder={placeholder}
          name="address"
          value={value}
          onChange={handleChange}
        ></textarea>
      ) : (
        <>
          <div
            className={`d-flex align-items-center form-control ${
              error ? 'border border-danger text-danger' : ''
            } ${inputClassName ? inputClassName : ''}`}
          >
            <input
              type={type}
              className={`w-100 h-100 border-0 ${disable ? 'bg-white' : ''}`}
              name={name}
              placeholder={placeholder}
              value={value}
              onChange={handleChange}
              disabled={disable}
            />
            {visibleText !== null && (
              <i
                className={`pointer ml-2 ${visibleText ? 'fas fa-eye-slash' : 'fas fa-eye'}`}
                style={{ left: '85%' }}
                aria-hidden="true"
                onClick={onShow}
              ></i>
            )}
          </div>
        </>
      )}
      <span className={error ? ' ml-3 lead text-danger font-size-13 visible' : 'd-none invisible'}>
        {errorMsg ? errorMsg : ''}
      </span>
    </>
  )
}

export default InputText
