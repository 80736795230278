import React from 'react'

const InputCheckbox = (data) => {
  return (
    <>
      <label className="d-inline-block height-20 width-60 position-relative">
        <input
          type="checkbox"
          className="d-none box-checkbox"
          name="agreement"
          onChange={data.onChange}
        />
        <span className="slide rounded-pill w-75"></span>
      </label>
    </>
  )
}

export default InputCheckbox
