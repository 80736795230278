import React from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'

const SliderCompany = () => {
  const { advertisement_banner } = []

  const settings = {
    dots: true,
    dotsClass: 'button__bar',
    arrow: false,
    pauseOnHover: true,
    infinite: true,
    speed: 2000,
    autoplay: true,
    fade: false,
    variableWidth: false,
    slidesToShow: 5,
    slidesToScroll: 1,
  }

  return (
    <>
      <div className="mb-8">
        <div className="py-2 border-top border-bottom">
          <div className="js-slide">
            <Slider {...settings}>
              {advertisement_banner?.map((banner, index) => {
                return (
                  <div className="" key={index}>
                    <Link to="/" className="link-hover__brand">
                      <img
                        className="img-fluid m-auto max-height-50"
                        src="../../assets/img/200X60/img1.png"
                        alt=""
                      />
                    </Link>
                  </div>
                )
              })}
            </Slider>
          </div>
        </div>
      </div>
    </>
  )
}

export default SliderCompany
