import moment from 'moment'
import { currency } from '../constant/global/currency'
import { imgFormat } from '../constant/global/validation'

export const inputNumber = (value) => {
  const pattern = /^[0-9\b]+$/
  if (value !== '' && pattern.test(value)) {
    return value
  }
  return ''
}

export const prettyUrl = (value) => {
  value = value.replace(/[^a-zA-Z ]/g, '')
  return value
    .replace(/ /g, '-')
    .replace(/@/g, '')
    .replace(/\$/g, '')
    .replace(/!/g, '')
    .replace(/#/g, '')
    .toLowerCase()
}

export const categoryUrl = (name, main, major, minor) => {
  const main_uid = main !== null ? '/' + main.uid : ''
  const major_uid = major !== null ? '.' + major.uid : ''
  const minor_uid = minor !== null ? '.' + minor.uid : ''
  const slug = prettyUrl(name) + main_uid + major_uid + minor_uid
  return `/product/category/${slug}`
}

export const numberFormatDestroy = (value) => {
  const parsed = typeof value === 'string' || value instanceof String ? value : value.toString()

  return parsed.replace(/([^0-9,]+)/g, '').replace(',', '.')
}

export const numberFormat = (value, options = { leadingZeroAllowed: false }) => {
  if (value === 0 || value === '0') return '0'
  if (!value) return ''
  let parsed = typeof value === 'string' || value instanceof String ? value : value.toString()
  if (!options.leadingZeroAllowed) parsed = parsed.replace(/^[0,]+/g, '')
  let number_string = parsed.replace(/[^,\d]/g, '')
  let split = number_string.split(','),
    sisa = split[0].length % 3,
    rupiah = split[0].substring(0, sisa),
    ribuan = split[0].substring(sisa).match(/\d{3}/gi)

  if (ribuan) {
    let separator = sisa ? '.' : ''
    rupiah += separator + ribuan.join('.')
  }

  rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah
  return rupiah
}

export const zeroDecimal = (originalValue, amountZeroDecimal) => {
  const value = (+originalValue).toFixed(amountZeroDecimal).replace(/(\.0+|0+)$/, '')
  return value
}

export const numberFormatAPI = (
  value,
  options = { leadingZeroAllowed: false, zeroDecimal: false, amountZeroDecimal: 2 },
) => {
  if (options.zeroDecimal) value = zeroDecimal(value, options.amountZeroDecimal)
  if (value === 0 || value === '0') return '0'
  if (!value) return ''
  let parsed = typeof value === 'string' || value instanceof String ? value : value.toString()
  if (!options.leadingZeroAllowed) parsed = parsed.replace(/^[0,]+/g, '')
  let number_string = parsed.replace(/[^.\d]/g, '')
  let split = number_string.split('.'),
    sisa = split[0].length % 3,
    rupiah = split[0].substring(0, sisa),
    ribuan = split[0].substring(sisa).match(/\d{3}/gi)

  if (ribuan) {
    let separator = sisa ? '.' : ''
    rupiah += separator + ribuan.join('.')
  }

  rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah
  return rupiah
}

export const currencyFormat = (value, currency_type) => {
  return currency[currency_type] ? `${currency[currency_type]} ${numberFormat(value)}` : value
}

export const subStringContent = (str, total) => {
  if (str.length > total - 1) return str.slice(0, total) + '...'
  return str
}

export const toDataUrl = (url, callback) => {
  let xhr = new XMLHttpRequest() //XMLHttpRequest (XHR)
  xhr.onload = function () {
    let reader = new FileReader()
    reader.onloadend = function () {
      callback(reader.result)
    }
    reader.readAsDataURL(xhr.response)
  }
  xhr.open('GET', url)
  xhr.responseType = 'blob'
  xhr.send()
}

export const findByArray = (array, key, value) => {
  return array.find((element) => {
    return element[key] === value
  })
}

export const formatDate = (value, format) => {
  if (value !== undefined) {
    value = moment(value)
    const unix_timestamp = Date.parse(new Date(value).toString())
    return moment(unix_timestamp).format(format)
  }
}

export const capitalizeFirstWord = (str) => {
  const words = str.toLowerCase().split(' ')
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1)
  }
  return words.join(' ')
}

function removeArray(array) {
  var what,
    a = arguments,
    L = a.length,
    ax
  while (L > 1 && array.length) {
    what = a[--L]
    while ((ax = array.indexOf(what)) !== -1) {
      array.splice(ax, 1)
    }
  }
  return array
}

export const removeArrayString = (array, value) => {
  return removeArray(array, value)
}

export const splitString = (str, maxLength) => {
  let words = str.split(' ')
  for (let j = 0; j < words.length; j++) {
    let k = words[j].length
    if (k > maxLength) {
      let temp = []
      for (let i = 0; i < k; i++) {
        temp.push(words[j].substr(i, maxLength))
        i += maxLength
      }
      words[j] = temp.join(' ')
    }
  }
  return words.join(' ')
}

export const GroupByCreatedDate = (array) => {
  const grouped = array.reduce((m, { devider: label, ...rest }) => {
    const group = m.get(label) || { label, options: [] }
    group.options.push({ ...rest })
    return m.set(label, group)
  }, new Map())
  return [...grouped.values()]
}

export const removeLastWord = (str, minusRange) => {
  return str.split(' ').slice(0, minusRange).join(' ')
}

const getMimeType = (type) => {
  return type.split('/')[0]
}

export const checkValidationFile = (type, size) => {
  let file_type = getMimeType(type)
  let status = true
  switch (file_type) {
    case 'image':
      if (size > 1 * 1024 * 1024 || imgFormat.indexOf(type) === -1) {
        status = false
      }
      break
    default:
      status = true
  }

  return status
}

export const handleLink = (callback, target) => {
  callback(target)
}

export const formatCash = (num) => {
  if (num < 1e3) return num
  if (num >= 1e3 && num < 1e6) return +(num / 1e3).toFixed(1) + 'RB'
  if (num >= 1e6 && num < 1e9) return +(num / 1e6).toFixed(1) + 'JT'
  if (num >= 1e9 && num < 1e12) return +(num / 1e9).toFixed(1) + 'M'
  if (num >= 1e12) return +(num / 1e12).toFixed(1) + 'T'
}

export const downloadFile = (blob, fileName) => {
  const link = document.createElement('a')
  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(blob)
  link.download = fileName
  // some browser needs the anchor to be in the doc
  document.body.append(link)
  link.click()
  link.remove()
  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 7000)
}

export const FindFileType = (value) => {
  return value.substring(value?.lastIndexOf('.') + 1)
}
