import Compressor from 'compressorjs'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import ktpDefault from '../../../assets/image/ktp/ktp-default.png'
import profileDefault from '../../../assets/image/profile/profile.png'
import {
  Breadcrumb,
  InputDatePicker,
  InputText,
  Loading,
  MenuAccount,
} from '../../../components/Main'
import Validation, {
  fullNameMaxLength,
  fullNameMinLength,
  numberRegex,
  phoneNumberMaxLength,
  phoneNumberMinLength,
} from '../../../constant/global/validation'
import { InteractorUpdateImage, InteractorUpdateProfile } from '../../../interactors/Main'
import { checkValidationFile, formatDate } from '../../../utility/Helpers'
import { showAlert } from '../../../utility/SweetAlert'

const ProfileIndex = () => {
  const { uploadImage } = InteractorUpdateImage()
  const { updateProfile } = InteractorUpdateProfile()
  const { validationInputNumber, validationInputText } = Validation()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()
  const { account } = useSelector((state) => state.account)
  const [profile, setProfile] = useState({
    account_type: '',
    date_birth: '',
    uid: '',
    full_name: '',
    email_address: '',
    gender: '',
    updated_by: '',
    phone_number: '',
    profile_picture: '',
    national_identity_verified: '',
    national_identity_file: '',
    source: '',
  })

  const [error, setError] = useState({
    full_name: null,
    phone_number: null,
    email_address: null,
    profile_picture: null,
    national_identity_file: null,
  })
  const fetchProfile = useMemo(
    () =>
      setProfile({
        account_type: account.account_type,
        date_birth: new Date(account.date_birth),
        uid: account.uid,
        full_name: account.full_name ?? '',
        email_address: account.email_address ?? '',
        gender: account.gender,
        updated_by: account.full_name,
        phone_number: account.phone_number ?? '',
        profile_picture: account.profile_picture ?? '',
        national_identity_verified: account.national_identity_verified,
        national_identity_file: account.national_identity_file ?? '',
        source: account.source,
      }),
    [
      account.account_type,
      account.date_birth,
      account.email_address,
      account.full_name,
      account.gender,
      account.national_identity_file,
      account.national_identity_verified,
      account.phone_number,
      account.profile_picture,
      account.source,
      account.uid,
    ],
  )

  const onCheckValidation = (name, value) => {
    if (name === 'phone_number') {
      let msg = validationInputNumber(
        value,
        phoneNumberMinLength,
        phoneNumberMaxLength,
        t('form.phone_number'),
      )
      setError({
        ...error,
        [name]: msg,
      })
    }

    if (name === 'full_name') {
      let msg = validationInputText(
        value,
        fullNameMinLength,
        fullNameMaxLength,
        t('form.full_name'),
      )
      setError({
        ...error,
        [name]: msg,
      })
    }
  }

  const inputOnhandler = (e) => {
    let { name, value } = e.target

    if (name !== 'email_address') {
      onCheckValidation(name, value)

      setProfile({
        ...profile,
        [name]: value,
      })
    }
  }

  const datePickHandler = (value) => {
    setProfile({
      ...profile,
      date_birth: value,
    })
  }
  const handleUploadPicture = (event) => {
    const { files, name } = event.target
    let image = files[0]
    let isValidImage = checkValidationFile(image.type, image.size)

    if (!isValidImage) {
      setError((prev) => ({ ...prev, [name]: t('message.invalid_picture') }))
      setProfile({
        ...profile,
        profile_picture: null,
      })
      return
    }
    new Compressor(image, {
      quality: 0.8,
      width: 300,
      height: 300,
      convertSize: 200000,
      success: async (compressedResult) => {
        const payload = {
          type: 'PUBLIC',
          category: 'PROFILE',
          file: new File(
            [compressedResult],
            `${Date.now().toString()}.${compressedResult.name.split('.').pop()}`,
            {
              type: compressedResult.name.split('.').pop(),
            },
          ),
          reference_uid: account.uid,
          updated_by: account.full_name,
        }
        uploadImage(payload, (response) => {
          setProfile({
            ...profile,
            profile_picture: response,
          })
        })
      },
    })
    setError((prev) => ({ ...prev, [name]: null }))
  }
  const handleUploadKtp = (event) => {
    const { files, name } = event.target
    let image = files[0]
    let isValidImage = checkValidationFile(image.type, image.size)

    if (!isValidImage) {
      setError((prev) => ({ ...prev, [name]: t('message.invalid_picture') }))
      setProfile({
        ...profile,
        national_identity_file: null,
      })
      return
    }
    new Compressor(image, {
      quality: 0.8,
      convertSize: 200000,
      success: async (compressedResult) => {
        const payload = {
          type: 'PRIVATE',
          category: 'KTP',
          file: new File(
            [compressedResult],
            `${Date.now().toString()}.${compressedResult.name.split('.').pop()}`,
            {
              type: compressedResult.name.split('.').pop(),
            },
          ),
          reference_uid: account.uid,
          updated_by: account.full_name,
        }
        uploadImage(payload, (response) => {
          setProfile({
            ...profile,
            national_identity_verified: 'REQUEST',
            national_identity_file: response,
          })
        })
      },
    })
    setError((prev) => ({ ...prev, [name]: null }))
  }

  const onGenderHandler = (e) => {
    let { name, value } = e.target
    setProfile({
      ...profile,
      [name]: value,
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()

    if (
      error.full_name ||
      error.phone_number ||
      error.profile_picture ||
      error.national_identity_file ||
      error.email_address
    ) {
      showAlert({
        icon: 'error',
        message: t('message.invalid_profile_submit'),
        reload: false,
        timer: 3000,
      })
    } else {
      setLoading(true)
      updateProfile(
        (response) => {
          setProfile({
            date_birth: new Date(response.date_birth),
            uid: response.uid,
            full_name: response.full_name,
            email_address: response.email_address,
            gender: response.gender,
            updated_by: response.updated_by,
            phone_number: response.phone_number,
            profile_picture: response.profile_picture,
            national_identity_file: response.national_identity_file,
            national_identity_verified: response.national_identity_verified,
            source: response.source,
          })
        },
        {
          ...profile,
          date_birth: formatDate(profile.date_birth, 'YYYY-MM-DD'),
        },
        setLoading,
      )
    }
  }

  return (
    <>
      {fetchProfile}
      <Breadcrumb title={t('menu.my_profile')} className="mb-4" />
      <div className="container">
        <div className="row mb-8">
          <MenuAccount />
          {loading ? (
            <div className="col-xl-9 col-wd-9gdot5">
              <div className="d-flex justify-content-center align-items-center h-100 d-inline-block">
                <Loading />
              </div>
            </div>
          ) : (
            <div className="col-xl-9 col-wd-9gdot5">
              <div className="flex-center-between">
                <h3 className="font-size-25 mb-0">{t('menu.my_profile')}</h3>
              </div>
              <hr />
              {/* Content */}
              <div className="col-lg-12">
                <div className="card-body">
                  <div className="row mb-3 form-group">
                    <div className="col-sm-2">
                      <label className="align-items-md-left font-size-12 font-weight-bold col-form-label">
                        {t('form.image_profile')}
                      </label>
                    </div>
                    <div className="col-sm-9">
                      <div className="container card-profile">
                        <div
                          className={`form-picture w160 ${
                            error.profile_picture ? 'border border-danger' : ''
                          }`}
                        >
                          <img
                            className="form-picture-image"
                            src={profile.profile_picture ? profile.profile_picture : profileDefault}
                            width="160"
                            height="160"
                            alt=""
                          />
                          <div className="form-picture-content">
                            <span className="form-picture__icon">
                              <i className="fas fa-camera"></i>
                            </span>
                            <span className="form-picture__text">
                              {t('placeholder.upload_image')}
                            </span>
                            <input
                              type="file"
                              className="input-file"
                              onChange={handleUploadPicture}
                              name="profile_picture"
                              accept="image/*"
                            />
                          </div>
                        </div>

                        <div className="content-profile mt-0 mt-md-4 ml-3">
                          {error.profile_picture && (
                            <div className="text-danger">{error.profile_picture}</div>
                          )}
                          <div>
                            <span className="font-size-12">
                              {t('form.image_size')}
                              <br />
                              {t('form.image_type')}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3 form-group">
                    <div className="col-sm-2">
                      <label className="align-items-md-left font-size-12 font-weight-bold col-form-label">
                        {t('form.image_ktp')}
                      </label>
                    </div>
                    <div className="col-sm-9">
                      <div className="container card-profile">
                        {profile.national_identity_verified !== 'VERIFIED' ? (
                          <>
                            <div
                              className={`form-picture w240 border ${
                                error.national_identity_file ? 'border-danger' : ''
                              }`}
                            >
                              <img
                                className="form-picture-image width-240 width-240-sm"
                                src={
                                  profile?.national_identity_file
                                    ? profile.national_identity_file
                                    : ktpDefault
                                }
                                // width="180"
                                alt=""
                              />
                              <div className="form-picture-content">
                                <span className="form-picture__icon">
                                  <i className="fas fa-camera"></i>
                                </span>
                                <span className="form-picture__text">
                                  {t('placeholder.upload_image')}
                                </span>
                                <input
                                  type="file"
                                  className="input-file"
                                  onChange={handleUploadKtp}
                                  name="national_identity_file"
                                  accept="image/*"
                                />
                              </div>
                            </div>
                            <div className="content-profile ml-3 mt-0 mt-md-4">
                              {error.national_identity_file && (
                                <div className="text-danger">{error.national_identity_file}</div>
                              )}
                              <div>
                                <span className="font-size-12">
                                  {t('form.image_size')}
                                  <br />
                                  {t('form.image_type')}
                                </span>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="mt-2 font-weight-bold text-dark">
                            <i className="fa fa-check-circle text-green mr-1" />
                            {t('title.ktp_has_been_verified')}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row form-group">
                    <div className="col-sm-2">
                      <label className="align-items-md-left font-size-12 font-weight-bold col-form-label">
                        {t('form.full_name')}
                      </label>
                    </div>
                    <div className="col-sm-9">
                      <InputText
                        placeholder={t('form.full_name')}
                        name="full_name"
                        onChange={inputOnhandler}
                        value={profile.full_name}
                        error={error.full_name}
                        errorMsg={error.full_name}
                      />
                    </div>
                  </div>
                  <div className="row mb-3 form-group">
                    <div className="col-sm-2">
                      <label className="align-items-md-left font-size-12 font-weight-bold col-form-label">
                        {t('form.email_address')}
                      </label>
                    </div>
                    <div className="col-sm-9">
                      <InputText
                        placeholder={t('form.email_address')}
                        name="email_address"
                        onChange={inputOnhandler}
                        value={profile.email_address}
                        error={error.email_address}
                        errorMsg={error.email_address}
                        disable={true}
                      />
                    </div>
                  </div>
                  <div className="row mb-3 form-group">
                    <div className="col-sm-2">
                      <label className="align-items-md-left font-size-12 font-weight-bold col-form-label">
                        {t('title.phone_number')}
                      </label>
                    </div>
                    <div className="col-sm-9">
                      <InputText
                        placeholder={t('title.phone_number')}
                        name="phone_number"
                        onChange={inputOnhandler}
                        value={profile.phone_number}
                        error={error.phone_number}
                        errorMsg={error.phone_number}
                        validator={numberRegex}
                      />
                    </div>
                  </div>
                  <div className="row mb-3 form-group">
                    <div className="col-sm-2">
                      <label className="align-items-md-left font-size-12 font-weight-bold col-form-label">
                        {t('form.gender')}
                      </label>
                    </div>
                    <div className="col-sm-9">
                      <div className="d-flex pl-4 col-form-label .col-custom2">
                        <div className="col-sm-1 col-custom1 d-sm-none"></div>
                        <div className="col-sm-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gender"
                            id="flexRadioDefault1"
                            value="MALE"
                            checked={profile.gender === 'MALE'}
                            onChange={onGenderHandler}
                          />
                          <label className="form-check-label" htmlFor="flexRadioDefault1">
                            {t('form.male')}
                          </label>
                        </div>
                        <div className="col-sm-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gender"
                            id="flexRadioDefault1"
                            value="FEMALE"
                            checked={profile?.gender === 'FEMALE'}
                            onChange={onGenderHandler}
                          />

                          <label className="form-check-label" htmlFor="flexRadioDefault1">
                            {t('form.female')}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3 form-group">
                    <div className="col-sm-2">
                      <label className="align-items-md-left font-size-12 font-weight-bold col-form-label">
                        {t('form.date_of_birth')}
                      </label>
                    </div>
                    <div className="col-sm-3 ">
                      <InputDatePicker
                        initial="DDMMYY"
                        required={false}
                        changeYear={true}
                        onCallback={datePickHandler}
                        startDate={profile.date_birth}
                      />
                    </div>
                  </div>
                  <div className="row mb-3 form-group">
                    <div className="col-sm-2"></div>
                    <div className="col-sm-3 ">
                      <button
                        type="submit"
                        className="btn btn-primary-dark-w px-5"
                        onClick={onSubmit}
                      >
                        {t('button.save')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Content */}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ProfileIndex
