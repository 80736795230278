import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ShowLoading, HideLoading } from '../../../redux/loading/actionCreator'
import {
  InteractorLocationProvince,
  InteractorLocationCity,
  InteractorLocationDistrict,
  InteractorLocationSubdistrict,
  InteractorLocationPostalcode,
} from '../../Main'
import ApiService from '../../../services/ApiService.js'
import { showAlert } from '../../../utility/SweetAlert'
import { useTranslation } from 'react-i18next'
import Validation from '../../../constant/global/validation'
import {
  addressMaxLength,
  addressMinLength,
  fullNameMaxLength,
  fullNameMinLength,
  phoneNumberMaxLength,
  phoneNumberMinLength,
  titleMaxLength,
  titleMinLength,
} from '../../../constant/global/validation'

const InteractorAccountAddressForm = (initial, datas) => {
  const dispatch = useDispatch()
  const { account } = useSelector((state) => state.account)
  const { t } = useTranslation()
  const { validationInputText, validationInputNumber } = Validation()
  const { listProvince } = InteractorLocationProvince()
  const { listCity } = InteractorLocationCity()
  const { listDistrict } = InteractorLocationDistrict()
  const { listSubdistrict } = InteractorLocationSubdistrict()
  const { listPostalcode } = InteractorLocationPostalcode()
  const [errorSubmit, setErrorSubmit] = useState(false)
  const [payloadLocation, setPayloadLocation] = useState({
    account_uid: account.uid,
    title: '',
    consignee_full_name: '',
    consignee_phone_number: '',
    country_uid: process.env.REACT_APP_COUNTRY_UID,
    country_name: 'Indonesia',
    province_uid: '',
    province_name: '',
    city_uid: '',
    city_name: '',
    district_uid: '',
    district_name: '',
    subdistrict_uid: '',
    subdistrict_name: '',
    postal_code_uid: '',
    postal_code: '',
    address: '',
    address_detail: '',
    address_primary: 'N',
    uid: '',
    created_by: account.full_name,
  })

  const [error, setError] = useState({
    title: null,
    consignee_full_name: null,
    consignee_phone_number: null,
    province_uid: null,
    province_name: null,
    city_uid: null,
    district_uid: null,
    subdistrict_uid: null,
    postal_code_uid: null,
    address: null,
  })
  const [optionProvince, setOptionProvince] = useState([])
  const [optionCity, setOptionCity] = useState([])
  const [optionDistrict, setOptionDistrict] = useState([])
  const [optionSubdistrict, setOptionSubdistrict] = useState([])
  const [optionPostalCode, setOptionPostalCode] = useState([])
  const updateData = useCallback(
    (datas) => {
      setPayloadLocation({
        account_uid: account.uid,
        title: datas.title,
        consignee_full_name: datas.consignee_full_name,
        consignee_phone_number: datas.consignee_phone_number,
        country_uid: datas.country_uid,
        province_uid: datas.province_uid,
        city_uid: datas.city_uid,
        district_uid: datas.district_uid,
        subdistrict_uid: datas.subdistrict_uid,
        postal_code_uid: datas.postal_code_uid,
        postal_code: datas.postal_code_uid,
        address: datas.address,
        address_detail: datas.address_detail,
        address_primary: datas.address_primary,
        uid: datas.uid,
        created_by: account.full_name,
      })
      listCity(setOptionCity, datas.province_uid)
      listDistrict(setOptionDistrict, datas.city_uid)
      listSubdistrict(setOptionSubdistrict, datas.district_uid)
      listPostalcode(setOptionPostalCode, datas.subdistrict_uid)
    },
    [account.full_name, account.uid, listCity, listDistrict, listPostalcode, listSubdistrict],
  )
  useEffect(() => {
    dispatch(ShowLoading())
    if (optionProvince.length <= 0) {
      listProvince(setOptionProvince)
    }
    if (initial === 'edit') {
      updateData(datas)
    }
    dispatch(HideLoading())
  }, [
    initial,
    updateData,
    datas,
    optionProvince,
    listProvince,
    listCity,
    listDistrict,
    listSubdistrict,
    listPostalcode,
    datas.title,
    datas.province_uid,
    datas.city_uid,
    datas.subdistrict_uid,
    dispatch,
  ])

  const handlerInput = (e) => {
    const { name, value } = e.target

    if (name === 'title') {
      let message = validationInputText(
        value,
        titleMinLength,
        titleMaxLength,
        t('form.address_title'),
      )
      setError((prev) => ({ ...prev, [name]: message }))
    }

    if (name === 'consignee_full_name') {
      let message = validationInputText(
        value,
        fullNameMinLength,
        fullNameMaxLength,
        t('form.consignee_full_name'),
      )
      setError((prev) => ({ ...prev, [name]: message }))
    }

    if (name === 'consignee_phone_number') {
      let message = validationInputNumber(
        value,
        phoneNumberMinLength,
        phoneNumberMaxLength,
        t('form.consignee_phone_number'),
      )
      setError((prev) => ({ ...prev, [name]: message }))
    }

    if (name === 'address') {
      let message = validationInputText(
        value,
        addressMinLength,
        addressMaxLength,
        t('form.address'),
      )
      setError((prev) => ({ ...prev, [name]: message }))
    }

    setPayloadLocation({
      ...payloadLocation,
      [name]: value,
    })
  }

  const handlerProvince = (e) => {
    const { name, value } = e.target
    if (value.length === 0) {
      setError({
        ...error,
        [name]: value,
      })
      return
    }
    const row = optionProvince.find((find) => find.uid === value)
    setPayloadLocation({
      ...payloadLocation,
      [name]: value,
      province_name: row.province_name,
      city_uid: '',
      district_uid: '',
      subdistrict_uid: '',
      postal_code_uid: '',
    })
    setError((prev) => ({ ...prev, [name]: null }))
    listCity(setOptionCity, value)
    setOptionDistrict(null)
    setOptionSubdistrict(null)
    setOptionPostalCode(null)
  }

  const handlerCity = (e) => {
    const { name, value } = e.target
    if (value.length === 0) {
      setError({
        ...error,
        [name]: value,
      })
      return
    }
    const row = optionCity.find((find) => find.uid === value)
    setPayloadLocation({
      ...payloadLocation,
      [name]: value,
      city_name: row.city_name,
      district_uid: '',
      subdistrict_uid: '',
      postal_code_uid: '',
    })
    setError((prev) => ({ ...prev, [name]: null }))
    listDistrict(setOptionDistrict, value)
    setOptionSubdistrict(null)
    setOptionPostalCode(null)
  }

  const handlerDistrict = (e) => {
    const { name, value } = e.target
    if (value.length === 0) {
      setError({
        ...error,
        [name]: value,
      })
      return
    }
    const row = optionDistrict.find((find) => find.uid === value)
    setPayloadLocation({
      ...payloadLocation,
      [name]: value,
      district_name: row.district_name,
      subdistrict_uid: '',
      postal_code_uid: '',
    })
    setError((prev) => ({ ...prev, [name]: null }))
    listSubdistrict(setOptionSubdistrict, value)
    setOptionPostalCode(null)
  }

  const handlerSubdistrict = (e) => {
    const { name, value } = e.target
    if (value.length === 0) {
      setError({
        ...error,
        [name]: value,
      })
      return
    }
    const row = optionSubdistrict.find((find) => find.uid === value)
    setPayloadLocation({
      ...payloadLocation,
      [name]: value,
      subdistrict_name: row.subdistrict_name,
      postal_code_uid: '',
    })
    setError((prev) => ({ ...prev, [name]: null }))
    listPostalcode(setOptionPostalCode, value)
  }

  const handlerPostalcode = (e) => {
    const { name, value } = e.target
    if (value.length === 0) {
      setError({
        ...error,
        [name]: value,
      })
      return
    }
    const row = optionPostalCode.find((find) => find.uid === value)
    setPayloadLocation({
      ...payloadLocation,
      [name]: value,
      postal_code: row.postal_code,
    })
    setError((prev) => ({ ...prev, [name]: null }))
  }
  const handleError = (name, message) => {
    setError((prev) => ({ ...prev, [name]: message }))
  }
  const beforeSubmitValidation = useCallback(() => {
    payloadLocation.title.length === 0 &&
      handleError('title', `${t('form.address_title')} ${t('message.not_empty')}`)

    payloadLocation.address.length === 0 &&
      handleError('address', `${t('form.address')} ${t('message.not_empty')}`)

    payloadLocation.consignee_full_name.length === 0 &&
      handleError(
        'consignee_full_name',
        `${t('form.consignee_full_name')} ${t('message.not_empty')}`,
      )

    payloadLocation.consignee_phone_number.length === 0 &&
      handleError(
        'consignee_phone_number',
        `${t('form.consignee_phone_number')} ${t('message.not_empty')}`,
      )

    payloadLocation.province_uid.length === 0 &&
      handleError('province_uid', `${t('form.province')} ${t('message.not_empty')}`)
    payloadLocation.city_uid.length === 0 &&
      handleError('city_uid', `${t('form.city')} ${t('message.not_empty')}`)
    payloadLocation.district_uid.length === 0 &&
      handleError('district_uid', `${t('form.district')} ${t('message.not_empty')}`)
    payloadLocation.subdistrict_uid.length === 0 &&
      handleError('subdistrict_uid', `${t('form.sub_district')} ${t('message.not_empty')}`)
    payloadLocation.postal_code_uid.length === 0 &&
      handleError('postal_code_uid', `${t('form.postal_code')} ${t('message.not_empty')}`)
  }, [
    payloadLocation.address.length,
    payloadLocation.city_uid.length,
    payloadLocation.consignee_full_name.length,
    payloadLocation.consignee_phone_number.length,
    payloadLocation.district_uid.length,
    payloadLocation.postal_code_uid.length,
    payloadLocation.province_uid.length,
    payloadLocation.subdistrict_uid.length,
    payloadLocation.title.length,
    t,
  ])
  const onSubmit = useCallback(
    (modalCallback) => {
      beforeSubmitValidation()
      if (
        error.title ||
        error.address ||
        error.consignee_full_name ||
        error.consignee_phone_number ||
        error.province_uid ||
        error.city_uid ||
        error.district_uid ||
        error.subdistrict_uid ||
        error.postal_code_uid
      ) {
        setErrorSubmit(true)
        showAlert({
          icon: 'error',
          message: t('message.disallow_submit_address'),
          reload: false,
          timer: 3000,
        })
        return false
      }

      const {
        title,
        address,
        subdistrict_name,
        district_name,
        city_name,
        province_name,
        postal_code,
      } = payloadLocation
      setErrorSubmit(false)

      if (
        !payloadLocation.subdistrict_uid ||
        !payloadLocation.district_uid ||
        !payloadLocation.city_uid ||
        !payloadLocation.province_uid ||
        !payloadLocation.postal_code_uid
      ) {
        setErrorSubmit(true)
        showAlert({
          icon: 'error',
          message: t('message.disallow_submit_address'),
          reload: false,
          timer: 3000,
        })
        return false
      }

      let province = '',
        city = '',
        district = '',
        subdistrict = '',
        postalcode = '',
        address_detail = ''

      if (!province_name) {
        let find = optionProvince.find((find) => find.uid === datas.province_uid)
        province = find.province_name
      } else province = province_name

      if (!city_name) {
        let find = optionCity.find((find) => find.uid === datas.city_uid)
        city = find.city_name
      } else city = city_name

      if (!district_name) {
        let find = optionDistrict.find((find) => find.uid === datas.district_uid)
        district = find.district_name
      } else district = district_name

      if (!subdistrict_name) {
        let find = optionSubdistrict.find((find) => find.uid === datas.subdistrict_uid)
        subdistrict = find.subdistrict_name
      } else subdistrict = subdistrict_name

      if (!postal_code) {
        let find = optionPostalCode.find((find) => find.uid === datas.postal_code_uid)
        postalcode = find.postal_code
      }
      postalcode = postal_code
      address_detail = `${address.trimEnd()}, ${subdistrict}, ${district}, ${city}, ${province}, ${postalcode}`

      let url = ''
      let alertMsg = ''
      let payload = {
        ...payloadLocation,
        title: title.trimEnd(),
        address: address.trimEnd(),
        address_detail: address_detail,
      }

      if (initial === 'edit') {
        alertMsg = t('alert.address_edit_successful')
        url = '/account-user/address-update'
        payload = {
          ...payload,
          uid: datas.uid,
          updated_by: account.uid,
        }
      } else {
        alertMsg = t('alert.address_add_successful')
        url = '/account-user/address-create'
      }
      ApiService.jsonRequest(url, payload, (response) => {
        if (response.status_code === 200) {
          showAlert({
            icon: 'success',
            message: alertMsg,
            timer: 2000,
            reload: initial === 'cart' ? false : true,
          })
          if (modalCallback) modalCallback()
        } else {
          showAlert({
            icon: 'warning',
            message: t(`response_message.${response.messages}`),
            timer: 2000,
            reload: false,
          })
        }
      })
      return true
    },
    [
      account.uid,
      beforeSubmitValidation,
      datas.city_uid,
      datas.district_uid,
      datas.postal_code_uid,
      datas.province_uid,
      datas.subdistrict_uid,
      datas.uid,
      error.address,
      error.city_uid,
      error.consignee_full_name,
      error.consignee_phone_number,
      error.district_uid,
      error.postal_code_uid,
      error.province_uid,
      error.subdistrict_uid,
      error.title,
      initial,
      optionCity,
      optionDistrict,
      optionPostalCode,
      optionProvince,
      optionSubdistrict,
      payloadLocation,
      t,
    ],
  )

  return {
    errorSubmit,
    payloadLocation,
    optionProvince,
    optionCity,
    optionDistrict,
    optionSubdistrict,
    optionPostalCode,
    handlerInput,
    handlerProvince,
    handlerCity,
    handlerDistrict,
    handlerSubdistrict,
    handlerPostalcode,
    onSubmit,
    error,
  }
}

export default InteractorAccountAddressForm
