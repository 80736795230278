import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Loading, WideCardFilter, Pagination } from '../Main'
import { Link } from 'react-router-dom'
import { currencyFormat, formatDate } from '../../utility/Helpers'
import { InteractorPaymentCreate } from '../../interactors/Main'
import { useSelector } from 'react-redux'

const WideCardPaymentHistory = (data) => {
  const { loading, datacard, payload, download } = data
  const { t } = useTranslation()
  const { account } = useSelector((state) => state.account)
  const { paymentCreate } = InteractorPaymentCreate()
  const [payloadFilter, setPayloadFilter] = useState(payload)
  const [loadingRequest, setLoadingRequest] = useState(false)
  const filter = {
    text_search: true,
    status: [
      { value: '', label: t(`title.select_status`) },
      { value: 'COMPLETED', label: t(`invoice.status.COMPLETED`) },
      { value: 'PENDING', label: t(`invoice.status.PENDING`) },
      { value: 'FAILED', label: t(`invoice.status.FAILED`) },
      { value: 'EXPIRED', label: t(`invoice.status.EXPIRED`) },
    ],
    download: download,
  }
  const handleFilter = (row) => {
    let objPayload = { ...payloadFilter }
    objPayload.page = 1
    objPayload.keywords.text_search = row.keywords.text_search
    objPayload.keywords.status = row.keywords.status
    data.handleFilter(objPayload)
    setPayloadFilter(objPayload)
  }
  const handlePageChange = (page) => {
    let objPayload = { ...payloadFilter }
    objPayload.page = page
    data.handleFilter(objPayload)
    setPayloadFilter(objPayload)
  }
  const handlePayment = (row) => {
    const payload_payment = {
      reference_category: row.reference_category,
      reference_uid: row.reference_uid,
      account_uid: account.uid,
      company_uid: null,
      payment_title: row.payment_title,
      payment_method: row.payment_method,
      payment_amount: row.payment_amount,
      payment_amount_currency: row.payment_amount_currency,
      payment_attachment_media_type: null,
      payment_attachment: null,
      payment_gateway_company: row.payment_gateway_company,
      description: null,
      invoice_duration: 86400,
      invoice_success_url: process.env.REACT_APP_DOMAIN_MARKETPLACE + '/payment/status/success',
      invoice_failure_url: process.env.REACT_APP_DOMAIN_MARKETPLACE + '/payment/status/failure',
      invoice_customer: {
        given_names: account.full_name,
        email: account.email_address,
        mobile_number: account.phone_number ?? '02180623840',
      },
      created_by: account.full_name,
    }
    setLoadingRequest(true)
    paymentCreate(payload_payment)
  }
  // CARD AREA
  const CardHeader = (data) => {
    const row = data.data
    return (
      <>
        <div className="row">
          <div className="col-8 mt-2">
            <span className="d-inline-block p-2">
              <small>{t('invoice.invoice_number')}</small> <br />
              <strong>{row.payment_code}</strong>
            </span>
          </div>
          <div className="col-4 mt-1 d-flex justify-content-end">
            <span className="d-inline-block py-4">
              <small>{t('title.status')}: </small>
              <span className={`text-gray font-weight-bold text-gray`}>
                {t(`invoice.status.${row.status}`)}
              </span>
            </span>
          </div>
        </div>
        <hr className="m-0" />
      </>
    )
  }
  const CardBody = (data) => {
    const row = data.data
    var expired =
      new Date(formatDate(row.created_date, 'YYYY-MM-DD HH:mm:ss')).getTime() + 1000 * 86400
    return (
      <>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <table className="table table-borderless m-2">
              <tbody>
                <tr>
                  <td className="col-xs-6 p-1">{t('invoice.title')}</td>
                  <td className="p-1">
                    <div className="font-weight-bold">{row.payment_title}</div>
                  </td>
                </tr>
                <tr>
                  <td className="col-xs-6 p-1">{t('invoice.paid_date')}</td>
                  <td className="p-1">
                    <div className="font-weight-bold">
                      {formatDate(row.created_date, 'DD/MM/YYYY HH:mm')}
                    </div>
                  </td>
                </tr>
                {row.status === 'PENDING' && (
                  <tr>
                    <td className="col-xs-6 p-1">{t('invoice.payment_expired')}</td>
                    <td className="p-1">
                      <div className="font-weight-bold">
                        {formatDate(expired, 'DD/MM/YYYY HH:mm')}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="col-xs-12 col-md-6 d-flex text-center justify-content-center justify-content-md-end mb-2">
            <div className="mt-2 mr-0 mr-md-3">
              <span className="font-size-16 font-weight-bold text-grey">
                {t('title.payment_total')}
              </span>
              <br />
              <span className="font-size-20 font-weight-bold text-dark">
                {currencyFormat(row.payment_amount, row.payment_amount_currency)}
              </span>
            </div>
          </div>
        </div>
      </>
    )
  }
  const CardFooter = (data) => {
    const row = data.data
    return (
      <>
        <hr className="m-0" />
        <div className="row">
          <div className="col-12 mt-2 mb-2 d-flex justify-content-end">
            {row.status === 'PENDING' && (
              <Link
                to={'#'}
                onClick={() => {
                  if (!row.payment_invoice_url) {
                    handlePayment(row)
                  } else {
                    window.location = row.payment_invoice_url
                  }
                }}
                className="d-inline btn btn-primary btn-xs m-2 font-size-10"
              >
                <i className="fa fa-credit-card"></i> {t('button.payment_process')}
              </Link>
            )}
            <Link to={'/page/help'} className="d-inline btn btn-red btn-xs m-2 font-size-10">
              <i className="fa fa-question-circle"></i> {t('button.help')}
            </Link>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      {/* FILTER */}
      <WideCardFilter
        filter={filter}
        payload={payload}
        handleFilter={handleFilter}
        label_input={t('placeholder.payment_history_filter')}
      />
      {loading || loadingRequest ? (
        <div className="d-flex justify-content-center">
          <Loading />
        </div>
      ) : (
        <>
          {datacard.items?.length > 0 ? (
            <>
              {datacard.items.map((row, key) => {
                return (
                  <div key={key}>
                    <div className="border border-width-2 border-color-3 borders-radius-6 container wide-card rounded mb-5">
                      <CardHeader data={row} />
                      <div className="row">
                        <div className="col-12">
                          <CardBody data={row} />
                        </div>
                      </div>
                      <CardFooter data={row} />
                    </div>
                  </div>
                )
              })}
              <Pagination data={datacard.pages} onChangePage={handlePageChange} />
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">{t('message.data_not_found')}</div>
            </>
          )}
        </>
      )}
    </>
  )
}

export default WideCardPaymentHistory
