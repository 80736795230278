import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'

const InteractorLocationPostalcode = () => {
  const listPostalcode = useCallback((callback, payload) => {
    payload = {
      subdistrict_uid: payload,
    }
    ApiService.jsonRequest('/master-postalcode/list', payload, (response) => {
      if (response.status_code === 200) {
        callback(response.data)
      }
    })
  }, [])

  return { listPostalcode }
}

export default InteractorLocationPostalcode
