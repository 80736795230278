import Cookies from 'js-cookie'

const getCookies = (key) => {
  return Cookies.get(key)
}

const setCookies = (row) => {
  let config = {
    expires: row.expires,
  }
  if (process.env.REACT_APP_ENV === 'production') {
    config.secure = true
    config.domain = 'adaloka.com'
  }
  Cookies.set(row.key, row.value, config)
}

const removeCookies = (key) => {
  let config = {}
  if (process.env.REACT_APP_ENV === 'production') {
    config.domain = 'adaloka.com'
  }
  Cookies.remove(key, config)
}

export { getCookies, setCookies, removeCookies }
