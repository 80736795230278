import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { currencyFormat, formatDate } from '../../utility/Helpers'

const InteractorCsvPayment = () => {
  const { t } = useTranslation()
  const csvPayment = useCallback(
    (data, callback) => {
      const headers = [
        { label: 'No', key: 'no' },
        { label: t('invoice.invoice_number'), key: 'invoice_number' },
        { label: t('title.status'), key: 'status' },
        { label: t('invoice.title'), key: 'title' },
        { label: t('invoice.paid_date'), key: 'paid_date' },
        { label: t('title.payment_total'), key: 'payment_total' },
      ]

      // MAPING ROW
      let results = []
      data?.forEach((row, idx) => {
        const obj = {
          no: idx + 1,
          invoice_number: row.payment_code,
          status: t(`invoice.status.${row.status}`),
          title: row.payment_title,
          paid_date: formatDate(row.created_date, 'DD/MM/YYYY HH:mm'),
          payment_total: currencyFormat(row.payment_amount, row.payment_amount_currency),
        }
        results.push(obj)
      })
      callback({
        headers: headers,
        data: results,
        filename: 'PAYMENT-' + new Date().getTime() + '.csv',
      })
    },
    [t],
  )
  return { csvPayment }
}

export default InteractorCsvPayment
