import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Slider from 'react-slick'
import {
  currencyFormat,
  subStringContent,
  prettyUrl,
  formatCash,
  numberFormatDestroy,
} from '../../utility/Helpers'
import { Rating } from '../Main'
import ProductSkeletonGrid from '../skeleton/ProductSkeletonGrid'
import { getItem } from '../../utility/LocalStorage'
import { decryptCrypto } from '../../utility/Encryption'
import { Buffer } from 'buffer'
import { useSelector } from 'react-redux'

const ProductRecentlyViewed = () => {
  const { t } = useTranslation()
  const { account } = useSelector((state) => state.account)
  const session_key = Buffer.from('recently-viewed').toString('base64')
  let recently_viewed = useMemo(
    () => (getItem(session_key) ? decryptCrypto(getItem(session_key)) : []),
    [session_key],
  )
  const [loading, setLoading] = useState(true)
  const [lastViewed, setLastViewed] = useState([])

  useEffect(() => {
    if (loading) {
      setLastViewed(recently_viewed)
      setLoading(false)
    }
  }, [loading, recently_viewed])

  const settings = {
    dots: true,
    dotsClass: 'button__bar',
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1204,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          dots: true,
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  }
  return (
    <>
      <div className="mb-2">
        <div className="position-relative">
          <div className="border-bottom border-color-1 mb-2">
            <h3 className="section-title mb-0 pb-2 font-size-22">{t('menu.last_seen')}</h3>
          </div>
          <div className="pt-2 px-1 mb-3">
            {loading ? (
              <ul className="row list-unstyled products-group no-gutters">
                <ProductSkeletonGrid maxBox={6} sizeStyle="col-4 col-md-4 col-lg-2 col-xl-2" />
              </ul>
            ) : (
              <Slider {...settings}>
                {lastViewed?.map((item, index) => {
                  return (
                    <div key={index} className="mb-lg-0 mb-lg-2 products-group">
                      <div className="product-item pb-2 mb-2 pb-md-0 mb-md-0 px-0">
                        <div className="product-item__outer h-100">
                          <div className="product-item__inner__slider px-1 py-2">
                            <Link
                              to={`/product/detail/${
                                prettyUrl(item.product_title) + '/' + item.uid
                              }`}
                            >
                              <div className="product-item__body px-2">
                                <div className="col-12 mb-1 px-0">
                                  <div className="pointer font-size-12 d-none d-lg-block store-title text-gray-5">
                                    {subStringContent(item.store_name, 25)}
                                  </div>
                                  <div className="pointer font-size-10-sm d-block d-lg-none store-title text-gray-5">
                                    {subStringContent(item.store_name, 20)}
                                  </div>
                                </div>
                                <div>
                                  <h5 className="col-12 px-0 mb-3 product-item__title height-33">
                                    <div className="text-blue font-weight-bold pointer">
                                      {subStringContent(item.product_title, 35)}
                                    </div>
                                  </h5>
                                </div>
                                <div className="col-12 px-0 px-sm-2 px-md-2 px-lg-2">
                                  <div className="d-block mb-2 text-center pointer img-product">
                                    <img
                                      className="img-fluid"
                                      src={item.product_thumbnail}
                                      alt=""
                                    />
                                  </div>
                                </div>
                                <div className="row mb-1">
                                  <div className="col-12">
                                    <div className="product-price text-gray-100 font-weight-bold">
                                      {currencyFormat(
                                        item.product_price,
                                        item.product_price_currency,
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <div className="border-top pt-2 mt-2">
                                    <div className="row text-gray-6 pointer justify-content-between mt-1 text-nowrap">
                                      <div className="col-12 col-lg-6 ">
                                        <Rating rating={item.product_rating} />
                                      </div>
                                      <div className="col-12 col-lg-6 text-left text-xl-right pl-3 pl-lg-0">
                                        {formatCash(+numberFormatDestroy(item.product_sold))}{' '}
                                        {t('title.sold')}
                                      </div>
                                    </div>
                                    <div className="row mt-1">
                                      <div className="text-gray-6 flex-nowrap font-size-12 col-12">
                                        {t('title.tax_status')}:
                                        {t(`title.tax_type.${item.store_tax_type}`)}
                                      </div>
                                    </div>
                                    <div className="row mt-1">
                                      <div className="text-gray-6 text-nowrap font-size-12 col-12">
                                        <i className="ec ec-map-pointer"></i>
                                        <span className="d-none d-md-block">
                                          {subStringContent(item.origin_city_name, 28)}
                                        </span>
                                        <span className="d-inline d-md-none">
                                          {subStringContent(item.origin_city_name, 10)}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                            {account?.uid !== item?.store_account_uid && (
                              <div className="product-item__footer__slider">
                                <Link
                                  to={`/chat?product=${item.uid}&seller=${item.store_uid}`}
                                  className="btn btn-primary btn-xs text-nowrap mt-1 font-weight-bold w-100"
                                >
                                  <i className="fa fa-comments mr-2" />
                                  {t('button.chat')}
                                </Link>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </Slider>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductRecentlyViewed
