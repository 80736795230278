import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Breadcrumb, MenuAccount, WideCardOrder } from '../../components/Main'
import { InteractorOrderSearch, InteractorCsvOrder } from '../../interactors/Main'
import { useSelector } from 'react-redux'

const Order = () => {
  const { t } = useTranslation()
  const queryParams = new URLSearchParams(window.location.search)
  const { account } = useSelector((state) => state.account)
  const { orderSearch } = InteractorOrderSearch()
  const { csvOrder } = InteractorCsvOrder()
  const [loading, setLoading] = useState(true)
  const [download, setDownload] = useState({
    headers: null,
    items: null,
    filename: null,
  })
  const [datacard, setDatacard] = useState([])
  const [payload, setPayload] = useState({
    page: 1,
    limit: 5,
    keywords: {
      text_search: queryParams.get('ref') ?? '',
      status: '',
      payment_type: '',
      account_uid: account.uid,
      store_uid: '',
    },
  })

  const handleCsv = useCallback(
    (data) => {
      csvOrder(data.items, setDownload)
    },
    [csvOrder],
  )

  const handleResponse = useCallback(
    (data) => {
      setDatacard(data)

      // CSV
      var payloadCsv = { ...payload }
      payloadCsv.limit = data?.pages.total_items ?? 0
      orderSearch(payloadCsv, handleCsv, () => null)
    },
    [handleCsv, orderSearch, payload],
  )

  useEffect(() => {
    if (loading) {
      orderSearch(payload, handleResponse, setLoading)
    }
  }, [handleResponse, loading, payload, orderSearch])

  const handleFilter = (row) => {
    setPayload(row)
    setDatacard([])
    setLoading(true)
  }

  return (
    <>
      <Breadcrumb title={t('menu.order')} className="mb-4" />
      <div className="container">
        <div className="row mb-8">
          <MenuAccount />
          <div className="col-xl-9 col-wd-9gdot5">
            <div className="flex-center-between mt-2 mt-0-lg">
              <h3 className="font-size-20 font-size-20-lg font-weight-bold mb-0">
                {t('menu.order')}
              </h3>
            </div>
            <hr />
            <WideCardOrder
              loading={loading}
              datacard={datacard}
              payload={payload}
              handleFilter={handleFilter}
              download={download}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Order
