import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import FooterLogo from '../../assets/image/logo/footer-logo.png'
const FooterBottom = () => {
  const { t } = useTranslation()
  return (
    <>
      <div className="pt-8 pb-4 bg-black">
        <div className="container mt-1">
          <div className="row">
            <div className="col-lg-5">
              <div className="mb-6">
                <Link to="/" className="d-inline-block">
                  <img src={FooterLogo} alt="adaloka.com" className="width-300 mt-5" />
                </Link>
              </div>
              <div className="mb-4">
                <div className="row no-gutters">
                  <div className="col-auto">
                    <i className="ec ec-support text-white font-size-56" />
                  </div>
                  <div className="col pl-3 text-white">
                    <div className="font-size-13 font-weight-light">{t('title.need_help')}</div>
                    <span className="font-size-20">(021) 80623840</span>
                  </div>
                </div>
              </div>
              <div className="mb-4 text-white">
                <h6 className="mb-1 font-weight-bold">{t('title.contact_info')}</h6>
                <address className="">
                  <strong>PT. Widea Dagang Nusantara</strong>
                  <br />
                  Jl. Jenderal Sudirman No.Kav 22-23, RT.10/RW.1, Kuningan, Karet, Kecamatan
                  Setiabudi, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12920
                </address>
              </div>
              <div className="my-4 my-md-4">
                <ul className="list-inline mb-0 opacity-7">
                  <li className="list-inline-item mr-0">
                    <Link
                      to="https://www.instagram.com/"
                      className="btn font-size-20 btn-icon btn-soft-dark rounded-circle"
                    >
                      <span className="fab fa-instagram btn-icon__inner text-white" />
                    </Link>
                  </li>
                  <li className="list-inline-item mr-0">
                    <Link
                      to="https://www.facebook.com/"
                      className="btn font-size-20 btn-icon btn-soft-dark rounded-circle"
                    >
                      <span className="fab fa-facebook-f btn-icon__inner text-white" />
                    </Link>
                  </li>
                  <li className="list-inline-item mr-0">
                    <Link
                      to="https://www.twitter.com/"
                      className="btn font-size-20 btn-icon btn-soft-dark rounded-circle"
                    >
                      <span className="fab fa-twitter btn-icon__inner text-white" />
                    </Link>
                  </li>
                  <li className="list-inline-item mr-0">
                    <Link
                      to="https://www.youtube.com/"
                      className="btn font-size-20 btn-icon btn-soft-dark rounded-circle"
                    >
                      <span className="fab fa-youtube btn-icon__inner text-white" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="row">
                <div className="col-12 col-md mb-4 mb-md-0">
                  <h6 className="mb-3 font-weight-bold text-white ">{t('title.buyer')}</h6>
                  <ul className="list-group list-group-flush list-group-borderless mb-0 list-group-transparent">
                    <li>
                      <Link
                        to="/product"
                        className="list-group-item list-group-item-action text-white"
                      >
                        {t('menu.new_product')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/page/how-to-order"
                        className="list-group-item list-group-item-action text-white"
                      >
                        {t('menu.how_to_order')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/page/how-to-pay"
                        className="list-group-item list-group-item-action text-white"
                      >
                        {t('menu.how_to_pay')}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md mb-4 mb-md-0">
                  <h6 className="mb-3 font-weight-bold text-white">{t('title.seller')}</h6>
                  <ul className="list-group list-group-flush list-group-borderless mb-0 list-group-transparent">
                    <li>
                      <Link
                        to="/page/how-do-start-selling "
                        className="list-group-item list-group-item-action text-white"
                      >
                        {t('menu.how_to_be_seller')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        onClick={() => (window.location.href = process.env.REACT_APP_DOMAIN_SELLER)}
                        className="list-group-item list-group-item-action text-white"
                      >
                        {t('menu.join_as_seller')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/page/seller-rules"
                        className="list-group-item list-group-item-action text-white"
                      >
                        {t('menu.seller_rules')}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-12 col-md mb-4 mb-md-0">
                  <h6 className="mb-3 font-weight-bold text-white">{t('title.information')}</h6>
                  <ul className="list-group list-group-flush list-group-borderless mb-0 list-group-transparent">
                    <li>
                      <Link
                        to="/page/about-us"
                        className="list-group-item list-group-item-action text-white"
                      >
                        {t('menu.about_us')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="page/faq"
                        className="list-group-item list-group-item-action text-white"
                      >
                        {t('menu.faq')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="page/terms-and-conditions"
                        className="list-group-item list-group-item-action text-white"
                      >
                        {t('menu.toc')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="page/privacy-policy"
                        className="list-group-item list-group-item-action text-white"
                      >
                        {t('menu.privacy')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/blog"
                        className="list-group-item list-group-item-action text-white"
                      >
                        {t('menu.blog')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FooterBottom
