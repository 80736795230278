import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'

const InteractorProductReviewSearch = () => {
  const reviewSearch = useCallback((payload, callback, loading) => {
    ApiService.jsonRequest('/product-review/search', payload, (response) => {
      loading(false)
      if (response.status_code === 200) {
        if (response.data) {
          callback(response.data)
        }
      }
    })
  }, [])
  return { reviewSearch }
}

export default InteractorProductReviewSearch
