import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { currencyFormat, formatCash, prettyUrl, subStringContent } from '../../utility/Helpers'
import { useTranslation } from 'react-i18next'
import { Rating } from '../Main'
import { useSelector } from 'react-redux'

const ProductGridStore = ({ items }) => {
  const { t } = useTranslation()
  const { account } = useSelector((state) => state.account)
  return (
    <>
      {items?.map((item, index) => {
        return (
          <li key={index} className="col-6 col-xl-2 col-lg-3 col-md-3 product-item px-0">
            <div className="product-item__outer h-100 w-100">
              <div className="product-item__inner w-100 px-1 py-2">
                <Link to={`/product/detail/${prettyUrl(item.product_title) + '/' + item.uid}`}>
                  <div className="product-item__body px-2">
                    <div className="col-12 mb-1 px-0">
                      <div className="pointer font-size-12 d-none d-lg-block store-title text-gray-5">
                        {item.store_name.length > 25 && (
                          <span className="tooltip-text">{item.store_name}</span>
                        )}
                        {subStringContent(item.store_name, 25)}
                      </div>
                      <div className="pointer font-size-10-sm d-block d-lg-none store-title text-gray-5">
                        {subStringContent(item.store_name, 20)}
                      </div>
                    </div>
                    <div>
                      <h5 className="col-12 px-0 mb-1 product-item__title">
                        <div className="text-blue font-weight-bold pointer">
                          {subStringContent(item.product_title, 30)}
                        </div>
                      </h5>
                    </div>
                    <div className="w-100">
                      <div className="d-block mb-2 text-center pointer img-product">
                        <img className="img-fluid" src={item.product_thumbnail} alt="" />
                      </div>
                    </div>
                    <div className="row mb-1">
                      <div className="col-12">
                        <div className="product-price text-gray-100 font-weight-bold">
                          {currencyFormat(item.product_price, item.product_price_currency)}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="border-top">
                        <div className="mt-1">
                          <div className="row text-gray-6 pointer flex-center-between text-nowrap">
                            <div className="col-12 col-lg-6">
                              <Rating rating={item.product_rating} />
                            </div>
                            <div className="col-12 col-lg-6 px-3 px-lg-2 text-left text-lg-center">
                              <span className="ml-1">
                                {formatCash(item.product_sold)} {t('title.sold')}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-1">
                          <div className="text-gray-6 flex-nowrap font-size-12 col-12">
                            {t('title.tax_status')}: {t(`title.tax_type.${item.store_tax_type}`)}
                          </div>
                        </div>
                        <div className="row mt-1">
                          <div className="text-gray-6 text-nowrap font-size-12 col-12">
                            <i className="ec ec-map-pointer"></i>
                            {subStringContent(item.origin_city_name, 28)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
                {account?.uid !== item?.store_account_uid && (
                  <div className="product-item__footer">
                    <Link
                      to={`/chat?product=${item.uid}&seller=${item.store_uid}`}
                      className="btn btn-primary btn-xs text-nowrap mt-1 font-weight-bold w-100"
                    >
                      <i className="fa fa-comments mr-2" />
                      {t('button.chat')}
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </li>
        )
      })}
      {!items && (
        <div className="col mt-2 text-center text-wrap">
          <span className="font-size-20 font-weight-bold text-grey">
            {t('message.product_search_empty')}
          </span>
        </div>
      )}
    </>
  )
}

ProductGridStore.propTypes = {
  items: PropTypes.any,
}

export default ProductGridStore
