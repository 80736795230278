import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { currencyFormat, formatCash, prettyUrl } from '../../utility/Helpers'
import { Rating } from '../Main'
import { InteractorQuotationCartCreate } from '../../interactors/Main'
import { useSelector } from 'react-redux'
import { showAlert } from '../../utility/SweetAlert'

const ProductListTile = ({ items }) => {
  const { t } = useTranslation()
  const { account } = useSelector((state) => state.account)
  const { cartCreate } = InteractorQuotationCartCreate()

  const [payloadCart] = useState({
    account_uid: account?.uid,
    created_by: account?.full_name,
  })
  const [loading, setLoading] = useState(false)

  const handleAddToCart = (product) => {
    if (!account) {
      window.location = '/account/signin'
    }
    const objPayload = {
      ...payloadCart,
      product_uid: product?.uid,
      product_unit_type: product?.product_unit_type,
      company_uid: null,
      request_price_currency: product?.product_price_currency,
      request_quantity: parseFloat(product?.minimum_order_quantity ?? 1),
      request_price: parseFloat(product?.product_price ?? 1),
    }
    let newLoading = loading
    newLoading = true
    setLoading(newLoading)
    if (account.uid !== product?.store_account_uid && newLoading) {
      cartCreate(objPayload, setLoading, false)
    } else {
      setLoading(false)
      showAlert({
        icon: 'error',
        message: t('message.product_owner'),
        reload: false,
      })
    }
  }
  return (
    <>
      {items?.map((item, index) => {
        return (
          <li key={index} className="product-item remove-divider mb-2 product-container-tile">
            <div className="product-item__outer w-100">
              <div className="product-item__inner remove-prodcut-hover py-2">
                <div key={index} className="row justify-content-center pointer">
                  <div className="product-item__header col-lg-3 col-md-4 col-sm-5 col-6">
                    <Link
                      to={`/product/detail/${prettyUrl(item.product_title) + '/' + item.uid}`}
                      className="d-block text-center"
                    >
                      <img className="w-100 h-100" src={item.product_thumbnail} alt="" />
                    </Link>
                  </div>
                  <div className="product-item__body col-lg-6 col-md-4 col-sm-5 col-6">
                    <div className="mb-1">
                      <Link
                        to={`/product/detail/${prettyUrl(item.product_title) + '/' + item.uid}`}
                        className="font-size-12 text-gray-5"
                      >
                        {item.store_name}
                      </Link>
                    </div>
                    <h5 className="mb-2 product-item__title">
                      <Link
                        to={`/product/detail/${prettyUrl(item.product_title) + '/' + item.uid}`}
                        className="text-blue font-weight-bold pointer"
                      >
                        {item.product_title}
                      </Link>
                    </h5>
                    <div className="flex-center-between mb-2">
                      <div className="product-price">
                        <div className="text-gray-110 font-size-16 font-weight-bold">
                          {currencyFormat(item.product_price, item.product_price_currency)}
                        </div>
                      </div>
                    </div>
                    <div className="flex-center-between mt-1">
                      <div className="text-gray-6 d-flex align-items-center pointer">
                        {t('title.moq')} : <span> {item.minimum_order_quantity}</span>
                      </div>
                    </div>
                    <div className="flex-center-between mt-1">
                      <div className="text-gray-6 d-flex align-items-center pointer">
                        <Rating rating={item.product_rating} />
                        <span className="ml-1">
                          {formatCash(item.product_sold)} {t('title.sold')}
                        </span>
                      </div>
                    </div>
                    <div className="flex-center-between mt-1">
                      <div className="text-gray-6 flex-nowrap">
                        {t('title.tax_status')}: {t(`title.tax_type.${item.store_tax_type}`)}
                      </div>
                    </div>
                    <div className="flex-center-between mt-1">
                      <div className="text-gray-6 flex-nowrap">{item.origin_city_name}</div>
                    </div>
                  </div>
                  <div className="product-item__footer col-lg-3 col-md-4 col-10 d-block align-self-center align-items-center my-3">
                    {account?.uid !== item?.store_account_uid && (
                      <>
                        <Link
                          to={`/chat?product=${item.uid}&seller=${item.store_uid}`}
                          role="button"
                          className="btn btn-primary btn-xs font-weight-bold mb-2 w-100 text-nowrap pointer"
                        >
                          <i className="fa fa-comments mx-1" />
                          {t('button.chat')}
                        </Link>
                      </>
                    )}
                    <div
                      role="button"
                      className="btn btn-gray-4 btn-xs font-weight-bold mb-2 text-nowrap pointer w-100"
                      onClick={() => handleAddToCart(item)}
                    >
                      <i className="ec ec-add-to-cart mx-1" />
                      {t('button.add_to_cart')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        )
      })}
      {!items && (
        <div className="col mt-2 text-center text-wrap">
          <span className="font-size-20 font-weight-bold text-grey">
            {t('message.product_search_empty')}
          </span>
        </div>
      )}
    </>
  )
}

ProductListTile.propTypes = {
  items: PropTypes.any,
}

export default ProductListTile
