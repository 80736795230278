import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { InteractorContentBannerSearch } from '../../interactors/Main'
const BannerFull = () => {
  const [payload] = useState({
    page: 1,
    limit: 1,
    keywords: {
      text_search: '',
      banner_category: 'PROMOTION_BANNER_FULL',
    },
  })
  const [loading, setLoading] = useState(true)
  const [banner, setBanner] = useState({})
  const { bannerSearch } = InteractorContentBannerSearch()
  const handleResponse = (data) => {
    if (data?.items?.length > 0) {
      setBanner(data.items)
    }
  }
  useEffect(() => {
    if (loading) {
      bannerSearch(payload, handleResponse, setLoading)
    }
  }, [bannerSearch, loading, payload])
  return (
    <>
      {banner.length > 0 &&
        banner.map((row, key) => {
          return (
            <React.Fragment key={key}>
              <div className="mb-6">
                <Link key={row.uid} to={row.target_link} className="d-block text-white">
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        {loading ? (
                          <div className="skeleton-loader min-height-45 max-height-50"></div>
                        ) : (
                          <div className="text-center">
                            <img className="img-fluid" src={row.media_url} alt="" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </React.Fragment>
          )
        })}
    </>
  )
}

export default BannerFull
