import React from 'react'
const FooterCopyright = () => {
  return (
    <>
      <div className="bg-black py-2">
        <div className="container">
          <div className="flex-center-between d-block d-md-flex">
            <div className="mb-3 mb-md-0 text-white">
              &copy; <span className="font-weight-bold"> Adaloka.com </span>- All Rights Reserved.
            </div>
          </div>
        </div>
      </div>
      {/* <a
        className="js-go-to u-go-to"
        href="/"
        data-position='{"bottom": 15, "right": 15 }'
        data-type="fixed"
        data-offset-top="400"
        data-compensation="#header"
        data-show-effect="slideInUp"
        data-hide-effect="slideOutDown"
      >
        <span className="fas fa-arrow-up u-go-to__inner"></span>
      </a> */}
    </>
  )
}

export default FooterCopyright
