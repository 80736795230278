import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'
import { useTranslation } from 'react-i18next'
import { InteractorAuthenticationOtpRequest } from '../../Main'
import { showAlert } from './../../../utility/SweetAlert'

const InteractorAccountUserPasswordForgot = () => {
  const { t } = useTranslation()

  const { OTPRequest } = InteractorAuthenticationOtpRequest()

  const requestChangePassword = useCallback(
    (payload, loading) => {
      payload = {
        ...payload,
      }
      ApiService.jsonRequest('/account-user/verify-email', payload, (response) => {
        loading(false)
        if (response.status_code === 200) {
          OTPRequest({
            target_type: 'EMAIL',
            target_identity: response.data.email_address,
            target_full_name: response.data.full_name,
            otp_category: 'FORGOT-PASSWORD',
            account_uid: response.data.uid,
          })
        } else {
          showAlert({
            icon: 'error',
            message: t(`response_message.${response.messages}`),
            timer: 2000,
          })
        }
      })
    },
    [OTPRequest, t],
  )

  return { requestChangePassword }
}

export default InteractorAccountUserPasswordForgot
