import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { showAlert } from '../../utility/SweetAlert'
import { Modal } from 'react-responsive-modal'
import ReCAPTCHA from 'react-google-recaptcha'
import { InteractorNewsletterCreate } from '../../interactors/Main'

const FooterNewsletter = () => {
  const { t } = useTranslation()
  const [emailAddress, setEmailAddress] = useState('')
  const { newsletterCreate } = InteractorNewsletterCreate()
  const recaptchaRef = React.createRef()
  const [modal, setModal] = useState(false)
  const handleModal = () => {
    setModal(false)
  }
  const handleSubmit = () => {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    if (!emailAddress.match(validRegex)) {
      showAlert({
        icon: 'warning',
        message: t('message.invalid_email_address'),
      })
    } else {
      setModal(true)
    }
  }
  const recaptchaChange = (value) => {
    if (value) {
      const payload = {
        email_address: emailAddress,
      }
      newsletterCreate(payload)
    } else {
      setModal(false)
    }
  }
  return (
    <>
      <Modal open={modal} onClose={handleModal}>
        <div className="row col-12">
          <div className="col-12 p-2 font-size-18 text-center mb-1 font-weight-bold">
            <h3>{t('title.confirmation_newsletter')}</h3>
            <hr />
          </div>
          <div className="col-12 text-center">
            <ReCAPTCHA
              style={{ display: 'inline-block' }}
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={recaptchaChange}
            />
          </div>
        </div>
      </Modal>
      <div className="bg-primary py-3">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 mb-md-3 mb-lg-0">
              <div className="row text-white align-items-center">
                <div className="col-auto flex-horizontal-center">
                  <i className="ec ec-newsletter font-size-40" />
                  <h2 className="font-size-20 mb-0 ml-3">
                    <strong>{t('title.newsletter_signup')}</strong>
                  </h2>
                </div>
                <div className="col my-4 my-md-0">
                  <h5 className="font-size-15 ml-4 mb-0">{t('title.newsletter_description')}</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <span className="js-validate js-form-message">
                <label className="sr-only" htmlFor="subscribeSrEmail">
                  {t('form.email_address')}
                </label>
                <div className="input-group input-group-pill">
                  <input
                    type="email"
                    value={emailAddress}
                    className="form-control border-0 height-40"
                    name="newsletter_email"
                    id="newsletter_email"
                    placeholder={t('form.email_address')}
                    aria-label={t('form.email_address')}
                    aria-describedby="newsletter_email"
                    required={true}
                    data-msg="tidak valid"
                    onChange={(e) => {
                      setEmailAddress(e.target.value)
                    }}
                  />
                  <div className="input-group-append">
                    <button
                      type="submit"
                      className="btn btn-dark btn-sm-wide height-40 py-2"
                      id="subscribeButton"
                      onClick={handleSubmit}
                    >
                      {t('button.send')}
                    </button>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FooterNewsletter
