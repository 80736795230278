import i18n from './i18n'
import { formatDate } from './Helpers'

function i18nValue(key) {
  let value = i18n.t(key)
  if (value === undefined) {
    value = key
  }
  return value
}

function getFormattedDate(date, prefomattedDate = false, hideYear = false) {
  let minutes = date.getMinutes()

  if (minutes < 10) {
    minutes = `0${minutes}`
  }

  if (prefomattedDate) {
    return `${prefomattedDate} ${formatDate(date, 'HH:mm:ss A')}`
  }

  if (hideYear) {
    return formatDate(date, 'DD/MM/YYYY HH:mm')
  }

  return formatDate(date, 'DD/MM/YYYY HH:mm')
}

const timeAgo = (dateParam) => {
  if (!dateParam) {
    return null
  }

  const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam)
  const DAY_IN_MS = 86400000 // 24 * 60 * 60 * 1000
  const today = new Date()
  const yesterday = new Date(today - DAY_IN_MS)
  const seconds = Math.round((today - date) / 1000)
  const minutes = Math.round(seconds / 60)
  const isToday = today.toDateString() === date.toDateString()
  const isYesterday = yesterday.toDateString() === date.toDateString()
  const isThisYear = today.getFullYear() === date.getFullYear()

  if (seconds < 5) {
    return i18nValue('time.now')
  } else if (seconds < 60) {
    return `${seconds} ${i18nValue('time.second_ago')}`
  } else if (minutes < 60) {
    return `${i18nValue('time.minute_ago')}`
  } else if (isToday) {
    return getFormattedDate(date, i18nValue('time.today'))
  } else if (isYesterday) {
    return getFormattedDate(date, i18nValue('time.yesterday'))
  } else if (isThisYear) {
    return getFormattedDate(date, false, true)
  }

  return getFormattedDate(date)
}

export { timeAgo }
