import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { InteractorProductItemSummary } from '../../interactors/Main'
import { currencyFormat, subStringContent, prettyUrl, formatCash } from '../../utility/Helpers'
import { Rating, VerticalGridSkeleton } from '../Main'
import { useSelector } from 'react-redux'

const ProductBestSeller = () => {
  const { t } = useTranslation()
  const { account } = useSelector((state) => state.account)
  const [loading, setLoading] = useState(true)
  const { productSummary } = InteractorProductItemSummary()
  const [dataProduct, setDataProduct] = useState({})
  const [payload] = useState({
    limit: 12,
    keywords: {
      sort_by: 'best_seller',
    },
  })
  useEffect(() => {
    if (loading) {
      productSummary(payload, setDataProduct, setLoading)
    }
  }, [loading, payload, productSummary])

  return (
    <>
      <div className=" d-flex justify-content-between border-bottom border-color-1 flex-md-nowrap flex-wrap border-sm-bottom-0">
        <h3 className="section-title text-gray-100 mb-0 pb-2 font-weight-bold font-size-18">
          {t('menu.best_seller_product')}
        </h3>
      </div>
      <div>
        <ul className="row list-unstyled products-group no-gutters pb-3 mb-3 overflow-lg-visble">
          {loading ? (
            <VerticalGridSkeleton />
          ) : (
            <>
              {dataProduct.items?.map((item, index) => {
                return (
                  <li
                    key={index}
                    className="col-lg-4 col-md-6 col-12 product-item product-item__card border-bottom border-md-bottom-0"
                  >
                    <div className="product-item__outer h-100 w-100">
                      <div className="product-item__inner no-gutters p-3">
                        <div className="product-item__body">
                          {/* START PRODUCT BODY */}
                          <div className="row mb-2 px-3">
                            <div className="col-6 px-0">
                              <Link
                                to={`/product/detail/${
                                  prettyUrl(item.product_title) + '/' + item.uid
                                }`}
                                className="w-100 h-100"
                                title={item.product_title}
                              >
                                <img className="img-fluid" alt="" src={item.product_thumbnail} />
                              </Link>
                            </div>

                            <div className="col-6 px-1">
                              <Link
                                to={`/product/detail/${
                                  prettyUrl(item.product_title) + '/' + item.uid
                                }`}
                              >
                                <div className="mb-2">
                                  <div className="mb-2 font-size-12 text-black">
                                    {item.store_name}
                                  </div>
                                  <h5 className="product-item__title">
                                    <div
                                      title={item.product_title}
                                      className="text-blue font-weight-bold font-size-14"
                                    >
                                      {subStringContent(item.product_title, 30)}
                                    </div>
                                  </h5>
                                </div>
                                <div className="d-block mb-1">
                                  <div className="product-price text-gray-100 font-weight-bold font-size-16">
                                    {currencyFormat(
                                      item.product_price,
                                      item.product_price_currency,
                                    )}
                                  </div>
                                </div>
                                <div className="d-block mb-1">
                                  <div className="d-flex flex-column flex-md-row text-gray-6 font-size-12 pointer">
                                    <div className="col-12 col-md-6 px-0">
                                      <Rating rating={item.product_rating} />
                                    </div>
                                    <div className="col-12 col-md-6 px-0">
                                      {formatCash(item.product_sold)} {t('title.sold')}
                                    </div>
                                  </div>
                                  <div className="flex-center-between mt-1">
                                    <div className="text-gray-6 font-size-12 flex-nowrap">
                                      {t('title.tax_status')}:
                                      {t(`title.tax_type.${item.store_tax_type}`)}
                                    </div>
                                  </div>
                                </div>
                                <div className="d-block mb-1">
                                  <div className="text-gray-6 font-size-12">
                                    <i className="ec ec-map-pointer"></i>
                                    <span>{subStringContent(item.origin_city_name, 28)}</span>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </div>
                          {/* END PRODUCT BODY */}
                          {account?.uid !== item?.store_account_uid && (
                            <div className="product-item__footer justify-content-end">
                              <div className="flex-center-between flex-wrap">
                                <Link
                                  to={`/chat?product=${item.uid}&seller=${item.store_uid}`}
                                  className="btn btn-xs btn-block btn-primary font-weight-bold font-size-12 text-center text-nowrap"
                                >
                                  <i className="fa fa-comments mr-1" />
                                  {t('button.chat')}
                                </Link>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                )
              })}
            </>
          )}
        </ul>
      </div>
    </>
  )
}

export default ProductBestSeller
