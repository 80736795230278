import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'

const InteractorLocationSubdistrict = () => {
  const listSubdistrict = useCallback((callback, payload) => {
    payload = {
      district_uid: payload,
    }
    ApiService.jsonRequest('/master-subdistrict/list', payload, (response) => {
      if (response.status_code === 200) {
        callback(response.data)
      }
    })
  }, [])

  return { listSubdistrict }
}

export default InteractorLocationSubdistrict
