import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import { Loading } from '../Main'

const ProductDetailCarousel = (props) => {
  const [slideImages, setSlideImages] = useState([])
  useEffect(() => {
    if (slideImages.length === 0) {
      let img = []
      props.images.forEach((row) => {
        img.push({
          original: row.product_media_url,
          thumbnail: row.product_media_url,
          loading: 'lazy',
          thumbnailLoading: 'lazy',
          originalWidth: '400px',
          thumbnailWidth: '40px',
          originalAlt: '',
          thumbnailAlt: '',
          originalTitle: '',
          thumbnailTitle: '',
          thumbnailLabel: '',
        })
      })
      setSlideImages(img)
    }
  }, [props.images, slideImages])

  if (slideImages.length) {
    return (
      <ImageGallery
        lazyLoad
        showBullets
        showNav={false}
        showPlayButton={false}
        autoPlay
        items={slideImages}
      />
    )
  } else {
    return (
      <div className="d-flex justify-content-center align-items-center height-100vh">
        <Loading />
      </div>
    )
  }
}

ProductDetailCarousel.propTypes = {
  images: PropTypes.any,
}

export default ProductDetailCarousel
