import React, { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { SidebarAccount } from '../Main'
import profileDefault from '../../assets/image/profile/profile.png'
import { useLocation } from 'react-router-dom'
import { findByArray } from '../../utility/Helpers'
import { removeCookies } from '../../utility/Cookies'

const MenuAccount = () => {
  const { t } = useTranslation()
  const { account } = useSelector((state) => state.account)
  const { header_info } = useSelector((state) => state.summaryHeader)
  const [menuGroup, setMenuGroup] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const location = useLocation()
  const sidebarCollapse = (val) => {
    let object_initial = { ...menuGroup }
    for (var key of Object.keys(menuGroup)) {
      object_initial[key] = val === key ? !menuGroup[key] : false
    }
    setMenuGroup(object_initial)
  }

  const list_menu = useMemo(
    () => [
      {
        uid: 'account',
        title: 'account',
        path: '#',
        icon: 'fa fa-user',
        badge: null,
        major: [
          {
            main_uid: 'account',
            title: 'profile',
            path: '/account/profile',
            icon: 'fa fa-circle fa-xs',
          },
          {
            main_uid: 'account',
            title: 'address',
            path: '/account/address',
            icon: 'fa fa-circle fa-xs',
          },
          {
            main_uid: 'account',
            title: 'change_password',
            path: '/account/change-password',
            icon: 'fa fa-circle fa-xs',
          },
        ],
      },
      {
        uid: 'rfq',
        title: 'quotation',
        path: '/quotation/request',
        icon: 'fa fa-file',
        badge: 'RFQ',
      },
      {
        uid: 'order',
        title: 'order',
        path: '/order',
        icon: 'fa fa-shopping-cart',
        badge: null,
      },
      // {
      //   uid: 'bill',
      //   title: 'bill',
      //   path: '/bill',
      //   icon: 'fa fa-money-bill',
      //   badge: null,
      // },
      // {
      //   uid: 'fund',
      //   title: 'payment_history',
      //   path: '/payment',
      //   icon: 'fa fa-credit-card',
      //   badge: null,
      // },
      {
        uid: 'notification',
        title: 'notification',
        path: '/notification',
        icon: 'fa fa-bell',
        badge:
          header_info.notification_unread > 99
            ? '99+'
            : header_info.notification_unread === 0
            ? null
            : header_info.notification_unread,
      },
      {
        uid: 'signout',
        title: 'logout',
        path: '/',
        icon: 'fa fa-key',
        badge: null,
      },
    ],
    [header_info.notification_unread],
  )

  useEffect(() => {
    if (isLoading) {
      // INITIAL
      let object_initial = { ...menuGroup }
      for (var init = 0; init < list_menu.length; init++) {
        if (list_menu[init]?.major) {
          object_initial[list_menu[init].uid] = false
        }
      }
      // MAPPING
      for (var i = 0; i < list_menu.length; i++) {
        if (list_menu[i]?.major) {
          const path_major = findByArray(list_menu[i]?.major, 'path', location.pathname)
          if (path_major) {
            if (list_menu[i].uid === path_major.main_uid) {
              object_initial[list_menu[i].uid] = true
            } else {
              object_initial[list_menu[i].uid] = false
            }
          }
        }
      }
      setMenuGroup(object_initial)
      setIsLoading(false)
    }
  }, [menuGroup, location.pathname, isLoading, list_menu])
  return (
    <>
      <div className="d-none d-xl-block col-xl-3 col-wd-2gdot5 wrapper">
        <nav id="sidebar">
          <div className="sidebar-header">
            <div className="row d-flex text-center justify-content-center">
              <div className="col-lg-12">
                <div className="form-picture w160 shadow-1-strong mb-3">
                  <img
                    className="img-fluid object-fit-cover"
                    src={account?.profile_picture ? account.profile_picture : profileDefault}
                    alt=""
                  />
                </div>
                <h5 className="mb-2 text-center">{account?.full_name}</h5>
                <Link to={'/account/profile'}>
                  <button type="button" className="btn btn-xs btn-block btn-dark">
                    <i className="fa fa-edit" />
                    &nbsp;
                    {t('button.profile_update')}
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <ul className="list-unstyled components">
            {list_menu.map((main, key_main) => {
              return !main.major?.length ? (
                <li key={key_main} className={location.pathname === main.path ? 'active' : ''}>
                  <Link
                    to={'#'}
                    onClick={() => {
                      if (main.uid === 'signout') {
                        removeCookies('_wsso')
                        removeCookies('_wchat')
                        removeCookies('_wssc')
                        removeCookies('summary-header')
                        window.location = '/'
                      } else {
                        window.location = main.path
                      }
                    }}
                  >
                    <i className={`${main.icon} mr-2`}></i>
                    {t(`account_menu.${main.title}`)}
                    {main.badge && (
                      <span className="m-1 p-1 btn-sm btn-danger badge">{main.badge}</span>
                    )}
                  </Link>
                </li>
              ) : (
                <li key={key_main} className={menuGroup[main.uid] ? 'active' : ''}>
                  <Link
                    to={'#'}
                    onClick={() => sidebarCollapse(main.uid)}
                    data-toggle="collapse"
                    aria-expanded={menuGroup[main.uid] ? 'true' : 'false'}
                    className={`sidebar-toggle dropdown-toggle ${
                      menuGroup[main.uid] ? 'collapsed' : ''
                    }`}
                  >
                    <i className={`${main.icon} mr-2`}></i>
                    {t(`account_menu.${main.title}`)}
                  </Link>
                  <ul className={`collapse list-unstyled ${menuGroup[main.uid] ? 'show' : ''}`}>
                    {main.major.map((major, key_major) => {
                      return (
                        <li key={key_major}>
                          <Link
                            to={'#'}
                            onClick={() => {
                              window.location = major.path
                            }}
                          >
                            {t(`account_menu.${major.title}`)}
                          </Link>
                        </li>
                      )
                    })}
                  </ul>
                </li>
              )
            })}
          </ul>
        </nav>
      </div>

      {/* Button Menu Mobile */}
      <div className="mt-3 ml-2 d-xl-none">
        <SidebarAccount>
          <i className="fas fa-align-left pointer" />
          <span className="ml-1 font-weight-bold">{t('title.main_menu').toUpperCase()}</span>
        </SidebarAccount>
      </div>
    </>
  )
}

export default MenuAccount
