import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Loading, WideCardFilter, Pagination, WirecardCancelForm } from '../Main'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { numberFormat, currencyFormat, formatDate, prettyUrl } from '../../utility/Helpers'
import { InteractorQuotationRequestUpdate, InteractorDownloadPdf } from '../../interactors/Main'
import { confirmationAlert } from '../../utility/SweetAlert'
import Modal from 'react-responsive-modal'
import QuotationChangeAddress from '../../containers/quotation/QuotationChangeAddress'
import { AddressForm } from '../../containers/Main'

const WideCardQuotationRequest = (data) => {
  const { loading, datacard, payload, download } = data
  const { account } = useSelector((state) => state.account)
  const { requestUpdate } = InteractorQuotationRequestUpdate()
  const { getRFQ } = InteractorDownloadPdf()
  const { t } = useTranslation()
  const [loadingRequest, setLoadingRequest] = useState(false)
  const [payloadFilter, setPayloadFilter] = useState(payload)
  const [payloadRejected, setPayloadRejected] = useState(null)

  const [addressInitial] = useState({})
  const [quotationItem, setQuotationItem] = useState([])
  const [modal, setModal] = useState({
    rfq: false,
    address: false,
  })

  const [isModalRejected, setIsModalRejected] = useState(false)

  const handleModal = (type) => {
    const popup = { ...modal }
    if (type === 'RFQ') {
      popup.rfq = true
      popup.address = false
    } else {
      popup.rfq = false
      popup.address = true
    }
    setModal(popup)
  }

  const handleModalClose = () => {
    const popup = { ...modal }
    popup.rfq = false
    popup.address = false
    setModal(popup)
  }

  const filter = {
    text_search: true,
    status: [
      { value: '', label: t(`title.select_status`) },
      { value: 'REQUESTED_BY_BUYER', label: t(`initial_status.REQUESTED_BY_BUYER`) },
      { value: 'OFFER_BY_SELLER', label: t(`initial_status.OFFER_BY_SELLER`) },
      { value: 'REJECTED_BY_SELLER', label: t(`initial_status.REJECTED_BY_SELLER`) },
      { value: 'APPROVED_BY_BUYER', label: t(`initial_status.APPROVED_BY_BUYER`) },
      { value: 'REJECTED_BY_BUYER', label: t(`initial_status.REJECTED_BY_BUYER`) },
      { value: 'CANCELLED_BY_BUYER', label: t(`initial_status.CANCELLED_BY_BUYER`) },
    ],
    download: download,
  }
  const handleFilter = (row) => {
    let objPayload = { ...payloadFilter }
    objPayload.page = 1
    objPayload.keywords.text_search = row.keywords.text_search
    objPayload.keywords.status = row.keywords.status
    data.handleFilter(objPayload)
    setPayloadFilter(objPayload)
  }
  const handlePageChange = (page) => {
    let objPayload = { ...payloadFilter }
    objPayload.page = page
    data.handleFilter(objPayload)
    setPayloadFilter(objPayload)
  }

  const handleRejectedRfq = async (value) => {
    const reject = await confirmationAlert({
      title: t('title.are_you_sure'),
      message:
        value.status === 'REJECTED_BY_BUYER'
          ? t('message.confirmation_rfq_reject')
          : t('message.confirmation_rfq_cancel'),
      icon: 'warning',
    })
    if (reject) {
      setLoadingRequest(true)
      requestUpdate(value, 'REJECTED')
    }
  }

  const handleDownloadPdf = (data) => {
    getRFQ(data)
  }

  const handleQuotationUpdate = async (row, status) => {
    let { request_quotation_note, ...payloadUpdate } = row
    payloadUpdate.status = status
    payloadUpdate.delivery_estimated_time = formatDate(
      row.delivery_estimated_time,
      'YYYY-MM-DD HH:mm:ss',
    )
    payloadUpdate.request_note = request_quotation_note
    if (row?.request_payment_term_date) {
      payloadUpdate.request_payment_term_date = formatDate(
        new Date(row.request_payment_term_date),
        'YYYY-MM-DD HH:mm:ss',
      )
    }
    payloadUpdate.updated_by = account.full_name

    switch (status) {
      case 'APPROVED_BY_BUYER':
        setLoadingRequest(true)
        requestUpdate(payloadUpdate)
        break
      case 'REJECTED_BY_BUYER':
        setIsModalRejected(true)
        setPayloadRejected(payloadUpdate)
        break
      case 'CANCELLED_BY_BUYER':
        setIsModalRejected(true)
        setPayloadRejected(payloadUpdate)
        break
      default:
        break
    }
  }
  // CARD AREA
  const CardHeader = (data) => {
    const row = data.data

    return (
      <>
        <div className="row">
          <div className="col-8 mt-2">
            <span className="d-inline-block">
              <img src={row.store_logo} className="img-fluid" alt="" style={{ width: '24px' }} />
            </span>
            <span className="d-inline-block p-2">
              <small>{t('title.seller_name')}</small> <br />
              <strong>{row.store_name}</strong>
            </span>
          </div>
          <div className="col-4 mt-1 d-flex justify-content-end">
            <span className="d-inline-block py-4">
              <small>{t('title.status')}: </small>
              <span className={`text-gray font-weight-bold text-gray`}>
                {t(`initial_status.${row.status}`)}
              </span>
            </span>
          </div>
        </div>
        <hr className="m-0" />
      </>
    )
  }
  const CardBody = (data) => {
    const row = data.data
    const items = row.quotation_items
    const installments = row.quotation_installment
    let subtotal = 0
    return (
      <>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <table className="table table-borderless ml-2 mt-2">
              <tbody>
                <tr>
                  <td className="col-xs-6 px-2 font-weight-bold">{t(`title.rfq_number`)}</td>
                  <td className="px-2">
                    {row.request_code}
                    <div
                      role="button"
                      onClick={() => navigator.clipboard.writeText(row?.request_code)}
                      className="ml-2 fa fa-copy btn-link border-0 bg-transparent pointer"
                    ></div>
                  </td>
                </tr>
                <tr>
                  <td className="col-xs-6 px-2 font-weight-bold">
                    {t(`title.purchase_request_number`)}
                  </td>
                  <td className="px-2 text-break">{row.purchase_request_number}</td>
                </tr>
                <tr>
                  <td className="px-2 font-weight-bold">{t(`title.rfq_date`)}</td>
                  <td className="px-2 vertical-center">
                    {formatDate(row.created_date, 'DD/MM/YYYY HH:mm')}
                  </td>
                </tr>
                <tr>
                  <td className="px-2 font-weight-bold">{t(`invoice.payment_method`)}</td>
                  <td className="px-2 vertical-center">
                    {t(`initial_status.${row.request_payment_type}`)}
                  </td>
                </tr>
                {row.request_payment_type === 'TOP' && (
                  <tr>
                    <td className="px-2 font-weight-bold">{t(`title.payment_due_date`)}</td>
                    <td className="px-2">
                      {formatDate(row.request_payment_term_date, 'DD/MM/YYYY HH:mm')}
                    </td>
                  </tr>
                )}
                {row.request_payment_type === 'TOP' && row.request_payment_installment > 0 && (
                  <tr>
                    <td className="px-2 font-weight-bold">{t(`invoice.total_installment`)}</td>
                    <td className="px-2">{row.request_payment_installment}</td>
                  </tr>
                )}
                {row.request_quotation_note !== '' && (
                  <tr>
                    <td className="px-2 font-weight-bold">{t(`title.note`)}</td>
                    <td className="px-2 text-break">{row.request_quotation_note}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="col-xs-12 col-md-6">
            <table className="table table-borderless ml-2 mt-2">
              <tbody>
                <tr>
                  <td className="col-xs-6 px-2 font-weight-bold">
                    {row.delivery_type === 'PICKUP' ? t(`title.pickup_at`) : t(`title.ship_to`)}
                  </td>
                  <td className="px-2 text-break">{row.destination_address}</td>
                </tr>
                <tr>
                  <td className="px-2 font-weight-bold">{t(`title.delivery_type`)}</td>
                  <td className="px-2">{t(`initial_status.${row.delivery_type}`)}</td>
                </tr>
                {row.delivery_estimated_time && (
                  <tr>
                    <td className="px-2 font-weight-bold">
                      {row.delivery_type === 'PICKUP'
                        ? t(`title.delivery_date_pickup`)
                        : t(`title.delivery_date_ship`)}
                    </td>
                    <td className="px-2">
                      {formatDate(row.delivery_estimated_time, 'DD/MM/YYYY HH:mm')}
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="px-2 font-weight-bold">
                    {row.delivery_type === 'PICKUP'
                      ? t(`title.ship_consignee_buyer`)
                      : t(`title.ship_consignee`)}
                  </td>
                  <td className="px-2  text-break">
                    {row.consignee_full_name} ({row.consignee_phone_number})
                  </td>
                </tr>
                {(row.status === 'REJECTED_BY_SELLER' ||
                  row.status === 'CANCELLED_BY_BUYER' ||
                  row.status === 'REJECTED_BY_BUYER') && (
                  <tr>
                    <td className="px-2 font-weight-bold">{t('title.rejected_reason')}</td>
                    <td className="px-2 text-break">{row.reason_cancel_reject}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {row.payment_amount > 0 && installments?.length > 0 && (
          <>
            <hr className="m-0" />
            <div className="table-responsive overflow-auto">
              <table className="table table-borderless">
                <thead>
                  <tr>
                    <th className="col-1 text-center">{t('title.no')}</th>
                    <th className="col-3 text-center">{t('title.installment_due_date')}</th>
                    <th className="col-4 text-center">{t('title.installment_amount')}</th>
                    <th className="col-4 text-center">{t('title.installment_note')}</th>
                  </tr>
                </thead>
                <tbody>
                  {installments.map((row, key) => {
                    return (
                      <React.Fragment key={key}>
                        <tr key={key}>
                          <td className="col-1 text-center">{key + 1}</td>
                          <td className="col-3 text-center">
                            {formatDate(row.due_date, 'DD/MM/YYYY HH:mm')}
                          </td>
                          <td className="col-4 text-center">{currencyFormat(row.amount, 'IDR')}</td>
                          <td className="col-4 text-center">{row.note}</td>
                        </tr>
                      </React.Fragment>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}

        <hr className="m-0" />
        <div className="table-responsive overflow-auto">
          <table className="table table-borderless">
            <thead>
              <tr>
                <th className="col-6">{t('title.product.title')}</th>
                {row.generate_source !== 'SELLER' && (
                  <th className="col-3">{t('title.my_request')}</th>
                )}
                <th className="col-3">{t('title.offer_by_seller')}</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, key) => {
                subtotal += item.product_approved_quantity * item.product_approved_price
                return (
                  <tr key={key}>
                    <td className="col d-flex flex-column flex-md-row">
                      <div className="col-8 col-md-3 min-width-60 px-0">
                        <Link
                          to={`/product/detail/${
                            prettyUrl(item.product_title) + '/' + item.product_uid
                          }`}
                        >
                          <img src={item.product_thumbnail} className="img-fluid" alt="" />
                        </Link>
                      </div>
                      <div className="col-12 col-md-9 px-0 px-md-3">
                        <Link
                          to={`/product/detail/${
                            prettyUrl(item.product_title) + '/' + item.product_uid
                          }`}
                          className="text-black"
                        >
                          <span>
                            <strong>{item.product_title}</strong>
                          </span>
                        </Link>
                      </div>
                    </td>
                    {row.generate_source !== 'SELLER' && (
                      <>
                        <td>
                          <span className="font-weight-bold">{t('title.request_quantity')}</span>
                          <br />
                          {numberFormat(item.product_request_quantity)}
                          <br />
                          <span className="font-weight-bold">{t('title.request_price')}</span>
                          <br />
                          {currencyFormat(
                            item.product_request_price,
                            item.product_request_price_currency,
                          )}
                          <small> / {item.product_unit_type}</small>
                        </td>
                      </>
                    )}
                    <td>
                      <span className="font-weight-bold">{t('title.request_seller')}</span> <br />
                      {row.status !== 'REQUESTED_BY_BUYER' ? (
                        <>
                          {numberFormat(item.product_approved_quantity)}
                          <br />
                        </>
                      ) : (
                        <>
                          <span className="text-red font-italic">
                            {t('title.not_yet_available')}
                          </span>
                          <br />
                        </>
                      )}
                      <span className="font-weight-bold">{t('title.request_price')}</span> <br />
                      {row.status !== 'REQUESTED_BY_BUYER' ? (
                        <div className="mb-2">
                          {currencyFormat(
                            item.product_approved_price,
                            item.product_approved_price_currency,
                          )}
                          <small> / {item.product_unit_type}</small>
                        </div>
                      ) : (
                        <>
                          <span className="text-red font-italic">
                            {t('title.not_yet_available')}
                          </span>
                          <br />
                        </>
                      )}
                    </td>
                    <td></td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

        <hr className="m-0" />
        <div className="row mb-2">
          <div className="col-xs-12 col-md-6">
            <table className="table table-borderless m-2">
              <tbody>
                <tr>
                  <td className="col-xs-6 p-0">{t('title.subtotal_product')}</td>
                  <td className="p-0">
                    {row.status !== 'REQUESTED_BY_BUYER' ? (
                      <div className="font-weight-bold">
                        {currencyFormat(subtotal, items[0]?.product_request_price_currency)}
                      </div>
                    ) : (
                      <>
                        <span className="text-red font-italic">{t('title.not_yet_available')}</span>
                      </>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="p-0">{t('title.delivery_cost')}</td>
                  <td className="p-0">
                    {row.status !== 'REQUESTED_BY_BUYER' ? (
                      <div className="font-weight-bold">
                        {currencyFormat(row.delivery_cost, row.delivery_cost_currency)}
                      </div>
                    ) : (
                      <>
                        <span className="text-red font-italic">{t('title.not_yet_available')}</span>
                      </>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="p-0">{t('title.delivery_insurance_cost')}</td>
                  <td className="p-0">
                    {row.status !== 'REQUESTED_BY_BUYER' ? (
                      <div className="font-weight-bold">
                        {currencyFormat(
                          row.delivery_insurance_cost,
                          row.delivery_insurance_currency,
                        )}
                      </div>
                    ) : (
                      <>
                        <span className="text-red font-italic">{t('title.not_yet_available')}</span>
                      </>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xs-12 col-md-6 d-flex text-center justify-content-center justify-content-md-end mb-2">
            <div className="mt-2">
              <span className="font-size-16 font-weight-bold text-grey">
                {t('title.total_offer')}
              </span>
              <br />
              {row.status !== 'REQUESTED_BY_BUYER' ? (
                <>
                  <span className="font-size-20 font-weight-bold text-dark">
                    {currencyFormat(
                      subtotal + row.delivery_cost + row.delivery_insurance_cost,
                      items[0]?.product_request_price_currency,
                    )}
                  </span>
                </>
              ) : (
                <>
                  <span className="text-red font-italic">{t('title.not_yet_available')}</span>
                  <br />
                </>
              )}
            </div>
          </div>
        </div>
      </>
    )
  }
  const CardFooter = (data) => {
    const row = data.data
    return (
      <>
        <hr className="m-0" />
        <div className="row">
          <div className="col-12 mt-2 mb-2 d-flex flex-column flex-md-row justify-content-center justify-content-md-end">
            <div
              onClick={() => handleDownloadPdf(row)}
              className="d-inline btn btn-warning btn-xs m-2 font-size-10 pointer"
            >
              <i className="far fa-file"></i> {t('button.download_pdf')}
            </div>

            {['REQUESTED_BY_BUYER', 'OFFER_BY_SELLER', 'APPROVED_BY_BUYER'].includes(
              row.status,
            ) && (
              <Link
                to={'#'}
                onClick={() => {
                  window.location = `/chat?rfq=${row.uid}&seller=${row.store_uid}`
                }}
                className="d-inline btn btn-red btn-xs m-2 font-size-10"
              >
                <i className="fa fa-comments"></i> {t('button.chat_seller')}
              </Link>
            )}
            {['REQUESTED_BY_BUYER'].includes(row.status) && (
              <>
                {row.delivery_type === 'SHIP_BY_SELLER' && (
                  <div
                    onClick={() => {
                      setQuotationItem(row)
                      handleModal('RFQ')
                    }}
                    className="d-inline btn btn-dark border border-gray-6 btn-xs m-2 font-size-10 pointer"
                  >
                    <i className="fa fa-truck"></i> {t('title.address_edit')}
                  </div>
                )}
                <div
                  onClick={() => handleQuotationUpdate(row, 'CANCELLED_BY_BUYER')}
                  className="d-inline btn btn-light border border-gray-6 btn-xs m-2 font-size-10 pointer"
                >
                  <i className="fa fa-times"></i> {t('button.cancel')}
                </div>
              </>
            )}
            {['OFFER_BY_SELLER'].includes(row.status) && (
              <>
                <div
                  onClick={() => handleQuotationUpdate(row, 'REJECTED_BY_BUYER')}
                  className="d-inline btn btn-dark text-white btn-xs m-2 font-size-10 pointer"
                >
                  <i className="fa fa-ban"></i> {t('button.reject')}
                </div>
                <Link
                  to="#"
                  onClick={() => handleQuotationUpdate(row, 'APPROVED_BY_BUYER')}
                  className="d-inline btn btn-primary btn-xs m-2 font-size-10 pointer"
                >
                  <i className="fa fa-check"></i> {t('button.approve')}
                </Link>
              </>
            )}
            {['APPROVED_BY_BUYER'].includes(row.status) && (
              <Link to={'/order'} className="d-inline btn btn-primary btn-xs m-2 font-size-10">
                <i className="fa fa-eye"></i> {t('button.view_order')}
              </Link>
            )}
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Modal
        open={modal.rfq}
        onClose={handleModalClose}
        classNames={{
          modal: 'col-10',
          modalContainer: 'row justify-content-center',
        }}
      >
        <QuotationChangeAddress data={quotationItem} handleModal={handleModal} />
      </Modal>
      <Modal
        open={modal.address}
        onClose={() => {
          handleModal('RFQ')
        }}
      >
        <AddressForm
          initial={'cart'}
          datas={addressInitial}
          onCallback={() => {
            handleModal('RFQ')
          }}
        />
      </Modal>

      <Modal
        open={isModalRejected}
        onClose={() => setIsModalRejected(false)}
        classNames={{
          modal: 'w-100 w-md-35 h-auto',
          modalContainer: 'row justify-content-center align-items-center',
        }}
      >
        <WirecardCancelForm
          data={payloadRejected}
          handleSubmit={handleRejectedRfq}
          handleModal={() => setIsModalRejected(false)}
        />
      </Modal>
      {/* FILTER */}
      <WideCardFilter
        filter={filter}
        payload={payload}
        handleFilter={handleFilter}
        label_input={t('placeholder.rfq_filter')}
      />
      {loading || loadingRequest ? (
        <div className="d-flex justify-content-center">
          <Loading />
        </div>
      ) : (
        <>
          {datacard.items?.length > 0 ? (
            <>
              {datacard.items.map((row, key) => {
                return (
                  <div key={key}>
                    <div className="border border-width-2 border-color-3 borders-radius-6 container wide-card rounded mb-5">
                      <CardHeader data={row} />
                      <div className="row">
                        <div className="col-12">
                          <CardBody data={row} />
                        </div>
                      </div>
                      {!['CANCELLED_BY_BUYER', 'REJECTED_BY_SELLER'].includes(row.status) && (
                        <CardFooter data={row} />
                      )}
                    </div>
                  </div>
                )
              })}
              <div className="container">
                <Pagination
                  data={{ range: 3, ...datacard.pages }}
                  onChangePage={handlePageChange}
                />
              </div>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">{t('message.data_not_found')}</div>
            </>
          )}
        </>
      )}
    </>
  )
}

export default WideCardQuotationRequest
