import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ApiService from '../../../services/ApiService'
import { removeCookies } from '../../../utility/Cookies'
import { showAlert } from '../../../utility/SweetAlert'

const InteractorQuotationCartCreate = () => {
  const { t } = useTranslation()
  const cartCreate = useCallback(
    (payload, loading, reload) => {
      ApiService.jsonRequest('/rfq-cart/create', payload, (response) => {
        if (response.status_code === 200) {
          removeCookies('summary-header')
          showAlert({
            icon: 'success',
            message: t('response_message.add_to_cart_success'),
            timer: 2000,
            reload: reload ?? true,
          })
        } else {
          showAlert({
            icon: 'warning',
            message: t('response_message.add_to_cart_failed'),
            timer: 2000,
            reload: reload ?? true,
          })
        }
        loading(false)
      })
    },
    [t],
  )
  return { cartCreate }
}

export default InteractorQuotationCartCreate
