import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Buffer } from 'buffer'
import { getCookies, setCookies } from '../../utility/Cookies'
import { encryptCrypto } from '../../utility/Encryption'

const Chat = () => {
  const { account } = useSelector((state) => state.account)
  const queryParams = new URLSearchParams(window.location.search)
  const [chatPayload] = useState({
    chat_target: queryParams.get('seller')
      ? 'SOC'
      : queryParams.get('target') === 'CS'
      ? 'COCS'
      : null,
    chat_from_uid: account.uid,
    chat_from_role: 'CUSTOMER',
    store_uid: queryParams.get('seller'),
    customer_account_uid: null,
    team_account_uid: queryParams.get('target') === 'CS' ? '0' : null,
    product_uid: queryParams.get('product') ?? null,
    quotation_uid: queryParams.get('rfq') ?? null,
    transaction_uid: queryParams.get('order') ?? null,
  })
  useEffect(() => {
    // DATA ACCESS
    if (getCookies('_wchat')) {
      setCookies({
        key: '_wchat',
        value: encryptCrypto(
          JSON.stringify({
            reference_uid: account.uid,
            role: 'CUSTOMER',
            full_name: account.full_name,
            profile_picture: account.profile_picture ?? '',
            notification_token: null,
          }),
        ),
        expires: new Date().getTime() + 8 * 3600 * 1000, // 8 Hours
      })
    }
    // DATA CONVERSATION
    let url = process.env.REACT_APP_DOMAIN_CHAT
    if (
      chatPayload.team_account_uid ||
      chatPayload.product_uid ||
      chatPayload.store_uid ||
      chatPayload.quotation_uid ||
      chatPayload.transaction_uid
    ) {
      Object.keys(chatPayload).forEach((key) => {
        if (chatPayload[key] === null || chatPayload[key] === undefined) {
          delete chatPayload[key]
        }
      })
      const payload = Buffer.from(JSON.stringify(chatPayload)).toString('base64')
      url += '/conversation/start?uid=' + payload
    }

    // NOTIFICATION TOKEN REMOVE
    const cookiesAccount = { ...account }
    cookiesAccount.notification_token = null
    setCookies({
      key: '_wsso',
      value: encryptCrypto(JSON.stringify(cookiesAccount)),
      expires: new Date().getTime() + 8 * 3600 * 1000, // 8 Hours
    })
    // NOTIFICATION TOKEN REMOVE

    window.location.href = url
  }, [
    account,
    account.full_name,
    account.notification_token,
    account.profile_picture,
    account.uid,
    chatPayload,
  ])

  return null
}

export default Chat
