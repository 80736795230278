import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { InteractorCategoryMain } from '../../interactors/Main'
import MenuCategorySubMain from './MenuCategorySubMain'

const MenuCategory = ({ setMenuCallback }) => {
  const { t } = useTranslation()
  const { categoryMainSummary } = InteractorCategoryMain()
  const [masterCategory, setMasterCategory] = useState([])
  useEffect(() => {
    categoryMainSummary(setMasterCategory)
  }, [categoryMainSummary])
  return (
    <>
      <ul id="headerSidebarList" className="u-header-collapse__nav">
        <li className="">
          <Link
            className="u-header-collapse__nav-link font-weight-bold"
            to="/"
            onClick={() => setMenuCallback(false)}
          >
            {t('menu.home')}
          </Link>
        </li>
        <li className="d-xl-none">
          <Link
            className="u-header-collapse__nav-link font-weight-bold"
            to="#"
            onClick={() => {
              window.location.href = process.env.REACT_APP_DOMAIN_SELLER
            }}
          >
            {t('menu.seller_center')}
          </Link>
        </li>
        {masterCategory?.map((main, indexMain) => (
          <MenuCategorySubMain
            categories={main}
            key={indexMain}
            setMenuCallback={setMenuCallback}
          />
        ))}
      </ul>
    </>
  )
}

MenuCategory.propTypes = {
  setMenuCallback: PropTypes.any,
}

export default MenuCategory
