import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import HeaderHome from './HeaderHome'
import HeaderMain from './HeaderMain'
import HeaderTopBar from './HeaderTopBar'
import { getCookies } from '../../utility/Cookies'
import { InteractorSummaryHeader } from '../../interactors/Main'

const Header = () => {
  const { pathname } = useLocation()
  const { account } = useSelector((state) => state.account)
  const { summaryHeader } = InteractorSummaryHeader()

  useEffect(() => {
    if (account && getCookies('_wide') && !getCookies('summary-header')) {
      summaryHeader()
    }
  }, [account, summaryHeader])

  return (
    <>
      <HeaderTopBar /> {pathname === '/' ? <HeaderHome /> : <HeaderMain />}
    </>
  )
}

export default Header
