import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import SidebarLeft from './SidebarLeft'
import MenuCategory from '../menu/MenuCategory'

const SidebarCategory = (props) => {
  const [openToggle, setOpenToggle] = useState(true)
  const [openSidebar, setOpenSidebar] = useState(false)
  const sidebarRef = useRef()
  const handleStatusSidebar = () => {
    openToggle ? setOpenToggle(false) : setOpenToggle(true)
    handleToggle(openToggle)
  }
  const handleToggle = (value) => setOpenSidebar(value)

  useEffect(() => {
    const handleCloseOutside = (e) => {
      if (sidebarRef && !sidebarRef.current.contains(e.target)) {
        setOpenSidebar(false)
        setOpenToggle(!openSidebar)
      }
    }
    document.addEventListener('mousedown', handleCloseOutside)

    return () => document.removeEventListener('mousedown', handleCloseOutside)
  }, [openSidebar])
  return (
    <>
      <div>
        <div
          id="sidebarHeaderInvokerMenu"
          type="button"
          className="navbar-toggler d-block btn u-hamburger px-1 z-index-2"
          data-clicked={openToggle}
          onClick={handleStatusSidebar}
        >
          {props.children}
        </div>
      </div>
      <SidebarLeft menu={openSidebar} setMenuCallback={handleToggle} sidebarRef={sidebarRef}>
        <MenuCategory setMenuCallback={handleToggle} />
      </SidebarLeft>
    </>
  )
}

SidebarCategory.propTypes = {
  children: PropTypes.any,
  content: PropTypes.any,
}

export default SidebarCategory
