import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'

const InteractorUpdateImage = () => {
  const uploadImage = useCallback((payload, callback) => {
    ApiService.formRequest('/storage-file/upload', payload, (response) => {
      if (response.status_code === 200) {
        callback(response.data[0].url)
      }
    })
  }, [])
  return { uploadImage }
}

export default InteractorUpdateImage
