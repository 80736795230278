import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getLanguage } from '../../utility/Language'
import { setItem } from '../../utility/LocalStorage'
import { useSelector } from 'react-redux'
import useHover from '../../hooks/useHover'

const HeaderTopBar = () => {
  const { t } = useTranslation()
  const { account } = useSelector((state) => state.account)
  const { header_info } = useSelector((state) => state.summaryHeader)
  const [currentLanguage, setcurrentLanguage] = useState({})
  const langRef = useRef(null)
  const [isOpen, handleOpen] = useHover(langRef)
  const { onMouseOver, onMouseLeave } = handleOpen
  const [isLogin, setIsLogin] = useState(false)
  const setLanguage = (lang) => {
    setItem('language', lang)
    window.location.reload(false)
  }
  const sellerHandler = () => {
    window.location.href = process.env.REACT_APP_DOMAIN_SELLER
  }
  useEffect(() => {
    if (account) {
      setIsLogin(true)
    }
    setcurrentLanguage(getLanguage)
  }, [account])

  return (
    <div className="u-header-topbar py-2 d-none d-xl-block" onMouseLeave={onMouseLeave}>
      <div className="container h-100">
        <div className="d-flex align-items-center">
          <div className="topbar-left">
            <Link
              to={'#'}
              onClick={sellerHandler}
              className="font-weight-bold text-red font-size-13 hover-on-dark"
            >
              <i className="fas fa-store mr-1"></i> {t('menu.seller_center')}
            </Link>
          </div>
          <div className="topbar-right ml-auto">
            <ul className="list-inline mb-0">
              {account && (
                <>
                  <li className="list-inline-item mr-0 u-header-topbar__nav-item u-header-topbar__nav-item-border">
                    <Link to="/notification" className="text-gray-70 font-size-13">
                      <i className="fas fa-bell mr-1" /> {t('menu.notification')}
                      {header_info.notification_unread > 0 && (
                        <span className="ml-1 text-white badge rounded-pill bg-danger">
                          {header_info.notification_unread > 99
                            ? '99+'
                            : header_info.notification_unread}
                        </span>
                      )}
                    </Link>
                  </li>
                </>
              )}
              <li className="list-inline-item mr-0 u-header-topbar__nav-item u-header-topbar__nav-item-border">
                <Link to="/page/help" className="text-gray-70 font-size-13">
                  <i className="fas fa-question-circle mr-1" /> {t('menu.help')}
                </Link>
              </li>
              <li
                className="list-inline-item mr-0 u-header-topbar__nav-item u-header-topbar__nav-item-border u-header-topbar__nav-item-no-border u-header-topbar__nav-item-border-single pointer"
                onMouseLeave={onMouseLeave}
              >
                <div className="d-flex align-items-center">
                  <div className="position-relative">
                    <div
                      id="languageDropdownInvoker"
                      className={`dropdown-nav-link dropdown-toggle d-flex align-items-center u-header-topbar__nav-link font-weight-normal ${
                        isOpen ? 'active' : ''
                      }`}
                      aria-expanded={isOpen}
                      onMouseOver={onMouseOver}
                      onMouseLeave={onMouseLeave}
                    >
                      <span className="d-inline-block d-sm-none">{currentLanguage.id}</span>
                      <span className="d-none d-sm-inline-flex align-items-center">
                        {currentLanguage.title}
                      </span>
                    </div>
                    {isOpen && (
                      <div
                        id="languageDropdown"
                        ref={langRef}
                        className={`dropdown-menu dropdown-unfold u-unfold--css-animation fadeInUp`}
                        style={{ animationDuration: '500ms' }}
                        aria-labelledby="languageDropdownInvoker"
                        onMouseLeave={onMouseLeave}
                      >
                        <Link
                          className="dropdown-item active"
                          to="#"
                          onClick={() => {
                            setLanguage('id')
                          }}
                        >
                          {t('menu.language_id')}
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="#"
                          onClick={() => {
                            setLanguage('en')
                          }}
                        >
                          {t('menu.language_en')}
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </li>
              {!isLogin ? (
                <>
                  <li className="list-inline-item mr-0 u-header-topbar__nav-item u-header-topbar__nav-item-border">
                    <Link
                      to="/account/register"
                      className="text-gray-70 font-size-13 font-weight-bold"
                    >
                      <i className="fas fa-user-plus mr-1"></i> {t('menu.register_account')}
                    </Link>
                  </li>
                  <li className="list-inline-item mr-0 u-header-topbar__nav-item u-header-topbar__nav-item-border">
                    <Link
                      to="/account/signin"
                      className="text-gray-70 font-size-13 font-weight-bold"
                    >
                      <i className="fas fa-user mr-1"></i> {t('menu.signin')}
                    </Link>
                  </li>
                </>
              ) : (
                <li className="list-inline-item mr-0 u-header-topbar__nav-item u-header-topbar__nav-item-border">
                  <Link
                    to="/account/profile"
                    className="text-gray-70 font-size-13 font-weight-bold"
                  >
                    <i className="fas fa-user mr-1"></i> {t('menu.my_account')}
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
export default HeaderTopBar
