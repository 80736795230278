import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'

const customInputSelect = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#ffffff' : '#000000',
    backgroundColor: state.isSelected ? '#2948FF' : '#ffffff',
  }),
  control: (base, state) => ({
    ...base,
    width: state.selectProps.width,
    borderRadius: '8px',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: '#ADB2B0',
    paddingTop: 0,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  clearIndicator: (base) => ({
    ...base,
    paddingTop: 0,
  }),
}

const InputSelect = (data) => {
  const { t } = useTranslation()
  const items = data.items
  const defaultSelectData = useMemo(() => {
    return data.items?.filter((value) => value.default === true)
  }, [data.items])

  const handleChangeValue = (e, action) => {
    let value = null
    if (e) value = e.value
    data.onChange(value, action.name)

    if (!data.validateFunc) return

    let message = null
    if (!value && data.isRequired) {
      message = `${data.title} ${t('alert.is_required')}`
    }
    data.validateFunc(message, action.name)
  }

  const inputProps = {
    styles: data.styles || customInputSelect,
    isClearable: data.isClearable || true,
    placeholder: data.placeholder || t('field_input.select'),
    title: data.title,
    name: data.name,
    autofocus: data.autoFocus || true,
    ref: data.ref,
    width: data.width || 230,
  }

  useEffect(() => {})
  return (
    <>
      <Select
        {...inputProps}
        options={items}
        onChange={handleChangeValue}
        defaultValue={defaultSelectData}
      />
      {data.errorMsg && (
        <div className="text-danger font-size-9">
          {data.errorMsg === true
            ? `${t('alert.input_select_alert')} ${data.title}.`
            : data.errorMsg}
        </div>
      )}
    </>
  )
}

export default InputSelect
