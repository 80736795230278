import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ImageModal, Loading, WirecardCancelForm } from '../Main'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import {
  numberFormat,
  currencyFormat,
  formatDate,
  prettyUrl,
  numberFormatAPI,
  checkValidationFile,
} from '../../utility/Helpers'
import {
  InteractorOrderUpdate,
  InteractorDownloadPdf,
  InteractorUpdateImage,
  InteractorBillUpdate,
} from '../../interactors/Main'
import { Modal } from 'react-responsive-modal'
import pdfLogo from '../../assets/image/icons/pdfLogo.png'
import { OrderPurchaseNumberForm } from '../../containers/Main'
import { FindFileType } from '../../utility/Helpers'
import { confirmationAlert, showAlert } from '../../utility/SweetAlert'
import Compressor from 'compressorjs'

const WideCardOrder = (data) => {
  const { loading, datacard } = data
  const { t } = useTranslation()
  const { account } = useSelector((state) => state.account)
  const location = useLocation()
  const { uploadImage } = InteractorUpdateImage()
  const { billUpdate } = InteractorBillUpdate()
  const { orderUpdate } = InteractorOrderUpdate()
  const { getInvoice, getTransaction } = InteractorDownloadPdf()
  const [modalPrOpen, setModalPrOpen] = useState(false)
  const [modalPoOpen, setModalPoOpen] = useState(false)
  const [error, setError] = useState(null)
  const [isModalRejected, setIsModalRejected] = useState(false)
  const [payloadRejected, setPayloadRejected] = useState(null)

  const handleModalPr = () => {
    setModalPrOpen(false)
  }
  const handleModalPo = () => {
    setModalPoOpen(false)
  }

  const handleUploadFile = (event, billData) => {
    const { files, name } = event.target
    const redirectLink = location.pathname
    let file = files[0]
    let isValidImage = checkValidationFile(file.type, file.size)

    if (!isValidImage) {
      setError((prev) => ({ ...prev, [name]: t('message.invalid_picture') }))
      return
    }
    if (file.type === 'application/pdf') {
      const payload = {
        type: 'PUBLIC',
        category: 'ORDER_PROOF_PAYMENT',
        file: new File([file], `${Date.now().toString()}.${file.name.split('.').pop()}`, {
          type: file.name.split('.').pop(),
        }),
        reference_uid: account.uid,
        updated_by: account.full_name,
      }
      uploadImage(payload, (response) => {
        billUpdate(
          {
            ...billData,
            file_proof_payment: response,
            status: 'PENDING_APPROVAL',
            updated_by: account.full_name,
          },
          redirectLink,
        )
      })
    } else {
      new Compressor(file, {
        quality: 0.8,
        width: 300,
        height: 300,
        convertSize: 200000,
        success: async (compressedResult) => {
          const payload = {
            type: 'PUBLIC',
            category: 'ORDER_PROOF_PAYMENT',
            file: new File(
              [compressedResult],
              `${Date.now().toString()}.${compressedResult.name.split('.').pop()}`,
              {
                type: compressedResult.name.split('.').pop(),
              },
            ),
            reference_uid: account.uid,
            updated_by: account.full_name,
          }
          uploadImage(payload, (response) => {
            billUpdate(
              {
                ...billData,
                file_proof_payment: response,
                status: 'PENDING_APPROVAL',
                updated_by: account.full_name,
              },
              redirectLink,
            )
          })
        },
      })
    }
    setError((prev) => ({ ...prev, [name]: null }))
  }

  const handleTransactionUpdate = async (row, status) => {
    let payloadUpdate = { ...row }
    payloadUpdate.status = status
    payloadUpdate.updated_by = account.full_name
    payloadUpdate.delivery_estimated_time = formatDate(
      row.delivery_estimated_time,
      'YYYY-MM-DD HH:mm:ss',
    )
    if (row?.payment_term_date) {
      payloadUpdate.payment_term_date = formatDate(
        new Date(row.payment_term_date),
        'YYYY-MM-DD HH:mm:ss',
      )
    }
    if (row.payment_status === 'UNPAID' && status === 'COMPLETED') {
      showAlert({
        icon: 'warning',
        message: t('message.disallow_order_completed'),
        reload: false,
      })
    } else {
      if (status === 'COMPLETED') {
        orderUpdate(payloadUpdate)
      } else {
        setIsModalRejected(true)
        setPayloadRejected(payloadUpdate)
      }
    }
  }

  const handleOrderRejected = async (value) => {
    const reject = await confirmationAlert({
      title: t('title.are_you_sure'),
      message: t('message.confirmation_order_cancel'),
      icon: 'warning',
    })
    if (reject) {
      orderUpdate(value, 'REJECTED')
    }
  }

  const handleDownloadPdf = (data) => {
    getTransaction(data)
  }

  const handleDownloadInvoice = (row) => {
    getInvoice(row)
  }

  // CARD AREA
  const CardHeader = (data) => {
    const row = data.data
    return (
      <>
        <div className="row">
          <div className="col-8 mt-2">
            <span className="d-inline-block p-2">
              <small>{t('title.order_number')}</small> <br />
              <strong className="font-size-16">{row.transaction_code}</strong>
            </span>
            <div
              role="button"
              onClick={() => navigator.clipboard.writeText(row?.transaction_code)}
              className="ml-2 fa fa-copy btn-link border-0 bg-transparent pointer"
            ></div>
          </div>
          <div className="col-4 mt-1 d-flex justify-content-end">
            <span className="d-inline-block p-2">
              <small>{t('title.status_transaction')}: </small> <br />
              <span
                className={`font-weight-bold font-size-16 badge badge-bigger ${
                  ['WAITING_PAYMENT', 'PROCESSED', 'SHIPPED'].includes(row.status)
                    ? 'badge-pending'
                    : ''
                }${['DELIVERED', 'COMPLETED'].includes(row.status) ? 'badge-success' : ''}${
                  ['CANCELLED', 'REFUND'].includes(row.status) ? 'badge-reject' : ''
                }`}
              >
                {t(`initial_status.${row.status}`)}
              </span>
            </span>
          </div>
        </div>
        <hr className="m-0" />
      </>
    )
  }

  const CardBody = (data) => {
    const row = data.data
    const bill = JSON.parse(row.bill)
    const items = row.transaction_items
    let subtotal = 0
    let subweight = 0
    return (
      <>
        <InformationTransaction data={row} />
        <hr className="m-0" />
        <div className="row my-3">
          <div className="col-12 my-2">
            <strong className="font-size-18 p-2">{t(`title.bill_list`)}</strong>
          </div>
        </div>
        <table className="table table-responsive table-borderless table-scrollable">
          <thead className="border-bottom">
            <tr>
              <th className="col-2">{t('title.bill.invoice_code')}</th>
              <th className="col-2">{t('title.bill.due_date')}</th>
              <th className="col-2">{t('title.bill.total_amount')}</th>
              <th className="col-2">{t('title.bill.status')}</th>
              <th className="col-2">{t('title.bill.payment_method')}</th>
              <th className="col-2">{t('title.bill.bill_file')}</th>
              <th className="col-2">{t('title.bill.tax_invoice_file')}</th>
              {bill[0].payment_method === 'PAYMENT_GATEWAY' ? (
                <th className="col-2">#</th>
              ) : (
                <th className="col-2">{t('title.bill.transfer_evidence_file')}</th>
              )}
            </tr>
          </thead>
          <tbody>
            <OrderInstallment data={bill} header={row} />
          </tbody>
        </table>
        <hr className="m-0" />
        <table className="table table-responsive table-borderless my-3">
          <thead className="border-bottom">
            <tr>
              <th className="col-4">{t('title.product.title')}</th>
              <th className="col-2">{t('title.product.price')}</th>
              <th className="col-2">{t('title.product.qty')}</th>
              <th className="col-2">{t('title.product.weight_total')}</th>
              <th className="col-2">{t('title.product.price_total')}</th>
              <th className=""></th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, key) => {
              subtotal += item.product_quantity * item.product_price
              subweight += item.product_weight * item.product_quantity
              return (
                <tr key={key}>
                  <td className="col d-flex flex-column flex-md-row">
                    <div className="col-8 col-md-3 min-width-60 px-0">
                      <Link
                        to={`/product/detail/${
                          prettyUrl(item.product_title) + '/' + item.product_uid
                        }`}
                      >
                        <img src={item.product_thumbnail} className="img-fluid" alt="" />
                      </Link>
                    </div>
                    <div className="col-12 col-md-9 px-0 px-md-3">
                      <Link
                        to={`/product/detail/${
                          prettyUrl(item.product_title) + '/' + item.product_uid
                        }`}
                        className="text-black"
                      >
                        <span>
                          <strong>{item.product_title}</strong>
                        </span>
                      </Link>
                    </div>
                  </td>
                  <td>
                    {currencyFormat(item.product_price, item.product_price_currency)} /{' '}
                    <small>{item.product_unit_type}</small>
                  </td>
                  <td>{numberFormat(item.product_quantity)}</td>
                  <td>
                    {`${numberFormatAPI(item.product_weight * item.product_quantity, {
                      leadingZeroAllowed: true,
                      zeroDecimal: true,
                      amountZeroDecimal: 2,
                    })} ${t('title.product.weight_kg')}`}
                  </td>
                  <td>
                    {currencyFormat(
                      item.product_price * item.product_quantity,
                      item.product_price_currency,
                    )}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
        <hr className="m-0" />
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <table className="table table-borderless m-2">
              <tbody>
                <tr>
                  <td className="col-xs-6 p-0">{t('title.subtotal_product')}</td>
                  <td className="p-0">
                    <div className="font-weight-bold">
                      {currencyFormat(subtotal, items[0]?.product_price_currency)}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="col-xs-6 p-0">{t('title.subtotal_weight')}</td>
                  <td className="p-0">
                    <div className="font-weight-bold">{`${numberFormatAPI(subweight, {
                      leadingZeroAllowed: true,
                      zeroDecimal: true,
                      amountZeroDecimal: 2,
                    })} ${t('title.product.weight_kg')}`}</div>
                  </td>
                </tr>
                <tr>
                  <td className="p-0">{t('title.delivery_cost')}</td>
                  <td className="p-0">
                    <div className="font-weight-bold">
                      {currencyFormat(row.delivery_cost, row.delivery_cost_currency)}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="p-0">{t('title.delivery_insurance_cost')}</td>
                  <td className="p-0">
                    <div className="font-weight-bold">
                      {currencyFormat(row.delivery_insurance_cost, row.delivery_insurance_currency)}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xs-12 col-md-6 d-flex text-center justify-content-center justify-content-md-end mb-2">
            <div className="mt-2">
              <span className="font-size-16 font-weight-bold text-grey">
                {t('title.payment_total')}
              </span>
              <br />
              <span className="font-size-20 font-weight-bold text-dark">
                {currencyFormat(row.payment_amount, row.payment_amount_currency)}
              </span>
            </div>
          </div>
        </div>
      </>
    )
  }

  const CardFooter = (data) => {
    const row = data.data
    return (
      row && (
        <>
          <hr className="m-0" />
          {/* <AlertBill row={row} /> */}
          <div className="row overflow-auto">
            <div className="col-12 mt-2 mb-2 d-flex flex-column flex-md-row justify-content-center justify-content-md-end">
              <div
                onClick={() => handleDownloadPdf(row)}
                className="d-inline btn btn-warning btn-xs m-2 font-size-10 pointer"
              >
                <i className="far fa-file"></i> {t('button.download_pdf')}
              </div>
              {!['CANCELLED', 'REFUND'].includes(row.status) && (
                <Link
                  to={'#'}
                  onClick={() => {
                    window.location = `/chat?order=${row.uid}&seller=${row.store_uid}`
                  }}
                  className="d-inline btn btn-red btn-xs m-2 font-size-10"
                >
                  <i className="fa fa-comments"></i> {t('button.chat_seller')}
                </Link>
              )}
              {row.status === 'WAITING_PAYMENT' &&
                row.payment_type === 'FULL_PAYMENT' &&
                row.payment_status === 'UNPAID' && (
                  <>
                    <div
                      onClick={() => handleTransactionUpdate(row, 'CANCELLED')}
                      className="d-inline btn btn-dark btn-xs m-2 font-size-10 pointer"
                    >
                      <i className="fa fa-ban"></i> {t('button.cancelled')}
                    </div>
                  </>
                )}
              {['DELIVERED'].includes(row.status) && (
                <>
                  <Link
                    to={'#'}
                    onClick={() => handleTransactionUpdate(row, 'COMPLETED')}
                    className="d-inline btn btn-success btn-xs m-2 font-size-10"
                  >
                    <i className="fa fa-check"></i> {t('button.finish_order')}
                  </Link>
                </>
              )}
            </div>
          </div>
        </>
      )
    )
  }

  const InformationTransaction = (data) => {
    const row = data.data
    return (
      <div className="row my-3 text-break">
        <div className="col-12 my-2">
          <strong className="font-size-18 p-2">{t(`title.order_information`)}</strong>
        </div>
        <div className="col-xs-12 col-md-3">
          <div className="p-2">
            <small>{t(`title.purchase_request_number`)}</small> <br />
            <strong>{row.purchase_request_number ? row.purchase_request_number : '-'}</strong>
            {!['CANCELLED', 'COMPLETED', 'REFUND'].includes(row.status) && (
              <div
                role="button"
                onClick={() => {
                  setModalPrOpen(true)
                }}
                className="ml-2 fas fa-edit btn-link border-0 bg-transparent pointer"
              ></div>
            )}
            <Modal open={modalPrOpen} onClose={handleModalPr}>
              <OrderPurchaseNumberForm
                data={row}
                handleModal={handleModalPr}
                initialPurchaseNumber="PR"
              />
            </Modal>
          </div>
        </div>
        <div className="col-xs-12 col-md-3">
          <div className="p-2">
            <small>{t(`title.purchase_order_number`)}</small> <br />
            <strong>{row.purchase_order_number ? row.purchase_order_number : '-'}</strong>
            {!['CANCELLED', 'COMPLETED', 'REFUND'].includes(row.status) && (
              <div
                role="button"
                onClick={() => {
                  setModalPoOpen(true)
                }}
                className="ml-2 fas fa-edit btn-link border-0 bg-transparent pointer"
              ></div>
            )}
            <Modal open={modalPoOpen} onClose={handleModalPo}>
              <OrderPurchaseNumberForm
                data={row}
                handleModal={handleModalPo}
                initialPurchaseNumber="PO"
              />
            </Modal>
          </div>
        </div>
        <div className="col-xs-12 col-md-3">
          <div className="p-2">
            <small>{t(`title.seller_name`)}</small>
            <br />
            <strong>{row.store_name}</strong>
          </div>
        </div>
        <div className="col-xs-12 col-md-3">
          <div className="p-2">
            <small>{t(`title.ship_consignee`)}</small>
            <br />
            <strong>{`${row.consignee_full_name} (${row.consignee_phone_number})`}</strong>
          </div>
        </div>
        <div className="col-xs-12 col-md-3">
          <div className="p-2">
            <small>{t(`title.order_date`)}</small>
            <br />
            <strong>{formatDate(row.created_date, 'DD/MM/YYYY HH:mm')}</strong>
          </div>
        </div>
        <div className="col-xs-12 col-md-3">
          <div className="p-2">
            <small>{t(`title.payment_status`)}</small>
            <br />
            <strong>{t(`title.${row.payment_status.toLowerCase()}`)}</strong>
          </div>
        </div>
        <div className="col-xs-12 col-md-3">
          <div className="p-2">
            <small>{t(`invoice.payment_method`)}</small>
            <br />
            <strong>{t(`initial_status.${row.payment_type}`)}</strong>
          </div>
        </div>
        <div className="col-xs-12 col-md-3">
          <div className="p-2">
            <small>{t(`title.delivery_type`)}</small>
            <br />
            <strong>{t(`initial_status.${row.delivery_type}`)}</strong>
          </div>
        </div>
        {row.delivery_estimated_time && (
          <div className="col-xs-12 col-md-3">
            <div className="p-2">
              <small>
                {row.delivery_type === 'PICKUP'
                  ? t(`title.delivery_date_pickup`)
                  : t(`title.delivery_date_ship`)}
              </small>
              <br />
              <strong>{formatDate(row.delivery_estimated_time, 'DD/MM/YYYY HH:mm')}</strong>
            </div>
          </div>
        )}
        {/* <div className="col-xs-12 col-md-3">
          <div className="p-2">
            <small>{t(`title.payment_status`)}</small>
            <br />
            <strong>{row.payment_status === 'PAID' ? t(`title.paid`) : t(`title.unpaid`)}</strong>
          </div>
        </div> */}
        {row.status === 'CANCELLED' && (
          <div className="col-xs-12 col-md-3">
            <div className="p-2">
              <small>{t('title.rejected_reason')}</small>
              <br />
              <strong>{row.reason_cancel_reject}</strong>
            </div>
          </div>
        )}
        <div className="col-xs-12 col-md-8">
          <div className="p-2">
            <small>
              {row.delivery_type === 'PICKUP' ? t(`title.pickup_at`) : t(`title.ship_to`)}
            </small>
            <br />
            <strong>{row.destination_address}</strong>
          </div>
        </div>
      </div>
    )
  }

  const OrderInstallment = (bill, header) => {
    return (
      <>
        {bill?.data?.map((data, index) => {
          return (
            <tr key={index}>
              <td>
                {data.invoice_code} <br />
                <small>{data.note}</small>
              </td>
              <td>{formatDate(data.due_date, 'DD/MM/YYYY HH:mm')}</td>
              <td>{currencyFormat(data.total_amount, 'IDR')}</td>
              <td>
                <div className="d-flex align-items-center">
                  <div
                    className={`status-indicator m-2 ${
                      ['ISSUED', 'PENDING_APPROVAL'].includes(data.status) ? 'status-pending' : ''
                    }${['PAID'].includes(data.status) ? 'status-success' : ''}${
                      ['REJECTED', 'CANCELLED'].includes(data.status) ? 'status-reject' : ''
                    }`}
                  ></div>
                  {t(`status.bill.${data.status}`)}
                </div>
              </td>
              <td>{t(`title.payment_method.${data.payment_method.toLowerCase()}`)}</td>
              <td>
                <div className="lightbox">
                  {['PAYMENT_GATEWAY', 'CREDIT_CARD'].includes(data.payment_method) ? (
                    // METHOD VIRTUAL ACCOUNT / CREDIT CARD
                    <>
                      {['ISSUED', 'PROCESSED', 'PAID'].includes(data.status) ? (
                        <div
                          onClick={() => handleDownloadInvoice(data)}
                          className="d-inline btn btn-primary btn-xs m-2 font-size-10 pointer"
                        >
                          <i className="fa fa-download"></i> {t('button.download_invoice')}
                        </div>
                      ) : (
                        <small className="text-grey">{t('title.no_data')}</small>
                      )}
                    </>
                  ) : (
                    // METHOD MANUAL TRANSFER
                    <>
                      {data.file_invoice ? (
                        ['jpg', 'jpeg', 'png'].includes(
                          FindFileType(data.file_invoice.toLowerCase()),
                        ) ? (
                          <ImageModal title={t('title.bill.bill_file')} src={data.file_invoice} />
                        ) : (
                          <a href={data.file_invoice} target={'_blank'} rel="noreferrer" download>
                            <img
                              className="form-picture-image"
                              src={pdfLogo}
                              width="60"
                              height="60"
                              alt=""
                            />
                          </a>
                        )
                      ) : (
                        <small className="text-grey">{t('title.no_data')}</small>
                      )}
                    </>
                  )}
                </div>
              </td>
              <td>
                <div className="lightbox">
                  {data.file_tax ? (
                    ['jpg', 'jpeg', 'png'].includes(FindFileType(data.file_tax.toLowerCase())) ? (
                      <ImageModal title={t('title.bill.bill_file')} src={data.file_tax} />
                    ) : (
                      <a href={data.file_tax} target={'_blank'} rel="noreferrer" download>
                        <img
                          className="form-picture-image"
                          src={pdfLogo}
                          width="60"
                          height="60"
                          alt=""
                        />
                      </a>
                    )
                  ) : (
                    <small className="text-grey">{t('title.no_data')}</small>
                  )}
                </div>
              </td>
              <td>
                {['PAYMENT_GATEWAY', 'CREDIT_CARD'].includes(data.payment_method) ? (
                  <>
                    {['ISSUED'].includes(data.status) &&
                    data.payment_invoice_url !== '' &&
                    bill?.header?.status !== 'CANCELLED' ? (
                      <div
                        onClick={() => {
                          window.location = data.payment_invoice_url
                        }}
                        className="d-inline btn btn-warning btn-xs m-2 font-size-10 pointer"
                      >
                        <i className="far fa-id-card"></i> {t('button.payment_process')}
                      </div>
                    ) : (
                      <>-</>
                    )}
                  </>
                ) : (
                  // METHOD MANUAL TRANSFER
                  <>
                    <td>
                      {data.file_invoice !== '' ? (
                        <div className="d-flex align-items-center">
                          {data.file_proof_payment !== '' &&
                            (['jpg', 'png', 'jpeg'].includes(
                              FindFileType(data.file_proof_payment.toLowerCase()),
                            ) ? (
                              <ImageModal
                                title={t('title.bill.transfer_evidence_file')}
                                src={data.file_proof_payment}
                                border={error?.file_proof_payment}
                              />
                            ) : (
                              <a
                                href={data.file_proof_payment}
                                target={'_blank'}
                                rel="noreferrer"
                                download
                              >
                                <img
                                  className="form-picture-image"
                                  src={pdfLogo}
                                  width="60"
                                  height="60"
                                  alt=""
                                />
                              </a>
                            ))}
                          {data.status !== 'PAID' && header.status !== 'CANCELLED' && (
                            <div
                              className={`btn btn-xs pointer position-relative mt-2 ${
                                data?.file_proof_payment
                                  ? 'font-weight-semi-bold font-size-12 btn-link'
                                  : 'btn-outline-primary'
                              }`}
                            >
                              {data.file_proof_payment !== ''
                                ? t('title.update')
                                : t('button.upload')}
                              <input
                                type="file"
                                className="input-file pointer"
                                onChange={(e) => {
                                  handleUploadFile(e, data)
                                }}
                                name="file_proof_payment"
                                accept="image/jpg,image/jpeg,image/png,.pdf"
                              />
                            </div>
                          )}
                        </div>
                      ) : (
                        <>-</>
                      )}
                    </td>
                  </>
                )}
              </td>
            </tr>
          )
        })}
        <Modal
          open={isModalRejected}
          onClose={() => setIsModalRejected(false)}
          classNames={{
            modal: 'w-100 w-xl-35 h-auto',
            modalContainer: 'row justify-content-center align-items-center',
          }}
        >
          <WirecardCancelForm
            data={payloadRejected}
            handleSubmit={handleOrderRejected}
            handleModal={() => setIsModalRejected(false)}
          />
        </Modal>
      </>
    )
  }

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center">
          <Loading />
        </div>
      ) : (
        <>
          {datacard.items?.length > 0 ? (
            <>
              {datacard.items.map((row, key) => {
                return (
                  <div key={key}>
                    <div className="border border-width-2 border-color-3 borders-radius-6 container wide-card rounded mb-5">
                      <CardHeader data={row} />
                      <div className="row">
                        <div className="col-12">
                          <CardBody data={row} />
                        </div>
                      </div>
                      <CardFooter data={row} />
                    </div>
                  </div>
                )
              })}
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">{t('message.data_not_found')}</div>
            </>
          )}
        </>
      )}
    </>
  )
}

export default WideCardOrder
