import { PRODUCT_ITEM_SEARCH } from './actions'

export const ProductItemSearch = (payload) => {
  return async (dispatch) => {
    dispatch({
      type: PRODUCT_ITEM_SEARCH,
      payload: payload,
    })
  }
}
