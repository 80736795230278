import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { InteractorProductItemSummary } from '../../interactors/Main'
import { currencyFormat, subStringContent, prettyUrl, formatCash } from '../../utility/Helpers'
import { Rating } from '../Main'
import ProductSkeletonGrid from '../skeleton/ProductSkeletonGrid'

const ProductLatest = (data) => {
  const defaultProps = {
    limit: data.limit || 6,
    mode: data.mode || 'grid',
  }
  const { t } = useTranslation()
  const { account } = useSelector((state) => state.account)
  const [loading, setLoading] = useState(true)
  const { productSummary } = InteractorProductItemSummary()
  const [dataProduct, setDataProduct] = useState({})
  const [payload] = useState({
    limit: defaultProps.limit,
    keywords: {
      sort_by: 'latest',
    },
  })

  useEffect(() => {
    if (loading) {
      productSummary(payload, setDataProduct, setLoading)
    }
  }, [loading, payload, productSummary])
  return (
    <>
      <div className=" d-flex justify-content-between border-bottom border-color-1 flex-md-nowrap flex-wrap border-sm-bottom-0">
        <h3 className="section-title text-gray-100 mb-0 pb-2 font-weight-bold font-size-18">
          {t('menu.new_product')}
        </h3>
      </div>
      <div
        className="tab-pane fade pt-2 show active animated slideInUp"
        id="pills-one-product"
        role="tabpanel"
        aria-labelledby="pills-one-product-tab"
        data-target-group="groups"
        style={{ opacity: 1 }}
      >
        {loading ? (
          <ul className="row list-unstyled products-group no-gutters">
            <ProductSkeletonGrid maxBox={6} sizeStyle="col-4 col-md-4 col-lg-2 col-xl-2" />
          </ul>
        ) : (
          <>
            {defaultProps.mode === 'grid' ? (
              <ul className="row list-unstyled products-group no-gutters">
                {dataProduct.items?.map((item, index) => {
                  return (
                    <li key={index} className="col-6 col-md-4 col-xl-2 product-item px-0">
                      <div className="product-item__outer h-100 w-100">
                        <div className="product-item__inner px-1 py-2 w-100">
                          <Link
                            to={`/product/detail/${prettyUrl(item.product_title) + '/' + item.uid}`}
                          >
                            <div className="product-item__body px-2">
                              <div className="col-12 px-0 my-2 height-22">
                                <div className="pointer font-size-12 d-none d-lg-block store-title text-gray-5">
                                  {subStringContent(item.store_name, 25)}
                                </div>
                                <div className="pointer font-size-10-sm d-block d-lg-none store-title text-gray-5">
                                  {subStringContent(item.store_name, 20)}
                                </div>
                              </div>
                              <div>
                                <h4 className="col-12 px-0 mb-3 product-item__title height-33">
                                  <div className="text-blue font-weight-bold pointer">
                                    {subStringContent(item.product_title, 35)}
                                  </div>
                                </h4>
                              </div>
                              <div className="col-12 px-0 px-sm-2 px-md-2 px-lg-2">
                                <div className="d-block mb-2 text-center pointer img-product">
                                  <img className="img-fluid" src={item.product_thumbnail} alt="" />
                                </div>
                              </div>
                              <div className="row mb-1">
                                <div className="col-12">
                                  <div className="product-price text-gray-100 font-weight-bold">
                                    {currencyFormat(
                                      item.product_price,
                                      item.product_price_currency,
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div>
                                <div className="border-top pt-2 mt-2">
                                  <div className="row text-gray-6 pointer text-nowrap">
                                    <div className="col-12 col-lg-6 pr-0">
                                      <Rating rating={item.product_rating} />
                                    </div>
                                    <div className="col-12 col-lg-6 text-left text-xl-right pl-3 pl-xl-0">
                                      {formatCash(item.product_sold)} {t('title.sold')}
                                    </div>
                                  </div>
                                  <div className="row mt-1">
                                    <div className="text-gray-6 flex-nowrap font-size-12 col-12">
                                      {t('title.tax_status')}:
                                      {t(`title.tax_type.${item.store_tax_type}`)}
                                    </div>
                                  </div>
                                  <div className="row mt-1">
                                    <div className="text-gray-6 text-nowrap font-size-12 col-12">
                                      <i className="ec ec-map-pointer"></i>
                                      <span className="d-none d-md-block">
                                        {subStringContent(item.origin_city_name, 28)}
                                      </span>
                                      <span className="d-inline d-md-none">
                                        {subStringContent(item.origin_city_name, 10)}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                          {account?.uid !== item?.store_account_uid && (
                            <div className="product-item__footer px-2">
                              <Link
                                to={`/chat?product=${item.uid}&seller=${item.store_uid}`}
                                className="btn btn-primary btn-xs text-nowrap mt-1 font-weight-bold w-100"
                              >
                                <i className="fa fa-comments mr-2" />
                                {t('button.chat')}
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    </li>
                  )
                })}
              </ul>
            ) : (
              <aside>
                {dataProduct.items?.map((item, index) => (
                  <article key={index} className="py-2 mb-4">
                    <Link to={`/product/detail/${prettyUrl(item.product_title) + '/' + item.uid}`}>
                      <div className="media text-gray-39">
                        <div className="width-75 height-75 mr-3">
                          <img
                            className="img-fluid object-fit-cover"
                            src={item.product_thumbnail}
                            alt={item.product_title}
                          />
                        </div>
                        <div className="media-body">
                          <div className="font-size-12">
                            {subStringContent(item.store_name, 25)}
                          </div>
                          <h4 className="font-size-14 mb-0 text-blue font-weight-bold pointer">
                            {subStringContent(item.product_title, 24)}
                          </h4>
                          <div className="text-gray-6 font-size-14 flex-nowrap">
                            {t('title.tax_status')}: {t(`title.tax_type.${item.store_tax_type}`)}
                          </div>
                          <div className="font-size-14 font-weight-bold">
                            {currencyFormat(item.product_price, item.product_price_currency)}
                          </div>
                          <div className="d-flex">
                            <Rating rating={item.product_rating} />
                            <span className="ml-2">
                              {formatCash(item.product_sold)} {t('title.sold')}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </article>
                ))}
              </aside>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default ProductLatest
