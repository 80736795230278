import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging'

var firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported()
    if (isSupportedBrowser) {
      const firebaseApp = initializeApp(firebaseConfig)
      return getMessaging(firebaseApp)
    }
    return null
  } catch (err) {
    return null
  }
})()

export const requestForToken = async () => {
  try {
    const messagingResolve = await messaging
    const currentToken = await getToken(messagingResolve, {
      vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
    })
    if (currentToken) {
      return currentToken
    }
    return null
  } catch (err) {}
}

export const onMessageListener = async () =>
  new Promise((resolve) =>
    (async () => {
      const messagingResolve = await messaging
      if (messagingResolve) {
        onMessage(messagingResolve, (payload) => {
          resolve(payload)
        })
      }
    })(),
  )
