import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { InteractorContentBannerSearch } from '../../interactors/Main'
const BannerSection = () => {
  const [payload] = useState({
    page: 1,
    limit: 4,
    keywords: {
      text_search: '',
      banner_category: 'FEATURED_CATEGORY',
    },
  })
  const [loading, setLoading] = useState(true)
  const [banner, setBanner] = useState({})
  const { bannerSearch } = InteractorContentBannerSearch()
  const handleResponse = (data) => {
    if (data?.items?.length > 0) {
      setBanner(data.items)
    }
  }
  useEffect(() => {
    if (loading) {
      bannerSearch(payload, handleResponse, setLoading)
    }
  }, [bannerSearch, loading, payload])
  return (
    <>
      <div className="row">
        {banner.length > 0 &&
          banner.map((row, key) => {
            return (
              <React.Fragment key={key}>
                <div className="col-md-6 mb-4 mb-xl-0 col-xl-3">
                  {loading ? (
                    <div className="d-black text-white">
                      <div className="py-1 d-flex align-items-center">
                        <div className="w-100 height-150">
                          <img
                            className="img-fluid bg-img-hero"
                            src={'/assets/img/skeleton/product.png'}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Link to={row.target_link} className="d-black text-white">
                      <div className="py-1 d-flex justify-content-center">
                        <img src={row.media_url} alt="" className="img-fluid bg-img-hero" />
                        {/* <div className="col-6 col-xl-5 col-wd-6 pr-0">
                        </div> */}
                      </div>
                    </Link>
                  )}
                </div>
              </React.Fragment>
            )
          })}
      </div>
    </>
  )
}

export default BannerSection
