import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Breadcrumb, MenuAccount, WideCardOrderDetail } from '../../components/Main'
import { InteractorOrderSearch, InteractorCsvOrder } from '../../interactors/Main'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'

const Order = () => {
  const { t } = useTranslation()
  const queryParams = new URLSearchParams(window.location.search)
  const { account } = useSelector((state) => state.account)
  const location = useLocation()
  const { orderSearch } = InteractorOrderSearch()
  const { csvOrder } = InteractorCsvOrder()
  const [loading, setLoading] = useState(true)
  const [download, setDownload] = useState({
    headers: null,
    items: null,
    filename: null,
  })
  const [datacard, setDatacard] = useState([])
  const [payload] = useState({
    page: 1,
    limit: 5,
    keywords: {
      text_search: queryParams.get('ref') ?? '',
      status: '',
      payment_type: '',
      account_uid: account.uid,
      store_uid: '',
      uid: location.pathname.split('/')[3],
    },
  })

  const handleResponse = useCallback(
    (data) => {
      setDatacard(data)
      csvOrder(data.items, setDownload)
    },
    [csvOrder],
  )

  useEffect(() => {
    if (loading) {
      orderSearch(payload, handleResponse, setLoading)
    }
  }, [handleResponse, loading, payload, orderSearch])

  return (
    <>
      <Breadcrumb title={t('menu.order')} className="mb-4" />
      <div className="container">
        <div className="row mb-8">
          <MenuAccount />
          <div className="col-xl-9 col-wd-9gdot5">
            <div className="flex-center-between mt-2 mt-0-lg">
              <h3 className="font-size-20 font-size-20-lg font-weight-bold mb-0">
                {t('menu.order_view')}
              </h3>
              <Link
                to={'#'}
                onClick={() => {
                  window.location = `/order`
                }}
                className="text-dark btn-outline-secondary btn btn-xs m-2 font-size-12"
              >
                <i className="fas fa-arrow-left px-2"></i> {t('button.back')}
              </Link>
            </div>
            <hr />
            <WideCardOrderDetail
              loading={loading}
              datacard={datacard}
              payload={payload}
              download={download}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Order
