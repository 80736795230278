import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Breadcrumb, MenuAccount, WideCardNotification } from '../../components/Main'
import { InteractorNotificationSearch, InteractorNotificationUpdate } from '../../interactors/Main'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { removeCookies } from '../../utility/Cookies'
import { showAlert } from '../../utility/SweetAlert'

const Notification = () => {
  const { t } = useTranslation()
  const { account } = useSelector((state) => state.account)
  const { header_info } = useSelector((state) => state.summaryHeader)
  const { notificationSearch } = InteractorNotificationSearch()
  const { notificationUpdate } = InteractorNotificationUpdate()
  const [loading, setLoading] = useState(true)
  const [datacard, setDatacard] = useState([])
  const [payload, setPayload] = useState({
    page: 1,
    limit: 10,
    keywords: {
      notification_target: 'CUSTOMER',
      account_uid: account.uid,
    },
  })

  const handleResponse = useCallback((data) => {
    setDatacard(data)
  }, [])

  useEffect(() => {
    if (!datacard.items) {
      notificationSearch(payload, handleResponse, setLoading)
    }
  }, [datacard.items, handleResponse, payload, notificationSearch])

  const handleFilter = (row) => {
    setLoading(true)
    notificationSearch(row, handleResponse, setLoading)
    setPayload(row)
  }

  const handleUnreadAll = () => {
    if (datacard.items.length) {
      const obj = { ...datacard.items[0] }
      obj.uid = '0'
      obj.notification_read = 'Y'
      obj.updated_by = account.full_name
      removeCookies('summary-header')
      notificationUpdate(obj)
      showAlert({
        icon: 'success',
        message: t('message.success_read_all'),
        timer: 3000,
        reload: true,
      })
    }
  }

  return (
    <>
      <Breadcrumb title={t('menu.notification')} className="mb-4" />
      <div className="container">
        <div className="row mb-8">
          <MenuAccount />
          <div className="col-xl-9 col-wd-9gdot5">
            <div className="flex-center-between">
              <h3 className="font-size-25 mb-0">{t('menu.notification')}</h3>
              {header_info.notification_unread > 0 && (
                <Link className="text-danger" to={'#'} onClick={handleUnreadAll}>
                  <h3 className="font-size-14 mb-0">{t('title.mark_read_all')}</h3>
                </Link>
              )}
            </div>
            <hr />
            <WideCardNotification
              loading={loading}
              datacard={datacard}
              payload={payload}
              handleFilter={handleFilter}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Notification
