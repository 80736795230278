import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { reasonRejectedLength } from '../../constant/global/validation'
import { showAlert } from '../../utility/SweetAlert'
import { InputText } from '../Main'

const WirecardCancelForm = (data) => {
  const { t } = useTranslation()
  const [otherReason, setOtherReason] = useState({
    visible: false,
    value: '',
  })
  const [payload, setPayload] = useState(null)

  const reason = useMemo(
    () => [
      { value: t('rejected_message.price'), label: t('rejected_message.price') },
      { value: t('rejected_message.new_offer'), label: t('rejected_message.new_offer') },
      {
        value: t('rejected_message.offer_new_seller'),
        label: t('rejected_message.offer_new_seller'),
      },
      { value: t('rejected_message.cancel_seller'), label: t('rejected_message.cancel_seller') },
    ],
    [t],
  )

  const onSubmit = () => {
    if (!payload || !payload?.reason_cancel_reject) {
      showAlert({
        icon: 'error',
        message: `${t('title.rejected_reason')} ${t('alert.is_required')}`,
        reload: false,
      })
      return
    }
    data.handleSubmit(payload)
    data.handleModal(false)
  }

  const handleChangeRadio = (e) => {
    const { value } = e.target
    if (value !== t('rejected_message.other')) {
      const payloadObj = { ...data.data }
      payloadObj.reason_cancel_reject = value
      setPayload(payloadObj)
      setOtherReason({ ...otherReason, visible: false })
    } else {
      const payloadObj = { ...data.data }
      payloadObj.reason_cancel_reject = ''
      setPayload(payloadObj)
      setOtherReason({ ...otherReason, visible: true })
    }
  }

  const handleInput = (e) => {
    const { value } = e.target
    if (value.length > reasonRejectedLength) return

    let msg = value ? `${t('rejected_message.other')} - ${value}` : ''
    const payloadObj = { ...data.data }
    setOtherReason({ ...otherReason, value: value })
    payloadObj.reason_cancel_reject = msg
    setPayload(payloadObj)
  }

  return (
    <>
      <div className="container">
        <div className="row justify-content-center py-2 font-weight-bold">
          <h5>{t('title.request_rejected')}</h5>
        </div>

        <div className="row align-items-baseline">
          <div className="col-12 my-3">
            <div className="font-weight-bold font-size-16">{t('title.rejected_reason')}</div>
            {reason.map((item, key) => (
              <div key={key} className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="rejected_reason"
                  value={item.value}
                  onChange={handleChangeRadio}
                />
                <label className="form-check-label">{item.label}</label>
              </div>
            ))}
            <div key="other" className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="rejected_reason"
                value={t('rejected_message.other')}
                onChange={handleChangeRadio}
              />
              <label className="form-check-label mb-2">{t('rejected_message.other')}</label>

              {otherReason.visible && (
                <InputText
                  placeholder="Alasan Pembatalan"
                  name="other_reject"
                  textarea={true}
                  row={2}
                  onChange={handleInput}
                  value={otherReason.value}
                />
              )}
            </div>
          </div>
          <div className="col-12 d-flex justify-content-center my-2">
            <div
              className="btn btn-sm btn-primary pointer font-weight-bold font-size-14"
              onClick={onSubmit}
            >
              {t('button.send')}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WirecardCancelForm
