import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'

const InteractorLocationCity = () => {
  const listCity = useCallback((callback, payload) => {
    payload = {
      province_uid: payload,
    }
    ApiService.jsonRequest('/master-city/list', payload, (response) => {
      if (response.status_code === 200) {
        callback(response.data)
      }
    })
  }, [])

  return { listCity }
}

export default InteractorLocationCity
