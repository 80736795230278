import React, { useEffect, useState, useMemo } from 'react'
import {
  Breadcrumb,
  InputQuantity,
  InputCurrency,
  Loading,
  ProductRelated,
  ProductReview,
  Rating,
} from '../../components/Main'
import { QuotationForm, AddressForm } from '../Main'
import { ProductDetailCarousel } from '../../components/Main.js'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import parser from 'react-html-parser'
import 'react-responsive-modal/styles.css'
import { currencyFormat, capitalizeFirstWord, formatCash } from '../../utility/Helpers'
import { InteractorProductItemView, InteractorQuotationCartCreate } from '../../interactors/Main'
import { Modal } from 'react-responsive-modal'
import { showAlert } from '../../utility/SweetAlert'

const ProductDetail = () => {
  const maximum_transaction = 50000000000
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { cartCreate } = InteractorQuotationCartCreate()
  const { account } = useSelector((state) => state.account)
  const { id } = useParams()
  const { productView } = InteractorProductItemView()
  const [payloadCart, setPayloadCart] = useState({
    account_uid: account?.uid,
    created_by: account?.full_name,
  })
  const [loading, setLoading] = useState(true)
  const [product, setProduct] = useState(null)
  const [navigation, setNavigation] = useState({
    description: true,
    company: false,
    review: false,
  })
  const [addressInitial] = useState({})
  const [quotationItem, setQuotationItem] = useState([])
  const [modal, setModal] = useState({
    rfq: false,
    address: false,
  })
  const handleModal = (type) => {
    const popup = { ...modal }
    if (type === 'RFQ') {
      popup.rfq = true
      popup.address = false
    } else {
      popup.rfq = false
      popup.address = true
    }
    setModal(popup)
  }

  const handleModalClose = () => {
    const popup = { ...modal }
    popup.rfq = false
    popup.address = false
    setModal(popup)
  }
  const handleInputQuantity = (row) => {
    setPayloadCart({
      ...payloadCart,
      request_quantity: parseFloat(row.value ?? 1),
    })
  }
  const handleInputPrice = (row) => {
    setPayloadCart({
      ...payloadCart,
      request_price: parseFloat(row.value ?? 1),
    })
  }

  const payloadView = useMemo(() => ({ uid: id }), [id])

  useEffect(() => {
    productView(payloadView, setProduct, setLoading)
  }, [payloadView, productView])

  const handleAddToCart = (e) => {
    e.preventDefault()
    if (!account) {
      return navigate('/account/signin')
    }
    setLoading(true)
    const objPayload = {
      ...payloadCart,
      product_uid: product?.uid,
      product_unit_type: product?.product_unit_type,
      company_uid: null,
      request_price_currency: product?.product_price_currency,
      request_quantity: parseFloat(
        payloadCart?.request_quantity ?? product?.minimum_order_quantity,
      ),
      request_price: parseFloat(payloadCart?.request_price ?? product?.product_price),
    }
    if (account.uid !== product?.store_account_uid) {
      const subtotal_item =
        parseFloat(payloadCart?.request_price ?? product?.product_price) *
        parseFloat(payloadCart?.request_quantity ?? product?.minimum_order_quantity)
      if (subtotal_item > maximum_transaction) {
        setLoading(false)
        showAlert({
          icon: 'warning',
          message: t('alert.transaction_over_limit'),
          timer: 0,
        })
        return
      } else {
        cartCreate(objPayload, setLoading)
      }
    } else {
      setLoading(false)
      showAlert({
        icon: 'error',
        message: t('message.product_owner'),
        reload: false,
      })
    }
  }

  const handleRequestQuotation = (e) => {
    e.preventDefault()
    if (!account) {
      return navigate('/account/signin')
    }
    const payloadQuotation = [
      {
        origin_address_detail: product.warehouse_address_detail,
        origin_city_name: product.origin_city_name,
        origin_city_uid: product.origin_city_uid,
        origin_country_uid: product.origin_country_uid,
        origin_district_uid: product.origin_district_uid,
        origin_postal_code_uid: product.origin_postal_code_uid,
        origin_province_uid: product.origin_province_uid,
        origin_subdistrict_uid: product.origin_subdistrict_uid,
        origin_province_name: product.origin_province_name,
        product_minimum_order_quantity: product.minimum_order_quantity,
        product_price: product.product_price,
        product_title: product.product_title,
        product_thumbnail: product?.product_media[0].product_media_url,
        product_uid: product.uid,
        product_unit_type: product.product_unit_type,
        request_price: parseFloat(payloadCart?.request_price ?? product?.product_price),
        request_price_currency: product?.product_price_currency,
        request_quantity: parseFloat(
          payloadCart?.request_quantity ?? product?.minimum_order_quantity,
        ),
        store_uid: product.store_uid,
        store_name: product.store_name,
      },
    ]
    if (account.uid !== product?.store_account_uid) {
      const subtotal_item =
        parseFloat(payloadCart?.request_price ?? product?.product_price) *
        parseFloat(payloadCart?.request_quantity ?? product?.minimum_order_quantity)
      if (subtotal_item > maximum_transaction) {
        setLoading(false)
        showAlert({
          icon: 'warning',
          message: t('alert.transaction_over_limit'),
          timer: 0,
        })
        return
      } else {
        setQuotationItem(payloadQuotation)
        handleModal('RFQ')
      }
    } else {
      setLoading(false)
      showAlert({
        icon: 'error',
        message: t('message.product_owner'),
        reload: false,
      })
    }
  }

  const handleNavigation = (key) => {
    setNavigation({
      ...navigation,
      description: key === 'description' ? true : false,
      company: key === 'company' ? true : false,
      review: key === 'review' ? true : false,
    })
  }

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center height-100vh">
          <Loading />
        </div>
      ) : (
        <>
          <Modal
            open={modal.rfq}
            onClose={handleModalClose}
            classNames={{
              modal: 'col-10',
              modalContainer: 'row justify-content-center',
            }}
          >
            <QuotationForm data={quotationItem} handleModal={handleModal} />
          </Modal>
          <Modal
            open={modal.address}
            onClose={() => {
              handleModal('RFQ')
            }}
          >
            <AddressForm
              initial={'cart'}
              datas={addressInitial}
              onCallback={() => {
                handleModal('RFQ')
              }}
            />
          </Modal>
          <Breadcrumb title={product.product_title ?? t('menu.product_detail')} />
          <div className="container">
            <div className="mb-10">
              <div className="row">
                <div className="col-md-4 col-lg-4 col-xl-4 mb-4 mb-md-0 p-0">
                  <ProductDetailCarousel images={product.product_media} />
                </div>
                <div className="col-md-8 col-lg-8 col-xl-8 mb-md-8 mb-lg-3 pl-5 pr-5">
                  <div className="mb-2">
                    <h2 className="font-size-25 text-lh-1dot2">{product.product_title}</h2>
                    <div className="mb-2 d-flex flex-column flex-md-row py-3 font-size-15 font-size-13-sm">
                      <div className="pr-2 u-ver-divider-black height-20 mb-2">
                        <span className="mr-1">{t('title.product.sku')}</span>
                        <span>{product.product_sku_store}</span>
                      </div>
                      <div className="flex-horizontal-center font-size-15 mb-2 font-size-13-sm text-lh-1 px-0 px-md-2 u-ver-divider-black height-20">
                        <span className="mr-2 d-block d-md-none">{t('title.rating')}</span>
                        <Rating rating={product.product_rating} />
                      </div>
                      <div className="px-0 px-md-2 flex-horizontal-center font-size-15 font-size-13-sm mb-2">
                        <span className="mr-2 d-block d-md-none">{t('title.product_sold')}</span>
                        <span>
                          {formatCash(parseFloat(product.product_sold.toString().replace(',', '')))}
                          {t('title.sold')}
                        </span>
                      </div>
                    </div>
                    <div className="font-size-24 font-weight-bold mb-3">
                      {currencyFormat(product.product_price, product.product_price_currency)}{' '}
                      <span className="font-size-16">
                        / {capitalizeFirstWord(product.product_unit_type)}
                      </span>
                    </div>
                    <div className="alert alert-red mb-3 d-flex align-items-center">
                      <i className="fas fa-exclamation-triangle mr-2"></i>
                      <div className="font-size-12 text-danger">
                        {t(`content.product_detail_price`)} <strong>{t(`button.chat`)}</strong>
                      </div>
                    </div>
                    <hr />
                    <div className="container-fluid mb-5 mb-md-0">
                      <div className="row px-0 d-flex align-items-center mb-2">
                        <div className="col-12 col-lg-3 font-weight-bold pt-0">
                          {t('title.minimum_purchase')}
                        </div>
                        <div className="col-12 col-lg-5 pt-0">
                          {`${product.minimum_order_quantity} ${capitalizeFirstWord(
                            product.product_unit_type,
                          )}`}
                        </div>
                      </div>
                      <div className="row px-0 d-flex align-items-center mb-2">
                        <div className="col-12 col-lg-3 font-weight-bold">
                          {t('title.purchase_amount')}
                        </div>
                        <div className="col-12 col-lg-5">
                          <InputQuantity
                            id={id}
                            value={product.minimum_order_quantity}
                            onChange={handleInputQuantity}
                          />
                        </div>
                      </div>
                      <div className="row px-0 d-flex mb-2">
                        <div className="col-12 col-lg-3 font-weight-bold">
                          {t('title.price_offer')}
                          <small> / {capitalizeFirstWord(product.product_unit_type)}</small>
                        </div>
                        <div className="col-12 col-lg-5">
                          <InputCurrency
                            id={id}
                            currency={currencyFormat(product.product_price_currency)}
                            value={product.product_price}
                            onChange={handleInputPrice}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6 col-lg-4">
                        <Link
                          to="#"
                          className="btn btn-xs w-100 btn-gray-4 font-size-14 font-size-15-md font-size-11-sm text-nowrap m-2"
                          onClick={handleAddToCart}
                        >
                          <i className="ec ec-add-to-cart mr-1" /> {t('button.add_to_cart')}
                        </Link>
                      </div>
                      <div className="col-12 col-md-6 col-lg-4">
                        <Link
                          to="#"
                          className="btn btn-xs w-100 btn-danger font-size-14 font-size-15-md font-size-11-sm text-nowrap m-2"
                          onClick={handleRequestQuotation}
                        >
                          <i className="fa fa-file-alt mr-1" /> {t('button.request_offer')}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container-fluid mt-3">
                  <div className="row align-items-center justify-content-between py-3 mx-1 border border-gray-4 border-radius-8">
                    <div className="col-12 col-lg-4 col-md-5 col-sm-6 d-flex align-items-center mb-2 mb-md-0">
                      <div className="col-5 col-lg-5  col-md-5 p-0 justify-content-center">
                        <div className="company-profile mx-auto">
                          <img className="company-profile-image" src={product.store_logo} alt="" />
                        </div>
                      </div>
                      <div className="col-7 col-lg-7 col-md-7 px-0">
                        <div className="company-profile-brand text-black mb font-size-15 font-size-15-sm">
                          {product.store_name}
                        </div>
                        <div className="company-profile-tax font-size-15 font-size-15-sm">
                          <span>{t('title.tax_status')} : </span>
                          <span>{t(`title.tax_type.${product.store_tax_type}`)}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 col-md-4 col-sm-1 d-sm-none d-md-inline">
                      <div className="text-black font-weight-bold">{t('title.location')}</div>
                      {product.origin_city_name}, {product.origin_province_name}
                    </div>
                    <div className="col-12 col-lg-2 col-md-3 d-flex flex-row flex-md-column align-items-center justify-content-center">
                      <Link
                        to={'/store/detail/' + product.store_uid}
                        className="btn btn-gray-2 active py-2 mb-0 mb-md-2 mr-2 mr-md-0 font-size-12 font-size-8-sm text-nowrap"
                      >
                        <i className="fa fa-store-alt mr-1" /> {t('button.visit_store')}
                      </Link>
                      {account?.uid !== product?.store_account_uid && (
                        <Link
                          to={`/chat?product=${product.uid}&seller=${product.store_uid}`}
                          className="btn py-2 btn-primary font-size-12 font-size-8-sm text-nowrap"
                        >
                          <i className="fa fa-comments mr-1" />
                          {t('button.chat')}
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-7 pt-6 pb-3 mb-6">
            <div className="container">
              <div className="js-scroll-nav">
                <div className="bg-white pt-4 pb-6 px-xl-11 px-md-5 px-4 mb-6">
                  <div className="mx-md-2">
                    <div className="position-relative mb-6">
                      <ul className="nav nav-classic nav-tab nav-tab-lg justify-content-xl-center flex-nowrap flex-xl-wrap overflow-auto overflow-xl-visble border-lg-down-bottom-0 pb-1 pb-xl-0 mb-n1 mb-xl-0 nav-scroll">
                        <li className="nav-item flex-shrink-0 flex-xl-shrink-1 z-index-2">
                          <div
                            className={`pointer nav-link ${navigation.description && 'active'}`}
                            to="#"
                            onClick={() => handleNavigation('description')}
                          >
                            <div className="d-md-flex justify-content-md-center align-items-md-center">
                              {t('title.product_description')}
                            </div>
                          </div>
                        </li>
                        <li className="nav-item flex-shrink-0 flex-xl-shrink-1 z-index-2">
                          <div
                            className={`pointer nav-link ${navigation.company && 'active'}`}
                            to="#"
                            onClick={() => handleNavigation('company')}
                          >
                            <div className="d-md-flex justify-content-md-center align-items-md-center">
                              {t('title.company_description')}
                            </div>
                          </div>
                        </li>
                        <li className="nav-item flex-shrink-0 flex-xl-shrink-1 z-index-2">
                          <div
                            className={`pointer nav-link ${navigation.review && 'active'}`}
                            to="#"
                            onClick={() => handleNavigation('review')}
                          >
                            <div className="d-md-flex justify-content-md-center align-items-md-center">
                              {t('title.review')}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="container-fluid">
                      {navigation.description && (
                        <div className="mx-md-4 pt-1 overflow-auto">
                          <h4 className="mb-2">{product.product_title}</h4>
                          <div className="text-break font-size-15 font-size-15-sm">
                            {parser(`${product.product_description}`)}
                          </div>
                        </div>
                      )}
                      {navigation.company && (
                        <div className="mx-md-5 pt-1 overflow-auto">
                          <p className="text-break">{parser(`${product.company_information}`)}</p>
                        </div>
                      )}
                      {navigation.review && (
                        <ProductReview
                          data={{ product_uid: product.uid, rating: product.product_rating }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <ProductRelated />
          </div>
        </>
      )}
    </>
  )
}

export default ProductDetail
