import React from 'react'
import { useTranslation } from 'react-i18next'

const InputTextButton = (data) => {
  const { value, onChange, placeholder, onSubmit, onClear } = data
  const { t } = useTranslation()
  return (
    <>
      <form className="input-group row col-12 col-md-6 mb-2 mb-md-0 mr-2" onSubmit={onSubmit}>
        <div className="form-control d-flex align-items-center height-40 py-0">
          <input
            type="text"
            className="w-100 h-100 border-0 py-0"
            value={value}
            placeholder={placeholder ? placeholder : t('placeholder.key_word')}
            onChange={onChange}
          />
          {value !== '' && (
            <i
              className={`pointer ml-2 font-size-22`}
              // style={{ left: '85%' }}
              aria-hidden="true"
              onClick={onClear}
            >
              &times;
            </i>
          )}
        </div>
        <div className="input-group-append">
          <button className="btn btn-primary height-40 py-2 px-3 rounded-right-pill" type="submit">
            <i className="fa fa-search"></i>
          </button>
        </div>
      </form>
    </>
  )
}

export default InputTextButton
