import { PRODUCT_ITEM_SEARCH } from './actions'

const initialState = {
  payload: {
    page: 1,
    limit: 20,
    keywords: {
      province_uid: [],
      category_main_uid: null,
      category_major_uid: null,
      category_minor_uid: null,
      store_uid: null,
      price_range: {
        minimum: null,
        maximum: null,
      },
      rating: null,
      text_search: '',
      sort_by: null,
      source: 'MARKETPLACE',
    },
  },
}

export default function ProductItemSearchReducer(state = initialState, action) {
  switch (action.type) {
    case PRODUCT_ITEM_SEARCH:
      return {
        ...state,
        payload: action.payload,
      }
    default:
      return state
  }
}
