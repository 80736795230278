import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ApiService from '../../../services/ApiService'
import { removeCookies } from '../../../utility/Cookies'
import { showAlert } from '../../../utility/SweetAlert'

const InteractorQuotationRequestCreate = () => {
  const { t } = useTranslation()
  const requestCreate = useCallback(
    (payload) => {
      ApiService.jsonRequest('/rfq-quotation/create', payload, (response) => {
        if (response.status_code === 200) {
          removeCookies('summary-header')
          showAlert({
            icon: 'success',
            message: t('response_message.rfq_create_success'),
            timer: 2000,
            redirect: '/quotation/request',
          })
        } else {
          showAlert({
            icon: 'warning',
            message: t('response_message.rfq_create_failed'),
            timer: 2000,
            reload: true,
          })
        }
      })
    },
    [t],
  )
  return { requestCreate }
}

export default InteractorQuotationRequestCreate
