import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Loading } from '../Main'
import { InteractorContentBannerSearch } from '../../interactors/Main'
import parser from 'react-html-parser'

const SliderSection = () => {
  const [payload] = useState({
    page: 1,
    limit: 4,
    keywords: {
      text_search: '',
      banner_category: 'HOME_PAGE_SLIDER',
    },
  })
  const [loading, setLoading] = useState(true)

  const [banner, setBanner] = useState({})
  const { bannerSearch } = InteractorContentBannerSearch()
  const handleResponse = (data) => {
    if (data?.items?.length > 0) {
      setBanner(data.items)
    }
  }
  useEffect(() => {
    if (loading) {
      bannerSearch(payload, handleResponse, setLoading)
    }
  }, [bannerSearch, loading, payload])

  const settings = {
    dots: true,
    dotsClass: 'button__bar',
    arrow: false,
    pauseOnHover: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 10000,
    fade: true,
    slidesToScroll: 1,
    variableWidth: false,
    centerMode: false,
  }

  return (
    <>
      <div className="mb-5">
        <div
          className="bg-img-hero"
          style={{ backgroundImage: 'url(/assets/img/1920X422/banner-003.png)' }}
        >
          <div className="container min-height-420">
            {banner.length === 0 ? (
              <Loading />
            ) : (
              <div>
                <Slider {...settings}>
                  {banner.length > 0 &&
                    banner?.map((row, index) => {
                      return (
                        <div className="bg-img-hero-center px-3" key={index}>
                          <Link
                            to={row.target_link}
                            className="text-white transition-3d-hover font-weight-normal py-2 px-md-7 px-3 font-size-16"
                          >
                            <div className="row min-height-420 py-md-0">
                              <div className="col-xl-4 col-lg-5 col-6 offset-xl-3 mt-md-8">
                                <h1 className="font-size-64 text-lh-57 font-weight-white">
                                  <span className="d-block font-size-40">{row.banner_title}</span>
                                </h1>
                                <div className="mb-4 font-size-16 font-font-size-15-sm p-white">
                                  {parser(`${row.banner_content}`)}
                                </div>
                              </div>
                              <div
                                className="col-xl-5 col-lg-7 col-6 d-flex align-items-center"
                                data-scs-animation-in="zoomIn"
                                data-scs-animation-delay={500}
                              >
                                <img className="img-fluid" src={row.media_url} alt="" />
                              </div>
                            </div>
                          </Link>
                        </div>
                      )
                    })}
                </Slider>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default SliderSection
