import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Loading, WideCardFilter, Pagination, WirecardCancelForm, InputText } from '../Main'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  numberFormat,
  currencyFormat,
  formatDate,
  prettyUrl,
  numberFormatAPI,
} from '../../utility/Helpers'
import {
  InteractorOrderUpdate,
  InteractorProductReviewCreate,
  InteractorDownloadPdf,
} from '../../interactors/Main'
import { confirmationAlert, showAlert } from '../../utility/SweetAlert'
import { Modal } from 'react-responsive-modal'
import { reviewCommentLength } from '../../constant/global/validation'

const WideCardOrder = (data) => {
  const { loading, datacard, payload, download } = data
  const { t } = useTranslation()
  const { account } = useSelector((state) => state.account)
  const { orderUpdate } = InteractorOrderUpdate()
  const { reviewCreate } = InteractorProductReviewCreate()
  const { getTransaction } = InteractorDownloadPdf()
  const [loadingRequest, setLoadingRequest] = useState(false)
  const [payloadFilter, setPayloadFilter] = useState(payload)
  const [isModalRejected, setIsModalRejected] = useState(false)
  const [payloadRejected, setPayloadRejected] = useState(null)

  const getPaymentMethod = () => {
    let payment_method = [
      { value: '', label: t(`title.select_payment_type`) },
      { value: 'FULL_PAYMENT', label: t(`initial_status.FULL_PAYMENT`) },
    ]
    if (account?.allow_term_payment === 'Y') {
      payment_method.push({ value: 'TOP', label: t(`initial_status.TOP`) })
    }
    return payment_method
  }
  const filter = {
    text_search: true,
    status: [
      { value: '', label: t(`title.select_status`) },
      // { value: 'WAITING_PAYMENT', label: t(`initial_status.WAITING_PAYMENT`) },
      { value: 'PROCESSED', label: t(`initial_status.PROCESSED`) },
      { value: 'SHIPPED', label: t(`initial_status.SHIPPED`) },
      { value: 'DELIVERED', label: t(`initial_status.DELIVERED`) },
      { value: 'COMPLETED', label: t(`initial_status.COMPLETED`) },
      { value: 'CANCELLED', label: t(`initial_status.CANCELLED`) },
      { value: 'REFUND', label: t(`initial_status.REFUND`) },
    ],
    payment_type: getPaymentMethod(),
    download: download,
  }
  const [modalRating, setModalRating] = useState(false)
  const [rating, setRating] = useState(3)
  const [hover, setHover] = useState(3)

  const [modalRatingData, setModalRatingData] = useState({})
  const handleModalRating = () => {
    setModalRating(false)
    setModalRatingData({})
  }

  const handleRatingFeedback = (e) => {
    e.preventDefault()
    const { value } = e.target
    if (value.length > reviewCommentLength) return
    let prevData = { ...modalRatingData }
    prevData.feedback = value
    setModalRatingData(prevData)
  }

  const handleRatingSend = () => {
    setModalRating(false)
    setLoadingRequest(true)
    let prevData = { ...modalRatingData }
    prevData.rating = rating
    reviewCreate(prevData)
  }

  const handleFilter = (row) => {
    let objPayload = { ...payloadFilter }
    objPayload.page = 1
    objPayload.keywords.text_search = row.keywords.text_search
    objPayload.keywords.status = row.keywords.status
    objPayload.keywords.payment_type = row.keywords.payment_type
    data.handleFilter(objPayload)
    setPayloadFilter(objPayload)
  }
  const handlePageChange = (page) => {
    let objPayload = { ...payloadFilter }
    objPayload.page = page
    data.handleFilter(objPayload)
    setPayloadFilter(objPayload)
  }
  const handleTransactionUpdate = async (row, status) => {
    let payloadUpdate = { ...row }
    payloadUpdate.status = status
    payloadUpdate.updated_by = account.full_name
    payloadUpdate.delivery_estimated_time = formatDate(
      row.delivery_estimated_time,
      'YYYY-MM-DD HH:mm:ss',
    )
    if (row?.payment_term_date) {
      payloadUpdate.payment_term_date = formatDate(
        new Date(row.payment_term_date),
        'YYYY-MM-DD HH:mm:ss',
      )
    }

    if (row.payment_status === 'UNPAID' && status === 'COMPLETED') {
      showAlert({
        icon: 'warning',
        message: t('message.disallow_order_completed'),
        reload: false,
      })
    } else {
      if (status === 'COMPLETED') {
        setLoadingRequest(true)
        orderUpdate(payloadUpdate)
      } else {
        setIsModalRejected(true)
        setPayloadRejected(payloadUpdate)
      }
    }
  }

  const handleDownloadPdf = (data) => {
    getTransaction(data)
  }

  const handleOrderRejected = async (value) => {
    const reject = await confirmationAlert({
      title: t('title.are_you_sure'),
      message: t('message.confirmation_order_cancel'),
      icon: 'warning',
    })
    if (reject) {
      setLoadingRequest(true)
      orderUpdate(value, 'REJECTED')
    }
  }

  // CARD AREA
  const CardHeader = (data) => {
    const row = data.data
    return (
      <>
        <div className="row">
          <div className="col-8 mt-2">
            <span className="d-inline-block">
              <img src={row.store_logo} className="img-fluid" alt="" style={{ width: '24px' }} />
            </span>
            <span className="d-inline-block p-2">
              <small>{t('title.seller_name')}</small> <br />
              <strong>{row.store_name}</strong>
            </span>
          </div>
          <div className="col-4 mt-1 d-flex justify-content-end">
            <span className="d-inline-block p-2">
              <small>{t('title.status_transaction')}: </small> <br />
              <span className={`text-gray font-weight-bold text-gray`}>
                {t(`initial_status.${row.status}`)}
              </span>
            </span>
          </div>
        </div>
        <hr className="m-0" />
      </>
    )
  }

  const CardBody = (data) => {
    const row = data.data
    const items = row.transaction_items
    return (
      <>
        <InformationTransaction data={row} />
        <table className="table table-responsive table-borderless">
          <thead>
            <tr>
              <th className="col-4">{t('title.product.title')}</th>
              <th className="col-2">{t('title.product.price')}</th>
              <th className="col-2">{t('title.product.qty')}</th>
              <th className="col-2">{t('title.product.weight_total')}</th>
              <th className="col-2">{t('title.product.price_total')}</th>
              <th className=""></th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, key) => {
              let rated = false
              if (row.status === 'COMPLETED' && item.customer_rated === '') {
                rated = true
              }
              return (
                <tr key={key}>
                  <td className="col d-flex flex-column flex-md-row">
                    <div className="col-8 col-md-3 min-width-60 px-0">
                      <Link
                        to={`/product/detail/${
                          prettyUrl(item.product_title) + '/' + item.product_uid
                        }`}
                      >
                        <img src={item.product_thumbnail} className="img-fluid" alt="" />
                      </Link>
                    </div>
                    <div className="col-12 col-md-9 px-0 px-md-3">
                      <Link
                        to={`/product/detail/${
                          prettyUrl(item.product_title) + '/' + item.product_uid
                        }`}
                        className="text-black"
                      >
                        <span>
                          <strong>{item.product_title}</strong>
                        </span>
                      </Link>
                    </div>
                  </td>
                  <td>
                    {currencyFormat(item.product_price, item.product_price_currency)} /{' '}
                    <small>{item.product_unit_type}</small>
                  </td>
                  <td>{numberFormat(item.product_quantity)}</td>
                  <td>
                    {`${numberFormatAPI(item.product_weight * item.product_quantity, {
                      leadingZeroAllowed: true,
                      zeroDecimal: true,
                      amountZeroDecimal: 2,
                    })} ${t('title.product.weight_kg')}`}
                  </td>
                  <td>
                    {currencyFormat(
                      item.product_price * item.product_quantity,
                      item.product_price_currency,
                    )}
                  </td>
                  {rated && (
                    <td>
                      <button
                        type="button"
                        onClick={() => {
                          setModalRating(true)
                          setModalRatingData({
                            product_uid: item.product_uid,
                            account_uid: account.uid,
                            transaction_uid: item.transaction_uid,
                            rating: 5,
                            feedback: '',
                            created_by: account.full_name,
                          })
                        }}
                        className="btn btn-xs font-size-12 btn-primary text-center"
                      >
                        {t('button.leave_review')}
                      </button>
                    </td>
                  )}
                </tr>
              )
            })}
          </tbody>
        </table>
        <hr className="m-0" />
      </>
    )
  }
  /*
  const AlertBill = (data) => {
    const row = data.row
    let alert = ''
    if (
      ['PROCESSED', 'SHIPPED', 'DELIVERED', 'COMPLETED'].includes(row.status) &&
      row.payment_type === 'TOP' &&
      row.payment_status === 'UNPAID'
    ) {
      const today = formatDate(new Date(), 'YYYY-MM-DD')
      const term_date = formatDate(row.payment_term_date, 'YYYY-MM-DD')
      let coming_soon = new Date(row.payment_term_date)
      coming_soon.setDate(coming_soon.getDate() - 3)
      const coming_date = formatDate(coming_soon, 'YYYY-MM-DD')
      if (today === term_date) {
        alert = (
          <div className="alert alert-danger col-12">
            {`${t('alert.due_date_today')} ${t('title.please_pay_bill')}`}
          </div>
        )
      } else if (today > term_date) {
        var passed_date = new Date().getTime() - new Date(row.payment_term_date).getTime()
        passed_date = passed_date / (1000 * 3600 * 24)
        alert = (
          <div className="alert alert-danger col-12">
            {t('alert.due_date_passed')}
            <span className="font-weight-bold">
              {` ${Math.ceil(passed_date)} ${t('title.day')}`}.
            </span>
            {t('title.please_pay_bill')}
          </div>
        )
      } else if (today >= coming_date && coming_date < term_date) {
        const diffTime = Math.abs(new Date(term_date) - new Date())
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        alert = (
          <div className="alert alert-warning col-12">
            {t('alert.due_date_coming_soon')}{' '}
            <span className="font-weight-bold">{`${diffDays} ${t('title.day')}`}</span>.{' '}
            {`${t('alert.due_date_please_pay')} ${formatDate(
              row.payment_term_date,
              'DD-MM-YYYY',
            )}.`}
          </div>
        )
      }
    }
    if (alert) {
      return <div className="row m-1">{alert}</div>
    } else {
      return null
    }
  }
  */

  const CardFooter = (data) => {
    const row = data.data
    return (
      <>
        <hr className="m-0" />
        {/* <AlertBill row={row} /> */}
        <div className="row overflow-auto">
          <div className="col-12 mt-2 mb-2 d-flex flex-column flex-md-row justify-content-center justify-content-md-end">
            <Link
              to={'#'}
              onClick={() => {
                window.location = `/order/view/${row.uid}`
              }}
              className="d-inline btn btn-xs m-2 font-size-10"
              style={{ background: '#396AFC' }}
            >
              {t('button.view_order')}
            </Link>
            <div
              onClick={() => handleDownloadPdf(row)}
              className="d-inline btn btn-warning btn-xs m-2 font-size-10 pointer"
            >
              <i className="far fa-file"></i> {t('button.download_pdf')}
            </div>
            {!['CANCELLED', 'REFUND'].includes(row.status) && (
              <Link
                to={'#'}
                onClick={() => {
                  window.location = `/chat?order=${row.uid}&seller=${row.store_uid}`
                }}
                className="d-inline btn btn-red btn-xs m-2 font-size-10"
              >
                <i className="fa fa-comments"></i> {t('button.chat_seller')}
              </Link>
            )}
            {/* {row.status === 'WAITING_PAYMENT' && row.payment_type === 'FULL_PAYMENT' && (
              <>
                <div
                  onClick={() => handleTransactionUpdate(row, 'CANCELLED')}
                  className="d-inline btn btn-dark btn-xs m-2 font-size-10 pointer"
                >
                  <i className="fa fa-ban"></i> {t('button.cancelled')}
                </div>
              </>
            )} */}
            {['DELIVERED'].includes(row.status) && (
              <>
                <Link
                  to={'#'}
                  onClick={() => handleTransactionUpdate(row, 'COMPLETED')}
                  className="d-inline btn btn-success btn-xs m-2 font-size-10"
                >
                  <i className="fa fa-check"></i> {t('button.finish_order')}
                </Link>
              </>
            )}
          </div>
        </div>
      </>
    )
  }

  const InformationTransaction = (data) => {
    const row = data.data
    return (
      <div className="row my-3 text-break">
        <div className="col-xs-12 col-md-3">
          <div className="p-2">
            <small>{t(`title.order_number`)}</small> <br />
            <strong>{row.transaction_code ?? '-'}</strong>
          </div>
        </div>
        <div className="col-xs-12 col-md-3">
          <div className="p-2">
            <small>{t(`title.purchase_request_number`)}</small> <br />
            <strong>
              {row.purchase_request_number !== '' ? row.purchase_request_number : '-'}
            </strong>
          </div>
        </div>
        <div className="col-xs-12 col-md-3">
          <div className="p-2">
            <small>{t(`title.purchase_order_number`)}</small> <br />
            <strong>{row.purchase_order_number !== '' ? row.purchase_order_number : '-'}</strong>
          </div>
        </div>
        <div className="col-xs-12 col-md-3">
          <div className="p-2">
            <small>{t(`title.payment_status`)}</small>
            <br />
            <strong>{row.payment_status === 'PAID' ? t(`title.paid`) : t(`title.unpaid`)}</strong>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {/* MODAL RATED */}
      <Modal open={modalRating} onClose={handleModalRating}>
        <div className="row col-12">
          <div className="col-12 p-2 font-size-18 text-center mb-1 font-weight-bold">
            <h3>{t('title.leave_review')}</h3>
            <hr />
          </div>
          <div className="col-12">
            <div className="form-group">
              <label className="form-label">
                {t('title.rating')}
                <span className="text-danger"> *</span>
              </label>
              {[...Array(5)].map((star, index) => {
                index += 1
                return (
                  <button
                    type="button"
                    key={index}
                    className={`leave-rating ${index <= (hover || rating) ? 'on' : 'off'}`}
                    onClick={() => setRating(index)}
                    onMouseEnter={() => setHover(index)}
                    onMouseLeave={() => setHover(rating)}
                  >
                    <i className="fa fa-star" />
                  </button>
                )
              })}
            </div>
            <div className="form-group">
              <label className="form-label">
                {t('title.leave_feedback')}
                <span className="text-danger"> *</span>
              </label>
              <InputText
                value={modalRatingData?.feedback}
                onChange={handleRatingFeedback}
                name="feedback"
                textarea={true}
                rows={2}
                placeholder={t('title.leave_feedback')}
              />
            </div>
            <div className="mb-6">
              <div className="mb-3">
                <button
                  type="submit"
                  onClick={() => {
                    if (modalRatingData.feedback !== '') {
                      handleRatingSend()
                    } else {
                      showAlert({
                        icon: 'info',
                        message: 'Silahkan isi data',
                      })
                    }
                  }}
                  className="btn btn-primary-dark-w px-5"
                >
                  {t('button.send')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={isModalRejected}
        onClose={() => setIsModalRejected(false)}
        classNames={{
          modal: 'w-100 w-xl-35 h-auto',
          modalContainer: 'row justify-content-center align-items-center',
        }}
      >
        <WirecardCancelForm
          data={payloadRejected}
          handleSubmit={handleOrderRejected}
          handleModal={() => setIsModalRejected(false)}
        />
      </Modal>
      {/* FILTER */}
      <WideCardFilter
        filter={filter}
        payload={payload}
        handleFilter={handleFilter}
        label_input={t('placeholder.order_filter')}
      />
      {loading || loadingRequest ? (
        <div className="d-flex justify-content-center">
          <Loading />
        </div>
      ) : (
        <>
          {datacard.items?.length > 0 ? (
            <>
              {datacard.items.map((row, key) => {
                return (
                  <div key={key}>
                    <div className="border border-width-2 border-color-3 borders-radius-6 container wide-card rounded mb-5">
                      <CardHeader data={row} />
                      <div className="row">
                        <div className="col-12">
                          <CardBody data={row} />
                        </div>
                      </div>
                      <CardFooter data={row} />
                    </div>
                  </div>
                )
              })}
              <Pagination data={datacard.pages} onChangePage={handlePageChange} />
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">{t('message.data_not_found')}</div>
            </>
          )}
        </>
      )}
    </>
  )
}

export default WideCardOrder
