import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Loading, Pagination, InputQuantity, InputCurrency } from '../Main'
import { currencyFormat, capitalizeFirstWord, prettyUrl } from '../../utility/Helpers'
import { confirmationAlert, showAlert } from '../../utility/SweetAlert'
import { useSelector } from 'react-redux'
import {
  InteractorQuotationCartUpdate,
  InteractorQuotationCartDelete,
} from '../../interactors/Main'
import 'react-responsive-modal/styles.css'
import { Modal } from 'react-responsive-modal'
import { AddressForm, QuotationForm } from '../../containers/Main'
import { Link } from 'react-router-dom'

const WideCardCart = (data) => {
  const { loading, datacard, payload } = data
  const { t } = useTranslation()
  const { account } = useSelector((state) => state.account)
  const [payloadFilter, setPayloadFilter] = useState(payload)
  const { cartDelete } = InteractorQuotationCartDelete()
  const { cartUpdate } = InteractorQuotationCartUpdate()
  const [addressInitial] = useState({})
  const [quotationItem, setQuotationItem] = useState([])
  const [modal, setModal] = useState({
    rfq: false,
    address: false,
  })
  const handleModal = (type) => {
    const popup = { ...modal }
    if (type === 'RFQ') {
      popup.rfq = true
      popup.address = false
    } else {
      popup.rfq = false
      popup.address = true
    }
    setModal(popup)
  }

  const handleModalClose = () => {
    const popup = { ...modal }
    popup.rfq = false
    popup.address = false
    setModal(popup)
  }
  const handlePageChange = (page) => {
    let objPayload = { ...payloadFilter }
    objPayload.page = page
    data.handleFilter(objPayload)
    setPayloadFilter(objPayload)
  }

  const CardBody = (data) => {
    const row = data.data
    let items = row.cart
    const [productChecked, setProductChecked] = useState(false)
    const [groupChecked, setgroupChecked] = useState([])
    const [isShow, setIsShow] = useState(false)

    const handleInputQuantity = (row) => {
      const index = items.findIndex((items) => items.uid === row.id)
      const newItem = items
      newItem[index].request_quantity = row.value
    }

    const handleInputPrice = (row) => {
      const index = items.findIndex((items) => items.uid === row.id)
      const newItem = items
      newItem[index].request_price = row.value
    }

    const handleChecked = (e) => {
      const uid = e.target.value
      const onChecked = groupChecked.find((element) => element === uid)
      if (!onChecked) {
        setgroupChecked([...groupChecked, uid])
      } else {
        let array = [...groupChecked]
        let index = array.indexOf(uid)
        if (index !== -1) {
          array.splice(index, 1)
          setgroupChecked(array)
        }
      }
    }

    const handleAllChecked = () => {
      const checkStatus = !productChecked
      if (checkStatus) {
        let all = []
        items.forEach((itm) => {
          all.push(itm.uid)
        })
        setgroupChecked(all)
      } else {
        setgroupChecked([])
      }
      setProductChecked(checkStatus)
    }

    const handleDelete = () => {
      const payloadDelete = {
        uid: groupChecked,
        updated_by: account.full_name,
      }
      cartDelete(payloadDelete)
    }

    const handleUpdate = (row) => {
      cartUpdate({
        uid: row.uid,
        account_uid: account.uid,
        company_uid: null,
        product_uid: row?.product_uid,
        product_unit_type: row?.product_unit_type,
        request_quantity: row?.request_quantity,
        request_price: row?.request_price,
        request_price_currency: row?.request_price_currency,
        updated_by: account.full_name,
      })
    }

    const handleBatchUpdate = () => {
      items.forEach((cart) => {
        if (groupChecked.includes(cart.uid)) {
          let row = { ...cart }
          const updatePayload = {
            uid: row.uid,
            account_uid: account.uid,
            company_uid: null,
            product_uid: row?.product_uid,
            product_unit_type: row?.product_unit_type,
            request_quantity: row?.request_quantity,
            request_price: row?.request_price,
            request_price_currency: row?.request_price_currency,
            updated_by: account.full_name,
          }
          cartUpdate(updatePayload, 'batch')
        }
      })
      showAlert({
        icon: 'success',
        message: t('response_message.cart_update_success'),
        timer: 2000,
        reload: true,
      })
    }

    return (
      <>
        <div className="row d-flex py-2">
          <div className="col-0 col-md-9 d-none d-lg-flex">
            <Link
              className="col-12 d-flex align-items-center px-2 text-black"
              to={`/store/detail/${row.store_uid}`}
            >
              <span className="mr-2">
                <img src={row.store_logo} className="img-fluid" alt="" style={{ width: '50px' }} />
              </span>
              <span>
                <small>{t('title.seller_name')}</small>
                <div>
                  <strong>{row.store_name}</strong>
                </div>
              </span>
            </Link>
          </div>
          <div className="col-9 col-md-0 d-flex d-lg-none">
            <span className="mr-2">
              <input
                id={'product_checked' + row.store_uid}
                checked={productChecked && groupChecked.length === items.length ? 'checked' : ''}
                type={'checkbox'}
                onChange={() => handleAllChecked()}
              />
            </span>
            <span>
              <Link
                to={`/store/detail/${row.store_uid}`}
                className="text-black d-flex align-items-center"
              >
                <strong>{row.store_name}</strong>
                <i className="fa fa-angle-right ml-3 font-size-18 text-gray-6"></i>
              </Link>
            </span>
          </div>
          <div className="col-3 mt-1 d-flex justify-content-end">
            {groupChecked.length > 0 && (
              <>
                <div className="d-none d-lg-flex">
                  <button
                    type="button"
                    onClick={async () => {
                      const confirm = await confirmationAlert({
                        title: t('title.are_you_sure'),
                        message: t('message.confirmation_update'),
                        icon: 'warning',
                      })
                      if (confirm) {
                        handleBatchUpdate()
                      }
                    }}
                    className={`btn btn-xs m-2 font-size-12 font-weight-bold btn-success d-flex text-center`}
                  >
                    <i className="fa fa-edit mr-1"></i>
                    <span>{t('button.update_all')}</span>
                  </button>
                  <button
                    type="button"
                    onClick={async () => {
                      const confirm = await confirmationAlert({
                        title: t('title.are_you_sure'),
                        message: t('message.confirmation_delete'),
                        icon: 'warning',
                      })
                      if (confirm) {
                        handleDelete()
                      }
                    }}
                    className={`btn btn-xs m-2 font-size-12 font-weight-bold btn-danger d-flex text-center`}
                  >
                    <i className="fa fa-trash mr-1"></i>
                    <span>{t('button.delete')}</span>
                  </button>
                </div>
                <div className="d-block d-lg-none">
                  <span>
                    <div
                      className="position-relative d-flex font-weight-bold"
                      title={t('menu.cart')}
                      onClick={() => setIsShow(!isShow)}
                    >
                      <i className="fa fa-ellipsis-v"></i>
                    </div>
                    {isShow && (
                      <div
                        id="basicDropdownHover"
                        className={`dropdown-menu dropdown-menu-right show`}
                        aria-labelledby="basicDropdownHoverInvoker"
                        style={{ top: '40%' }}
                      >
                        <div
                          className="dropdown-item align-items-center d-flex pointer"
                          onClick={async () => {
                            const confirm = await confirmationAlert({
                              title: t('title.are_you_sure'),
                              message: t('message.confirmation_update'),
                              icon: 'warning',
                            })
                            if (confirm) {
                              handleBatchUpdate()
                            }
                          }}
                        >
                          <i className="fa fa-edit mr-1"></i> <span>{t('button.update_all')}</span>
                        </div>
                        <div
                          className="dropdown-item align-items-center d-flex pointer"
                          onClick={async () => {
                            const confirm = await confirmationAlert({
                              title: t('title.are_you_sure'),
                              message: t('message.confirmation_delete'),
                              icon: 'warning',
                            })
                            if (confirm) {
                              handleDelete()
                            }
                          }}
                        >
                          <i className="fa fa-trash mr-1"></i> <span>{t('button.delete')}</span>
                        </div>
                      </div>
                    )}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
        <hr className="mt-2 mb-2" />
        <div className="container my-3">
          <div className="row mb-3 d-none d-lg-flex">
            <div className="col-5 font-weight-bold">
              <span className="col-1 px-0 px-xl-3">
                <input
                  id={'product_checked' + row.store_uid}
                  checked={productChecked && groupChecked.length === items.length ? 'checked' : ''}
                  type={'checkbox'}
                  onChange={() => handleAllChecked()}
                />
              </span>
              <span className="col-3 px-0 mx-2">{t('title.product.title')}</span>
            </div>
            <div className="col-2 font-weight-bold">{t('title.product_detail')}</div>
            <div className="col-2 font-weight-bold">{t('title.request_amount')}</div>
            <div className="col-2 font-weight-bold">{t('title.price_offer')}</div>
            <div className="col-1 font-weight-bold">{t('title.update')}</div>
          </div>
          {items.map((item, key) => {
            return (
              <React.Fragment key={key}>
                <div id={`cart-${item.uid}`} className="row mb-3">
                  <div className="col-12 col-lg-5 col-md-7 d-flex align-content-center mb-2 pl-0 pl-xl-3">
                    <span className="col-1 px-0 px-lg-3">
                      <input
                        id={item.uid}
                        type={'checkbox'}
                        value={item.uid}
                        checked={groupChecked.includes(item.uid) ? 'checked' : ''}
                        onChange={(e) => handleChecked(e)}
                      />
                    </span>
                    <span className="col-3 px-0 mx-2">
                      <Link
                        to={`/product/detail/${
                          prettyUrl(item.product_title) + '/' + item.product_uid
                        }`}
                      >
                        <img src={item.product_thumbnail} className="img-fluid w-100" alt="" />
                      </Link>
                    </span>
                    <span className="col-8 px-0">
                      <Link
                        to={`/product/detail/${
                          prettyUrl(item.product_title) + '/' + item.product_uid
                        }`}
                        className="text-black"
                      >
                        <strong>{item.product_title}</strong>
                      </Link>
                    </span>
                  </div>
                  <div className="col-12 col-lg-2 col-md-5 mb-2">
                    <div>
                      <span className="font-weight-bold">{t('title.minimum_purchase')}: </span>
                      <div>{item.product_minimum_order_quantity}</div>
                    </div>
                    <div>
                      <span className="font-weight-bold">
                        {t('title.product.price')}{' '}
                        <small className="font-weight-bold">
                          / {capitalizeFirstWord(item.product_unit_type)}
                        </small>
                        :
                      </span>
                      <div>{currencyFormat(item.product_price, item.request_price_currency)}</div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-2 col-md-5 my-2">
                    <div className="d-block d-lg-none d-md-block">{t('title.request_amount')}</div>
                    <InputQuantity
                      id={item.uid}
                      value={parseInt(item.request_quantity)}
                      onChange={handleInputQuantity}
                    />
                  </div>
                  <div className="col-12 col-lg-2 col-md-5 my-2">
                    <div className="d-block d-lg-none d-md-block">{t('title.price_offer')}</div>
                    <InputCurrency
                      id={item.uid}
                      currency={currencyFormat(item.request_price_currency)}
                      value={item.request_price}
                      onChange={handleInputPrice}
                    />
                  </div>
                  <div className="col-12 col-lg-1 col-md-2 my-2 d-flex align-items-center align-items-lg-start">
                    <button
                      type="button"
                      onClick={() => handleUpdate(item)}
                      className={`btn btn-xs d-flex font-size-12 btn-primary mx-auto font-weight-bold`}
                    >
                      <i className="fa fa-edit"></i>
                      <span className="d-block d-lg-none">{t('button.update')}</span>
                    </button>
                  </div>
                </div>
                <hr className="mx-0 my-4" />
              </React.Fragment>
            )
          })}
        </div>
        <div className="container mt-2 mb-2 d-flex justify-content-center align-items-center justify-content-md-end flex-column flex-md-row">
          <div className="m-2 mt-3">{`${groupChecked.length} ${t(
            'alert.cart_product_selected',
          )}`}</div>
          <button
            type="button"
            onClick={() => {
              if (groupChecked.length) {
                let selecteds = []
                items.forEach((cart) => {
                  if (groupChecked.includes(cart.uid)) {
                    let obj = { ...cart }
                    obj.store_uid = row.store_uid
                    obj.store_name = row.store_name
                    selecteds.push(obj)
                  }
                })
                setQuotationItem(selecteds)
                handleModal('RFQ')
              } else {
                showAlert({
                  icon: 'warning',
                  message: t('alert.cart_product_not_selected'),
                  timer: 0,
                })
              }
            }}
            className={`d-flex btn btn-xs m-2 font-size-12 font-weight-bold ${
              groupChecked.length > 0 ? 'btn-primary' : 'btn-dark'
            }`}
          >
            <i className="fa fa-file-alt mr-1"></i> {t('button.product_selected_request')}
          </button>
        </div>
      </>
    )
  }
  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center">
          <Loading />
        </div>
      ) : (
        <>
          {datacard.items?.length > 0 ? (
            <>
              <Modal
                open={modal.rfq}
                onClose={handleModalClose}
                classNames={{
                  modal: 'col-10',
                  modalContainer: 'row justify-content-center',
                }}
              >
                <QuotationForm data={quotationItem} handleModal={handleModal} />
              </Modal>
              <Modal
                open={modal.address}
                onClose={() => {
                  handleModal('RFQ')
                }}
              >
                <AddressForm
                  initial={'cart'}
                  datas={addressInitial}
                  onCallback={() => {
                    handleModal('RFQ')
                  }}
                />
              </Modal>
              {datacard.items.map((row, key) => {
                return (
                  <div key={key}>
                    <div className="border border-width-2 box-shadow-1 p-3 border-color-3 borders-radius-6 container wide-card rounded mb-5">
                      <div className="row">
                        <div className="col-12">
                          <CardBody data={row} />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
              <Pagination data={datacard.pages} onChangePage={handlePageChange} />
            </>
          ) : (
            <>
              <div className="p-5 text-center font-size-20">
                <i className="fa fa-shopping-cart fa-3x mb-2"></i>
                <br />
                {t('message.cart_empty')}
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}

export default WideCardCart
