import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'
import { decryptCrypto, encryptCrypto } from '../../../utility/Encryption'
import { Buffer } from 'buffer'
import { getItem, setItem } from '../../../utility/LocalStorage'

const InteractorContentPageSearch = () => {
  const pageSearch = useCallback((payload, callback, loading) => {
    // SESSION
    const session_key = Buffer.from(payload.keywords.page_category + payload.limit).toString(
      'base64',
    )
    const session_data = getItem(session_key) ? decryptCrypto(getItem(session_key)) : null
    if (session_data && session_data?.expires > new Date().getTime()) {
      callback(session_data)
      loading(false)
    } else {
      ApiService.jsonRequest('/content-page/search', payload, (response) => {
        if (response.status_code === 200) {
          if (response.data) {
            callback(response.data)
            let session_data = { ...response.data }
            session_data.expires = new Date().getTime() + 1 * 3600 * 1000 // 1 Hours
            setItem(session_key, encryptCrypto(JSON.stringify(session_data)))
          }
        }
        loading(false)
      })
    }
  }, [])
  return { pageSearch }
}

export default InteractorContentPageSearch
