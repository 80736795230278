import React from 'react'
import {
  SliderSection,
  BannerSection,
  BannerFull,
  ProductLatest,
  ProductBestSeller,
  ProductRecentlyViewed,
} from '../../components/Main'
const Home = () => {
  return (
    <>
      <SliderSection />
      <div className="container">
        <div className="mb-5">
          <BannerSection />
        </div>
        <ProductLatest />
      </div>
      <div className="space-top-2 bg-gray-7 mb-3">
        <div className="container">
          <ProductBestSeller />
        </div>
      </div>
      <div className="container space-1">
        <BannerFull />
        <ProductRecentlyViewed />
      </div>
    </>
  )
}
export default Home
