import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useLocation } from 'react-router-dom'
import { Loading } from '../../components/Main'
import {
  InteractorAuthenticationOtpRequest,
  InteractorAuthenticationOtpVerify,
} from '../../interactors/Main'
import parser from 'react-html-parser'
import { confirmationAlert } from '../../utility/SweetAlert'
import useCountDown, { getReturnValues } from '../../hooks/useCountDown'
import { formatDate } from '../../utility/Helpers'
import moment from 'moment'

const OtpVerification = () => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const { OTPVerify } = InteractorAuthenticationOtpVerify()
  const { OTPRequest } = InteractorAuthenticationOtpRequest()
  const { token } = useParams()
  const location = useLocation()
  const [otpVerifyPayload, setOtpVerifyPayload] = useState({
    verify_token: token,
    otp_code: '',
  })
  const [optionsPayload, setOptionsPayload] = useState({
    type: 'VERIFY-EMAIL',
    email: '',
  })
  const expires = moment(
    formatDate(new Date().toString(), 'YYYY-MM-DD HH:mm:ss'),
    'YYYY-MM-DD HH:mm:ss',
  )
    .add(5, 'minutes')
    .format('YYYY-MM-DD HH:mm:ss')
  const { timer, setRepeatTimer } = useCountDown(expires)
  const [days, hours, minutes, seconds] = getReturnValues(timer)

  useEffect(() => {
    if (location.state) {
      setOptionsPayload({
        type: location.state?.type,
        email: location.state?.email,
      })
    }
  }, [location])
  const handlerOtpCode = (e) => {
    const { name, value } = e.target
    const re = /^[0-9\b]+$/
    if (value === '' || re.test(value)) {
      setOtpVerifyPayload({
        ...otpVerifyPayload,
        [name]: value,
      })
    }
  }

  const handleResendOtp = async (e) => {
    e.preventDefault()
    const confirm = await confirmationAlert({
      title: t('title.are_you_sure'),
      message: t('message.confirmation_resend_otp'),
      icon: 'warning',
    })
    if (confirm) {
      OTPRequest({
        target_type: 'EMAIL',
        target_identity: location.state.email,
        target_full_name: location.state.full_name,
        otp_category: location.state.type,
        account_uid: location.state.uid,
      })
      setRepeatTimer(expires)
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    OTPVerify(otpVerifyPayload, optionsPayload, setLoading)
  }

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center">
          <div className="px-10 py-10">
            <Loading />
          </div>
        </div>
      ) : (
        <>
          <div className="container">
            <div className="d-flex justify-content-center my-4 my-xl-8">
              <div className="row">
                <div className="col-md-12 ml-xl-0 mr-xl-auto">
                  <div className="border-bottom border-color-1 mb-6">
                    <h3 className="d-md-flex justify-content-md-center align-items-md-center font-size-26 font-weight-bold">
                      {t('menu.otp_verification')}
                    </h3>
                  </div>
                  <p className="mb-2">
                    {parser(t('message.otp_verification') + ' ' + optionsPayload?.email)}
                  </p>
                  <form onSubmit={onSubmit}>
                    <div className="js-form-message form-group">
                      <label className="form-label" htmlFor="signin">
                        {t('form.otp_code')}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        className="otp"
                        name="otp_code"
                        type="text"
                        placeholder=""
                        maxLength="6"
                        value={otpVerifyPayload.otp_code}
                        required
                        onChange={handlerOtpCode}
                      />
                    </div>
                    <div className="d-flex justify-content-end">
                      {days + hours + minutes + seconds <= 0 ? (
                        <div onClick={handleResendOtp} className="text-decoration-on pointer">
                          {t('title.resend_otp')}
                        </div>
                      ) : (
                        <div className="font-weight-bold">
                          {!isNaN(parseFloat(seconds)) && (
                            <>
                              {t('message.resend_otp') + ' '}
                              {minutes < 10 ? '0' + minutes.toString() : minutes}:
                              {seconds < 10 ? '0' + seconds.toString() : seconds}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="mb-6">
                      <div className="mb-3">
                        <button type="submit" className="btn btn-primary-dark-w px-5">
                          {t('button.send')}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default OtpVerification
