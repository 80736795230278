import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'
import { showAlert } from '../../../utility/SweetAlert'
import { useTranslation } from 'react-i18next'

const InteractorQuotationRequestUpdate = () => {
  const { t } = useTranslation()
  const requestUpdate = useCallback(
    (payload, status = 'APPROVED') => {
      ApiService.jsonRequest('/rfq-quotation/update', payload, (response) => {
        if (response.status_code === 200) {
          status === 'REJECTED'
            ? showAlert({
                icon: 'success',
                message: t('response_message.rfq_rejected'),
                timer: 2000,
                redirect: '/quotation/request',
              })
            : status === 'ADDRESS'
            ? showAlert({
                icon: 'success',
                message: t('response_message.rfq_address_update_success'),
                timer: 2000,
                redirect: '/quotation/request',
              })
            : showAlert({
                icon: 'success',
                message: t('response_message.rfq_approve'),
                timer: 2000,
                redirect: '/quotation/request',
              })
        } else {
          showAlert({
            icon: 'warning',
            message: t('response_message.send_failed'),
            timer: 2000,
            reload: true,
          })
        }
      })
    },
    [t],
  )
  return { requestUpdate }
}

export default InteractorQuotationRequestUpdate
