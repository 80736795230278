import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'
import { showAlert } from '../../../utility/SweetAlert'
import { useTranslation } from 'react-i18next'
import { removeCookies } from '../../../utility/Cookies'

const InteractorQuotationCartUpdate = () => {
  const { t } = useTranslation()
  const cartUpdate = useCallback(
    (payload, type) => {
      ApiService.jsonRequest('/rfq-cart/update', payload, (response) => {
        if (!type && type !== 'batch') {
          if (response.status_code === 200) {
            removeCookies('summary-header')
            showAlert({
              icon: 'success',
              message: t('response_message.cart_update_success'),
              timer: 2000,
              reload: true,
            })
          } else {
            showAlert({
              icon: 'warning',
              message: t('response_message.cart_update_failed'),
              timer: 2000,
              reload: true,
            })
          }
        }
      })
    },
    [t],
  )
  return { cartUpdate }
}

export default InteractorQuotationCartUpdate
