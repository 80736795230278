import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { FetchAccount } from '../../../redux/account/actionCreator'
import ApiService from '../../../services/ApiService'
import { encryptCrypto } from '../../../utility/Encryption'
import { setCookies } from '../../../utility/Cookies'
import { useTranslation } from 'react-i18next'
import { confirmationAlert, showAlert } from '../../../utility/SweetAlert'

const InteractorUpdateProfile = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const updateProfile = useCallback(
    (callback, payload, loading) => {
      confirmationAlert({
        message: t('alert.alert_changes'),
        icon: 'question',
      }).then((result) => {
        if (result === true) {
          ApiService.jsonRequest('/account-user/update', payload, (response) => {
            if (response.status_code === 200) {
              callback(response.data)
              setCookies({
                key: '_wsso',
                value: encryptCrypto(JSON.stringify(response.data)),
                expires: new Date().getTime() + 8 * 3600 * 1000, // 8 Hours
              })
              dispatch(FetchAccount(response.data))
              loading(false)
            }
          })
          showAlert({
            icon: 'success',
            message: t('alert.profile_edit_successful'),
            timer: false,
          })
        }
        loading(false)
      })
    },
    [dispatch, t],
  )
  return { updateProfile }
}

export default InteractorUpdateProfile
