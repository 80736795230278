import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { formatDate } from '../../utility/Helpers'
import {
  InteractorProductReviewSearch,
  InteractorProductReviewSummary,
} from '../../interactors/Main'
import { Loading, Pagination, Rating } from '../Main'
import { useTranslation } from 'react-i18next'

const ProductReview = (data) => {
  const { t } = useTranslation()
  const { product_uid, rating } = data.data
  const [loading, setLoading] = useState(true)
  const [payload, setPayload] = useState({
    page: 1,
    limit: 10,
    keywords: {
      text_search: '',
      rating: '',
      product_uid: product_uid,
    },
  })

  const [dataReview, setDataReview] = useState([])
  const [dataReviewSummary, setDataReviewSummary] = useState({
    star_one: 0,
    star_two: 0,
    star_three: 0,
    star_four: 0,
    star_five: 0,
  })

  const { reviewSearch } = InteractorProductReviewSearch()
  const { reviewSummary } = InteractorProductReviewSummary()
  const handlePageChange = (page) => {
    let objPayload = { ...payload }
    objPayload.page = page
    setLoading(true)
    reviewSearch(objPayload, handleResponse, setLoading)
    setPayload(objPayload)
  }
  const handleResponseSummary = (row) => {
    setDataReviewSummary({
      star_one: row.star_one,
      star_two: row.star_two,
      star_three: row.star_three,
      star_four: row.star_four,
      star_five: row.star_five,
    })
  }
  const handleResponse = useCallback(
    (row) => {
      setDataReview(row)
      reviewSummary(
        {
          product_uid: product_uid,
        },
        handleResponseSummary,
      )
    },
    [product_uid, reviewSummary],
  )

  const handleFilterReview = useCallback(
    (star) => {
      const objPayload = {
        ...payload,
        keywords: {
          ...payload.keywords,
          rating: star,
        },
      }

      reviewSearch(objPayload, handleResponse, setLoading)
    },
    [handleResponse, payload, reviewSearch],
  )

  useEffect(() => {
    reviewSearch(payload, handleResponse, setLoading)
  }, [reviewSearch, data.product_uid, payload, handleResponse])

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center height-100vh">
          <Loading />
        </div>
      ) : (
        <div className="mb-4 px-lg-4">
          <div className="row mb-8">
            <div className="col-md-6">
              <div className="mb-3">
                <h3 className="font-size-18 mb-6">
                  {t('title.total_rated')}{' '}
                  {dataReviewSummary.star_one +
                    dataReviewSummary.star_two +
                    dataReviewSummary.star_three +
                    dataReviewSummary.star_four +
                    dataReviewSummary.star_five}
                </h3>
                <h2 className="font-size-30 font-weight-bold text-lh-1 mb-0">{rating ?? 0}</h2>
                <div className="text-lh-1 mt-2">{t('title.average_rating')}</div>
              </div>
              <ul className="list-unstyled">
                <li className="py-1">
                  <div
                    className="row align-items-center mx-gutters-2 font-size-1 pointer"
                    onClick={() => handleFilterReview(5)}
                  >
                    <div className="col-auto mb-2 mb-md-0">
                      <div className="text-warning text-ls-n2 font-size-16" style={{ width: 80 }}>
                        <small className="fas fa-star" />
                        <small className="fas fa-star" />
                        <small className="fas fa-star" />
                        <small className="fas fa-star" />
                        <small className="fas fa-star" />
                      </div>
                    </div>
                    <div className="col-auto mb-2 mb-md-0">
                      <div className="progress ml-xl-5" style={{ height: 10, width: 200 }}>
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: '100%' }}
                          aria-valuenow={100}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </div>
                    <div className="col-auto text-right">
                      <span>{dataReviewSummary.star_five}</span>
                    </div>
                  </div>
                </li>
                <li className="py-1">
                  <div
                    className="row align-items-center mx-gutters-2 font-size-1 pointer"
                    onClick={() => handleFilterReview(4)}
                  >
                    <div className="col-auto mb-2 mb-md-0">
                      <div className="text-warning text-ls-n2 font-size-16" style={{ width: 80 }}>
                        <small className="fas fa-star" />
                        <small className="fas fa-star" />
                        <small className="fas fa-star" />
                        <small className="fas fa-star" />
                        <small className="far fa-star text-muted" />
                      </div>
                    </div>
                    <div className="col-auto mb-2 mb-md-0">
                      <div className="progress ml-xl-5" style={{ height: 10, width: 200 }}>
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: '100%' }}
                          aria-valuenow={100}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </div>
                    <div className="col-auto text-right">
                      <span>{dataReviewSummary.star_four}</span>
                    </div>
                  </div>
                </li>
                <li className="py-1">
                  <div
                    className="row align-items-center mx-gutters-2 font-size-1 pointer"
                    onClick={() => handleFilterReview(3)}
                  >
                    <div className="col-auto mb-2 mb-md-0">
                      <div className="text-warning text-ls-n2 font-size-16" style={{ width: 80 }}>
                        <small className="fas fa-star" />
                        <small className="fas fa-star" />
                        <small className="fas fa-star" />
                        <small className="far fa-star text-muted" />
                        <small className="far fa-star text-muted" />
                      </div>
                    </div>
                    <div className="col-auto mb-2 mb-md-0">
                      <div className="progress ml-xl-5" style={{ height: 10, width: 200 }}>
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: '53%' }}
                          aria-valuenow={53}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </div>
                    <div className="col-auto text-right">
                      <span>{dataReviewSummary.star_three}</span>
                    </div>
                  </div>
                </li>
                <li className="py-1">
                  <div
                    className="row align-items-center mx-gutters-2 font-size-1 pointer"
                    onClick={() => handleFilterReview(2)}
                  >
                    <div className="col-auto mb-2 mb-md-0">
                      <div className="text-warning text-ls-n2 font-size-16" style={{ width: 80 }}>
                        <small className="fas fa-star" />
                        <small className="fas fa-star" />
                        <small className="far fa-star text-muted" />
                        <small className="far fa-star text-muted" />
                        <small className="far fa-star text-muted" />
                      </div>
                    </div>
                    <div className="col-auto mb-2 mb-md-0">
                      <div className="progress ml-xl-5" style={{ height: 10, width: 200 }}>
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: '20%' }}
                          aria-valuenow={20}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </div>
                    <div className="col-auto text-right">
                      <span>{dataReviewSummary.star_two}</span>
                    </div>
                  </div>
                </li>
                <li className="py-1">
                  <div
                    className="row align-items-center mx-gutters-2 font-size-1 pointer"
                    onClick={() => handleFilterReview(2)}
                  >
                    <div className="col-auto mb-2 mb-md-0">
                      <div className="text-warning text-ls-n2 font-size-16" style={{ width: 80 }}>
                        <small className="fas fa-star" />
                        <small className="far fa-star text-muted" />
                        <small className="far fa-star text-muted" />
                        <small className="far fa-star text-muted" />
                        <small className="far fa-star text-muted" />
                      </div>
                    </div>
                    <div className="col-auto mb-2 mb-md-0">
                      <div className="progress ml-xl-5" style={{ height: 10, width: 200 }}>
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: '1%' }}
                          aria-valuenow={1}
                          aria-valuemin={0}
                          aria-valuemax={100}
                        />
                      </div>
                    </div>
                    <div className="col-auto text-right">
                      <span>{dataReviewSummary.star_one}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          {dataReview?.items?.length > 0 ? (
            <>
              {dataReview.items?.map((item, index) => (
                <div key={index} className="border-bottom border-color-1 pb-4 mb-4">
                  <div className="d-flex justify-content-between align-items-center text-secondary font-size-1 mb-2">
                    <Rating rating={item.rating} />
                  </div>
                  <p className="text-break">{item.feedback}</p>
                  <div className="mb-2">
                    <strong>{item.full_name}</strong>
                    <span className="font-size-13 text-gray-23 ml-3">
                      {formatDate(item.created_date, 'DD/MM/YYYY HH:mm')}
                    </span>
                  </div>
                </div>
              ))}
              <Pagination data={dataReview.pages} onChangePage={handlePageChange} />
            </>
          ) : (
            t('title.unrating')
          )}
        </div>
      )}
    </>
  )
}

ProductReview.propTypes = {
  data: PropTypes.any,
}

export default ProductReview
