import axios from 'axios'
import { getCookies, removeCookies } from '../utility/Cookies'
import { downloadFile } from '../utility/Helpers'
import { showAlert } from '../utility/SweetAlert'
import i18n from './../utility/i18n'

function i18nValue(key) {
  let value = i18n.t(key)
  if (value === undefined) {
    value = key
  }
  return value
}

const ApiService = {
  jsonRequest: async (path, payload, callback) => {
    const url = process.env.REACT_APP_API_BASE + path
    const bearer = getCookies('_wide')
    if (path !== '/authorization/request' && !bearer) {
      callback(null)
    }
    const headers = {
      'Content-Type': 'application/json',
    }
    if (bearer) {
      headers.Authorization = 'Bearer ' + bearer
    }
    const requestOptions = {
      method: 'POST',
      url: url,
      headers: headers,
      data: payload,
      withCredentials: true,
    }
    axios(requestOptions)
      .then(function (response) {
        callback(response.data)
      })
      .catch(function (error) {
        const data = error.response.data
        if (data?.messages?.toUpperCase() === 'UNAUTHORIZED' && data?.status_code === 401) {
          removeCookies('_wide')
          showAlert({
            icon: 'warning',
            message: i18nValue('message.token_expired'),
            timer: 2000,
            reload: true,
          })
        }
        callback(data)
      })
  },
  formRequest: async (path, payload, callback) => {
    const url = process.env.REACT_APP_API_BASE + path
    const bearer = getCookies('_wide')
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + bearer,
    }
    let formdata = new FormData()
    Object.keys(payload).map((key) => formdata.append(key, payload[key]))
    const requestOptions = {
      method: 'POST',
      url: url,
      headers: headers,
      data: formdata,
      withCredentials: true,
    }
    axios(requestOptions)
      .then(function (response) {
        callback(response.data)
      })
      .catch(function (error) {
        const data = error.response.data
        if (data?.messages?.toUpperCase() === 'UNAUTHORIZED' && data?.status_code === 401) {
          removeCookies('_wide')
          showAlert({
            icon: 'warning',
            message: i18nValue('message.token_expired'),
            timer: 2000,
            reload: true,
          })
        }
        callback(data)
      })
  },
  fileRequest: async (path, payload, filename, target_type) => {
    const url = process.env.REACT_APP_API_BASE + path
    const bearer = getCookies('_wide')
    const headers = {
      'Content-Type': 'application/json',
    }
    if (bearer) {
      headers.Authorization = 'Bearer ' + bearer
    }
    const requestOptions = {
      method: 'POST',
      url: url,
      headers: headers,
      data: payload,
      withCredentials: true,
      responseType: 'blob',
    }
    axios(requestOptions)
      .then((response) => new Blob([response.data], { type: target_type }))
      .then((blob) => {
        downloadFile(blob, filename)
      })
      .catch(function (error) {
        const data = error.response.data
        if (data?.messages?.toUpperCase() === 'UNAUTHORIZED' && data?.status_code === 401) {
          removeCookies('_wide')
          showAlert({
            icon: 'warning',
            message: i18n.t('message.token_expired'),
            timer: 2000,
            reload: true,
          })
          return
        }
      })
  },
}

export default ApiService
