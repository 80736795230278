import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Breadcrumb, ProductLatest } from '../../components/Main'
import { useSelector } from 'react-redux'

const PageHelp = () => {
  const { t } = useTranslation()
  const { account } = useSelector((state) => state.account)
  return (
    <>
      <Breadcrumb title={t('menu.help')} />
      <div className="container">
        <div className="row mb-10">
          <div className="col-md-8 col-xl-9">
            <div className="mr-xl-6">
              <div className="border-bottom border-color-1 mb-5">
                <h3 className="section-title mb-0 pb-2 font-size-25">{t('menu.help')}</h3>
              </div>
              <h5 className="font-size-14 font-weight-bold mb-3">Customer Care</h5>
              <div className="font-size-24 font-weight-bold mb-2">(021) 80623840</div>
              <div className="font-size-16 font-weight-bold mb-6">cs@adaloka.com</div>
              <address className="mb-6 text-lh-23">
                <div className="font-weight-bold mb-2">PT. Widea Dagang Nusantara</div>
                Jl. Jenderal Sudirman No.Kav 22-23, RT.10/RW.1, Kuningan, Karet, Kecamatan
                Setiabudi, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12920
              </address>
              {account && (
                <div className="font-size-16 font-weight-bold mb-6 col-xs-12 col-sm-6 col-lg-4 row">
                  <Link
                    to={`/chat?target=CS`}
                    className="btn btn-block btn-danger btn-xs mt-1 font-weight-bold pointer text-nowrap"
                  >
                    <i className="fa fa-comments mr-2" />
                    {t('button.chat_cs')}
                  </Link>
                </div>
              )}
            </div>
          </div>
          {account && (
            <div className="col-md-4 col-xl-3">
              <div className="mt-md-3 mt-1">
                <ProductLatest limit={3} mode="list" />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default PageHelp
