import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ApiService from '../../../services/ApiService'
import { decryptCrypto, encryptCrypto } from '../../../utility/Encryption'
import { getItem, setItem } from '../../../utility/LocalStorage'
import { showAlert } from '../../../utility/SweetAlert'
import { Buffer } from 'buffer'

const InteractorProductItemView = () => {
  const { t } = useTranslation()
  const recentlyViewed = (row) => {
    const session_key = Buffer.from('recently-viewed').toString('base64')
    const recently_viewed = getItem(session_key) ? decryptCrypto(getItem(session_key)) : []
    let data = []
    const obj = {
      store_name: row.store_name,
      product_title: row.product_title,
      uid: row.uid,
      product_thumbnail: row?.product_media[0]?.product_media_url,
      product_price: row.product_price,
      product_price_currency: row.product_price_currency,
      product_rating: row.product_rating,
      product_sold: row.product_sold,
      store_tax_type: row.store_tax_type,
      origin_city_name: row.origin_city_name,
      store_account_uid: row.store_account_uid,
    }
    data.push(obj)
    if (recently_viewed) {
      let i = 1
      recently_viewed.forEach((item) => {
        if (item.uid !== row.uid) {
          if (i < 12) {
            data.push(item)
          }
        }
        i++
      })
    }
    setItem(session_key, encryptCrypto(JSON.stringify(data)))
  }
  const productView = useCallback(
    (payload, callback, loading) => {
      loading(true)
      ApiService.jsonRequest('/product-item/view', payload, (response) => {
        if (response.status_code === 200 && response.data.status === 'LIVE') {
          callback(response.data)
          recentlyViewed(response.data)
        } else {
          showAlert({
            icon: 'info',
            message: t(`message.product_not_found`),
            timer: 2000,
            redirect: '/product',
          })
        }
        loading(false)
      })
    },
    [t],
  )
  return { productView }
}

export default InteractorProductItemView
