import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReCAPTCHA from 'react-google-recaptcha'
import { InteractorAccountUserPasswordForgot } from '../../../interactors/Main'
import { getCookies } from '../../../utility/Cookies'
import { useNavigate } from 'react-router'
import { Loading } from '../../../components/Main'

const ForgotPassword = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { requestChangePassword } = InteractorAccountUserPasswordForgot()
  const [formIsValid, setFormIsValid] = useState(false)
  const recaptchaRef = React.createRef()
  const [changePasswordPayload, setChangePasswordPayload] = useState({
    email_address: '',
  })

  useEffect(() => {
    if (getCookies('_wsso')) {
      return navigate('/')
    }
  })

  const inputOnhandler = (e) => {
    let { name, value } = e.target
    setChangePasswordPayload({
      ...changePasswordPayload,
      [name]: value,
    })
  }

  const recaptchaChange = (value) => {
    if (value) {
      setFormIsValid(true)
    }
  }
  const onSubmit = (e) => {
    e.preventDefault()
    const recaptchaValue = recaptchaRef.current.getValue()
    if (!recaptchaValue) {
      setFormIsValid(false)
      return
    }
    setLoading(true)
    requestChangePassword(changePasswordPayload, setLoading)
  }

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center">
          <div className="px-10 py-10">
            <Loading />
          </div>
        </div>
      ) : (
        <>
          <div className="container">
            <div className="d-flex justify-content-center my-4 my-xl-8">
              <div className="row">
                <div className="col-md-12 ml-xl-0 mr-xl-auto">
                  <div className="border-bottom border-color-1 mb-6">
                    <h3 className="d-md-flexjustify-content-md-center align-items-md-center font-size-26 font-weight-bold">
                      {t('menu.forgot_password')}
                    </h3>
                  </div>
                  <p className="mb-4">{t('message.forgot_password')}</p>
                  <form onSubmit={onSubmit}>
                    <div className="js-form-message form-group">
                      <label className="form-label" htmlFor="forgot-password">
                        {t('form.email_address')}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        name="email_address"
                        id="forgot-password"
                        placeholder={t('form.email_address')}
                        aria-label={t('form.email_address')}
                        value={changePasswordPayload.email_address}
                        required=""
                        data-msg={t('message.invalid_email_address')}
                        data-error-class="u-has-error"
                        data-success-class="u-has-success"
                        onChange={inputOnhandler}
                      />
                    </div>
                    <div className="js-form-message form-group">
                      <ReCAPTCHA
                        style={{ display: 'inline-block' }}
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        onChange={recaptchaChange}
                      />
                    </div>
                    <div className="mb-6">
                      <div className="mb-3">
                        <button
                          disabled={!changePasswordPayload.email_address || !formIsValid}
                          type="submit"
                          className="btn btn-primary-dark-w px-5"
                        >
                          {t('button.send')}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default ForgotPassword
