import React from 'react'

const Rating = (data) => {
  const total = data?.rating ?? 0

  return (
    <div className="text-warning">
      {[...Array(Math.floor(total))].map((key, idx) => (
        <i id={key} key={idx} className="fas fa-star" />
      ))}
      {(total - Math.floor(total) && total - Math.floor(total)) !== 0 && (
        <i className={'fas fa-star-half-alt'} />
      )}
      {[...Array(5 - Math.ceil(total))].map((key, idx) => (
        <i
          id={key}
          key={idx}
          className={`far fa-star ${total > 0 ? 'text-warning' : 'text-muted'}`}
        />
      ))}
    </div>
  )
}

export default Rating
