import React from 'react'
import PropTypes from 'prop-types'
import FeatherIcon from 'feather-icons-react'

const FilterRatingStar = ({ ratingStar }) => {
  return (
    <>
      {[...Array(5)].map((_, index) => {
        const ratingValue = index + 1
        return (
          <label key={index}>
            <FeatherIcon
              className="star"
              icon="star"
              size="16"
              color={ratingValue <= ratingStar ? ' #F8BE2D' : 'grey'}
              fill={ratingValue <= ratingStar ? ' #F8BE2D' : 'grey'}
            />
          </label>
        )
      })}
    </>
  )
}

FilterRatingStar.propTypes = {
  ratingStar: PropTypes.number,
}

export default FilterRatingStar
