import { useCallback, useState } from 'react'
import ApiService from '../../../services/ApiService'
import { setCookies } from '../../../utility/Cookies'
import { encryptCrypto } from '../../../utility/Encryption'

const InteractorAccountUserStore = () => {
  const [seller] = useState({})

  const accountStoreCheck = useCallback((payload) => {
    const { ...body } = payload
    payload = {
      ...body,
      source: 'CMS',
    }
    ApiService.jsonRequest('/store-account/view', payload, (response) => {
      if (response.status_code === 200) {
        setCookies({
          key: '_wssc',
          value: encryptCrypto(JSON.stringify(response.data)),
          expires: new Date().getTime() + 8 * 3600 * 1000, // 8 Hours
        })
      }
      window.location.href = '/'
    })
  }, [])

  return { accountStoreCheck, seller }
}

export default InteractorAccountUserStore
