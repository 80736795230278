import React, { useEffect } from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import RoutePath from '../routes/RoutePath'
import { setPageTitle } from '../utility/SearchEngine'
import { useSelector } from 'react-redux'

export default function AppContent() {
  let currentPath = useLocation()
  const { account } = useSelector((state) => state.account)
  useEffect(() => {
    setPageTitle(RoutePath, currentPath)
  }, [currentPath])

  function authentication(outlet) {
    if (!account) {
      return <Navigate to="/account/signin" />
    }
    if (
      account &&
      account?.must_change_password === 'Y' &&
      currentPath.pathname !== '/account/change-password'
    ) {
      return <Navigate to="/account/change-password?next=/account/profile" />
    }
    return outlet
  }
  return (
    <>
      <main id="content" role="main">
        <Routes>
          {RoutePath.map((route, key) => {
            return (
              <Route
                key={key}
                path={route.path}
                title={route.title}
                element={route.auth ? authentication(route.element) : route.element}
              />
            )
          })}
        </Routes>
      </main>
    </>
  )
}
