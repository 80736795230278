import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { showAlert } from './../../../utility/SweetAlert'
import { useSearchParams } from 'react-router-dom'
import { setCookies } from '../../../utility/Cookies'
import { encryptCrypto } from '../../../utility/Encryption'
import { useDispatch, useSelector } from 'react-redux'
import { FetchAccount } from '../../../redux/account/actionCreator'

const InteractorAccountUserPasswordChange = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { account } = useSelector((state) => state.account)
  const [searchParams] = useSearchParams()

  const changePassword = useCallback(
    (payload) => {
      payload = {
        ...payload,
      }
      ApiService.jsonRequest('/account-user/change-password', payload, (response) => {
        if (response.status_code === 200) {
          showAlert({
            icon: 'success',
            message: t(`response_message.password_has_been_changed`),
            timer: 2000,
          })
          if (payload?.must_change_password === 'N') {
            // SET NEW COOKIES
            const objAccount = { ...account }
            objAccount.must_change_password = 'N'
            setCookies({
              key: '_wsso',
              value: encryptCrypto(JSON.stringify(objAccount)),
              expires: new Date().getTime() + 8 * 3600 * 1000, // 8 Hours
            })
            dispatch(FetchAccount(objAccount))
          }
          if (searchParams.get('next') === '/') {
            navigate('/account/signin', { replace: true })
          } else {
            window.location = searchParams.get('next')
          }
        } else {
          showAlert({
            icon: 'error',
            message: t(`response_message.${response.messages}`),
            timer: 2000,
          })
        }
      })
    },
    [t, searchParams, account, dispatch, navigate],
  )

  return { changePassword }
}

export default InteractorAccountUserPasswordChange
