import React from 'react'
import { Breadcrumb } from '../../components/Main.js'
import { ProductSearch } from '../../components/Main'
import { useTranslation } from 'react-i18next'
const ProductIndex = () => {
  const { t } = useTranslation()
  return (
    <>
      <Breadcrumb title={t('title.product.title')} />
      <div className="container">
        <div className="row mb-8">
          <ProductSearch />
        </div>
      </div>
    </>
  )
}

export default ProductIndex
