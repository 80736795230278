import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { currencyFormat, findByArray, formatDate, numberFormat } from '../../utility/Helpers'

const InteractorCsvQuotation = () => {
  const { t } = useTranslation()
  const csvQuotation = useCallback(
    (data, callback) => {
      const headers = [
        { label: 'No', key: 'no' },
        { label: t('title.purchase_request_number'), key: 'purchase_request_number' },
        { label: t('title.seller_name'), key: 'seller_name' },
        { label: t('title.status'), key: 'status' },
        { label: t(`title.rfq_number`), key: 'request_code' },
        { label: t(`title.rfq_date`), key: 'created_date' },
        { label: t(`invoice.payment_method`), key: 'payment_method' },
        { label: t(`title.delivery_type`), key: 'delivery_type' },
        { label: t(`title.note`), key: 'note' },
        { label: t(`title.ship_to`), key: 'destination_address' },
        { label: t(`title.ship_consignee`), key: 'ship_consignee' },
        { label: t('title.product.title'), key: 'product_title' },
        { label: t('title.request_quantity'), key: 'request_quantity' },
        { label: t('title.request_price'), key: 'request_price' },
        { label: t('title.offer_quantity'), key: 'offer_quantity' },
        { label: t('title.offer_price'), key: 'offer_price' },
        { label: t('title.subtotal_product'), key: 'subtotal_product' },
        { label: t('title.delivery_cost'), key: 'subtotal_delivery' },
        { label: t('title.delivery_insurance_cost'), key: 'subtotal_insurance' },
        { label: t('title.total_offer'), key: 'total_offer' },
      ]

      // TOTAL SUM
      let summary_total = []
      data?.forEach((summary) => {
        let total_approved = 0
        summary?.quotation_items?.forEach((item) => {
          total_approved += item.product_approved_quantity * item.product_approved_price
        })
        summary_total.push({
          uid: summary.uid,
          total: total_approved,
        })
      })

      // MAPING ROW
      let results = []
      let subtotal = 0
      data?.forEach((row, idx) => {
        row?.quotation_items?.forEach((item, key) => {
          subtotal = findByArray(summary_total, 'uid', item.request_quotation_uid).total
          const obj = {
            no: key === 0 ? idx + 1 : '',
            purchase_request_number: key === 0 ? row.purchase_request_number : '',
            seller_name: key === 0 ? row.store_name : '',
            status: key === 0 ? t(`initial_status.${row.status}`) : '',
            request_code: key === 0 ? row.request_code : '',
            created_date: key === 0 ? formatDate(row.created_date, 'DD/MM/YYYY HH:mm') : '',
            payment_method: key === 0 ? t(`initial_status.${row.request_payment_type}`) : '',
            delivery_type: key === 0 ? t(`initial_status.${row.delivery_type}`) : '',
            note: key === 0 ? row.request_quotation_note : '',
            destination_address: key === 0 ? row.destination_address : '',
            ship_consignee:
              key === 0 ? row.consignee_full_name + ' / ' + row.consignee_phone_number : '',
            product_title: item.product_title,
            request_quantity: numberFormat(item.product_request_quantity),
            request_price: currencyFormat(
              item.product_request_price,
              item.product_request_price_currency,
            ),
            offer_quantity: numberFormat(item.product_approved_quantity),
            offer_price:
              item.product_approved_price !== 0
                ? currencyFormat(item.product_approved_price, item.product_approved_price_currency)
                : 0,
            subtotal_product:
              key === 0
                ? subtotal !== 0
                  ? currencyFormat(subtotal, item.product_request_price_currency)
                  : 0
                : '',
            subtotal_delivery:
              key === 0
                ? subtotal !== 0
                  ? currencyFormat(row.delivery_cost, row.delivery_cost_currency)
                  : 0
                : '',
            subtotal_insurance:
              key === 0
                ? subtotal !== 0
                  ? currencyFormat(row.delivery_insurance_cost, row.delivery_insurance_currency)
                  : 0
                : '',
            total_offer:
              key === 0
                ? subtotal !== 0
                  ? currencyFormat(
                      subtotal + row.delivery_cost + row.delivery_insurance_cost,
                      item.product_request_price_currency,
                    )
                  : 0
                : '',
          }
          results.push(obj)
        })
      })

      callback({
        headers: headers,
        data: results,
        filename: 'RFQ-' + new Date().getTime() + '.csv',
      })
    },
    [t],
  )
  return { csvQuotation }
}

export default InteractorCsvQuotation
