import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ProductItemSearch } from '../../redux/product/search/actionCreator'
import { capitalizeFirstWord } from '../../utility/Helpers'
import { InteractorLocationProvince } from './../../interactors/Main'

export default function FilterProvince() {
  const { t } = useTranslation()
  const { listProvince, listProvinceFilter } = InteractorLocationProvince()
  const dispatch = useDispatch()
  const { payload } = useSelector((state) => state.productItemSearch)
  const [showAll, setShowAll] = useState(false)
  const [province, setProvince] = useState([])
  const handleLoadFilter = useCallback(
    (data) => {
      listProvinceFilter(data, setProvince)
    },
    [listProvinceFilter],
  )
  useEffect(() => {
    if (showAll) {
      listProvince(setProvince)
    } else {
      listProvince(handleLoadFilter)
    }
  }, [handleLoadFilter, listProvince, payload, showAll])

  const handlerChecked = (e) => {
    const { value, checked } = e.target
    let prevPayload = { ...payload }

    if (checked) {
      prevPayload.keywords.province_uid.push(value)
    } else {
      prevPayload.keywords.province_uid = prevPayload.keywords.province_uid.filter((remove) => {
        return remove !== value
      })
    }
    dispatch(ProductItemSearch(prevPayload))
  }

  const handleShowHideProvince = () => {
    const type = !showAll
    setShowAll(type)
  }

  return (
    <div className="mb-5">
      <div className="border-bottom border-color-1 mb-3">
        <h4 className="section-title section-title__sm mb-0 pb-2 font-size-18">
          {t('title.location')}
        </h4>
      </div>
      {/* Checkboxes */}
      {province?.map((row, index) => {
        return (
          <div
            key={index}
            className="form-group d-flex align-items-center justify-content-between mb-2 pb-1"
          >
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id={row.uid}
                name={row.province_name}
                value={row.uid}
                onChange={handlerChecked}
              />
              <label className="custom-control-label" htmlFor={row.uid}>
                {capitalizeFirstWord(row.province_name)}
              </label>
            </div>
          </div>
        )
      })}
      <div className="row align-items-center">
        <div className="col-12">
          <div
            className="btn btn-xs btn-primary font-weight-bold pointer"
            onClick={handleShowHideProvince}
          >
            {showAll ? t('button.show_less') : t('button.show_all')}
          </div>
        </div>
      </div>
      {/* End Checkboxes */}
    </div>
  )
}
