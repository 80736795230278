import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { InteractorCategoryMain } from './../../interactors/Main'
import { FilterHeaderKeyword, SearchMobile, SidebarCategory } from '../../components/Main'
import useHover from '../../hooks/useHover'
import { categoryUrl } from '../../utility/Helpers.js'
import { useSelector } from 'react-redux'
import LogoWhite from '../../assets/image/logo/logo_white.png'
import AlertMessage from '../../components/alert/AlertMessage'

const HeaderMain = () => {
  const { t } = useTranslation()
  const shopRef = useRef()
  const { account } = useSelector((state) => state.account)
  const { header_info } = useSelector((state) => state.summaryHeader)
  const [masterCategory, setMasterCategory] = useState([])
  const [isOpenCategory, setIsOpenCategory] = useState(false)
  const [isLogin, setIsLogin] = useState(false)
  const openMenu = (e) => e.currentTarget.classList.add('hs-mega-menu-opened')
  const closeMenu = (e) => e.currentTarget.classList.remove('hs-mega-menu-opened')
  const handlerOpenDropdown = () => setIsOpenCategory(!isOpenCategory)
  const handleCloseDropdown = () => setIsOpenCategory(false)
  const [isOpen, handleOpen] = useHover(shopRef)
  const { onMouseOver, onMouseLeave } = handleOpen
  const { categoryMainSummary } = InteractorCategoryMain()
  useEffect(() => {
    if (account) {
      setIsLogin(true)
    }
    categoryMainSummary(setMasterCategory)
  }, [account, categoryMainSummary])

  return (
    <>
      <div className="py-2  py-xl-4 bg-primary-down-lg">
        <div className="container my-0dot5 my-xl-0">
          <div>
            {/* <AlertMessage
              action
              type="danger"
              message={
                <div>
                  <strong>Pemberitahuan!</strong> Pertanggal 9 Oktober 2023 metode pembayaran
                  tagihan hanya dapat dilakukan dengan menggunakan Virtual Akun (VA). Semua
                  transaksi pembayaran akan masuk pada SALDO penjual. Silahkan tambahkan No.
                  Rekening sesuai dengan identitas yang terdaftar pada menu Keuangan. <br />
                  Jika ada pertanyaan silahkan hubungi team kami pada email:{' '}
                  <strong>cs@adaloka.com</strong>
                </div>
              }
            /> */}
          </div>
          <div className="row align-items-center justify-content-between">
            <div className="col-6 col-lg-auto px-2">
              <div className="row align-items-center flex-lg-row justify-content-center justify-content-lg-between order-1 order-xl-0 u-header__navbar py-0">
                <div className="col-3">
                  <SidebarCategory>
                    <span id="hamburgerTriggerMenu" className="u-hamburger__box">
                      <span className="u-hamburger__inner" />
                    </span>
                  </SidebarCategory>
                </div>
                <div className="col-9">
                  <Link
                    className="navbar-brand u-header__navbar-brand u-header__navbar-brand-center"
                    to="/"
                  >
                    <img className="d-none d-xl-block" alt="adaloka.com" src="/logo.png" />
                    <img className="d-block d-xl-none" alt="adaloka.com" src={LogoWhite} />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col d-none d-xl-block">
              <nav className="js-mega-menu navbar navbar-expand-md u-header__navbar u-header__navbar--no-space">
                <div id="navBar" className="collapse navbar-collapse u-header__navbar-collapse">
                  <ul className="navbar-nav u-header__navbar-nav">
                    <li className="nav-item u-header__nav-item">
                      <Link className="nav-link u-header__nav-link" to="/">
                        {t('menu.home')}
                      </Link>
                    </li>
                    <li className="nav-item u-header__nav-item">
                      <Link className="nav-link u-header__nav-link" to="/product">
                        {t('menu.product')}
                      </Link>
                    </li>
                    <li className="nav-item u-header__nav-item">
                      <Link className="nav-link u-header__nav-link" to="/page/how-to-order">
                        {t('menu.how_to_order')}
                      </Link>
                    </li>
                    <li className="nav-item u-header__nav-item">
                      <Link className="nav-link u-header__nav-link" to="/page/how-to-pay">
                        {t('menu.how_to_pay')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            <div className="d-none d-xl-block col-md-auto">
              <div className="d-flex">
                <i className="ec ec-support text-primary font-size-50" />
                <div className="ml-2">
                  <div className="phone">
                    <strong>Support</strong>
                    <span className=""> (021) 80623840</span>
                  </div>
                  <div className="email">
                    E-mail: <span className="">cs@adaloka.com</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-xl-none d-block col-auto px-0 position-static">
              <ul className="d-flex list-unstyled mb-0 align-items-center">
                <SearchMobile />
                <li className="col px-2">
                  <Link
                    to={`/account/${isLogin ? 'profile' : 'signin'}`}
                    className="text-white"
                    data-placement="top"
                    title={t('menu.my_account')}
                  >
                    <i className="font-size-22 ec ec-user" />
                  </Link>
                </li>
                <li className="col px-2">
                  <Link
                    to="/cart"
                    className="text-white position-relative d-flex"
                    data-placement="top"
                    title={t('menu.cart')}
                  >
                    <i className="font-size-22 ec ec-shopping-bag text-white" />
                    {header_info.cart.total > 0 && (
                      <span className="width-22 height-22 bg-dark position-absolute d-flex align-items-center justify-content-center rounded-circle left-12 top-8 font-weight-bold font-size-12 text-white">
                        {header_info.cart.total}
                      </span>
                    )}
                  </Link>
                </li>
                <li className="col px-2">
                  <Link
                    to="/chat"
                    className="text-white position-relative d-flex"
                    data-placement="top"
                    title={t('menu.chat')}
                  >
                    <i className="font-size-22 fa fa-comments" />
                    {header_info.chat_unread > 0 && (
                      <span className="width-22 height-22 bg-red position-absolute flex-content-center text-white rounded-circle left-12 top-8 font-weight-bold font-size-11">
                        {header_info.chat_unread < 100 ? header_info.chat_unread : '99+'}
                      </span>
                    )}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="d-none d-xl-block bg-primary">
        <div className="container">
          <div className="row align-items-stretch min-height-50">
            <div className="col-md-auto d-none d-xl-flex align-items-end">
              <div className="max-width-270 min-width-270">
                <div id="basicsAccordion">
                  <div className="card border-0 rounded-0">
                    <div
                      className="card-header bg-primary rounded-0 card-collapse border-0"
                      id="basicsHeadingOne"
                    >
                      <button
                        type="button"
                        className="btn-link btn-remove-focus btn-block d-flex card-btn py-3 text-lh-1 px-4 shadow-none btn-primary rounded-top-lg border-0 font-weight-bold text-white"
                        data-toggle="collapse"
                        data-target="#basicsCollapseOne"
                        aria-expanded="true"
                        aria-controls="basicsCollapseOne"
                        onClick={handlerOpenDropdown}
                      >
                        <span className="pl-1 text-white">{t('menu.category')}</span>
                        <span className="text-white ml-3">
                          <span className="ec ec-arrow-down-search" />
                        </span>
                      </button>
                    </div>
                    <div
                      id="basicsCollapseOne"
                      className={`collapse vertical-menu v1 ${isOpenCategory && 'show'}`}
                      aria-labelledby="basicsHeadingOne"
                      data-parent="#basicsAccordion"
                    >
                      <div className="card-body p-0">
                        <nav className="js-mega-menu navbar navbar-expand-xl u-header__navbar u-header__navbar--no-space hs-menu-initialized">
                          <div
                            id="navBar"
                            className="collapse navbar-collapse u-header__navbar-collapse"
                          >
                            <ul className="navbar-nav u-header__navbar-nav border-primary border-top-0">
                              {masterCategory.map((main, indexMain) => {
                                return (
                                  <li
                                    key={indexMain}
                                    className="nav-item hs-has-mega-menu u-header__nav-item"
                                    onMouseEnter={openMenu}
                                    onMouseLeave={closeMenu}
                                  >
                                    <Link
                                      id="basicMegaMenu"
                                      className="nav-link u-header__nav-link u-header__nav-link-toggle"
                                      to={categoryUrl(main.name, main, null, null)}
                                      onClick={handleCloseDropdown}
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      {main.name}
                                    </Link>
                                    <div
                                      className="hs-mega-menu vmm-tfw u-header__sub-menu"
                                      aria-labelledby="basicMegaMenu"
                                    >
                                      <div className="row u-header__mega-menu-wrapper category-scrollbar">
                                        {main.major.map((major, indexMajor) => {
                                          return (
                                            <div key={indexMajor} className="col mb-3 mb-sm-0">
                                              <Link
                                                className="u-header__sub-menu-title"
                                                to={categoryUrl(major.name, main, major, null)}
                                                onClick={handleCloseDropdown}
                                              >
                                                {major.name}
                                              </Link>
                                              <ul className="u-header__sub-menu-nav-group mb-3">
                                                {major.minor.map((minor, indexMinor) => {
                                                  let borderBottom = ''
                                                  if (indexMinor === major.minor.length - 1) {
                                                    borderBottom = 'border-bottom pb-3'
                                                  }
                                                  return (
                                                    <li key={indexMinor}>
                                                      <Link
                                                        className={`nav-link u-header__sub-menu-nav-link ${borderBottom}`}
                                                        to={categoryUrl(
                                                          minor.name,
                                                          main,
                                                          major,
                                                          minor,
                                                        )}
                                                        onClick={handleCloseDropdown}
                                                      >
                                                        {minor.name}
                                                      </Link>
                                                    </li>
                                                  )
                                                })}
                                              </ul>
                                            </div>
                                          )
                                        })}
                                      </div>
                                    </div>
                                  </li>
                                )
                              })}
                            </ul>
                          </div>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FilterHeaderKeyword />
            <div className="col-md-auto d-none d-lg-block align-self-center">
              <div className="d-flex">
                <ul className="d-flex list-unstyled mb-0">
                  <li className="col">
                    <Link
                      to="/cart"
                      className="text-white position-relative d-flex"
                      data-placement="top"
                      title={t('menu.cart')}
                      onMouseOver={onMouseOver}
                      onMouseLeave={onMouseLeave}
                    >
                      <i className="font-size-22 ec ec-shopping-bag" />
                      {header_info.cart.total > 0 && (
                        <span className="width-22 height-22 bg-dark position-absolute flex-content-center text-white rounded-circle left-12 top-8 font-weight-bold font-size-12">
                          {header_info.cart.total}
                        </span>
                      )}
                    </Link>
                    {header_info.cart.total > 0 && (
                      <div
                        id="basicDropdownHover"
                        className={`cart-dropdown dropdown-menu dropdown-unfold border-top border-top-primary mt-3 border-width-2 border-left-0 border-right-0 border-bottom-0 left-auto right-0 ${
                          isOpen && 'show'
                        }`}
                        ref={shopRef}
                        aria-labelledby="basicDropdownHoverInvoker"
                        onMouseLeave={onMouseLeave}
                      >
                        <ul className="list-unstyled px-3 pt-3">
                          {header_info.cart.items.map((row, key) => {
                            return (
                              <React.Fragment key={key}>
                                <li className="border-bottom pb-3 mb-3">
                                  <div className="">
                                    <ul className="list-unstyled row mx-n2">
                                      <li className="px-2 col-auto">
                                        <img className="summary-cart" src={row.store_logo} alt="" />
                                      </li>
                                      <li className="px-2 col">
                                        <h5 className="text-blue font-size-14 font-weight-bold">
                                          {row.store_name}
                                        </h5>
                                        <span className="font-size-14">
                                          {row.total_product} {t('title.product.title')}
                                        </span>
                                      </li>
                                    </ul>
                                  </div>
                                </li>
                              </React.Fragment>
                            )
                          })}
                        </ul>
                        <div className="flex-center-between px-4 pt-0">
                          <Link
                            to="/cart"
                            className="btn btn-primary mb-3 mb-md-0 font-weight-normal btn-block px-5 px-md-4 px-lg-5"
                          >
                            {t('menu.cart')}
                          </Link>
                        </div>
                      </div>
                    )}
                  </li>
                  <li className="col pr-0">
                    <Link
                      to="/chat"
                      className="text-white position-relative d-flex"
                      data-placement="top"
                      title={t('menu.chat')}
                    >
                      <i className="font-size-22 fa fa-comments text-white" />
                      {header_info.chat_unread > 0 && (
                        <span className="width-22 height-22 bg-red position-absolute flex-content-center text-white rounded-circle left-12 top-8 font-weight-bold font-size-11">
                          {header_info.chat_unread < 100 ? header_info.chat_unread : '99+'}
                        </span>
                      )}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HeaderMain
