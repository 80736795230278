import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { showAlert } from './../../../utility/SweetAlert'
import { useSearchParams } from 'react-router-dom'

const InteractorAuthenticationOtpVerify = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const OTPVerify = useCallback(
    (payload, options, loading) => {
      payload = {
        ...payload,
      }
      const next = searchParams.get('next')
      ApiService.jsonRequest('/auth-otp/verify', payload, (response) => {
        loading(false)
        if (response.status_code === 200) {
          if (options.type === 'VERIFY-EMAIL') {
            showAlert({
              icon: 'success',
              message: t(`response_message.account_verified_please_sign_in`),
              timer: 2000,
            })
            if (next === '/' || !next) {
              navigate('/account/signin')
            } else {
              window.location = searchParams.get('next')
            }
          }
          if (options.type === 'FORGOT-PASSWORD') {
            const params = {
              next: next,
            }
            const queryString = new URLSearchParams(params).toString()
            navigate('/account/confirmation-password?' + queryString, {
              state: {
                email: options.email,
              },
            })
          }
        } else {
          showAlert({
            icon: 'error',
            message: t(`response_message.${response.messages}`),
            timer: 2000,
          })
        }
      })
    },
    [t, navigate, searchParams],
  )

  return { OTPVerify }
}

export default InteractorAuthenticationOtpVerify
