import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import ApiService from '../../../services/ApiService'
import { showAlert } from '../../../utility/SweetAlert'
import { useTranslation } from 'react-i18next'

const InteractorAccountAddressDelete = () => {
  const { t } = useTranslation()
  const { account } = useSelector((state) => state.account)
  const addressDelete = useCallback(
    (payload) => {
      payload = {
        ...payload,
        updated_by: account.full_name,
      }
      ApiService.jsonRequest('/account-user/address-delete', payload, (response) => {
        if (response.status_code === 200) {
          showAlert({
            icon: 'success',
            message: t(`response_message.data_has_been_deleted`),
            timer: 2000,
          })
        }
        window.location.reload(true)
      })
    },
    [t, account.full_name],
  )

  return { addressDelete }
}

export default InteractorAccountAddressDelete
