import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'

const Pagination = (data) => {
  const limit = data.data?.limit ?? 0
  const total_pages = data.data?.total_pages ?? 0
  const page = data.data?.page ?? 0

  const itemsPerPage = limit
  const [pageCount, setPageCount] = useState(0)

  useEffect(() => {
    setPageCount(total_pages)
  }, [itemsPerPage, total_pages])

  const handlePageClick = (event) => {
    const val = event.selected + 1
    data.onChangePage(val)
    window.scrollTo({ top: 50, behavior: 'smooth' })
  }

  const NextLabel = () => {
    return <i className="fa fa-angle-right"></i>
  }

  const PreviousLabel = () => {
    return <i className="fa fa-angle-left"></i>
  }

  return (
    <>
      <ReactPaginate
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        nextLabel={<NextLabel />}
        previousLabel={<PreviousLabel />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={data.data?.range || 5}
        pageCount={pageCount}
        renderOnZeroPageCount={null}
        containerClassName="pagination justify-content-center row"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        activeClassName="active"
        forcePage={page - 1}
      />
    </>
  )
}

export default Pagination
