import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'

const InteractorPaymentSearch = () => {
  const paymentSearch = useCallback((payload, callback, loading) => {
    ApiService.jsonRequest('/fund-payment/search', payload, (response) => {
      if (response.status_code === 200) {
        if (response.data) {
          callback(response.data)
        }
      }
      loading(false)
    })
  }, [])
  return { paymentSearch }
}

export default InteractorPaymentSearch
