import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Breadcrumb } from '../../components/Main'
import faq_id from '../../constant/faq/faq_id.json'
import { getItem } from '../../utility/LocalStorage'

const PageFaq = () => {
  const { t } = useTranslation()
  const [faq, setFaq] = useState([])
  const lng = getItem('language') ? getItem('language') : 'id'
  useEffect(() => {
    if (lng === 'id') {
      setFaq(faq_id)
    } else {
      setFaq(faq_id)
    }
  }, [lng])

  return (
    <>
      <Breadcrumb title={t('menu.faq')} />
      <div className="container">
        <div className="mb-10 text-center">
          <h1>{t('menu.faq')}</h1>
        </div>
        <div className="row mb-8">
          {faq.map((row, key) => {
            return (
              <React.Fragment key={key}>
                <div className="col-lg-6 mb-5 mb-lg-8">
                  <h3 className="font-size-18 font-weight-semi-bold text-gray-39 mb-4">
                    {row.title}
                  </h3>
                  <p className="text-justify">{row.description}</p>
                </div>
              </React.Fragment>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default PageFaq
