import React, { useState } from 'react'
import { InputText } from '../../components/Main'
import { titleMaxLength } from '../../constant/global/validation'
import { confirmationAlert } from '../../utility/SweetAlert'
import { InteractorOrderUpdate } from '../../interactors/Main'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { formatDate } from '../../utility/Helpers'
import { useSelector } from 'react-redux'

const OrderPurchaseNumberForm = (data) => {
  const date = new Date()
  const { account } = useSelector((state) => state.account)
  const { t } = useTranslation()
  const { orderUpdate } = InteractorOrderUpdate()
  const [loadingProcess, setLoadingProcess] = useState(false)
  const [orderData, setOrderData] = useState([])

  const handlePurchasRequestNumber = (e) => {
    const { value } = e.target

    if (value.length < titleMaxLength) {
      let payload = { ...orderData }
      delete payload.bill
      payload.purchase_request_number = value
      setOrderData(payload)
    }
  }

  const handlePurchaseOrderNumber = (e) => {
    const { value } = e.target

    if (value.length < titleMaxLength) {
      let payload = { ...orderData }
      delete payload.bill
      payload.purchase_order_number = value
      setOrderData(payload)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    let payloadPurchaseNumber = { ...orderData }
    payloadPurchaseNumber.created_date = formatDate(
      payloadPurchaseNumber.created_date,
      'YYYY-MM-DD HH:mm:ss',
    )
    payloadPurchaseNumber.delivery_estimated_time = formatDate(
      payloadPurchaseNumber.delivery_estimated_time,
      'YYYY-MM-DD HH:mm:ss',
    )
    payloadPurchaseNumber.payment_term_date = formatDate(
      payloadPurchaseNumber.payment_term_date,
      'YYYY-MM-DD HH:mm:ss',
    )
    payloadPurchaseNumber.updated_date = formatDate(date, 'YYYY-MM-DD HH:mm:ss')
    payloadPurchaseNumber.updated_by = account.full_name
    const confirm = await confirmationAlert({
      title: t('title.are_you_sure'),
      message:
        data.initialPurchaseNumber === 'PR'
          ? t('message.confirmation_purchase_request_number')
          : t('message.confirmation_purchase_order_number'),
      icon: 'warning',
    })
    if (confirm) {
      setLoadingProcess(true)
      orderUpdate(payloadPurchaseNumber, 'APPROVED', orderData.uid)
    }
  }

  useEffect(() => {
    if (data) {
      setOrderData(data.data)
    }
  }, [data])
  return (
    <div className="container-fluid mx-0">
      <div className="col-12 p-2 font-size-18 text-center mb-1 font-weight-bold">
        <h3>
          {data.initialPurchaseNumber === 'PR'
            ? t('title.change_purchase_request_number')
            : t('title.change_purchase_order_number')}
        </h3>
        <hr />
      </div>
      <div className="col-12">
        <div className="row flex-column flex-sm-column mb-3">
          <div className="font-weight-bold my-2">
            {data.initialPurchaseNumber === 'PR'
              ? t('title.change_purchase_request_number')
              : t('title.change_purchase_order_number')}
          </div>
          <div>
            {data.initialPurchaseNumber === 'PR' ? (
              <InputText
                onChange={handlePurchasRequestNumber}
                value={orderData.purchase_request_number ?? ''}
                name="purchase_request_number"
                placeholder={t('placeholder.purchase_request_number')}
              />
            ) : (
              <InputText
                onChange={handlePurchaseOrderNumber}
                value={orderData.purchase_order_number ?? ''}
                name="purchase_order_number"
                placeholder={t('placeholder.purchase_order_number')}
              />
            )}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center mb-3">
        {!loadingProcess ? (
          <button
            type="submit"
            className="btn btn-md btn-primary-dark font-weight-700"
            to="#"
            onClick={handleSubmit}
          >
            {t('button.save')}
          </button>
        ) : (
          <button type="submit" className="btn btn-md btn-primary font-weight-700" to="#">
            {t('title.loading')}
          </button>
        )}
      </div>
    </div>
  )
}

export default OrderPurchaseNumberForm
