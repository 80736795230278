import React from 'react'
import PropTypes from 'prop-types'
import { Header } from '../containers/Main'

const AppHeader = () => {
  return (
    <>
      <header id="header" className="u-header u-header-left-aligned-nav">
        <div className="u-header__section">
          <Header />
        </div>
      </header>
    </>
  )
}

AppHeader.propTypes = {
  layoutChat: PropTypes.bool,
}

export default AppHeader
