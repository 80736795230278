import { combineReducers } from 'redux'
import AccountReducer from './account/reducers'
import LoadingReducer from './loading/reducers'
import ProductItemSearchReducer from './product/search/reducers'
import SummaryHeaderReducer from './summary/header/reducers'

const rootReducers = combineReducers({
  loading: LoadingReducer,
  account: AccountReducer,
  productItemSearch: ProductItemSearchReducer,
  summaryHeader: SummaryHeaderReducer,
})

export default rootReducers
