import React from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Breadcrumb, MenuAccount } from '../../components/Main'

const PaymentStatus = () => {
  const { t } = useTranslation()
  const { type } = useParams()

  return (
    <>
      <Breadcrumb title={t('menu.payment_status')} className="mb-4" />
      <div className="container">
        <div className="row mb-8">
          <MenuAccount />
          <div className="col-xl-9 col-wd-9gdot5">
            <div className="flex-center-between">
              <h3 className="font-size-25 mb-0">{t('menu.payment_status')}</h3>
            </div>
            <hr />
            {type.toLowerCase() === 'success' ? (
              <>
                <div className="alert alert-green mb-3 d-flex align-items-center">
                  <i className="fas fa-check mr-2"></i>
                  <div className="font-size-18 text-grey">{t('content.payment_success')}</div>
                </div>
              </>
            ) : (
              <>
                <div className="alert alert-red mb-3 d-flex align-items-center">
                  <i className="fas fa-exclamation-triangle mr-2"></i>
                  <div className="font-size-18 text-danger">{t('content.payment_failure')}</div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default PaymentStatus
