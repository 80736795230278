import React, { useEffect, useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Breadcrumb, Loading, Pagination, ProductLatest } from '../../components/Main'
import { formatDate, prettyUrl, subStringContent } from '../../utility/Helpers'
import { InteractorContentBlogSearch } from '../../interactors/Main'

const BlogList = () => {
  const { t } = useTranslation()
  const { blogSearch } = InteractorContentBlogSearch()
  const [loading, setLoading] = useState(true)
  const [blogList, setBlogList] = useState([])
  const [payload, setPayload] = useState({
    page: 1,
    limit: 3,
    keywords: {
      text_search: '',
    },
  })

  const handleResponse = useCallback((data) => {
    setBlogList(data)
  }, [])

  useEffect(() => {
    if (!blogList.items) {
      blogSearch(payload, handleResponse, setLoading)
    }
  }, [blogList.items, blogSearch, handleResponse, payload])

  const handlePagination = (val) => {
    setLoading(true)
    let objPayload = { ...payload }
    objPayload.page = val
    blogSearch(objPayload, handleResponse, setLoading)
    setPayload(objPayload)
  }
  return (
    <>
      <Breadcrumb title={t('title.blog')} />
      {loading ? (
        <div className="d-flex justify-content-center align-items-center height-100vh">
          <Loading />
        </div>
      ) : (
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-wd">
              <div className="min-width-1100-wd">
                {blogList.items?.map((blog, idx) => (
                  <article key={idx} className="card mb-13 border-0">
                    <div className="row">
                      <div className="col-lg-4 mb-5 mb-lg-0">
                        <Link
                          to={`/blog/detail/${prettyUrl(blog.blog_title) + '/' + blog.uid}`}
                          className="d-block"
                        >
                          <img className="w-100 h-100" src={blog.media_url} alt="" />
                        </Link>
                      </div>
                      <div className="col-lg-8">
                        <div className="card-body p-0">
                          <h2 className="mb-3">
                            <Link
                              to={`/blog/detail/${prettyUrl(blog.blog_title) + '/' + blog.uid}`}
                            >
                              {blog.blog_title}
                            </Link>
                          </h2>
                          <div className="mb-3 pb-3 border-bottom">
                            <div className="list-group list-group-horizontal flex-wrap list-group-borderless align-items-center mx-n0dot5">
                              <Link
                                to={`/blog/detail/${prettyUrl(blog.blog_title) + '/' + blog.uid}`}
                                className="mx-0dot5 text-gray-5"
                              >
                                {blog.blog_category}
                              </Link>
                              <span className="mx-2 font-size-n5 mt-1 text-gray-5">
                                <i className="fas fa-circle" />
                              </span>
                              <Link
                                to={`/blog/detail/${prettyUrl(blog.blog_title) + '/' + blog.uid}`}
                                className="mx-0dot5 text-gray-5"
                              >
                                {formatDate(blog.created_date, 'DD/MM/YYYY')}
                              </Link>
                            </div>
                          </div>
                          <p>{subStringContent(blog.blog_headline, 200)}</p>
                          <div className="flex-horizontal-center">
                            <Link
                              to={`/blog/detail/${prettyUrl(blog.blog_title) + '/' + blog.uid}`}
                              className="btn btn-primary mb-md-0 font-weight-normal px-5 px-md-4 px-lg-5"
                            >
                              {t('button.read_more')}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </article>
                ))}
                {blogList?.pages && (
                  <>
                    <div className="mb-13">
                      <Pagination data={blogList.pages} onChangePage={handlePagination} />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-xl-3 col-wd">
              <ProductLatest limit={3} mode="list" />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default BlogList
