import React, { useState } from 'react'
import { inputNumber, numberFormat, numberFormatDestroy } from '../../utility/Helpers'
import { currency } from '../../constant/global/currency'

const InputCurrency = (data) => {
  const [value, setValue] = useState(numberFormat(data.value) ?? 1)
  const maximum = 50000000000

  const onChange = (e) => {
    e.preventDefault()
    const inputValue = inputNumber(numberFormatDestroy(e.target.value))

    let val = ''
    if (+inputValue >= 1 && +inputValue <= maximum) {
      val = inputValue
    } else if (inputValue > maximum) {
      val = maximum
    }
    data.onChange({
      id: data.id,
      value: val,
    })
    setValue(numberFormat(val))
  }

  const onChangeBlur = (e) => {
    e.preventDefault()
    const inputValue = numberFormatDestroy(e.target.value)
    if (inputValue === '') {
      let val = 1
      data.onChange({
        id: data.id,
        value: val,
      })
      setValue(numberFormat(val))
    }
  }

  return (
    <>
      <div className="input-group">
        <div className="input-group-append">
          <span className="btn border-radius-left p-1 pl-3 pr-2 btn-xs btn-gray-4 text-secondary font-weight-bold font-size-14">
            {currency[data.currency]}
          </span>
        </div>
        <input
          type="text"
          value={value}
          onChange={onChange}
          onBlur={onChangeBlur}
          className="pl-2 py-3 height-20 form-control"
        />
      </div>
    </>
  )
}

export default InputCurrency
