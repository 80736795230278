import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useShowMenuButton from '../../hooks/useShowMenuButton'
import { ProductItemSearch } from '../../redux/product/search/actionCreator'

const SearchMobile = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { payload } = useSelector((state) => state.productItemSearch)
  const navigate = useNavigate()
  const [keyword, setKeyword] = useState('')
  const [payloadFilter, setPayloadFilter] = useState({})
  const searchFieldRef = useRef(null)
  const [openSearch, setOpenSearch] = useShowMenuButton(searchFieldRef)

  const onChangeKeyword = (value) => {
    setKeyword(value)
    setPayloadFilter({
      ...payloadFilter,
      keywords: {
        ...payloadFilter.keywords,
        text_search: value.trim(),
      },
    })
  }

  const handleSubmitSearch = (e) => {
    e.preventDefault()
    dispatch(ProductItemSearch(payloadFilter))
    navigate('/product')
  }

  useEffect(() => {
    setPayloadFilter(payload)
  }, [payload])
  return (
    <>
      <li className="col px-2 position-static" ref={searchFieldRef}>
        <div
          id="searchClassicInvoker"
          className="font-size-22 text-white texst-lh-1 pointer"
          title={t('title.search')}
          onClick={() => setOpenSearch(!openSearch)}
        >
          <span className="ec ec-search" />
        </div>
        <div
          id="searchClassic"
          className={`dropdown-menu dropdown-unfold dropdown-menu-right left-0 mx-2 overflow-hidden ${
            openSearch && 'show'
          }`}
          aria-labelledby="searchClassicInvoker"
        >
          <form className="js-focus-state input-group px-3" onSubmit={handleSubmitSearch}>
            <input
              className="form-control"
              type="search"
              value={keyword}
              placeholder={t('placeholder.search_keyword')}
              onChange={(e) => onChangeKeyword(e.target.value)}
            />
            <div className="input-group-append">
              {/* <button
                className={`btn text-gray-60 font-weight-bolder border-0 rounded-0 px-3 py-1 bg-light text-center`}
                type="button"
              >
                <span className="font-size-16">
                  <i className="fas fa-microphone mb-1" />
                </span>
                <span className="tooltip-text">{t('title.voice_search')}</span>
              </button> */}
              <button className="btn btn-primary px-3" type="submit">
                <i className="font-size-18 ec ec-search" />
              </button>
            </div>
          </form>
        </div>
      </li>
    </>
  )
}

export default SearchMobile
