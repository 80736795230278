import React, { useEffect, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Breadcrumb, WideCardCart } from '../../components/Main'
import { InteractorQuotationCartSearch } from '../../interactors/Main'
import { useSelector } from 'react-redux'

const Cart = () => {
  const { t } = useTranslation()
  const { account } = useSelector((state) => state.account)
  const { cartSearch } = InteractorQuotationCartSearch()
  const [loading, setLoading] = useState(true)
  const [datacard, setDatacard] = useState([])
  const [payload, setPayload] = useState({
    page: 1,
    limit: 5,
    keywords: {
      account_uid: account.uid,
    },
  })

  const handleResponse = useCallback((data) => {
    setDatacard(data)
  }, [])

  useEffect(() => {
    if (!datacard.items) {
      cartSearch(payload, handleResponse, setLoading)
    }
  }, [datacard.items, handleResponse, payload, cartSearch])

  const handleFilter = (row) => {
    setLoading(true)
    cartSearch(row, handleResponse, setLoading)
    setPayload(row)
  }

  return (
    <>
      <Breadcrumb title={t('menu.cart')} className="mb-4" />
      <div className="container">
        <div className="row mb-8">
          <div className="col-xl-12">
            <div className="flex-center-between">
              <h3 className="font-size-25 mb-0">{t('menu.cart')}</h3>
            </div>
            <hr />
            <WideCardCart
              loading={loading}
              datacard={datacard}
              payload={payload}
              handleFilter={handleFilter}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Cart
