import React from 'react'
import { useTranslation } from 'react-i18next'
import FilterCategory from './FilterCategory.js'
import FilterProvince from './FilterProvince'
import { FilterRating } from './FilterRating'
import { FilterPrice } from './FilterPrice'
const FilterMainMobile = () => {
  const { t } = useTranslation()
  return (
    <>
      <div className="mb-6 border border-width-2 border-color-3 borders-radius-6">
        <ul id="sidebarNav" className="list-unstyled mb-0 sidebar-navbar view-all">
          <li>
            <div className="dropdown-title font-weight-bold">{t('title.filter')}</div>
          </li>
          <FilterCategory />
        </ul>
      </div>
      <div className="mb-6">
        <FilterProvince />
        <FilterRating />
        <FilterPrice />
      </div>
    </>
  )
}

export default FilterMainMobile
