import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FetchSummaryHeader } from '../../redux/summary/header/actionCreator'
import ApiService from '../../services/ApiService'
import { setCookies } from '../../utility/Cookies'
import { encryptCrypto } from '../../utility/Encryption'

const InteractorSummaryHeader = () => {
  const { account } = useSelector((state) => state.account)
  const dispatch = useDispatch()

  const summaryHeader = useCallback(() => {
    let summary = {
      cart: {
        total: 0,
        items: null,
      },
      chat_unread: 0,
      notification_unread: 0,
    }
    if (account) {
      const payload_cart = {
        page: 1,
        limit: 10,
        keywords: {
          account_uid: account.uid,
        },
      }
      // CART
      ApiService.jsonRequest('/rfq-cart/search', payload_cart, (response) => {
        if (response.status_code === 200) {
          const row = response.data.items
          if (row?.length > 0) {
            let item_cart = []
            row.forEach((obj) => {
              const obj_cart = {
                store_uid: obj.store_uid,
                store_logo: obj.store_logo,
                store_name: obj.store_name,
                total_product: obj.cart.length,
              }
              item_cart.push(obj_cart)
            })

            // CHAT
            ApiService.jsonRequest(
              '/communication-chat/message-summary',
              {
                chat_to_role: 'CUSTOMER',
                chat_to_reference_uid: account.uid,
              },
              (response) => {
                let chat_unread = 0
                if (response.status_code === 200) {
                  chat_unread = response.data.total_unread ?? 0
                }

                // NOTIFICATION
                ApiService.jsonRequest(
                  '/communication-notification/total-unread',
                  {
                    notification_target: 'CUSTOMER',
                    account_uid: account.uid,
                  },
                  (response) => {
                    let notification_unread = 0
                    if (response.status_code === 200) {
                      notification_unread = response.data.total_unread ?? 0
                    }
                    summary.cart = {
                      total: item_cart.length,
                      items: item_cart,
                    }
                    summary.chat_unread = chat_unread
                    summary.notification_unread = notification_unread
                    setCookies({
                      key: 'summary-header',
                      value: encryptCrypto(JSON.stringify(summary)),
                      expires: new Date().getTime() + 5 * 60 * 1000, // 5 Minutes
                    })
                    dispatch(FetchSummaryHeader(summary))
                  },
                )
              },
            )
          }
        }
      })
    }
  }, [account, dispatch])

  return { summaryHeader }
}

export default InteractorSummaryHeader
