import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import ApiService from '../../services/ApiService'
import { showAlert } from '../../utility/SweetAlert'

const InteractorStoreView = () => {
  const { t } = useTranslation()
  const storeView = useCallback(
    (payload, callback, loading) => {
      ApiService.jsonRequest('/store-account/view', payload, (response) => {
        if (response.status_code === 200) {
          if (response.data) {
            callback(response.data)
          }
        } else {
          showAlert({
            icon: 'info',
            message: t(`response_message.data_not_exist`),
            timer: 2000,
            redirect: '/product',
          })
        }
        loading(false)
      })
    },
    [t],
  )
  return { storeView }
}

export default InteractorStoreView
