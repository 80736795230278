import { useCallback } from 'react'
import ApiService from '../../../services/ApiService'
import { capitalizeFirstWord } from '../../../utility/Helpers'
import { setItem, getItem } from '../../../utility/LocalStorage'
import { Buffer } from 'buffer'
import { decryptCrypto, encryptCrypto } from '../../../utility/Encryption'

const InteractorLocationProvince = () => {
  const listProvince = useCallback((callback) => {
    const session_key = Buffer.from('list-province').toString('base64')
    let list_province = getItem(session_key) ? decryptCrypto(getItem(session_key)) : null
    if (list_province === null) {
      ApiService.jsonRequest(
        '/master-province/list',
        {
          country_uid: process.env.REACT_APP_COUNTRY_UID,
        },
        (response) => {
          if (response.status_code === 200) {
            setItem(session_key, encryptCrypto(JSON.stringify(response.data)))
            callback(response.data)
          }
        },
      )
    } else {
      callback(list_province)
    }
  }, [])

  const listProvinceFilter = useCallback((data, callback) => {
    let province_filter = []
    for (let i = 0; i < data.length; i++) {
      const row = data[i]
      if (['11', '15', '2', '6', '16', '27', '13', '10'].includes(row.uid)) {
        province_filter.push({
          uid: row.uid,
          province_name: capitalizeFirstWord(row.province_name),
        })
      }
    }
    callback(province_filter)
  }, [])
  return { listProvince, listProvinceFilter }
}

export default InteractorLocationProvince
